



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsLoader',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '64px',
    }) private size!: string

    @Prop({
        required: false,
        default: '8px',
    }) private borderWidth!: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) private overlay?: boolean

    @Prop({
        required: false,
        default: 'rgba(255, 255, 255, 0.8)',
    }) private overlayColor!: string

    @Prop({
        required: false,
        default: 'absolute',
    }) private position!: string
}
