
export class SmsCounter {
    gsm7bitChars = '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà'
    gsm7bitExChar = '\\^{}\\\\\\[~\\]|€'
    gsm7bitRegExp: RegExp
    gsm7bitExRegExp: RegExp
    gsm7bitExOnlyRegExp: RegExp
    GSM_7BIT: 'GSM_7BIT' = 'GSM_7BIT'
    GSM_7BIT_EX: 'GSM_7BIT_EX' = 'GSM_7BIT_EX'
    UTF16: 'UTF16' = 'UTF16'
    messageLength = {
        GSM_7BIT: 160,
        GSM_7BIT_EX: 160,
        UTF16: 70,
    }

    multiMessageLength = {
        GSM_7BIT: 153,
        GSM_7BIT_EX: 153,
        UTF16: 67,
    }

    constructor () {
        this.gsm7bitRegExp = RegExp('^[' + this.gsm7bitChars + ']*$')

        this.gsm7bitExRegExp = RegExp('^[' + this.gsm7bitChars + this.gsm7bitExChar + ']*$')

        this.gsm7bitExOnlyRegExp = RegExp('^[\\' + this.gsm7bitExChar + ']*$')
    }

    count = (text: string) => {
        let length, per_message, remaining
        const encoding = this.detectEncoding(text)
        length = text.length
        if (encoding === this.GSM_7BIT_EX) {
            length += this.countGsm7bitEx(text)
        }
        per_message = this.messageLength[encoding]
        if (length > per_message) {
            per_message = this.multiMessageLength[encoding]
        }
        const messages = Math.ceil(length / per_message)
        remaining = (per_message * messages) - length
        if (remaining === 0 && messages === 0) {
            remaining = per_message
        }
        return {
            encoding: encoding,
            length: length,
            per_message: per_message,
            remaining: remaining,
            messages: messages,
        }
    }

    detectEncoding = (text: string): 'GSM_7BIT' | 'GSM_7BIT_EX' | 'UTF16' => {
        switch (false) {
            case text.match(this.gsm7bitRegExp) == null:
                return this.GSM_7BIT
            case text.match(this.gsm7bitExRegExp) == null:
                return this.GSM_7BIT_EX
            default:
                return this.UTF16
        }
    }

    countGsm7bitEx = (text: string) => {
        let char2
        let _i, _len
        const _results = []
        for (_i = 0, _len = text.length; _i < _len; _i++) {
            char2 = text[_i]
            if (char2.match(this.gsm7bitExOnlyRegExp) != null) {
                _results.push(char2)
            }
        }
        return _results.length
    }
}
