





















































































































































































































































































































































































import { UserModule } from '@/store/modules/user'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsMessageModal from '@/components/VsMessageModal/Index.vue'
import { AppModule } from '@/store/modules/app'
import VsCampaignCreateModal from '@/modules/campaigns/components/CreateCampaignModal/Index.vue'
import VsEmailPreviewModal from '@/modules/campaigns/components/VsEmailPreviewModal/Index.vue'
import VsBlockedFunctionalityModal from '@/components/VsBlockedFunctionalityModal/Index.vue'
import VsMediaLibrary from '@/modules/mediaLibrary/views/Index.vue'
import { get } from 'lodash'
import VsRouterLink from '@/components/VsRouterLink/Index.vue'
import { getReputation } from '@/api/consoleApi/user'
import { pSBC } from '@/utils/colors'
import { exitImpersonate } from '@/api/userapi/users'

@Component({
    name: 'VsSideBar',
    components: {
        VsSeparator,
        VsCampaignCreateModal,
        VsEmailPreviewModal,
        VsMediaLibrary,
        VsRouterLink,
        VsBlockedFunctionalityModal,
        VsMessageModal,
    },
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    })
    private currentPage?: string

    @Prop({
        required: false,
        default: false,
    })
    private externalLibrary?: boolean

    private currentNav = ''

    private humanReputation = ''

    private comunication = false
    private grow = false
    private analyze = false
    private audience = false
    private creativity = false
    private mocks = false

    @Watch('comunication', { immediate: false })
    @Watch('grow', { immediate: false })
    @Watch('analyze', { immediate: false })
    @Watch('audience', { immediate: false })
    @Watch('creativity', { immediate: false })
    @Watch('mocks', { immediate: false })
    saveSessionStorage () {
        window.sessionStorage.setItem(this.user._id + '-sidebar-navigation', JSON.stringify({
            comunication: this.comunication,
            grow: this.grow,
            analyze: this.analyze,
            audience: this.audience,
            creativity: this.creativity,
            mocks: this.mocks,
        }))
    }

    $refs!: {
        campaignCreateModal: VsCampaignCreateModal
        mediaLibrary: VsMediaLibrary
        blockedFunctionality: VsBlockedFunctionalityModal
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get sidebarOpen () {
        return get(AppModule, 'sidebarOpen', false)
    }

    set sidebarOpen (sidebarOpen: boolean) {
        AppModule.SET_SIDEBAR_OPEN(sidebarOpen)
    }

    get isImpersonate () {
        return this.user?.impersonate || false
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get hasShop () {
        return this.user?.configuration?.rules?.shop || false
    }

    get logo () {
        return get(UserModule, 'settings.theme.logoConsole', '')
    }

    get user () {
        return UserModule.user
    }

    set user (user: any) {
        UserModule.SET_USER(user)
    }

    get username () {
        return UserModule.userCompleteName(true)
    }

    get configurationName () {
        return get(this.user, 'configuration.name', '')
    }

    get showMocks () {
        return process.env.VUE_APP_LOAD_MOCK_MODULE === 'true'
    }

    get isComunicationSection () {
        return this.currentPage === 'campaignsIndex' ||
            this.currentPage === 'AutomationLists' ||
            this.currentPage === 'SmtpPlus' ||
            this.currentPage === 'AuthorizedSenderSms' ||
            this.currentNav === 'comunication'
    }

    get isGrowSection () {
        return this.currentPage === 'FogCampaigns' ||
            this.currentPage === 'PagesCards' ||
            this.currentPage === 'Stores' ||
            this.currentNav === 'grow'
    }

    get isAnalyzeSection () {
        return this.currentNav === 'analyze'
    }

    get isAudienceSection () {
        return this.currentPage === 'listsIndex' ||
            this.currentPage === 'TagSectorial' ||
            this.currentPage === 'Campaign' ||
            this.currentPage === 'Lists' ||
            this.currentNav === 'audience'
    }

    get isMockSection () {
        return this.currentPage === 'mockIndex' ||
            this.currentNav === 'mocks'
    }

    get showManager () {
        return true
    }

    get hasManager () {
        return this.user?.configuration?.rules?.manager || false
    }

    get primaryColor () {
        return get(UserModule, 'settings.theme.colorConsole', null)
    }

    get darkerPrimaryColor () {
        if (!this.primaryColor) return ''
        return pSBC(-0.2, this.primaryColor)
    }

    get layoutCssVars () {
        // da cancellare quando non ci sarà più bisogno di buildare la sidebar
        if (this.primaryColor) {
            return `
                :root {
                    --vs-color-primary-800: ${this.primaryColor};
                    --vs-color-primary-900: ${this.darkerPrimaryColor};
                }
            `
        }
        return ``
    }

    beforeMount () {
        this.getReputation()
    }

    private toastReady (el: any) {
        if (!this.$root.$vsToast) {
            this.$root.$vsToast = el.addToast
        }
    }

    @Watch('user._id', {
        immediate: true,
        deep: true,
    })
    private getOpenNavigationFromSessionStorage () {
        if (!this.user) return
        let navigations: any = window.sessionStorage.getItem(this.user._id + '-sidebar-navigation')
        if (!navigations) return
        navigations = JSON.parse(navigations)
        this.comunication = navigations.comunication
        this.grow = navigations.grow
        this.analyze = navigations.analyze
        this.audience = navigations.audience
        this.creativity = navigations.creativity
        this.mocks = navigations.mocks
    }

    private async getReputation () {
        try {
            const resp = await getReputation()
            this.humanReputation = resp.data.human_reputation
        } catch (e) {
            console.log(e)
        }
    }

    private formatLinkParams (path: string, isBlocked?: boolean) {
        if (isBlocked) return {}
        if (!this.$router) {
            return {
                url: '/user' + path,
            }
        }
        return {
            to: {
                path,
            },
        }
    }

    private openNav (navName: string) {
        if (this.currentNav === navName) {
            this.currentNav = ''
            return
        }
        this.currentNav = navName
    }

    private newCampaign () {
        this.$refs.campaignCreateModal.openModal()
    }

    private async openMediaLibrary () {
        if (this.externalLibrary) {
            try {
                await window.openVsMediaLibrary()
            } catch (e) {
                console.log(e)
            }
        } else {
            try {
                await this.$refs.mediaLibrary.openMediaLibrary()
            } catch (e) {
                console.log(e)
            }
        }
    }

    private openBlockedFunctionality (e: any, isBlocked: boolean) {
        if (isBlocked) {
            e.preventDefault()
            // non utilizzare metodo globale fino a quando la sidebar è utilizzata a parte in console
            this.$refs.blockedFunctionality.openModal()
        }
    }

    private proReputation () {
        if (this.user?.configuration?.rules?.reputationFull) {
            window.location.href = '/app/user/reputation'
        } else {
            // non utilizzare metodo globale fino a quando la sidebar è utilizzata a parte in console
            this.$refs.blockedFunctionality.openModal()
        }
    }

    private async exitImpersonate () {
        try {
            await exitImpersonate()
            window.location.href = '/user/manager/'
        } catch (e) {
            console.log(e)
        }
    }
}
