var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-flex vs-items-center vs-gap-2 vs-overflow-hidden"},_vm._l((_vm.paths),function(path,index){return _c('div',{key:path.id,staticClass:"vs-flex vs-items-center vs-gap-2",class:{
            'vs-overflow-hidden': (index + 1) === _vm.paths.length
        }},[((index !== 0 && _vm.isVisiblePath(index)) || (!_vm.isVisiblePath(index) && index === 1))?_c('VsIcon',{staticClass:"vs-flex-none",attrs:{"size":"4","name":"chevron-right"}}):_vm._e(),(_vm.isVisiblePath(index))?_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                boundary: 'window',
                appendTo: 'parent',
                placement: 'bottom',
            }),expression:"{\n                boundary: 'window',\n                appendTo: 'parent',\n                placement: 'bottom',\n            }"}],staticClass:"vs-truncate vs-overflow-hidden vs-relative",class:{
                'vs-root-crumb': (index + 1) !== _vm.paths.length,
                'vs-current-crumb': (index + 1) === _vm.paths.length,
                'vs-text-grey-600': _vm.paths.length === (index + 1),
                'vs-cursor-pointer': _vm.paths.length !== (index + 1)
            },attrs:{"title":_vm.paths.length === (index + 1) ? path.name : ''},on:{"click":function($event){_vm.paths.length === (index + 1) ? '' : _vm.pathSelected(path)}}},[_vm._v(" "+_vm._s(path.name)+" ")]):_vm._e(),(index === 1 && _vm.paths.length > _vm.visiblePaths)?_c('div',{staticClass:"vs-flex-none",staticStyle:{"position":"relative"}},[_c('VsPopover',{staticClass:"vs-bread-crumb-menu",attrs:{"boundary":"window","appendTo":"parent"},on:{"dropdown-close":function($event){_vm.hover = false}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('VsIcon',{ref:"breadcrumbDropdown",refInFor:true,staticClass:"vs-flex-none vs-cursor-pointer",attrs:{"size":"4","name":"more-horizontal"}})]},proxy:true},{key:"default",fn:function(ref){
            var closePopover = ref.closePopover;
return [_c('div',{on:{"click":closePopover}},[_vm._l((_vm.paths),function(patho,ind){return [(!_vm.isVisiblePath(ind))?_c('VsActionItem',{key:patho.id,attrs:{"label":patho.name},on:{"click":function($event){return _vm.pathSelected(patho)}}}):_vm._e()]})],2)]}}],null,true),model:{value:(_vm.breadcrumbDropdownIsOpen),callback:function ($$v) {_vm.breadcrumbDropdownIsOpen=$$v},expression:"breadcrumbDropdownIsOpen"}})],1):_vm._e()],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }