


























import { Component, Prop, Vue } from 'vue-property-decorator'

export interface IPercentage {
    color?: string
    partialValue: number
}

@Component({
    name: 'VsPercentageBar',
    components: {},
})
export default class extends Vue {
    @Prop({
        default: '8px',
        required: false,
    }) height!: string

    @Prop({
        default: () => [],
        required: true,
    }) percentages!: IPercentage[]

    @Prop({
        default: 100,
        required: false,
    }) totalValue!: number

    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) dark!: boolean

    get colors () {
        return [
            'var(--vs-color-primary-800)',
            'var(--vs-color-warning-500)',
            'var(--vs-color-success-500)',
        ]
    }

    get percentagesBars () {
        const percentagesBasrs = []
        for (const percentageIndex in this.percentages) {
            const percentage = this.percentages[percentageIndex]
            let valuePercentage = this.calculatePercentage(percentageIndex)
            let color = this.getPresetColors(percentageIndex)
            if (valuePercentage <= -1) {
                valuePercentage = 100
                color = this.colors[0]
            }
            percentagesBasrs.unshift({
                ...percentage,
                width: valuePercentage + '%',
                color: percentage.color || color,
            })
        }
        return percentagesBasrs
    }

    private getPresetColors (index: any) {
        return this.colors[index] || this.colors[1]
    }

    private calculatePercentage (index: any) {
        let total = 0
        for (let i = 0; i <= index; i++) {
            const currentIndex = index - i
            if (!this.percentages[currentIndex]) return 0
            const partialValue = this.percentages[currentIndex].partialValue
            const percentage = (100 * partialValue) / this.totalValue
            total += percentage
        }
        if (total > 100) total = 100
        return total
    }
}
