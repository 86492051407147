import { serviceShopapi } from '@/utils/request'
import { CancelToken } from 'axios'
import {
    CreateCartDto,
    CreateCheckoutSessionDto,
    AddItemsToCartDto,
    UpdateCartItemDto,
} from './types/shop'

export const getPricelistByIdAndQuery = (id: string, params?: any, cancelToken?: CancelToken) =>
    serviceShopapi({
        url: '/pricelists/' + id,
        method: 'get',
        params,
        cancelToken,
    })

export const getActiveCartUpcoming = (params?: { applyProrationBehavior?: boolean, cartId: string }, cancelToken?: CancelToken) =>
    serviceShopapi.get('/checkout/subscription/invoice-upcoming', {
        params,
        cancelToken,
    })

export const updateSubscription = (cartId: string, cancelToken?: CancelToken) =>
    serviceShopapi({
        url: '/checkout/subscription',
        data: { cartId },
        method: 'patch',
        cancelToken,
    })

export const renewSubscription = (cartId: string, cancelToken?: CancelToken) =>
    serviceShopapi({
        url: '/checkout/renew-subscription',
        data: { cartId },
        method: 'patch',
        cancelToken,
    })

export const getUserUpcomingInvoice = (cancelToken?: CancelToken) =>
    serviceShopapi({
        url: '/users/invoice-upcoming',
        method: 'get',
        cancelToken,
    })

export const createCart = (data: CreateCartDto, cancelToken?: CancelToken) =>
    serviceShopapi.post(`/carts`, data, { cancelToken })

export const createDriveToCart = (type: 'yearly' | 'upgrade', cancelToken?: CancelToken) =>
    serviceShopapi.post(`/carts/drive-to/${type}`, {}, { cancelToken })

export const addItemsToCart = (cartId: string, data: AddItemsToCartDto, cancelToken?: CancelToken) =>
    serviceShopapi.post(`/carts/${cartId}/items`, data, { cancelToken })

export const updateCartItem = (cartId: string, itemId: string, data: UpdateCartItemDto, cancelToken?: CancelToken) =>
    serviceShopapi.patch(`/carts/${cartId}/items/${itemId}`, data, { cancelToken })

export const removeItemToCart = (cartId: string, itemId: string, cancelToken?: CancelToken) =>
    serviceShopapi.delete(`/carts/${cartId}/items/${itemId}`, { cancelToken })

export const deleteAllCartItems = (cartId: string, cancelToken?: CancelToken) =>
    serviceShopapi.delete(`/carts/${cartId}/items`, { cancelToken })

export const deleteUserSubscription = (cancelToken?: CancelToken) =>
    serviceShopapi.delete(`/users/subscription`, { cancelToken })

export const getActiveCart = () =>
    serviceShopapi({
        url: '/carts/status/active',
        method: 'get',
    })

export const checkoutSession = (data: CreateCheckoutSessionDto, cancelToken?: CancelToken) =>
    serviceShopapi.post(`/checkout/session`, data, { cancelToken })

export const createPendingOrder = (data: any) =>
    serviceShopapi({
        url: '/orders',
        method: 'post',
        data,
    })

export const getShopUser = () =>
    serviceShopapi({
        url: '/users/me',
        method: 'get',
    })

export const getTaxRate = () =>
    serviceShopapi({
        url: '/common/tax',
        method: 'get',
    })

export const getPricesAddable = () =>
    serviceShopapi({
        url: '/users/prices-addable',
        method: 'get',
    })

export const getPriceList = () =>
    serviceShopapi({
        url: '/users/pricelist',
        method: 'get',
    })

export const getInvoiceData = () =>
    serviceShopapi({
        url: '/users/invoice-data',
        method: 'get',
    })

export const createOrUpdateInvoicesData = (data: any) =>
    serviceShopapi({
        url: '/users/invoice-data',
        data,
        method: 'patch',
    })

export const createInvoiceData = (data: any) =>
    serviceShopapi({
        url: '/users/invoice-data',
        data,
        method: 'post',
    })

export const customerPortal = (data: {
    returnUrl: string
}) =>
    serviceShopapi({
        url: '/users/customer-portal',
        data,
        method: 'post',
    })

export const getIsLegacyUser = async () => {
    try {
        const resp = await serviceShopapi({
            url: '/users-subscription-legacy/me',
            method: 'get',
        })
        return !!resp.data
    } catch (e) {
        return false
    }
}

export const createLegacyUser = (data: {startAt: number}) =>
    serviceShopapi({
        url: '/users-subscription-legacy/subscription',
        data,
        method: 'post',
    })

export const getLegacyUpcomingInvoice = () =>
    serviceShopapi({
        url: '/users-subscription-legacy/subscription-invoice-upcoming',
        method: 'get',
    })
