














































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { CustomField } from '@/utils/customFields'
import VsEmailSenderCard from '@/modules/campaigns/components/VsEmailSenderCard/Index.vue'
import VsEmailSubjectCard from '@/modules/campaigns/components/VsEmailSubjectCard/Index.vue'
import VsEmailAdvancedCard from '@/modules/campaigns/components/VsEmailAdvancedCard/Index.vue'
import EditNameModal from '@/modules/workflows/components/EditNameModal/Index.vue'
import VsEmailContentCard from '@/modules/campaigns/components/VsEmailContentCard/Index.vue'
import VsSmsContentCard from '@/modules/campaigns/components/VsSmsContentCard/Index.vue'
import VsSmsSenderCard from '@/modules/campaigns/components/VsSmsSenderCard/Index.vue'
import VsPhonePreview from '@/modules/campaigns/components/VsPhonePreview/Index.vue'
import { getEmail, updateEmail } from '@/api/consoleApi/contents/emails'
import { IMessageEmail, IMessageSms } from '@/api/consoleApi/dto/campaigns.dto'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import { getListCustomFields } from '@/api/consoleApi/recipients'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getSms } from '@/api/consoleApi/contents/sms'
import { getCampaign } from '@/api/consoleApi/campaigns'
import { getWorkflowMessage, updateWorkflowMessage } from '@/api/consoleApi/workflows'
import axios from 'axios'
import { get } from 'lodash'
import { SmsCounter } from '@/utils/smsCounter'

@Component({
    name: 'VsMessageModal',
    components: {
        VsContainer,
        VsSectionHeader,
        VsFullTopBarLayout,
        VsLoader,
        EditNameModal,
        VsConfirm,
        VsEmailSenderCard,
        VsEmailSubjectCard,
        VsEmailContentCard,
        VsEmailAdvancedCard,
        VsSmsContentCard,
        VsSmsSenderCard,
        VsSidebarLayout,
        VsPhonePreview,
        // VsDropdownButton,
    },
})
export default class extends Vue {
    private open = false
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) disableGet!: boolean
    private loading = false
    private customFields: CustomField[] = []
    public email: IMessageEmail | null = null
    private sms: IMessageSms | null = null
    private messageId: any = ''
    private containerType: 'campaignSplit' | 'campaign' | 'automation' | '' = ''
    private containerId = ''
    private workflowMessageId: any = ''
    private selectedLists = []
    private listId = ''
    private tabOpen = ''
    private emailError = false
    private missingSteps: any[] = []
    private contentType = ''
    private confirmPromiseResolve: any = null
    private confirmPromiseReject: any = null
    title = ''
    private smsCounter = new SmsCounter()

    $refs: any

    advancedSettings: any = {
        id: null,
        settings: {
            google_analytics_settings: null,
            goal_tracking: false,
            ecommerce_tracking: {
                active: false,
                ttl: 7,
            },
        },
    }

    advanceOptionCallback: any = false

    get previewCounter () {
        if (!this.sms?.body) return false
        return this.smsCounter.count(this.sms.body)
    }

    get contentMode () {
        // in futuro gestire anche il mode senza container ma con solo il messaggio
        if (this.containerType === 'campaignSplit') {
            return 'campaign'
        }
        return this.containerType || 'campaign'
    }

    mounted () {
        // to delete when send preview of console would be removed
        window.openMessageModal = this.openModal
    }

    setAdvancedSettings (value: any) {
        this.advancedSettings = value
    }

    private calculateMissingSteps () {
        const missingSteps = []
        if (this.$refs.vsEmailSenderCard && (this.$refs.vsEmailSenderCard.senderCardStatus === 'error' || this.$refs.vsEmailSenderCard.senderCardStatus === 'default')) missingSteps.push('sender')
        if (this.$refs.VsEmailSubjectCard && this.$refs.VsEmailSubjectCard.subjectCardStatus === 'default') missingSteps.push('subject')
        if (this.$refs.vsEmailContentCard && (this.$refs.vsEmailContentCard.campaignContentStatus === 'error' || this.$refs.vsEmailContentCard.campaignContentStatus === 'default')) missingSteps.push('content')
        this.missingSteps = missingSteps
    }

    emailHasError () {
        this.calculateMissingSteps()
        return this.missingSteps.length > 0
    }

    public async openModal (data: {
        messageId: any
        contentType: string
        containerId?: any
        containerType?: 'campaignSplit' | 'campaign' | 'automation' | ''
        title?: string
        listId?: any
        workflowMessageId?: any
    }) {
        this.loading = true
        this.title = data.title || ''
        this.email = null
        this.sms = null
        this.contentType = data.contentType
        this.containerType = data.containerType || ''
        this.containerId = data.containerId
        this.messageId = data.messageId
        this.workflowMessageId = data.workflowMessageId
        this.listId = data.listId || null

        if (this.contentType === 'email') {
            await this.getEmail()
        }
        if (this.contentType === 'sms') {
            await this.getSms()
        }

        if (this.listId) {
            this.getListCustomFields()
        }

        await this.getContainerOptions()

        this.open = true
        this.loading = false
        return new Promise((resolve, reject) => {
            this.confirmPromiseResolve = resolve
            this.confirmPromiseReject = reject
        })
    }

    public closeModal () {
        this.confirmPromiseResolve()
        this.$emit('save')
        this.open = false
    }

    private openTab (tabName: string) {
        this.tabOpen = tabName
    }

    async getListCustomFields () {
        try {
            const res = await getListCustomFields(this.listId, { limit: 10000 })
            this.customFields = res.data.data
        } catch (e) {
            this.customFields = []
        }
    }

    private async getContainerOptions () {
        if (!this.containerType || !this.containerId) return
        if (this.containerType === 'campaign' || this.containerType === 'campaignSplit') {
            try {
                const resp = await getCampaign(this.containerId)
                this.advancedSettings = resp.data.data
            } catch (e) {
                console.log(e)
            }
        }
        if (this.containerType === 'automation') {
            try {
                const resp = await getWorkflowMessage(this.containerId, this.workflowMessageId)
                this.advancedSettings.id = this.containerId
                this.advancedSettings.settings.ecommerce_tracking.active = resp.data.data?.settings?.tracking?.ecommerce?.active || false
                this.advancedSettings.settings.ecommerce_tracking.ttl = resp.data.data?.settings?.tracking?.ecommerce?.ttl || 7
            } catch (e) {
                console.log(e)
            }
        }
    }

    private async getEmail () {
        this.emailError = false
        this.$emit('message-updated', this.messageId)
        if (this.disableGet) return
        try {
            const resp = await getEmail(this.messageId)
            this.email = resp.data.data
        } catch (e) {
            this.email = null
        }
    }

    private async getSms () {
        try {
            this.emailError = false
            const resp = await getSms(this.messageId)
            this.sms = resp.data.data
        } catch (e) {
            this.sms = null
        }
    }

    saveName () {
        this.$refs.editNameModal.openModal(this.title)
    }

    async updateName (e: any) {
        try {
            if (this.containerType === 'campaignSplit') {
                await updateEmail(this.messageId, {
                    name: e.name,
                })
            }

            if (this.containerType === 'automation') {
                await updateWorkflowMessage(this.containerId, this.workflowMessageId, {
                    name: e.name,
                })
            }

            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Nome del messaggio modificato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.title = e.name
            this.$emit('message-updated', this.messageId)
        } catch (e) {
            const toast = this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante la modifica del nome del messaggio',
                aspect: VsToastAspectEnum.alert,
            })
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    const message = get(e, 'response.data.message.name', [''])
                    if (message[0] === 'The name may not be greater than 250 characters.') {
                        toast.heading = this.$t('campaigns.editCampaign.toasts.maxLengthCampaignNameError')
                    }
                }
            }
            console.log(e)
        }
    }
}
