import Mixpanel from 'mixpanel-browser'
import { get } from 'lodash'

const init = (data: any, config: any) => {
    const apiKey = get(config, 'mixpanelApikey', false)
    if (apiKey) {
        Mixpanel.init(apiKey)
        Mixpanel.identify(data._id)
        console.log('mixpanel-loaded')
        return Mixpanel
    }
}

export default {
    init,
}
