// import 'nprogress/nprogress.css'
import Router, { Route } from 'vue-router'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { routeHasPermission, hasPermission } from '@/utils/permission'
import { me } from '@/api/userapi/users'
import { resetRouter } from './router'
import i18n from '@/lang' // Internationalization
// import settings from './settings'
import {
    isPendingEnabled,
    isEnabled,
    isDisabled,
    isPendingActivation,
} from '@/utils/users'
import { get } from 'lodash'
import { getIsLegacyUser, getShopUser } from './api/shop'
import moment from 'moment'
import usercom from '@/utils/usercom'

// const getPageTitle = (key: string) => {
//     // .te non è un errore è una funzione che controlla se esiste la stringa invece che stamparla. returna bool
//     const hasKey = i18n.te(`route.${key}`)
//     if (hasKey) {
//         const pageName = i18n.t(`route.${key}`)
//         return `${pageName} - ${settings.title}`
//     }
//     return `${settings.title}`
// }

const goToLogin = (next: any, query?: any) => {
    if (hasPermission(AppModule.integrationPermissions, 'route-login')) {
        next({ name: 'loginIndex', query })
    } else {
        window.location.href = '/app/user'
    }
}

const goToRegistration = (next: any, query?: any) => {
    if (hasPermission(AppModule.integrationPermissions, 'route-signup')) {
        next({ name: 'registration', query })
    } else {
        window.location.href = '/app/user'
    }
}

const checkLoggedinStatusRedirect = (to: Route, _: Route, next: any) => {
    if (isDisabled(UserModule.user)) {
        if (to.name === 'disabledUser') {
            next()
        } else {
            next({ name: 'disabledUser' })
        }
        return
    }
    if (isPendingActivation(UserModule.user)) {
        if (to.name === 'pendingActivation') {
            next()
        } else {
            next({ name: 'pendingActivation' })
        }
        return
    }
    if (isPendingEnabled(UserModule.user)) {
        if (to.name === 'activationIndex') {
            next()
        } else {
            next({ name: 'activationIndex' })
        }
        return
    }

    if (hasPermission(AppModule.integrationPermissions, 'route-activation') && isPendingEnabled(UserModule.user)) {
        if (to.name === 'activationIndex') {
            next()
        } else {
            next({ name: 'activationIndex' })
        }
    }
}

const isNotLoggedinRoute = (to: any) => {
    return !!to.meta.notLoggedinRoute
}

export const isLegacyPlanToConvert = () => {
    if (
        UserModule.user &&
        UserModule.user.endAt &&
        UserModule.user.startAtLegacy &&
        UserModule.user.configuration.rulesV2.expiredPolicy === 'start_at' &&
        UserModule.shopUser &&
        !UserModule.shopUser.subscription &&
        UserModule.isLegacyUser
    ) {
        const endDate = moment(UserModule.user.endAt)
        const now = moment()
        const dayDiff = endDate.diff(now, 'days')
        return dayDiff <= 45 && dayDiff > 15
    }

    return false
}

const loggedinRedirect = (to: Route, _: Route, next: any) => {
    // check integration permissions
    if (!isEnabled(UserModule.user)) {
        checkLoggedinStatusRedirect(to, _, next)
        return
    }

    if (isLegacyPlanToConvert()) {
        if (to.name === 'automaticPayment') {
            next()
        } else {
            next({ name: 'automaticPayment' })
        }
        return
    }

    if (
        !routeHasPermission(AppModule.integrationPermissions, 'integrationPermissions', to) ||
        !routeHasPermission([UserModule.user.status], 'userStatusPermissions', to) ||
        isNotLoggedinRoute(to)
    ) {
        next({ name: 'dashboard' })
        return
    }

    next()
}

const setFavicon = () => {
    let favicon: any = document.querySelector('link[rel~="icon"]')
    if (!favicon) {
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(favicon)
    }
    favicon.href = AppModule.consoleConf?.theme.logoFavicon
}

const retriveUserData = async () => {
    const resp = await me()
    return resp.data
}

export const setRouterMiddlewares = (router: Router) => {
    router.beforeEach(async (to: Route, _: Route, next: any) => {
        if (AppModule.pageAbortController) {
            AppModule.pageAbortController.abort()
        }
        AppModule.SET_PAGE_ABORT_CONTROLLER(new AbortController())
        await AppModule.loadAppConfig()
        setFavicon()
        if (UserModule.isLoggedIn) {
            loggedinRedirect(to, _, next)
        } else {
            try {
                const user = await retriveUserData()
                let shopUser = null
                try {
                    const resp = await getShopUser()
                    shopUser = resp.data
                } catch (e) {
                    console.log(e)
                }
                const isLegacyUser = await getIsLegacyUser()
                // if not catch me() call you are logged in
                UserModule.loginUser({ user, shopUser, isLegacyUser })
                await UserModule.setUserSettings(AppModule.consoleConf)
                resetRouter()
                loggedinRedirect(to, _, next)
                return
            } catch (e) {
                console.log(e)
            }
            // if you are here you are not logged in
            const publicPage = get(to, 'meta.public', false)
            if (publicPage) {
                if (!routeHasPermission(AppModule.integrationPermissions, 'integrationPermissions', to)) {
                    next({ name: 'dashboard' })
                    return
                }
                // In the public page, go directly
                next()
            } else {
                if (to?.query?.['redirect-destination'] === 'signup') {
                    goToRegistration(next, {
                        redirect: window.location.href,
                    })
                } else {
                    // Other pages that do not have permission to access are redirected to the login page.
                    goToLogin(next, {
                        redirect: window.location.href,
                    })
                }
            }
        }
    })

    router.afterEach((to: Route) => {
        // set page title
        let title = get(AppModule, 'consoleConf.productName', window.location.host) || window.location.host
        const routeTitle = i18n.te(`route.${to.name}`)
        if (routeTitle) title += ' - ' + i18n.t(`route.${to.name}`)
        document.title = title
        // Finish app loading
        AppModule.SET_LOADING_SCREEN(false)
        AppModule.SET_SIDEBAR_OPEN(false)
        usercom.trackPageView()
    })
}
