import moment from 'moment'
import i18n from '@/lang'

export const isValidDate = (date: string | Date | number) => {
    return moment(date).isValid()
}

export const formatDate = (date: string | Date | number, format?: string, defult?: string): string | Date | number => {
    if (!isValidDate(date)) return defult || date
    return moment(date).format(format || 'DD MMM YYYY - HH:mm')
}

export const formatUnixDate = (date: number, format?: string): string => {
    if (!isValidDate(date)) return '-'
    return moment.unix(date).format(format || 'DD MMM YYYY - HH:mm')
}

export const formatFromNow = (date: string | Date): string | Date => {
    if (!isValidDate(date)) return date

    const now = moment()
    return moment(date).from(now)
}

export const formatFromNowUnix = (date: number): string | Date => {
    const now = moment()
    return moment.unix(date).from(now)
}

export const formatNumberInfinite = (number: number) => {
    if (number < 0) return 'illimitato'
    return number.toLocaleString(undefined, { maximumFractionDigits: 2 })
}

export const formatNotNegativeNumber = (number: number) => {
    if (number < 0) return 0
    return number.toLocaleString(undefined, { maximumFractionDigits: 2 })
}

export const formatPercentage = (number: number) => {
    if (number < 0) return 0
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'
}

export const formatNumber = (number: number) => {
    return number.toLocaleString(undefined, { maximumFractionDigits: 2 })
}

export const zeroToDashValue = (value: number) => {
    if (!value && value !== 0) {
        return '-'
    } else {
        return formatPercentage(value)
    }
}

export const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value)
}
export const formatCurrencyNoZero = (value: number) => {
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(value)
}

export const formatNumberWithThousandsSeparator = (value: any) => {
    return parseFloat(value).toLocaleString('it-IT', { maximumFractionDigits: 2 })
}

export const formatBytes = (bytes: number, decimals = 2, size = ''): string => {
    if (bytes === 0) return size ? `0 ${size}` : '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let i = Math.floor(Math.log(bytes) / Math.log(k))
    const indSize = sizes.indexOf(size)
    if (indSize > -1) {
        i = indSize
    }

    return formatNumber(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) + ' ' + sizes[i]
}

export const formatKbToMb = (kbytes: number, decimals = 2): string => {
    if (kbytes === 0) return '0 MB'

    const m = 1000
    const dm = decimals < 0 ? 0 : decimals

    return formatNumber(parseFloat((kbytes / m).toFixed(dm))) + ' MB'
}

export const calculateBytes = (bytes: number, decimals = 2, size = ''): number => {
    if (bytes === 0) return 0

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let i = Math.floor(Math.log(bytes) / Math.log(k))
    const indSize = sizes.indexOf(size)
    if (indSize > -1) {
        i = indSize
    }

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
}

export const convertMsToTime = (milliseconds: number) => {
    let seconds = Math.floor(milliseconds / 1000)
    if (seconds === 0) return i18n.t('general.lessThanOneSecond')
    let minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)

    seconds = seconds % 60
    minutes = minutes % 60

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you `24:00:00` instead of `00:00:00`
    // or `36:15:31` instead of `12:15:31`, etc.
    // hours = hours % 24

    return `${hours ? i18n.tc('general.time.hours', hours) : ''} ${minutes ? i18n.tc('general.time.minutes', minutes) : ''} ${seconds ? i18n.tc('general.time.seconds', seconds) : ''}`
}
