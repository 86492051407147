import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'

const whatsappRouter: RouteConfig[] = [
    {
        path: '/whatsapp',
        redirect: { name: 'templates' },
        name: 'whatsapp',
        component: VsLayouts,
        children: [
            {
                path: 'templates',
                name: 'whatsappTemplates',
                component: () => import(/* webpackChunkName: "whatsapp-templates" */ './../views/templates.vue'),
                meta: {
                    hidden: true,
                },
            },
        ],
    },
]

export default whatsappRouter
