/* eslint-disable max-len */
const developers = {
    title: 'Sviluppatori',
    tabs: {
        apikey: 'API Key',
        integrations: 'Integrazioni',
        goalTracking: 'Goal Tracking',
    },
    apikey: {
        searchPlaceholder: 'Cerca...',
        apiCopiedSuccess: 'API key copiata correttamente',
        apiCopiedError: 'API key non copiata',
        createApikeyModal: {
            title: 'Crea API Key',
        },
        noteCaption: 'Aggiungi una nota per la API Key per scopi amministrativi. In questo modo è possibile distinguere tra differenti API Key.',
        ipCaption: 'È possibile limitare l\'accesso alla tua API Key da uno specifico indirizzo IP. Lascia vuoto se non si vuole limitare l\'accesso.',
        copyToClipboard: 'Copia negli appunti',
    },
    goalTracking: {
        title: 'Goal tracking',
        description: 'Per attivare il Goal Tracking inserisci questo codice nell\'header del tuo sito o della landing page che vuoi monitorare.',
        copy: 'Copia codice',
    },
    integrations: {
        index: {
            title: 'Integrazioni',
            subtitle: '4Dem è facilmente integrabile con tutti i principali CMS e E-commerce.',
            description: 'Scopri tutte le integrazioni. Scarica i plugin, le guide ed esplora le librerie API per creare le tue integrazioni custom.',
            goToSite: 'Vai al sito',
            integrateNow: 'Integrati ora',
            prestashopTitle: 'Prestashop',
            prestashopDescription: 'Permette di mantenere la console sincronizzata con il tuo sito Prestashop. Il livello di integrazione e i dati sincronizzabili dipendono dalla configurazione del tuo sito e dalle caratteristiche del tuo account.',
            wooCommerceTitle: 'WooCommerce',
            wooCommerceDescription: 'Permette di mantenere la console sincronizzata con il tuo sito WooCommerce. I livelli di integrazione e i dati sincronizzabili dipendono dalla configurazione del tuo sito e dalle caratteristiche del tuo account.',
            magentoTitle: 'Magento',
            magentoDescription: 'Permette di sincronizzare gli utenti di un sito internet Magento con una lista in console. Il plug-in mette a disposizione anche un Widget per le iscrizioni di utenti senza account Magento.',
            wordPressTitle: 'WordPress',
            wordPressDescription: 'Permette di mantenere la console sincronizzata con il tuo sito Wordpress. Il livello di integrazione e i dati sincronizzabili dipendono dalla configurazione del tuo sito e dalle caratteristiche del tuo account.',
            contactFormTitle: 'Contact Form 7',
            contactFormDescription: 'Permette di integrare le liste della tua console con un sito web tramite form di contatto CF7. Il livello di integrazione e i dati sincronizzabili dipendono dalle caratteristiche del tuo account.',
            tilbyTitle: 'Tilby',
            tilbyDescription: 'L\'integrazione con Tilby crea due liste, una email e una sms, collegate al tuo registratore di cassa. Alla creazione o modifica di un cliente le informazioni vengono sincronizzate nelle liste newsletters.',
            facebookTitle: 'Facebook Form',
            facebookDescription: 'Consente di sincronizzare un Form Facebook (Facebook Lead Ads) con una lista della tua console. Il livello d\'integrazione dipende dalla configurazione del tuo piano 4Dem e del tuo account di Zapier.',
            zapierTitle: 'Zapier',
            zapierDescription: 'Consente di sincronizzare una lista della tua console con più di 1,500 app tramite Zapier. Il livello d\'integrazione dipende dalla configurazione del tuo piano 4Dem e dal tuo account di Zapier.',
            leadChampionTitle: 'Lead Champion',
            leadChampionDescription: 'Permette di sincronizzare i potenziali lead raccolti tramite Lead Champion con una lista del tuo account per effettuare attività di permission marketing. Il livello d\'integrazione dipende dalla configurazione del tuo account.',
            eventbriteTitle: 'Eventbrite',
            eventbriteDescription: 'Consente di sincronizzare una lista di 4Dem con i partecipanti di un evento creato su Eventbrite. Il livello d\'integrazione dipende dalla configurazione del tuo piano 4Dem e del tuo account Eventbrite.',
            googleSheetsTitle: 'Google Sheets',
            googleSheetsDescription: 'Consente di sincronizzare i contatti di un foglio Google sheets con una lista della tua console. Il livello d\'integrazione dipende dalla configurazione del tuo piano 4Dem e del tuo account di Zapier.',
            shopifyTitle: 'Shopify',
            shopifyDescription: 'Consente di sincronizzare i clienti del tuo sito Shopify con una o più liste della tua console. Il livello d\'integrazione dipende dalla configurazione del tuo piano 4Dem e del tuo account di Zapier.',
            googleFormTitle: 'Google Form',
            googleFormDescription: 'Consente di sincronizzare le email e le risposte di un modulo Google con una lista della tua console. Il livello d\'integrazione dipende dalla configurazione del tuo piano 4Dem e del tuo account di Zapier.',
        },
    },
}
export default {
    developers,
}
