import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'
import { userStatusEnum } from '@/utils/users'

const loginRouter: RouteConfig[] = [
    {
        path: '/activation',
        name: 'activation',
        redirect: {
            name: 'activationIndex',
        },
        component: VsLayouts,
        meta: {
            hidden: true,
            integrationPermissions: ['route-activation'],
        },
        children: [
            {
                path: '/',
                name: 'activationIndex',
                component: () => import(/* webpackChunkName: "activation" */ '@/modules/activation/views/activation.vue'),
                meta: {
                    hidden: true,
                    userStatusPermissions: [userStatusEnum.PENDING_ENABLED],
                    integrationPermissions: ['route-activation'],
                    layout: LayoutsEnum.vsAuth,
                },
            },
        ],
    },
]

export default loginRouter
