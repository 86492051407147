/* eslint-disable max-len */
const sms = {
    status: {
        queued: 'Pianificato',
        sent: 'In uscita',
        delivered: 'Inviato',
        failed: 'Fallito',
        enabled: 'Bozza',
        default: 'Bozza',
    },
    foundSms: '{count} trovato | {count} trovati',
    noResult: '0 sms trovati',
}
export default {
    sms,
}
