/* eslint-disable */
export default {
  'alpha': 'Il campo {_field_} può contenere solo caratteri alfabetici',
  'alpha_num': 'Il campo {_field_} può contenere solo caratteri alfanumerici',
  'alpha_dash': 'Il campo {_field_} può contenere caratteri alfa-numerici così come lineette e trattini di sottolineatura',
  'alpha_spaces': 'Il campo {_field_} può contenere solo caratteri alfanumerici così come spazi',
  'between': 'Il campo {_field_} deve essere compreso tra {min} e {max}',
  'confirmed': 'Il campo {_field_} non corrisponde con {target}',
  'digits': 'Il campo {_field_} deve essere numerico e contenere esattamente {length} cifre',
  'dimensions': 'Il campo {_field_} deve essere {width} x {height}',
  'email': 'Il campo {_field_} deve essere un indirizzo email valido',
  'excluded': 'Il campo {_field_} deve avere un valore valido',
  'ext': 'Il campo {_field_} deve essere un file valido',
  'image': 'Il campo {_field_} deve essere un\'immagine',
  'integer': 'Il campo {_field_} deve essere un numero',
  'is_not': 'Il campo {_field_} non è valido',
  'length': 'La lunghezza del campo {_field_} deve essere {length}',
  'max_value': 'Il campo {_field_} deve essere minore o uguale a {max}',
  'max': 'Il campo {_field_} non può essere più lungo di {length} caratteri',
  'mimes': 'Il campo {_field_} deve avere un tipo di file valido',
  'min_value': 'Il campo {_field_} deve essere maggiore o uguale a {min}',
  'min': 'Il campo {_field_} deve avere almeno {length} caratteri',
  'numeric': 'Il campo {_field_} può contenere solo caratteri numerici',
  'oneOf': 'Il campo {_field_} deve avere un valore valido',
  'regex': 'Il campo {_field_} non ha un formato valido',
  'required': 'Il campo {_field_} è richiesto',
  'required_if': 'Il campo {_field_} è richiesto',
  'size': 'Il campo {_field_} deve essere inferiore a {size}KB',
}
