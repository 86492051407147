











































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsTopbar from './../components/VsTopbar/Index.vue'
import VsSidenav from './../components/VsSidenav/Index.vue'
import MyFiles from './MyFiles.vue'
import Trash from './Trash.vue'
import ImportSearchOnline from './ImportSearchOnline.vue'
import ImportFromUrl from './ImportFromUrl.vue'
import { getMe } from '@/api/filemanager/user'
import { UserModule } from '@/store/modules/user'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'

export interface MediaLibraryOptions {
    url: string
    mode: string
    allowedTypes: string
}

@Component({
    name: 'MediaLibraryIndex',
    components: {
        VsTopbar,
        VsSidenav,
        MyFiles,
        VsConfirm,
        Trash,
        ImportSearchOnline,
        VsContainer,
        ImportFromUrl,
    },
})
export default class extends Vue {
    $refs: any
    $root: any
    open = false

    async beforeMount () {
        window.openVsMediaLibrary = this.openMediaLibrary
    }

    get currentPage () {
        return MediaLibraryModule.currentPage
    }

    set currentPage (val) {
        MediaLibraryModule.SET_CURRENT_PAGE(val)
    }

    get libraryItems () {
        return MediaLibraryModule.libraryItems
    }

    set libraryItems (val) {
        MediaLibraryModule.SET_LIBRARY_ITEMS(val)
    }

    get checkedFileIds () {
        return MediaLibraryModule.checkedFileIds
    }

    get searchInput () {
        return MediaLibraryModule.searchInput
    }

    set searchInput (val) {
        MediaLibraryModule.SET_SEARCH_INPUT(val)
    }

    get modalPromiseResolve () {
        return MediaLibraryModule.modalPromiseResolve
    }

    set modalPromiseResolve (val) {
        MediaLibraryModule.SET_MODAL_PROMISE_RESOLVE(val)
    }

    get modalPromiseReject () {
        return MediaLibraryModule.modalPromiseReject
    }

    set modalPromiseReject (val) {
        MediaLibraryModule.SET_MODAL_PROMISE_REJECT(val)
    }

    get showMediaLibrary () {
        return MediaLibraryModule.showMediaLibrary
    }

    get hasToken () {
        return !!UserModule.filemanagerToken
    }

    get vsMediaLibraryClasses () {
        return {
            'vs-bg-white': true,
        }
    }

    private fileSelected (url: string) {
        return url
    }

    private fileUploaded () {
        if (this.$refs[this.currentPage]) {
            this.$refs[this.currentPage].getFiles()
            this.$refs[this.currentPage].checkEmptyState()
        }
    }

    private clearTrash () {
        if (this.$refs['trash']) {
            this.$refs['trash'].clearTrash()
        }
    }

    @Watch('searchInput', { immediate: false, deep: true })
    private async searchFiles (e: any) {
        if (e && this.currentPage !== 'home') this.currentPage = 'home'
    }

    private appendElementOnRoot (el: any) {
        this.$root.$mediaLibraryDeleteConfirm = el
    }

    private toastReady (el: any) {
        this.$root.$mediaLibraryToast = el.addToast
    }

    public openMediaLibrary (options?: MediaLibraryOptions) {
        this.searchInput = ''
        this.currentPage = 'home'
        MediaLibraryModule.SET_SHOW_MEDIALIBRARY(true)
        if (options) MediaLibraryModule.SET_URL(options.url)
        MediaLibraryModule.SET_MODE(options?.mode || 'standard')
        MediaLibraryModule.SET_ALLOWED_TYPES(options?.allowedTypes || '')
        this.getMe()
        return new Promise((resolve, reject) => {
            this.modalPromiseResolve = resolve
            this.modalPromiseReject = reject
        })
    }

    private async getMe () {
        try {
            const resp = await getMe()
            MediaLibraryModule.SET_USER_INFO(resp.data)
        } catch (e) {
            console.log(e)
        }
    }
}
