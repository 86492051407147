import { VuexModule, Module, getModule, Mutation, Action } from 'vuex-module-decorators'
import store from '@/store'

export interface IMissingStep {
    name: string
    buttonText: string
    buttonUrl: string
}

export interface ILandingpageOptionsState {
    baseUrl: string
    renderUrl: string
    missingSteps: IMissingStep[]
}

@Module({ dynamic: true, store, name: 'landingpageoptions', namespaced: true })
class LandingpageOptions extends VuexModule implements ILandingpageOptionsState {
    public baseUrl = process.env.VUE_APP_LANDINGPAGE_API || ''
    public renderUrl = process.env.VUE_APP_LANDINGPAGE_RENDER_URL || ''
    // spostarlo in appmodule (è l'host di leadplus)
    // public leadplusUrl = ''
    public changePageCancelTokenSource: any = null
    public missingSteps: IMissingStep[] = []

    @Mutation
    public SET_CHANGE_PAGE_CANCEL_TOKEN_SOURCE (changePageCancelTokenSource: any) {
        this.changePageCancelTokenSource = changePageCancelTokenSource
    }

    @Mutation
    private SET_BASE_URL (baseUrl: string) {
        this.baseUrl = baseUrl
    }

    @Mutation
    public SET_RENDER_URL (renderUrl: string) {
        this.renderUrl = renderUrl
    }

    @Mutation
    private SET_MISSING_STEPS (missingSteps: IMissingStep[]) {
        this.missingSteps = missingSteps
    }

    @Action
    public setMissingSteps (missingSteps: IMissingStep[]) {
        if (!missingSteps) return
        this.SET_MISSING_STEPS(missingSteps)
    }
}

export const LandingpageOptionsModule = getModule(LandingpageOptions)
