











































































































































import { Component, Mixins } from 'vue-property-decorator'
import MediaLibraryActionsMixin from './../../mixins/MediaLibraryActionsMixin.vue'
import Vue2Dropzone from 'vue2-dropzone'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'
import { importFromUrl, uploadFile } from '@/api/filemanager/files'
import { isValidURL } from '@/utils/validate'

@Component({
    name: 'VsTopbar',
    components: {
        Vue2Dropzone,
    },
})
export default class extends Mixins(MediaLibraryActionsMixin) {
    private showDragArea = false
    private filelist: any[] = []
    private uploading = false
    private importUrlloading = false

    get searchInput () {
        return MediaLibraryModule.searchInput
    }

    set searchInput (val) {
        MediaLibraryModule.SET_SEARCH_INPUT(val)
    }

    get checkedFileIds () {
        return MediaLibraryModule.checkedFileIds
    }

    set checkedFileIds (val) {
        MediaLibraryModule.SET_CHECKED_FILE_IDS(val)
    }

    get currentDirectory () {
        return MediaLibraryModule.currentDirectory
    }

    get currentPage () {
        return MediaLibraryModule.currentPage
    }

    set currentPage (val) {
        MediaLibraryModule.SET_CURRENT_PAGE(val)
    }

    get dropzoneOptions () {
        return {
            url: 'https://httpbin.org/post',
            thumbnailWidth: 150,
            thumbnailHeight: 150,
            // maxFilesize: 0.5,
            acceptedFiles: this.acceptedMimes,
            autoProcessQueue: false,
            autoQueue: false,
        }
    }

    get acceptedMimes () {
        return MediaLibraryModule.uploadAcceptedFiles.join(',')
    }

    get libraryItems () {
        return MediaLibraryModule.libraryItems
    }

    set libraryItems (val) {
        MediaLibraryModule.SET_LIBRARY_ITEMS(val)
    }

    get importUrl () {
        return MediaLibraryModule.importUrl
    }

    set importUrl (val) {
        MediaLibraryModule.SET_IMPORT_URL(val)
    }

    get importUrlDisabled () {
        return !isValidURL(this.importUrl)
    }

    get selectionMode () {
        return MediaLibraryModule.mode === 'selection'
    }

    private async importFromUrl () {
        this.importUrlloading = true
        try {
            const resp = await importFromUrl(this.importUrl)

            const errors = resp.data.filter((el: any) => !el.success)

            if (errors.length > 0) {
                let heading = this.$t('mediaLibrary.notifications.importFromUrl.error')
                if (errors[0].message === 'AvailableSpaceReached') heading = this.$t('mediaLibrary.notifications.noSpaceAvailable')
                // può succedere???
                if (errors[0].message.includes('ExtensionNotAllowed')) heading = `Errore, l'estensione del file non è permessa`
                this.$root.$mediaLibraryToast({
                    heading,
                    timeout: 3000,
                })
            } else {
                this.importUrl = ''
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.importFromUrl.success'),
                    timeout: 3000,
                })
                if (this.selectionMode) {
                    this.selectFile(resp.data[0].file)
                }
                this.getMe()
            }
        } catch (e) {
            console.log(e)
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.importFromUrl.error'),
                timeout: 3000,
            })
        }
        this.importUrlloading = false
    }

    private openUpload () {
        this.showDragArea = true
    }

    private closeUpload () {
        this.showDragArea = false
    }

    onChange () {
        this.filelist = [...this.$refs.file.files]
        if (!this.checkFilesSize(this.filelist)) {
            this.$root.$mediaLibraryToast({
                heading: 'Errore, dimensione massima 10MB',
                timeout: 3000,
            })
            return
        }
        this.closeUpload()
        this.uploadFile()
    }

    private checkFilesSize (files: any[]) {
        let filesize = 0
        for (const x in files) {
            filesize = filesize + ((files[x].size / 1024) / 1024)
        }
        if (filesize > 10) return false
        return true
    }

    async uploadFile () {
        const data = new FormData()
        for (const file of this.filelist) {
            data.append('file[]', file)
        }

        if (this.currentDirectory) {
            data.append('directory_id', this.currentDirectory.id || '')
        }

        const toast = this.$root.$mediaLibraryToast({
            heading: 'Caricamento in corso...',
            message: this.loadingText(this.filelist.length),
        })
        this.uploading = true
        try {
            const resp = await uploadFile(
                data,
                {
                    onUploadProgress: (progressEvent: any) => {
                        toast.message = this.loadingText(this.filelist.length, progressEvent)
                        if (progressEvent.loaded === progressEvent.total) {
                            setTimeout(() => {
                                toast.dismiss(toast.id)
                            }, 3000)
                        }
                    },
                },
            )

            for (const singleUpdate of resp.data) {
                let message = ''
                if (!singleUpdate.success) {
                    let heading = this.$t('mediaLibrary.notifications.uploadFile.error')
                    if (singleUpdate.message === 'AvailableSpaceReached') heading = this.$t('mediaLibrary.notifications.noSpaceAvailable')

                    if (singleUpdate.message.includes('MimeTypeNotAllowed')) {
                        message = `Estensione ${singleUpdate.file.extension} non permessa per il file: `
                    }

                    this.$root.$mediaLibraryToast({
                        heading,
                        message: `${message}${singleUpdate.file.name}.${singleUpdate.file.extension}`,
                        timeout: 5000,
                    })
                } else {
                    this.$root.$mediaLibraryToast({
                        heading: this.$t('mediaLibrary.notifications.uploadFile.success'),
                        message: `${singleUpdate.file.name}`,
                        timeout: 5000,
                    })
                }
            }
            this.getMe()
            this.$emit('file-uploaded')
        } catch (e) {
            toast.dismiss(toast.id)
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.uploadFile.error'),
                message: ``,
                timeout: 3000,
            })
        }
        this.uploading = false
    }

    private loadingText (fileNumber: number, progressEvent?: any) {
        const loaded = progressEvent ? progressEvent.loaded : 0
        const total = progressEvent ? progressEvent.total : 0
        return `${fileNumber} elementi / ${this.$options?.filters?.formatBytes(loaded)} di ${this.$options?.filters?.formatBytes(total)}`
    }

    remove (i: any) {
        this.filelist.splice(i, 1)
    }

    dragover (event: any) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        // if (!event.currentTarget.classList.contains('bg-green-300')) {
        //     event.currentTarget.classList.remove('bg-gray-100')
        //     event.currentTarget.classList.add('bg-green-300')
        // }
    }

    dragleave () {
        // Clean up
        // event.currentTarget.classList.add('vs-bg-gray-100')
        // event.currentTarget.classList.remove('vs-bg-green-300')
    }

    drop (event: any) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        // event.currentTarget.classList.add('vs-bg-gray-100')
        // event.currentTarget.classList.remove('vs-bg-green-300')
    }

    public closeMediaLibrary () {
        if (MediaLibraryModule.modalPromiseReject) MediaLibraryModule.modalPromiseReject()
        MediaLibraryModule.SET_MODAL_PROMISE_REJECT(null)
        MediaLibraryModule.SET_MODAL_PROMISE_RESOLVE(null)
        MediaLibraryModule.SET_SHOW_MEDIALIBRARY(false)
    }
}
