import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'
const basePath = '/contents/wa'

export const getWaTemplates = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/templates`,
        {
            cancelToken,
            params,
        },
    )

export const getWaTemplatePreview = (templateId: string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/templates/${templateId}/preview`,
        {
            cancelToken,
            params,
        },
    )

export const createWaTemplate = (data: {name: string, body: string}, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}/templates`,
        data,
        {
            cancelToken,
        },
    )

export const getIntegration = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/integrations`,
        {
            cancelToken,
        },
    )

export const requestIntegration = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}/request-integration`,
        data,
        {
            cancelToken,
        },
    )

export const deleteWaTemplates = (templateId: string, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `${basePath}/templates/${templateId}`,
        {
            cancelToken,
        },
    )
