import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'
import { userStatusEnum } from '@/utils/users'

const signupRouter: RouteConfig[] = [
    {
        path: '/signup',
        name: 'signup',
        redirect: {
            name: 'registration',
        },
        component: VsLayouts,
        meta: {
            hidden: true,
            public: true,
            integrationPermissions: ['route-signup'],
        },
        children: [
            {
                path: '/',
                name: 'registration',
                component: () => import(/* webpackChunkName: "registration" */ '@/modules/signup/views/registration.vue'),
                meta: {
                    hidden: true,
                    public: true,
                    notLoggedinRoute: true,
                    integrationPermissions: ['route-signup'],
                    layout: LayoutsEnum.vsAuth,
                },
            },
            {
                path: 'pending-activation',
                name: 'pendingActivation',
                component: () => import(/* webpackChunkName: "pending-activation" */ '@/modules/signup/views/pending-activation.vue'),
                meta: {
                    hidden: true,
                    public: false,
                    userStatusPermissions: [userStatusEnum.PENDING_ACTIVATION],
                    integrationPermissions: ['route-signup'],
                    layout: LayoutsEnum.vsAuth,
                },
            },
        ],
    },
]

export default signupRouter
