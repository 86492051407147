import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'

const automationsRouter: RouteConfig[] = [
    {
        path: '/automations',
        redirect: { name: 'automations' },
        name: 'automationsIndex',
        component: VsLayouts,
        children: [
            {
                path: '/',
                name: 'automations',
                component: () => import(/* webpackChunkName: "automations" */ './../views/index.vue'),
            },
            {
                path: ':automationId/edit',
                name: 'editAutomation',
                component: () => import(/* webpackChunkName: "edit-automations" */ './../views/id/edit.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
            },
        ],
    },
]

export default automationsRouter
