
































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'
import VsButtonActionsContainer from '@/components/VsButtonActionsContainer/Index.vue'
import VsDirectoryEditor from './../VsDirectoryEditor/Index.vue'

@Component({
    name: 'VsTopMultiActions',
    components: {
        VsButtonActionsContainer,
        VsDirectoryEditor,
    },
})
export default class extends Vue {
    private show = true
    $refs: any

    get currentDirectory () {
        return MediaLibraryModule.currentDirectory
    }

    get checkedFileIds () {
        return MediaLibraryModule.checkedFileIds
    }

    set checkedFileIds (val) {
        MediaLibraryModule.SET_CHECKED_FILE_IDS(val)
    }

    get currentPage () {
        return MediaLibraryModule.currentPage
    }

    get vsTopMultiActionsClasses () {
        return {
            'vs-shadow-light-small': true,
        }
    }

    get acceptedMimes () {
        return MediaLibraryModule.uploadAcceptedFiles.join(',')
    }

    get libraryItems () {
        return MediaLibraryModule.libraryItems
    }

    set libraryItems (val) {
        MediaLibraryModule.SET_LIBRARY_ITEMS(val)
    }

    @Watch('checkedFileIds', { immediate: true, deep: true })
    private toggleVsTopMultiActions (val: any[]) {
        if (val.length > 0) {
            this.openVsTopMultiActions()
        } else {
            this.closeVsTopMultiActions()
        }
    }

    private openVsTopMultiActions () {
        this.show = true
    }

    private closeVsTopMultiActions () {
        this.show = false
    }

    private closeAndClear () {
        this.checkedFileIds = []
    }

    private openDirectoryEditor () {
        this.$refs.directoryEditor.show(this.currentDirectory.id)
    }
}
