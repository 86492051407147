const calcImageSize = (base64: string) => {
    let y = 1
    if (base64.endsWith('==')) {
        y = 2
    }
    const x_size = (base64.length * (3 / 4)) - y
    return Math.round(x_size / 1024)
}

const reduceImageFileSize = async (base64Str: string, MAX_WIDTH = 400, MAX_HEIGHT = 500) => {
    const resized_base64: string = await new Promise((resolve) => {
        const img = new Image()
        img.src = base64Str
        img.onload = () => {
            const canvas = document.createElement('canvas')
            let width = img.width
            let height = img.height

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width
                    width = MAX_WIDTH
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height
                    height = MAX_HEIGHT
                }
            }
            canvas.width = width
            canvas.height = height
            const ctx = canvas.getContext('2d')
            if (!ctx) {
                return base64Str
            }
            ctx.drawImage(img, 0, 0, width, height)
            resolve(canvas.toDataURL('image/jpeg', 0.1)) // this will return base64 image results after resize
        }
    })
    return resized_base64
}

export const processImage = async (base64: string, min_image_size = 20) => {
    if (base64) {
        const old_size = calcImageSize(base64)
        if (old_size > min_image_size) {
            const resized = await reduceImageFileSize(base64)
            return resized
        } else {
            return base64
        }
    } else {
        return base64
    }
}
