import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import axios from 'axios'
import { getToken } from '@/api/userapi/auth'
import { SubscriberAreaModule } from '@/store/modules/subscriberArea'

export const serviceSysapi = axios.create({
    baseURL: process.env.VUE_APP_SYSAPI, // url = base url + request url
    timeout: 10000,
    withCredentials: true,
    headers: {
        'x-source': 'console',
    },
})

export const serviceShopapi = axios.create({
    baseURL: process.env.VUE_APP_SHOPAPI,
    timeout: 10000,
    headers: {
        'x-source': 'console',
    },
})

export const serviceFilemanager = axios.create({
    baseURL: process.env.VUE_APP_FILEMANAGERAPI,
    timeout: 10000,
    headers: {
        'x-source': 'console',
    },
})

export const serviceConsole = axios.create({
    baseURL: '',
    timeout: 10000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'x-Requested-With': 'XMLHttpRequest',
        'x-source': 'console',
    },
})

export const serviceConsoleApi = axios.create({
    baseURL: '/api',
    timeout: 30000,
    withCredentials: true,
    headers: {
        'x-source': 'console',
    },
})

export const serviceConsoleApiSubscriberArea = axios.create({
    baseURL: '/api',
    timeout: 30000,
    headers: {
        'x-source': 'console',
    },
})

export const serviceLandingPage = axios.create({
    baseURL: process.env.VUE_APP_LANDINGPAGE_API,
    timeout: 10000,
    headers: {
        'x-source': 'console',
    },
})

export const serviceLeadplus = axios.create({
    baseURL: process.env.VUE_APP_LEADPLUS_API,
    timeout: 10000,
    headers: {
        'x-source': 'console',
    },
})

export const serviceGamification = axios.create({
    baseURL: process.env.VUE_APP_GAMIFICATION_API,
    timeout: 10000,
    headers: {
        'x-source': 'console',
    },
})

export const serviceDrago = axios.create({
    baseURL: process.env.VUE_APP_DRAGOAPI,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'x-Requested-With': 'XMLHttpRequest',
        'x-source': 'console',
    },
})

export const serviceUserApi = axios.create({
    baseURL: process.env.VUE_APP_USER_API,
    withCredentials: true,
    timeout: 10000,
    headers: {
        'x-source': 'console',
    },
})

export const serviceConsentWriter = axios.create({
    baseURL: process.env.VUE_APP_CONSENT_WRITER_API,
})

serviceGamification.interceptors.request.use(
    (config) => {
        if (UserModule.gamificationToken) {
            if (config.headers) {
                config.headers['Authorization'] = 'Bearer ' + UserModule.gamificationToken
            } else {
                config.headers = {
                    Authorization: 'Bearer ' + UserModule.gamificationToken,
                }
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

serviceShopapi.interceptors.request.use(
    (config) => {
        if (UserModule.shopToken) {
            if (config.headers) {
                config.headers['Authorization'] = 'Bearer ' + UserModule.shopToken
            } else {
                config.headers = {
                    Authorization: 'Bearer ' + UserModule.shopToken,
                }
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

serviceShopapi.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const originalConfig = error.config
        if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true
                try {
                    const resp = await getToken('shop')
                    UserModule.SET_SHOP_TOKEN(resp.data.token)
                    serviceShopapi.defaults.headers['Authorization'] = 'Bearer ' + UserModule.shopToken
                    return serviceShopapi(originalConfig)
                } catch (e) {
                    return Promise.reject(e)
                }
            }
        }
        return Promise.reject(error)
    },
)

serviceFilemanager.interceptors.request.use(
    (config) => {
        if (UserModule.filemanagerToken) {
            if (config.headers) {
                config.headers['Authorization'] = 'Bearer ' + UserModule.filemanagerToken
            } else {
                config.headers = {
                    Authorization: 'Bearer ' + UserModule.filemanagerToken,
                }
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

serviceFilemanager.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const originalConfig = error.config
        if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true
                try {
                    const resp = await getToken('filemanager')
                    UserModule.SET_FILEMANAGER_TOKEN(resp.data.token)
                    serviceFilemanager.defaults.headers['Authorization'] = 'Bearer ' + UserModule.filemanagerToken
                    return serviceFilemanager(originalConfig)
                } catch (e) {
                    return Promise.reject(e)
                }
            }
        }
        return Promise.reject(error)
    },
)

serviceLandingPage.interceptors.request.use(
    (config) => {
        if (UserModule.landingpageToken) {
            if (config.headers) {
                config.headers['Authorization'] = 'Bearer ' + UserModule.landingpageToken
            } else {
                config.headers = {
                    Authorization: 'Bearer ' + UserModule.landingpageToken,
                }
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

serviceLandingPage.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const originalConfig = error.config
        if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true
                try {
                    const resp = await getToken('landingpage')
                    UserModule.SET_LANDINGPAGE_TOKEN(resp.data.token)
                    serviceLandingPage.defaults.headers['Authorization'] = 'Bearer ' + UserModule.landingpageToken
                    return serviceLandingPage(originalConfig)
                } catch (e) {
                    return Promise.reject(e)
                }
            }
        }
        return Promise.reject(error)
    },
)

serviceLeadplus.interceptors.response.use(
    (res) => {
        return res
    },
    async (error) => {
        const originalConfig = error.config
        if (error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true
                try {
                    const resp = await getToken('leadplus')
                    UserModule.SET_LEADPLUS_TOKEN(resp.data.token)
                    serviceLeadplus.defaults.headers['Authorization'] = 'Bearer ' + UserModule.leadplusToken
                    return serviceLeadplus(originalConfig)
                } catch (e) {
                    return Promise.reject(e)
                }
            }
        }
        return Promise.reject(error)
    },
)

serviceSysapi.interceptors.request.use(
    (config) => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        // if (UserModule.token) {
        //     config.headers.Authorization = 'Bearer ' + UserModule.token
        // }
        if (config.headers) {
            config.headers['X-IntegrationApiKey'] = AppModule.consoleConf?.integrationApiKey || ''
        } else {
            config.headers = {
                'X-IntegrationApiKey': AppModule.consoleConf?.integrationApiKey || '',
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

serviceUserApi.interceptors.request.use(
    (config) => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        // if (UserModule.token) {
        //     config.headers.Authorization = 'Bearer ' + UserModule.token
        // }
        config.headers['x-integrationapikey'] = AppModule.consoleConf?.integrationApiKey || ''
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)

serviceConsoleApiSubscriberArea.interceptors.request.use(
    (config) => {
        if (SubscriberAreaModule.token) {
            if (config.headers) {
                config.headers['Authorization'] = 'Bearer ' + SubscriberAreaModule.token
            } else {
                config.headers = {
                    Authorization: 'Bearer ' + UserModule.landingpageToken,
                }
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    },
)
