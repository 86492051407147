import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'
import { UserModule } from '@/store/modules/user'
import { getShopUser } from '@/api/shop'

const shopRouter: RouteConfig[] = [
    {
        path: '/shop',
        name: 'shop',
        component: VsLayouts,
        redirect: {
            name: 'subscriptions',
        },
        beforeEnter: async (_to: any, _from: any, next: any) => {
            if (!UserModule.shopUser) {
                try {
                    const resp = await getShopUser()
                    UserModule.SET_SHOP_USER(resp.data)
                } catch (e) {
                    UserModule.SET_SHOP_USER(null)
                }
            }
            next()
        },
        children: [
            {
                path: 'success',
                name: 'cartSuccess',
                component: () => import(/* webpackChunkName: "cart-success" */ '../views/success-url.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'error',
                name: 'cartError',
                component: () => import(/* webpackChunkName: "cart-error" */ '../views/error-url.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'happy-hour-estate-24',
                name: 'happyHour',
                component: () => import(/* webpackChunkName: "happy-hour-estate-24" */ '../views/happy-hour-estate-24.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'cart',
                name: 'cart',
                component: () => import(/* webpackChunkName: "cart" */ '../views/cart.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
            },
            {
                path: 'automatic-payment',
                name: 'automaticPayment',
                component: () => import(/* webpackChunkName: "automatic-payment" */ '../views/automaticPayment.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
            },
        ],
    },
    {
        path: '/shop/prices',
        name: 'prices',
        component: VsLayouts,
        redirect: {
            name: 'subscriptions',
        },
        beforeEnter: async (_to: any, _from: any, next: any) => {
            if (!UserModule.shopUser) {
                try {
                    const resp = await getShopUser()
                    UserModule.SET_SHOP_USER(resp.data)
                } catch (e) {
                    UserModule.SET_SHOP_USER(null)
                }
            }
            next()
        },
        children: [
            {
                path: '',
                name: 'pricesIndex',
                component: () => import(/* webpackChunkName: "prices-index" */ '../views/prices/index.vue'),
                redirect: {
                    name: 'subscriptions',
                },
                children: [
                    {
                        path: 'subscriptions',
                        name: 'subscriptions',
                        component: () => import(/* webpackChunkName: "prices-subscriptions" */ '../views/prices/subscriptions.vue'),
                    },
                    {
                        path: 'credits-email',
                        name: 'credits-email',
                        component: () => import(/* webpackChunkName: "prices-credits-email" */ '../views/prices/credits-email.vue'),
                    },
                    {
                        path: 'credits-sms',
                        name: 'credits-sms',
                        component: () => import(/* webpackChunkName: "prices-credits-sms" */ '../views/prices/credits-sms.vue'),
                    },
                    {
                        path: 'credits-transactional',
                        name: 'credits-transactional',
                        component: () => import(/* webpackChunkName: "prices-credits-transactional" */ '../views/prices/credits-transactional.vue'),
                    },
                ],
            },
        ],
    },
]

export default shopRouter
