import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
// import { UserModule } from '@/store/modules/user'

const campaignsRouter: RouteConfig[] = [
    {
        path: '/pages',
        redirect: { name: 'PagesCards' },
        name: 'landingpages',
        component: VsLayouts,
        // example of root request
        // beforeEnter: async (to, from, next) => {
        //     if (!UserModule.landingpageToken) {
        //         try {
        //             const token = await examplecall()
        //         } catch (e) {
        //             console.log(e)
        //         }
        //     }
        //     next()
        // },
        meta: {
            title: 'landing page',
        },
        children: [
            {
                path: '/',
                name: 'PagesCards',
                meta: {
                    hidden: true,
                    title: 'landing page',
                },
                component: () => import(/* webpackChunkName: "landingpages" */ './../views/pages/LandingpagesCards.vue'),
            },
            {
                path: 'create',
                name: 'CreatePage',
                meta: {
                    hidden: true,
                    title: 'crea landing page',
                },
                component: () => import(/* webpackChunkName: "createpage" */ './../views/pages/Create.vue'),
            },
            {
                path: ':pageId/edit',
                name: 'EditPage',
                meta: {
                    hidden: true,
                    title: 'modifica pagina',
                },
                component: () => import(/* webpackChunkName: "editpage" */ './../views/pages/_id/Edit.vue'),
            },
            {
                path: ':pageId/projects/edit',
                name: 'EditPageProject',
                meta: {
                    hidden: true,
                    title: 'modifica progetto',
                },
                component: () => import(/* webpackChunkName: "editproject" */ './../views/projects/Index.vue'),
            },
            {
                path: 'subdomains',
                name: 'Subdomains',
                meta: {
                    hidden: true,
                    title: 'Gestione URL personalizzate',
                },
                component: () => import(/* webpackChunkName: "createpage" */ './../views/pages/subdomains/Index.vue'),
            },
        ],
    },
]

export default campaignsRouter
