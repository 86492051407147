































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsWhatsappMobile',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) text!: string

    @Prop({
        required: false,
        default: 'Mittente',
    }) sender!: string

    @Prop({
        required: false,
        default: '',
    }) avatar!: string

    @Prop({ required: false, default: false, type: Boolean }) full?: boolean

    wrapLinksInSpan (text: string) {
        const regex = /(\bhttps?:\/\/\S+\b)/gi
        return text.replace(regex, '<span class="wa-link-text">$1</span>')
    }
}
