











import { Component, Prop, Vue } from 'vue-property-decorator'
import CKEditor from 'ckeditor4-vue'
import VsMediaLibrary from '@/modules/mediaLibrary/views/Index.vue'

@Component({
    name: 'VsCkeditor',
    components: {
        ckeditor: CKEditor.component,
        VsMediaLibrary,
    },
})
export default class extends Vue {
    $refs: any
    @Prop({ default: '', required: true }) value!: string
    @Prop({ default: () => [], required: false }) tags!: any[]
    @Prop({ default: () => [], required: false }) buttons!: any[]
    private loading = false

    // private editorConfig = {
    //     toolbarGroups: [
    //         { name: 'clipboard', groups: ['clipboard', 'undo'] },
    //         { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    //         { name: 'links' },
    //         { name: 'insert' },
    //         { name: 'forms' },
    //         { name: 'tools' },
    //         { name: 'document', groups: ['mode', 'document', 'doctools'] },
    //         '/',
    //         { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    //         { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
    //         { name: 'styles' },
    //         { name: 'colors' },
    //         { name: 'others' },
    //     ],
    //     extraPlugins: 'widget,codemirror,image2,templates,listbuttons,colorbutton,panelbutton,floatpanel,font,richcombo,listblock,panel,justify,button',
    //     removeButtons: 'Underline,Subscript,Superscript,Styles,About',
    //     format_tags: 'p;h1;h2;h3;div;pre',
    //     removeDialogTabs: 'image:advanced',
    // }

    get publicPath () {
        return process.env.BASE_URL
    }

    onEditorReady (e: any) {
        e.focus()
    }

    onNamespaceLoaded (CKEDITOR: any) {
        const buttons = this.buttons
        const tags = this.tags
        CKEDITOR.plugins.add('listbuttons', {
            requires: ['richcombo'], //, 'styles' ],
            init: function (editor: any) {
                const config = editor.config
                const lang = editor.lang.format
                const plugin_path = this.path
                // Create style objects for all defined styles.

                const createRich = function (tags: any) {
                    editor.ui.addRichCombo(tags.title, {
                        label: tags.title,
                        title: tags.title,
                        voiceLabel: tags.title,
                        className: 'cke_listbuttons',
                        multiSelect: false,
                        isHTML: function (str: any) {
                            const a = document.createElement('div')
                            a.innerHTML = str
                            for (let c = a.childNodes, i = c.length; i--;) {
                                if (c[i].nodeType === 1) return true
                            }
                            return false
                        },

                        panel: {
                            css: [plugin_path + '/css/listbuttons-style.css', config.contentsCss, CKEDITOR.getUrl(CKEDITOR.skin.getPath('editor') + 'editor.css')],
                            voiceLabel: lang.panelVoiceLabel,
                        },

                        init: function () {
                            // this.add('value', 'drop_text', 'drop_label');
                            const editor_tags = tags.tags
                            for (const i in editor_tags) {
                                this.add(escape(editor_tags[i].tag), editor_tags[i].title, editor_tags[i].title)
                            }
                        },

                        onClick: function (value: any) {
                            let html: any = ''
                            if (this.isHTML(unescape(value))) {
                                const placeholder = document.createElement('div')
                                placeholder.innerHTML = unescape(value)
                                html = placeholder.firstElementChild?.outerHTML
                            } else {
                                html = unescape(value)
                            }
                            editor.focus()
                            editor.fire('saveSnapshot')
                            editor.insertHtml(html)
                            editor.fire('saveSnapshot')
                        },
                    })
                }

                for (const i in tags) {
                    createRich(tags[i])
                }

                // add two extra buttons for quick access
                const CommandConstruct: any = (html: any) => {
                    this.html = html
                    this.exec = function (ed: any) {
                        ed.focus()
                        ed.fire('saveSnapshot')
                        ed.insertHtml(this.html)
                        ed.fire('saveSnapshot')
                    }

                    return this
                }

                for (let i = 0; i < buttons.length; i++) {
                    const btn = buttons[i]

                    editor.addCommand(btn.command, new CommandConstruct(btn.html))

                    editor.ui.addButton(btn.name, {
                        label: btn.label,
                        command: btn.command,
                    })
                }
            },
        })
    }

    beforeDestroy () {
        this.$nextTick(() => {
            const scripts = this.$el.ownerDocument.querySelectorAll('script')
            for (let index = 0; index < scripts.length; index++) {
                const element = scripts[index]
                if (element.getAttribute('src')?.match(/ckeditor/)) {
                    this.$el.ownerDocument.head.removeChild(element)
                }
            }
            const styleSheets = this.$el.ownerDocument.querySelectorAll('[rel="stylesheet"]')
            for (let index = 0; index < styleSheets.length; index++) {
                const element = styleSheets[index]
                if (element.getAttribute('href')?.match(/ckeditor/)) {
                    this.$el.ownerDocument.head.removeChild(element)
                }
            }
            delete window.CKEDITOR
        })
    }
}
