













































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsMediaLibraryImage from './../VsMediaLibraryImage/Index.vue'
import { copyToClipboard } from '@/utils/copyToClipboard'

export interface ILibraryItem {
    id: string
    user_id: string
    directory_id: string
    uuid: string
    name: string
    size: number
    mime: string
    public_url: string
    deleted_at: string | null
}

export enum libraryItemType {
    default = 'default',
    trash = 'trash',
    insert = 'insert',
}

@Component({
    name: 'VsMediaLibraryCard',
    components: {
        VsDropdownButton,
        VsMediaLibraryImage,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                id: '',
                user_id: '',
                directory_id: '',
                uuid: '',
                name: '',
                size: '',
                mime: '',
                public_url: '',
                deleted_at: '',
            }
        },
    }) private libraryItem!: ILibraryItem

    @Prop({
        required: false,
        default: () => [],
    }) private value!: any[]

    @Prop({
        required: false,
        default: libraryItemType.default,
    }) private type?: libraryItemType

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) private loading?: boolean

    @Prop({
        required: false,
        default: '',
    }) private allowedTypes?: string

    @Prop({
        required: false,
        default: '',
    }) private currentSelectedFile?: string

    private hover = false
    private menuIsOpen = false
    private focused = false
    private imageSize = ''

    get selected () {
        return this.value.indexOf(this.libraryItem.id) > -1
    }

    get cardTitleClasses () {
        return {
            'vs-h300': true,
            'vs-text-grey-900': true,
            'vs-text-primary-800': this.selected,
            'vs-text-grey-600': this.libraryItem.deleted_at || !this.isAllowedType,
            'vs-truncate': true,
        }
    }

    get isAllowedType () {
        if (!this.allowedTypes) return true
        if (!this.libraryItem.mime) return false
        return this.allowedTypes.split(',').find((el: string) => el === this.libraryItem.mime)
    }

    get isImage () {
        return this.libraryItem.mime.includes('image/')
    }

    private onMouseOver () {
        this.hover = true
    }

    private onMouseLeave () {
        if (this.menuIsOpen) return
        this.hover = false
    }

    private openMenu () {
        this.focused = true
        this.menuIsOpen = true
        this.$emit('menu-open')
    }

    private closeMenu () {
        this.menuIsOpen = false
        this.$emit('menu-close')
    }

    private openDetails (itemId: string) {
        this.$emit('show-details', itemId)
    }

    private selectFile (libraryItem: any) {
        this.$emit('select-file', libraryItem)
    }

    private setImageSize (imageSize: string) {
        this.imageSize = imageSize
    }

    private checkCard (e: any) {
        // private toggleCheckFile (e: boolean, libraryItemId: string) {
        // if (!this.libraryItem.id) return
        // const index = this.value.indexOf(this.libraryItem.id)
        // const data = [...this.value]
        // if (e) {
        //     if (index === -1) data.push(this.libraryItem.id)
        // } else {
        //     if (index !== -1) data.splice(index, 1)
        // }
        this.$emit('input', e)
    }

    private copyToClipboard () {
        try {
            copyToClipboard(this.libraryItem.public_url)
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.copyFile.success'),
                timeout: 3000,
            })
        } catch (error) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.copyFile.error'),
                timeout: 3000,
            })
        }
    }
}
