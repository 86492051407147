import { formatCurrency, formatNumber, formatNumberWithThousandsSeparator } from '@/utils/formatter'
import i18n from '@/lang'

export const formatPriceName = (price: any) => {
    let base = price.product.name
    if (price.metadata?.monthlyCredits) base += ' ' + formatNumber(parseInt(price.metadata.monthlyCredits)) + ' email al mese'
    if (price.metadata?.credits) base = formatNumberWithThousandsSeparator(price.metadata.credits) + ' crediti ' + i18n.tc(`shop.cart.creditType.${price.product.metadata.creditType}`).toLowerCase()
    return base
}

export const formatAddress = (address: any) => {
    if (!address.line1) return ''
    let base = address.line1
    if (address.postal_code) base += ', ' + address.postal_code
    if (address.city) base += ', ' + address.city
    if (address.state) base += ', ' + address.state
    if (address.country) base += ', ' + address.country
    return base
}

export const formatPriceRateValue = (price: any) => {
    return price.recursive ? ` / ${i18n.tc(`general.rateSlim.${price.rate.type}`, price.rate.value)}` : ''
}

export const calculateAndFormatItemPrice = (item: any) => {
    return formatCurrency(item.price.value * item.quantity)
}

export const calculateAndFormatItemPriceAndRate = (item: any) => {
    return calculateAndFormatItemPrice(item) + formatPriceRateValue(item.price)
}

export const formatEmailCreditsRateFromMetadata = (metadata: any) => {
    return i18n.tc(`general.rateSlim.${metadata.rateKey || 'months'}`, metadata.rateValue ? parseInt(metadata.rateValue) : 1)
}

export const getUserPlan = (shopUser: any) => {
    if (!shopUser?.subscription?.items) return null
    return shopUser.subscription.items.find((el: any) => el.price.product.strategy === 'baseSet') || null
}

export const getUserAddons = (shopUser: any) => {
    if (!shopUser?.subscription?.items) return null
    return shopUser.subscription.items.filter((el: any) => el.price.product.strategy === 'stackable' || el.price.product.strategy === 'switchable')
}

export const addonsDriveToShop = {
    happyhourestate2024: {
        bestOption: ['recipients'],
        recommended: ['noLogo', 'senderAuthorized'],
    },
    communication: {
        bestOption: ['recipients'],
        recommended: ['noLogo', 'senderAuthorized'],
    },
    acquisition: {
        bestOption: ['recipients'],
        recommended: ['mediaLibrary', 'backupData'],
    },
}

export type DriveToShopType = 'communication' | 'acquisition' | 'happyhourestate2024'
