


























































import { Component, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { sendActivationCodeSms, confirmSenderSms } from '@/api/consoleApi/senders/sms'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'
import { get } from 'lodash'

@Component({
    name: 'VsConfirmSmsSender',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private loadingSendSms = false
    private sender: any = null
    private code = ''

    $refs: any

    public async confirmSender () {
        const validation = await this.$refs.confirmSenderForm.validate()
        if (!validation) return
        this.loading = true
        try {
            await confirmSenderSms(this.sender.id, { auth_code: this.code })
            this.$root.$vsToast({
                heading: 'Mittente abilitato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('sender-confirmed')
            this.closeModal()
        } catch (e) {
            console.log(e)
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 409 && e.response.data.error === 'AuthCodeIsNotMatched') {
                    this.$refs.confirmSenderForm.setErrors({
                        confirmCode: ['Codice di conferma non valido'],
                    })
                }
            }
            this.$root.$vsToast({
                heading: 'Impossibile abilitare il mittente',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    public async sendVerifySenderSms () {
        this.loadingSendSms = true
        try {
            await sendActivationCodeSms(this.sender.id)
            this.$root.$vsToast({
                heading: 'Messaggio inviato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            const err = get(e, 'response.data.error', null)
            let message = 'Errore durante l\'invio del messaggio di conferma'
            switch (err) {
                case 'SenderIsAlreadyConfirmed':
                    message = 'Il mittente è già stato confermato.'
                    break
                case 'CreditNotSufficientToSendConfirmationSms':
                    message = 'Non hai abbastanza crediti per inviare SMS di conferma. Fino a quando non confermerai il tuo numero di cellulare non potrai utilizzare il mittente.'
                    break
            }

            this.$root.$vsToast({
                heading: message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loadingSendSms = false
    }

    public openModal (sender: any) {
        this.sender = sender
        this.code = ''
        this.show = true
    }

    public closeModal () {
        this.show = false
    }
}
