















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    name: 'VsActionCard',
})
export default class extends Vue {
    @Prop({ default: 'white', required: false }) private aspect!: string
    @Prop({ default: 'medium', required: false }) private padding!: string
    @Prop({ default: 'medium', required: false }) private rounded!: string
    @Prop({ default: false, required: false, type: Boolean }) private disabled!: boolean
}
