var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Scegli l'etichetta da assegnare al click ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":function($event){_vm.open = false}}},[_vm._v(" Chiudi ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{on:{"click":_vm.save}},[_vm._v(" Salva ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"mappedTagForm"},[_c('div',{class:{
                'vs-grid': true,
                'vs-grid-cols-2': true,
                'vs-gap-4': true
            }},[_c('VsLabel',[_vm._v("Link")]),_c('VsLabel',[_vm._v("Etichetta")])],1),_c('div',{class:{
                'vs-grid': true,
                'vs-grid-cols-2': true,
                'vs-gap-4': true
            }},[_c('ValidationProvider',{attrs:{"name":"Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsSelect',{attrs:{"boundary":"viewport","filterable":"","error":errors.length > 0,"errorMessage":errors[0],"placeholder":"Seleziona un link","options":_vm.filteredLinks.map(function (el) {
                        return  {
                            value: el,
                            label: el,
                        }
                    })},model:{value:(_vm.selectedLink),callback:function ($$v) {_vm.selectedLink=$$v},expression:"selectedLink"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Tag","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsSelect',{attrs:{"placeholder":"Seleziona un tag","boundary":"viewport","debounce":500,"error":errors.length > 0,"errorMessage":errors[0],"labelHidden":true,"options":_vm.tagOptions},model:{value:(_vm.selectedTag),callback:function ($$v) {_vm.selectedTag=$$v},expression:"selectedTag"}})]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }