






























































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { IMessageEmail } from '@/api/consoleApi/dto/campaigns.dto'
import { updateEmail } from '@/api/consoleApi/contents/emails'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import { CustomField } from '@/utils/customFields'
import { getEmailSubjectTags } from '@/utils/personalizations'
import { Picker } from 'emoji-mart-vue-2'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'VsEmailSubjectCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsInlineErrorsCheck,
        VsSeparator,
        VsHintCard,
        VsDropdownButton,
        Picker,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) message!: IMessageEmail
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    @Prop({ required: false, default: () => [] }) customFields!: CustomField[]
    private loading = false
    private tempSubject = ''
    private tempSummary = ''
    private emptyEmoji = 'department_store'
    private cursorPosition: undefined | number = undefined
    $refs: any

    openTab () {
        this.$emit('open-tab', 'subject')
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get subjectCardStatus () {
        return this.message.subject ? 'success' : 'default'
    }

    get subjectTags () {
        return getEmailSubjectTags(this.customFields)
    }

    get maxNineWordsStatus () {
        if (!this.tempSubject) return 'default'
        return this.tempSubject.split(' ').filter(Boolean).length <= 9 ? 'success' : 'warning'
    }

    get maxSixtyLettersStatus () {
        if (!this.tempSubject) return 'default'
        return this.tempSubject.length <= 60 ? 'success' : 'warning'
    }

    get hasExclamationMarksStatus () {
        if (!this.tempSubject) return 'default'

        return this.tempSubject.split('').filter(el => el === '!').length < 2 ? 'success' : 'warning'
    }

    get totalSubjectStatus () {
        if (
            this.maxNineWordsStatus === 'warning' ||
            this.maxSixtyLettersStatus === 'warning' ||
            this.hasExclamationMarksStatus === 'warning'
        ) return 'warning'
        return 'success'
    }

    get differentFromSubjectStatus () {
        if (!this.tempSummary) return 'default'

        return this.tempSummary !== this.tempSubject ? 'success' : 'warning'
    }

    get totalSummaryStatus () {
        if (
            this.differentFromSubjectStatus === 'warning'
        ) return 'warning'
        return 'success'
    }

    private detectSubjectInputCursorPosition () {
        this.cursorPosition = this.$refs?.subjectInput?.$children[1].$refs?.input?.selectionStart || this.tempSubject.length
    }

    private openTabSubject () {
        this.tempSubject = this.message.subject
        this.tempSummary = this.message.pre_header || ''
        this.cursorPosition = undefined
        this.openTab()
    }

    private appendCustomFields (tag: string) {
        this.tempSubject = this.insertString(this.tempSubject, tag, this.cursorPosition)
    }

    private addEmoji (emoji: {
        id: string
        name: string
        colons: string
        text: string
        emoticons: string[]
        skin: number | null
        native: string
    }) {
        this.tempSubject = this.insertString(this.tempSubject, emoji.native, this.cursorPosition)
    }

    private insertString (mainString: string, insString: string, pos?: undefined | number) {
        if (typeof pos === 'undefined') {
            pos = this.tempSubject.length
        }
        if (typeof insString === 'undefined') {
            insString = ''
        }
        this.cursorPosition = pos + insString.length
        return mainString.slice(0, pos) + insString + mainString.slice(pos)
    }

    private async saveSubject () {
        this.loading = true
        try {
            await updateEmail(
                this.message.id,
                {
                    subject: this.tempSubject,
                    pre_header: this.tempSummary,
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.subject.subjectSave'),
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('subject-saved')
            this.$emit('open-tab', '')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.subject.subjectSaveError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }
}
