















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { openProject, generateProject, addCustomStringToUser } from '@/api/drago'
import { getTokenForDragoUser } from '@/api/console'
import { updateEmail } from '@/api/consoleApi/contents/emails'
import { UserModule } from '@/store/modules/user'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { CustomField } from '@/utils/customFields'
import { getDragoContentTags } from '@/utils/personalizations'

@Component({
    name: 'VsDragoModal',
    components: {
        VsFullTopBarLayout,
        VsContainer,
        VsSectionHeader,
        VsDropdownButton,
        VsConfirm,
        VsPreviewModal,
    },
})
export default class extends Vue {
    @Prop({ required: false, default: 'campaign' }) contentMode!: 'campaign' | 'optin' | 'transactional'
    @Prop({ required: false, default: false, type: Boolean }) isTemplate!: boolean
    private loading = false
    private email: any = null
    private iframeSrc = ''
    private alwaysMount = true
    private open = false
    private isClosableAfterSave = false
    private openPreviewAfterSave = false
    private token = ''
    private dragoUserId = ''
    private isDirty = false
    private tags: any = {}
    $refs!: any

    get user () {
        return UserModule.user
    }

    get consoleUrl () {
        return `${window.location.protocol}//${window.location.hostname}`
    }

    beforeMount () {
        window.addEventListener('message', this.receiveMessage)
    }

    beforeDestroy () {
        window.removeEventListener('message', this.receiveMessage)
    }

    public async openModal (email: any, customFields: CustomField[]) {
        this.iframeSrc = ''
        this.openPreviewAfterSave = false
        this.isClosableAfterSave = false
        this.isDirty = false
        this.tags = getDragoContentTags(customFields)
        this.open = true
        this.openProject(email)
    }

    public closeModal () {
        this.open = false
    }

    private async confirmAndClose () {
        try {
            if (this.isDirty) {
                await this.$refs.confirmExit.openConfirm()
            }
            this.closeModal()
        } catch (e) {}
    }

    private async openProject (email: any) {
        this.email = email
        this.loading = true
        const autosave = 0 // 7500
        const language = 'it'
        try {
            const resp = await getTokenForDragoUser()
            this.token = resp.data.token
            this.dragoUserId = resp.data.uid
            const params: any = {
                user: resp.data.uid,
                token: resp.data.token,
                language: language,
            }

            await addCustomStringToUser(
                params,
                {
                    ...this.tags,
                },
            )
            params.data = { autosave }
            const res = await openProject(email.project_id, params)
            this.iframeSrc = `${res.data.url}${res.data.queryString}`
        } catch (e) {
            console.log(e)
        }
    }

    async receiveMessage (event: any) {
        try {
            const data = JSON.parse(event.data)
            this.actionsWithObjectMessage(data, event)
        } catch (e) {
            switch (event.data) {
                case 'ProjectLoadingSuccess':
                    this.loading = false
                    break
                case 'JsonChanged':
                    this.isDirty = true
                    break
                case 'Save result: success':
                    await this.updateDragoContent()
                    this.$emit('content-saved')
                    this.loading = false
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Contenuto salvato con successo',
                        aspect: VsToastAspectEnum.success,
                    })
                    if (this.isClosableAfterSave) {
                        this.isClosableAfterSave = false
                        this.closeModal()
                    }
                    if (this.openPreviewAfterSave) {
                        this.openPreviewAfterSave = false
                        this.$refs.previewModal.openModalNew(this.isTemplate ? { ...this.email, id: this.email.themeId } : this.email, this.isTemplate ? 'emailTemplate' : 'email')
                    }
                    break
                case 'Save result: failed':
                    this.loading = false
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Errore durante il salvataggio del contenuto',
                        aspect: VsToastAspectEnum.alert,
                    })
                    if (this.isClosableAfterSave) {
                        this.isClosableAfterSave = false
                    }
                    if (this.openPreviewAfterSave) {
                        this.openPreviewAfterSave = false
                        this.$refs.previewModal.openModalNew(this.isTemplate ? { ...this.email, id: this.email.themeId } : this.email, this.isTemplate ? 'emailTemplate' : 'email')
                    }
                    break
                default:
                    break
            }
        }
    }

    private actionsWithObjectMessage (msg: any, event: any) {
        switch (msg.action) {
            case 'delete':
                this.deleteElement(event)
                break
            case 'edit':
            case 'subEdit':
                if (msg.elementJson.type === 'IMAGE') {
                    this.openMediaLibrary(msg, event)
                }
                break
            case 'editBackground':
                if (msg.elementJson.type === 'BOX' || msg.elementJson.type === 'BUTTON' || msg.elementJson.type === 'GENERAL') {
                    this.openMediaLibrary(msg, event)
                }
        }
    }

    private openMediaLibrary (msg: any, event: any) {
        window.openVsMediaLibrary({
            url: msg.elementJson.type === 'IMAGE' ? msg.elementJson.src : msg.elementJson.background.image.src,
            mode: 'selection',
            allowedTypes: 'image/jpeg,image/gif,image/png,image/x-icon,image/webp,image/ico,image/icon,image/vnd.microsoft.icon,image/svg+xml',
        }).then((url: string) => {
            const props = msg.elementJson.type === 'IMAGE' ? {
                src: url,
            } : {
                background: {
                    image: {
                        src: url,
                    },
                },
            }

            event.source.postMessage(JSON.stringify({
                _dynId: msg._dynId,
                action: 'setProps',
                props,
            }), event.origin)
        }).catch((e: any) => { console.log(e) })
    }

    async saveProject () {
        this.loading = true
        this.$refs.dragoIframe.contentWindow.postMessage('saveProject', '*')
    }

    saveProjectAndClose () {
        this.loading = true
        this.saveProject()
        this.isClosableAfterSave = true
    }

    deleteElement (event: any) {
        const message = JSON.parse(event.data)
        event.source.postMessage(JSON.stringify({
            action: 'applyDelete',
            _dynId: message._dynId,
        }), '*')
    }

    private openPreview () {
        this.saveProject()
        this.openPreviewAfterSave = true
    }

    private async updateDragoContent () {
        try {
            const resp = await generateProject(
                this.email.project_id,
                {
                    user: this.dragoUserId,
                    token: this.token,
                },
            )

            if (!this.isTemplate) {
                await updateEmail(
                    this.email.id,
                    {
                        content: {
                            html: resp.data,
                        },
                        footer_id: 0,
                    },
                )
            }

            this.isDirty = false
        } catch (e) {
            console.log(e)
        }
    }
}
