var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsPopover',{ref:"searchPopover",staticClass:"vs-block vs-w-full",attrs:{"placement":"bottom-end","trigger":"manual"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('VsInput',{ref:"searchInput",staticClass:"vs-w-full",attrs:{"labelHidden":"","iconName":"search","identifier":"VsMainConsoleSearch","debounce":500,"size":"large","clearable":"","placeholder":_vm.$t('topnav.search')},on:{"input":function($event){return _vm.$refs.searchPopover.showPopover()},"click":_vm.openSearch},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})]},proxy:true},{key:"default",fn:function(ref){
var closePopover = ref.closePopover;
return [_c('div',{attrs:{"id":"vs-result-search"},on:{"click":closePopover}},[(_vm.loading)?_c('div',[_c('div',{staticClass:"group"},[_c('div',{staticClass:"header"},[_vm._v(" "+_vm._s(_vm.$t('topnav.searching'))+" ")])])]):_c('div',[_vm._l((_vm.users),function(user){return _c('VsActionItem',{key:user._id,attrs:{"showTick":"","label":user.email,"to":{
                        name: 'managerChild',
                        params: {
                            childId: user._id,
                        },
                    }}})}),(_vm.users.length === 0)?_c('VsActionItem',{attrs:{"label":"NESSUN ACCOUNT TROVATO","disabled":""}}):_vm._e()],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }