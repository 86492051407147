















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsInlineErrorsCheck',
})
export default class extends Vue {
    @Prop({ required: true, default: 'default' }) status!: 'default' | 'success' | 'warning' | 'error'

    get iconName () {
        switch (this.status) {
            case 'success': return 'success'
            case 'warning': return 'alert'
            case 'error': return 'error'
            default: return 'dot'
        }
    }

    get iconColor () {
        switch (this.status) {
            case 'success': return 'success-900'
            case 'warning': return 'warning-900'
            case 'error': return 'alert-900'
            default: return 'grey-400'
        }
    }
}
