import { serviceDrago } from '@/utils/request'
import { CancelToken } from 'axios'

export const openProject = (projectId: any, params?: any, cancelToken?: CancelToken) =>
    serviceDrago.get(
        `/jsonp/project/open/${projectId}`,
        {
            params,
            cancelToken,
        },
    )

export const generateProject = (projectId: any, params: {user: string, token: string}, cancelToken?: CancelToken) =>
    serviceDrago.get(
        `/jsonp/project/generate/${projectId}`,
        {
            params,
            cancelToken,
        },
    )

export const addCustomStringToUser = (params: {user: string, token: string}, data: any, cancelToken?: CancelToken) =>
    serviceDrago.post(
        `/json/customStrings/addToCurrentUser`,
        data,
        {
            params,
            cancelToken,
        },
    )
