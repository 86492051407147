
























































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import MediaLibraryActionsMixin from './../mixins/MediaLibraryActionsMixin.vue'
import VsMediaLibraryCard from './../components/VsMediaLibraryCard/Index.vue'
import VsMediaLibraryDetail from './../components/VsMediaLibraryDetail/Index.vue'
import VsImageEditor from './../components/VsImageEditor/Index.vue'
import VsMediaLibraryDirectoryCard from './../components/VsMediaLibraryDirectoryCard/Index.vue'
import VsBreadCrumbs from './../components/VsBreadCrumbs/Index.vue'
import VsButtonActionsContainer from '@/components/VsButtonActionsContainer/Index.vue'
import VsTopMultiActions from './../components/VsTopMultiActions/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsEmptyState from '@/components/VsEmptyState/Index.vue'

import {
    downloadFiles,
    getFiles,
    moveToTrashFiles,
    replaceFile,
    uploadFile,
    moveFilesToDirectory,
} from '@/api/filemanager/files'

import {
    getNextPage,
} from '@/api/filemanager/utils'

import {
    createDirectory,
    getDirectories,
    getDirectoryAnchestors,
    renameDirectory,
} from '@/api/filemanager/directories'
import { IUserInfo, MediaLibraryModule } from '@/store/modules/mediaLibrary'
import axios from 'axios'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'MyFiles',
    components: {
        VsMediaLibraryCard,
        VsMediaLibraryDetail,
        VsImageEditor,
        VsBreadCrumbs,
        VsMediaLibraryDirectoryCard,
        VsButtonActionsContainer,
        VsTopMultiActions,
        VsConfirm,
        VsEmptyState,
    },
})
export default class extends Mixins(MediaLibraryActionsMixin) {
    private folderTree: any[] = [
        {
            name: 'Tutti i files',
            id: 0,
        },
    ]

    private mediaLibraryType = 'default'
    private nextPageUrl = ''
    private nextDirectoryPageUrl = ''
    private showCreateFolderModal = false
    private emptyState = true
    private directoryFormName = ''
    private directoryFormId = ''
    private libraryDirectories: any[] = []
    private mimeFilter = ''
    private orderBy = null
    private total = 0
    private counter = 0
    private directoryUpdateLoader = false
    $options: any

    get filterOptions () {
        return [
            {
                value: 'image/jpeg,image/gif,image/png,image/x-icon,image/webp,image/ico,image/vnd.microsoft.icon,image/icon,image/svg+xml',
                label: `Immagini`,
            },
            {
                value: 'text/plain,application/pdf,application/msword,application/excel,application/vnd.ms-excel,application/x-excel,application/x-msexcel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/ico,application/epub+zip,application/x-mobipocket-ebook,application/octet-stream',
                label: `Documenti`,
            },
            {
                value: 'application/zip,application/x-rar-compressed,application/x-rar,application/x-7z-compressed',
                label: `Archivi`,
            },
        ]
    }

    get orderOptions () {
        return [
            {
                value: 'created_at-desc',
                label: 'Più recenti',
            },
            {
                value: 'created_at-asc',
                label: 'Meno recenti',
            },
        ]
    }

    get user () {
        return UserModule.user
    }

    get hasShop () {
        return this.user.configuration.rules.shop
    }

    get searchInput () {
        return MediaLibraryModule.searchInput
    }

    set searchInput (val) {
        MediaLibraryModule.SET_SEARCH_INPUT(val)
    }

    get currentSelectedFile () {
        return MediaLibraryModule.url
    }

    set currentSelectedFile (val) {
        MediaLibraryModule.SET_URL(val)
    }

    get libraryItems () {
        return MediaLibraryModule.libraryItems
    }

    set libraryItems (val) {
        MediaLibraryModule.SET_LIBRARY_ITEMS(val)
    }

    get checkedFileIds () {
        return MediaLibraryModule.checkedFileIds
    }

    set checkedFileIds (val) {
        MediaLibraryModule.SET_CHECKED_FILE_IDS(val)
    }

    get rootFolder () {
        return MediaLibraryModule.rootFolder
    }

    get currentDirectory () {
        return MediaLibraryModule.currentDirectory
    }

    set currentDirectory (val) {
        MediaLibraryModule.SET_CURRENT_DIRECTORY(val)
    }

    get userInfo (): IUserInfo {
        return MediaLibraryModule.userInfo
    }

    get totalSpaceAvailable (): number {
        return this.userInfo.user.rules.limit.value
    }

    get totalSpaceAvailableUnit (): string {
        return this.userInfo.user.rules.limit.unit
    }

    get totalSpaceUsed (): number {
        return (this.notTrashedSize + this.trashedSize)
    }

    get notTrashedSize (): number {
        return parseInt(this.userInfo.files.notTrashedSize) || 0
    }

    get trashedSize (): number {
        return parseInt(this.userInfo.files.trashedSize) || 0
    }

    get spacePercentage (): number {
        return (100 * this.$options.filters.calculateBytes(this.totalSpaceUsed, 3, this.totalSpaceAvailableUnit)) / this.totalSpaceAvailable
    }

    goToShop () {
        if (this.$router) {
            this.$router.push({
                name: 'subscriptions',
            })
            this.closeMediaLibrary()
            return
        }
        window.location.href = '/user/shop/prices/subscriptions'
    }

    public closeMediaLibrary () {
        if (MediaLibraryModule.modalPromiseReject) MediaLibraryModule.modalPromiseReject()
        MediaLibraryModule.SET_MODAL_PROMISE_REJECT(null)
        MediaLibraryModule.SET_MODAL_PROMISE_RESOLVE(null)
        MediaLibraryModule.SET_SHOW_MEDIALIBRARY(false)
    }

    private openCreateFolderModal (libraryDirectory?: any) {
        if (libraryDirectory) {
            this.directoryFormName = libraryDirectory.name
            this.directoryFormId = libraryDirectory.id
        } else {
            this.directoryFormName = ''
            this.directoryFormId = ''
        }
        this.showCreateFolderModal = true
    }

    private openImageEditor (libraryItem: string) {
        this.$refs.imageEditor.open(libraryItem)
    }

    async beforeMount () {
        if (MediaLibraryModule.mode === 'selection') this.mediaLibraryType = 'insert'
        this.openDirectory(this.rootFolder)
    }

    @Watch('searchInput', { immediate: false, deep: true })
    @Watch('orderBy', { immediate: false, deep: true })
    @Watch('mimeFilter', { immediate: false, deep: true })
    public async getFiles () {
        try {
            this.checkedFileIds = []
            let search = ''
            let searchFields = ''
            let whereNull = null
            let mode = 'notDeleted'
            if (this.searchInput) {
                search += this.searchInput
                searchFields += `name:like`
                mode = 'withDeleted'
            } else {
                if (this.currentDirectory.id) {
                    search += `directory_id:${this.currentDirectory.id}`
                    searchFields += `directory_id:=`
                } else {
                    whereNull = 'directory_id'
                }
            }

            if (this.mimeFilter) {
                search += `;mime:${this.mimeFilter}`
                searchFields += ';mime:in'
            }

            const orderByToSplit = this.orderBy || 'created_at-desc'

            const [orderBy, sortedBy] = orderByToSplit.split('-')

            const resp = await getFiles({
                mode,
                orderBy,
                sortedBy,
                limit: 20,
                page: 1,
                search,
                searchFields,
                searchJoin: 'and',
                whereNull,
            })
            this.libraryItems = resp.data.data
            this.nextPageUrl = resp.data.next_page_url
            this.total = resp.data.total
        } catch (e) {
            console.log(e)
            this.nextPageUrl = ''
            this.libraryItems = []
            this.total = 0
        }
    }

    private async checkEmptyState () {
        const dirLenght = await this.checkDirectoryForEmptyState()
        const filesLenght = await this.checkFilesForEmptyState()

        if (dirLenght === 0 && filesLenght === 0) {
            this.emptyState = true
            return
        }
        this.emptyState = false
    }

    private async checkDirectoryForEmptyState () {
        try {
            let search = ''
            let searchFields = ''
            let whereNull = null
            if (this.currentDirectory.id) {
                search += `${this.currentDirectory.id}`
                searchFields += `directory_id:=`
            } else {
                whereNull = ''
                whereNull += 'directory_id'
            }

            const resp = await getDirectories({
                orderBy: 'created_at',
                sortedBy: 'desc',
                search,
                searchFields,
                limit: 1,
                page: 1,
                whereNull,
                searchJoin: 'and',
            })

            return resp.data.total
        } catch (e) {
            console.log(e)
        }
    }

    private async checkFilesForEmptyState () {
        try {
            let search = ''
            let searchFields = ''
            let whereNull = null
            if (this.currentDirectory.id) {
                search = `directory_id:${this.currentDirectory.id}`
                searchFields = `directory_id:=`
            } else {
                whereNull = 'directory_id'
            }
            if (this.mimeFilter) {
                search += `;mime:${this.mimeFilter}`
                searchFields += ';mime:in'
            }

            const resp = await getFiles({
                mode: 'notDeleted',
                limit: 1,
                page: 1,
                search,
                searchFields,
                searchJoin: 'and',
                whereNull,
            })

            return resp.data.total
        } catch (e) {
            console.log(e)
        }
    }

    private async getNextPage () {
        if (!this.nextPageUrl) return
        try {
            const resp = await getNextPage(this.nextPageUrl)
            this.nextPageUrl = resp.data.next_page_url
            this.libraryItems.push(...resp.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    private async getNextDirectoryPageUrl (nextDirectoryPageUrl: string) {
        if (!nextDirectoryPageUrl) return
        try {
            const resp = await getNextPage(nextDirectoryPageUrl)
            nextDirectoryPageUrl = resp.data.next_page_url
            this.libraryDirectories.push(...resp.data.data)
            this.getNextDirectoryPageUrl(nextDirectoryPageUrl)
        } catch (e) {
            console.log(e)
        }
    }

    public async getDirectories () {
        try {
            let search = ''
            let searchFields = ''
            let whereNull = null
            if (this.currentDirectory.id) {
                search += `${this.currentDirectory.id}`
                searchFields += `directory_id:=`
            } else {
                whereNull = ''
                whereNull += 'directory_id'
            }

            const resp = await getDirectories({
                orderBy: 'created_at',
                sortedBy: 'desc',
                search,
                searchFields,
                limit: 40,
                page: 1,
                whereNull,
                searchJoin: 'and',
            })
            const nextDirectoryPageUrl = resp.data.next_page_url
            this.libraryDirectories = resp.data.data
            this.getNextDirectoryPageUrl(nextDirectoryPageUrl)
            await this.checkEmptyState()
        } catch (e) {
            console.log(e)
            this.nextDirectoryPageUrl = ''
            this.libraryDirectories = []
        }
    }

    private async directoryCreated () {
        this.getDirectories()
    }

    private async openNoSpaceConfirm (file: any) {
        try {
            await this.$refs.noSpaceAvailableConfirm.openConfirm()
            this.directDownload(file, file.name)
        } catch (e) {
            console.log(e)
        }
    }

    private async saveImageReplace (e: any) {
        try {
            const data = new FormData()
            data.append('file', e.file)
            const resp = await replaceFile(e.id, data)
            if (!resp.data.success) {
                let heading = this.$t('mediaLibrary.notifications.saveImageReplace.error')
                if (resp.data.message === 'AvailableSpaceReached') heading = this.$t('mediaLibrary.notifications.noSpaceAvailable')
                this.$root.$mediaLibraryToast({
                    heading,
                    timeout: 3000,
                })
                this.openNoSpaceConfirm(e.file)
                return
            }
            this.detailItem = null
            this.$refs.imageEditor.closeEditor()
            this.getMe()
            this.removeImageCache(e.id)
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.saveImageReplace.success'),
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.saveImageReplace.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async saveImage (e: any) {
        try {
            const data = new FormData()
            data.append('file[]', e.file)
            const resp = await uploadFile(data)
            const errors = resp.data.filter((el: any) => !el.success)
            if (errors.length > 0) {
                let heading = this.$t('mediaLibrary.notifications.saveImage.error')
                if (errors[0].message === 'AvailableSpaceReached') heading = this.$t('mediaLibrary.notifications.noSpaceAvailable')
                this.$root.$mediaLibraryToast({
                    heading,
                    timeout: 3000,
                })

                this.openNoSpaceConfirm(e.file)
                return
            }
            this.detailItem = null
            this.$refs.imageEditor.closeEditor()
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.saveImage.success'),
                timeout: 3000,
            })
            this.getFiles()
            this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.saveImage.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private removeImageCache (id: string) {
        this.libraryItems.map((el: any) => {
            if (el.id === id) {
                el.public_url = el.public_url + '?version=' + this.counter
                this.counter++
            }
            return el
        })
    }

    private async getDirectoryAnchestors (id: string) {
        try {
            const resp = await getDirectoryAnchestors(id)
            this.folderTree = []
            this.folderTree.push(this.rootFolder, ...resp.data.map((el: any) => {
                return {
                    name: el.name,
                    id: el.directory_id,
                }
            }))

            if (this.currentDirectory && this.currentDirectory.id !== 0) {
                this.folderTree.push(this.currentDirectory)
            }
        } catch (e) {
            console.log(e)
            this.folderTree = []
        }
    }

    private async createDirectory () {
        const resp = await this.$refs.createDirectoryForm.validate()
        if (!resp) return
        this.directoryUpdateLoader = true
        try {
            if (this.directoryFormId) {
                await renameDirectory(this.directoryFormId, {
                    name: this.directoryFormName,
                })
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.createDirectory.successCreate'),
                    timeout: 3000,
                })
            } else {
                await createDirectory({
                    name: this.directoryFormName,
                    directory_id: this.currentDirectory && this.currentDirectory.id ? this.currentDirectory.id : null,
                })
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.createDirectory.successUpdate'),
                    timeout: 3000,
                })
            }
            this.directoryUpdateLoader = false
            this.showCreateFolderModal = false
            this.getDirectories()
        } catch (e) {
            this.directoryUpdateLoader = false
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.createDirectory.error'),
                timeout: 3000,
            })
        }
    }

    private async openDirectory (directory: any) {
        this.checkedFileIds = []
        this.libraryItems = []
        this.libraryDirectories = []
        this.currentDirectory = directory
        this.getDirectoryAnchestors(this.currentDirectory.id)
        this.getFiles()
        this.getDirectories()
    }

    private async downloadSelectedFiles () {
        try {
            const resp = await downloadFiles({
                ids: this.checkedFileIds,
            })
            const blob = new Blob([resp.data])
            this.directDownload(blob, 'file.zip')
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.downloadSelectedFiles.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async directDownloadFile (libraryItem: any) {
        try {
            const resp = await axios.get(libraryItem.public_url, {
                responseType: 'blob',
            })
            this.directDownload(resp.data, libraryItem.name)
        } catch (e) {
            console.log(e)
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.downloadSelectedFiles.error'),
                timeout: 3000,
            })
        }
    }

    private async moveToTrashSelectedFiles () {
        if (this.checkedFileIds.length === 0) return
        try {
            const resp = await moveToTrashFiles({
                ids: this.checkedFileIds,
            })

            const succes = resp.data.filter((el: any) => el.success)
            const errors = resp.data.filter((el: any) => !el.success)

            this.$root.$mediaLibraryToast({
                heading: this.$tc('mediaLibrary.notifications.moveToTrashSelectedFiles.success', succes.length),
                timeout: 3000,
            })

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$tc('mediaLibrary.notifications.moveToTrashSelectedFiles.errorSome', errors.length),
                    timeout: 3000,
                })
            }

            this.checkedFileIds = []
            this.getFiles()
            this.getMe()
        } catch (e) {
            console.log(e)
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.moveToTrashSelectedFiles.error'),
                timeout: 3000,
            })
        }
    }

    private selectAllFiles () {
        this.checkedFileIds = this.libraryItems.filter((el: any) => {
            return !el.deleted_at
        }).map((el: any) => el.id)
    }

    private async moveAllFiles (id: string) {
        try {
            const resp = await moveFilesToDirectory({
                ids: this.checkedFileIds,
                directory_id: id,
            })
            const succes = resp.data.filter((el: any) => el.success)
            const errors = resp.data.filter((el: any) => !el.success)

            this.$root.$mediaLibraryToast({
                heading: this.$tc('mediaLibrary.notifications.moveAllFiles.success', succes.length),
                timeout: 3000,
            })

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$tc('mediaLibrary.notifications.moveAllFiles.errorSome', succes.length),
                    timeout: 3000,
                })
            }

            this.checkedFileIds = []
            this.getFiles()
            this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.moveAllFiles.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private searchItemAndReplace (libraryItem: any) {
        this.libraryItems = this.libraryItems.map((el: any) => {
            if (el.id === libraryItem.id) {
                el = libraryItem
            }
            return el
        })
    }
}
