/* eslint-disable max-len */
const landingpages = {
    general: {
        save: 'Salva',
        cancel: 'Annulla',
        edit: 'Modifica',
        delete: 'Elimina',
        exit: 'Esci',
        yes: 'Sì',
        no: 'No',
        confirm: 'Conferma',
        properties: {
            createdAt: 'Data di creazione',
            updatedAt: 'Data di ultima modifica',
        },
        active: 'Attivo',
        disactive: 'Disattivo',
        drafts: 'Bozze',
        draft: 'Bozza',
        public: 'Pubblico',
        online: 'Online',
        offline: 'Offline',
        wait: 'Attendere prego...',
        close: 'Chiudi',
        next: 'Prossimo',
        continue: 'Continua',
        back: 'Indietro',
        create: 'Crea',
        filter: 'Filtra',
        publishedAt: 'Pubblicata il',
        createdAt: 'Creata il',
        atTime: 'alle',
        ok: 'Ok',
        clean: 'Pulisci',
        selectImage: 'Scegli immagine',
        saveEdit: 'Salva modifiche',
        remove: 'Rimuovi',
        insert: 'Inserisci',
        send: 'Invia',
    },
    pages: {
        title: 'Le tue Landing Page',
        newLandingPage: 'Crea landing',
        properties: {
            name: 'Nome',
            description: 'Note',
            status: 'Status',
            projectId: 'Progetto',
            slug: 'Slug',
            favicon: 'Favicon',
            userId: 'Utente',
            title: 'Titolo',
            metaDescription: 'Descrizione',
            socialTitle: 'Titolo per i social',
            socialDescription: 'Descrizione per i Social',
            ogImage: 'Immagine per condivisione su Facebook',
            twitterImage: 'Immagine per condivisione su Twitter',
        },
        allertTitleCreate: 'Limite landing page creabili raggiunto!',
        allertSubtitleCreate: 'Hai raggiunto il limite di landing page creabili per il tuo piano.',
        allertTitlePublish: 'Limite landing page pubblicabili raggiunto!',
        allertSubtitlePublish: 'Hai raggiunto il limite di landing page pubblicabili per il tuo piano.',
        allertTitleCreateAndPublish: 'Limite landing page raggiunto!',
        allertSubtitleCreateAndPublish: 'Hai raggiunto il limite di landing page per il tuo piano.',
        allertLink: 'Gestisci piano',
        noData: 'Nessun elemento disponibile',
        deleteAlert: 'Sei sicuro di voler eliminare la tua landing page?',
        tableLoading: 'Attendere per favore...stiamo caricando i dati',
        filterButtonTip: 'Filtri',
        multipleActions: 'Azioni multiple',
        pageDeleteMessage: 'Landing page eliminata correttamente',
        pageDeleteMessageError: 'Errore durante l\'eliminazione della landing page',
        pageDuplicatedMessage: 'Landing page duplicata correttamente',
        pageDuplicatedErrors: {
            default: 'Errore durante la duplicazione della landing page',
            PagesLimitReached: '@:landingpages.CreatePage.errors.PagesLimitReached',
            PagesPublicReached: '@:landingpages.CreatePage.errors.PagesPublicReached',
        },
        pageSentPreviewMessage: 'Anteprima landing page inviata correttamente',
        pageSentPreviewErrors: {
            default: 'Errore durante l\'invio anteprima della landing page',
        },
        pageStatusMessage: 'Status modificato correttamente',
        pageStatusErrors: {
            default: 'Errore durante la modifica dello Status',
            PagesLimitReached: '@:landingpages.CreatePage.errors.PagesLimitReached',
            PagesPublicReached: '@:landingpages.CreatePage.errors.PagesPublicReached',
        },
        pageExportErrors: {
            default: 'Non è possibile esportare l\'html',
        },
    },
    emptyState: {
        noform: {
            title: 'Crea la tua prima landing Page.',
            subtitle: 'Letteralmente, si chiama pagina di atterraggio perché è lì che arriva un visitatore dopo avere cliccato su una CTA o un\'inserzione.',
        },
        missingSteps: {
            title: 'Configura prima il tuo account',
            subtitle: 'Una landing page senza un form è come uno strudel senza mele :|',
            subtitle2: 'Ti consigliamo di seguire prima questi passaggi:',
            cta: 'Crea comunque una landing page',
        },
    },
    subdomains: {
        title: 'Gestione URL personalizzate',
        emptyState: {
            title: 'Ancora nessuna URL presonalizzata creata',
            message: 'Aggiungi subito la tua URL personalizzata e verifica la sua disponibilità',
        },
        addUrl: 'Aggiungi URL',
        editUrl: 'Modifica URL',
        deleteUrl: 'Elimina URL',
        subdomainCard: {
            pagesFoundPopover: '{count} landing | {count} landing',
            pagesFound: 'Nessuna landing page associata a questo sottodominio | associata a questo sottodominio | associate a questo sottodominio',
        },
        editSubdomainModal: {
            titleEdit: 'Modifica Url personalizzata',
            titleCreate: 'Aggiungi URL personalizzata',
        },
        deleteSubdomainModal: {
            title: 'Elimina URL personalizzata',
            message: 'Vuoi davvero eliminare questo dominio?',
        },
        editSuccess: 'URL modificata',
        urlDeleted: 'URL eliminata',
        urlAdded: 'URL aggiunta',
        urlNotAllowed: 'La url inserita è già utilizzata all\'interno della piattaforma o non permessa. Inserisci una nuova URL personalizzata',
        urlgeneralError: 'Qualcosa è andato storto, riprova in un secondo momento',
    },
    pageCard: {
        publicLink: 'Link pubblico',
        result: 'nessun risultato | {total} risultato | {total} risultati',
        editContent: 'Modifica contenuto',
        statistics: 'Statistiche',
        settings: 'Impostazioni',
        actions: 'Altre azioni',
        select: 'Seleziona',
        statusFilter: 'Stato',
        statusAll: 'Tutti gli stati',
        statusOnline: 'Online',
        statusOffline: 'Offline',
        editStatus: 'Modifica status',
        sortBy: 'Ordina per',
        mostRecent: 'Più recenti',
        older: 'Meno recenti',
        createdAt: 'Data di creazione',
        updatedAt: 'Ultima modifica',
        order: 'In ordine',
        descending: 'Decrescente',
        ascending: 'Crescente',
        searchFilter: 'Cerca...',
        id: 'ID',
        slug: 'Slug',
        noSlug: 'Nessuno Slug',
        publicatedAt: 'Data di pubblicazione',
        noPublicatedAt: 'Nessuna data',
        titleEditTip: 'Modifica Nome',
        titleEditSaveTip: 'Salva',
        titleEditCancelTip: 'Annulla',
    },
    pageMenu: {
        actions: 'Azioni',
        offline: 'Metti offline',
        duplicate: 'Duplica',
        sendPreview: 'Invio anteprima',
        export: 'Esporta HTML',
        delete: 'Elimina',
    },
    pageActions: {
        publish: 'Pubblica',
    },
    selectTemplate: {
        select: 'Usa Template',
        preview: 'Anteprima',
        fromZero: 'Parti da zero',
    },
    pageNameModal: {
        title: 'Dai un titolo alla tua landing page',
    },
    PageEmailModal: {
        title: 'Invio anteprima',
        description: 'Inserisci gli indirizzi email con cui vuoi condividere l\'anteprima della tua landing page',
        hint: 'Inserisci un massimo di 10 contatti email',
        label: 'Aggiungi un indirizzo email',
    },
    CreatePage: {
        errors: {
            PagesLimitReached: 'Hai raggiunto il numero di landing page creabili.',
            PagesPublicReached: 'Hai raggiunto il numero di landing page pubblicabili.',
            linkAction: 'Gestisci abbonamento',
            default: 'Errore durante la creazione della landing page',
        },
        title: 'Lasciati ispirare',
        subtitleMainData: 'Dati Principali',
        subtitleTemplate: 'Scegli un modello grafico tra quelli proposti',
    },
    EditPage: {
        defaultPageName: 'Landing page senza titolo',
        pageEditMessage: 'Salvato con successo',
        pageEditMessageError: 'Errore durante il salvataggio dei dati, riprova più tardi',
        publishSuccess: 'La landing page è stata pubblicata.',
        showPublish: 'Vedi',
        showPublishHere: 'Puoi Vederla qui',
        compileFields: 'Completa i campi sotto per garantire un\'esperienza ottimale',
        mainData: {
            name: '@:landingpages.pages.properties.name',
            description: '@:landingpages.pages.properties.description',
            descriptionHint: 'Max 100 caratteri',
            descriptionTip: 'In questo campo è possibile inserire una breve descrizione che aiuti, te o i tuoi collaboratori, a identificare più nel dettaglio lo scopo della landing page. Questo campo non è visibile pubblicamente.',
            slug: '@:landingpages.pages.properties.slug',
            slugHint: 'Max 50 caratteri',
            slugTip: 'In questo campo puoi personalizzare la parte finale dell\'URL pubblico della tua landing page. Ti consigliamo fortemente di compilare questo campo.',
        },
        seoData: {
            headerTitle: 'Impostazioni SEO',
            favicon: '@:landingpages.pages.properties.favicon',
            faviconTip: 'La favicon è l\'icona pubblica che il browser mostra nella scheda di navigazione. Ti consigliamo fortemente di caricare un\'icona per brandizzare la tua landing page delle dimensioni 32x32px.',
            faviconHint: 'Dimensioni 32x32 pixel. Formati accettati .jpg, .jpeg, .png.',
            title: '@:landingpages.pages.properties.title',
            titleTip: 'Il title è il nome che il browser mostra nella scheda di navigazione. Ti consigliamo di compilare questo campo affinchè i motori di ricerca siano in grado di suggerirlo tra i loro risultati. Questo campo non è visibile nel contenuto della landing page.',
            metaDescription: '@:landingpages.pages.properties.metaDescription',
            metaDescriptionTip: 'Questo campo fornisce la descrizione del contenuto della tua landing page per i motori di ricerca. Solitamente viene visualizzato nei risultati di ricerca dopo il title. Questo campo non è visibile nel contenuto della landing page.',
        },
        socialData: {
            headerTitle: 'Social Network',
            socialTitle: 'Titolo',
            socialTitleTip: 'In questo campo puoi specificare il titolo della landing page che verrà mostrato nell\'anteprima di un post Social.',
            socialDescription: 'Descrizione',
            socialDescriptionTip: 'In questo campo puoi specificare la descrizione della landing page che verrà mostrata nell\'anteprima di un post Social.',
            ogImage: '@:landingpages.pages.properties.ogImage',
            ogImageHint: 'Dimensione consigliata 1200 x 630 pixel.',
            ogImageTip: 'In questo campo puoi caricare dalla tua media gallery l\'immagine che verrà mostrata nell\'anteprima di Facebook, Instagram e Linkedin.',
            twitterImage: '@:landingpages.pages.properties.twitterImage',
            twitterImageTip: 'In questo campo puoi caricare dalla tua media gallery l\'immagine che verrà mostrata nell\'anteprima di Twitter.',
            twitterImageHint: 'Dimensione consigliata 1024 x 512 pixel.',
        },
        integrations: {
            title: 'Integrazione',
            tagManagerId: 'Id tag manager',
        },
        leadplus: {
            headerTitle: 'Integrazioni',
            noSelected: 'Nessuno',
            leadplusGroupId: 'Gruppo di Form e Popup',
            description: 'Seleziona un gruppo di campagne per collegarlo alla tua landing page. In questo modo sarà possibile visualizzare i form di Lead Plus in base alle regole da te indicate.',
            selectedGroupNotFound: 'Il gruppo di campagne salvato non è più presente. Il gruppo di campagne potrebbe essere stato cancellato. Seleziona un nuovo gruppo oppure seleziona "Nessuno" per scollegarlo dalla landing page.',
            createGroupLink: 'Crea nuovo',
            noAvailableGroups: 'Non hai gruppi di campagne disponibili.',
            pageLeadplusEditDomainNotFoudError: 'Errore di sincronizzazione con Lead Plus, l\'integrazione non è stata trovata. Provare più tardi',
        },
    },
    statistics: {
        title: 'Statistiche landing page',
        backTip: 'Torna alle tue landing page',
        mainMenuTip: 'Azioni',
        exportPdf: 'Esporta PDF',
        totalVisits: 'Totale Visite',
        totalVisitsTip: 'Indica il numero totale dei visitatori della landing page.',
        totalConversions: 'Totale Conversioni',
        totalConversionsTip: 'Indica il numero di conversioni totali della landing page.',
        conversionRate: 'Tasso di conversione',
        conversionRateTip: 'Indica il rapporto percentuale tra le conversioni e visite uniche.',
        uniqueVisits: 'Visite uniche',
        uniqueVisitsTip: 'Indica il numero di visitatori unici della landing page. Un visitatore che accede più volte nell\'arco di 15 giorni, viene contato una sola volta.',
        uniqueConversions: 'Conversioni uniche',
        uniqueConversionsTip: 'Indica il numero di conversioni uniche della landing page. Una conversione effettuata più volte nell\'arco di 15 giorni dallo stesso visitatore, viene contata una sola volta.',
        eventsTable: {
            title: 'Eventi',
            tip: 'Elenca tutti gli eventi tracciati dalla tua landing page, sia quelli di sistema che quelli configurati da te attraverso l\'editor.',
            headers: {
                eventType: 'Tipologia evento',
                source: 'Fonte',
                createdAt: 'Data',
            },
        },
        tableDataErrorMessage: 'Errore durante il caricamento dei dati della tabella',
    },
    projects: {
        savePublish: 'Salva e pubblica',
        saveExit: 'Salva ed esci',
        saveSuccess: 'Modifiche salvate',
        viewports: {
            desktop: 'Desktop',
            tablet: 'Tablet',
            mobile: 'Mobile',
        },
        highlight: 'Evidenzia bordi',
        code: 'Visualizza codice',
        preview: 'Anteprima',
        undo: 'Annulla modifica',
        redo: 'Ripristina modifica',
        closeTab: 'Chiudi la barra degli strumenti',
        openTab: 'Apri la barra degli strumenti',
        styles: 'Stile',
        settings: 'Impostazioni',
        blocks: 'Elementi',
        layers: 'Livelli',
        layout: 'LAYOUT',
        base: 'BASE',
        blocksTitle: 'TRASCINA GLI ELEMENTI',
        stylesTitle: 'CREA IL TUO DESIGN',
        noSelected: 'Nessun elemento selezionato',
        tiptapPlaceholder: 'Scrivi del testo...',
        normal: 'Normale',
        hover: 'Hover',
        exitConfirm: {
            title: 'Sei sicuro di voler abbandonare?',
            content: 'Continuando perderai il lavoro se non hai ancora salvato.',
        },
        selectedGroupNotFound: 'Il gruppo di campagne di Lead Plus inserito non è stato trovato.',
    },
    leadplusModal: {
        leadplusForm: 'Form e Popup',
        leadplusFormEmbedded: 'Form e Popup Embedded',
        groupNotFound: 'Non hai un gruppo di campagne collegato. Assicurati di collegarne uno per gestire i tuoi form oppure cancellali.',
        formNotFound: 'Il form selezionato non esiste più oppure è stato disattivato. Riseleziona un form dall\'elenco oppure cancella l\'elemento.',
        createNewForm: 'Crea un nuovo Form',
        formName: 'Nome Form',
        selectList: 'Seleziona la lista',
        selectLeadplusForm: 'Seleziona il form',
        errors: {
            default: 'Errore durante la creazione del form',
        },
    },
    grapesjsOptionsComponents: {
        image: {
            align: 'Allineamento immagine',
        },
        fdlpTextimage: {
            direction: 'Inverti contenuto',
            align: 'Allineamento contenuto',
        },
        fdlpWrapper: {
            title: 'Larghezza contenitore',
            container: 'Fissa',
            containerFluid: 'Tutto schermo',
        },
        video: {
            title: 'Proporzioni',
        },
    },
    textEditor: {
        tips: {
            bold: 'Ctrl + b',
            italic: 'Ctrl + i',
            undeline: 'Ctrl + u',
        },
    },
    Templates: {
        properties: {
            title: 'Nome',
            description: 'Descrizione',
            status: 'Status',
            projectId: 'Progetto',
        },
    },
    Trackings: {
        properties: {
            visitorId: 'Visitatore',
            userId: 'Utente',
            pageId: 'Landing',
            type: 'Tipologia',
            ip: 'Indirizzo Ip',
        },
    },
    errorPage: {
        message: 'Non hai i permessi per visualizzare questa landing page.',
    },
}
export default {
    landingpages,
}
