import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'

const smsRouter: RouteConfig[] = [
    {
        path: '/singlesend',
        redirect: { name: 'smsIndex' },
        name: 'singlesend',
        component: VsLayouts,
        children: [
            {
                path: 'sms',
                name: 'smsIndex',
                component: () => import(/* webpackChunkName: "sms" */ './../views/sms.vue'),
                meta: {
                    hidden: true,
                },
            },
        ],
    },
]

export default smsRouter
