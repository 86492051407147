



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import icona from 'tui-image-editor/dist/svg/icon-a.svg'
import iconb from 'tui-image-editor/dist/svg/icon-b.svg'
import iconc from 'tui-image-editor/dist/svg/icon-c.svg'
import icond from 'tui-image-editor/dist/svg/icon-d.svg'
import 'tui-color-picker/dist/tui-color-picker.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import { ImageEditor } from '@toast-ui/vue-image-editor'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'

const blackTheme = {
    'menu.normalIcon.path': icond,
    'menu.activeIcon.path': iconb,
    'menu.disabledIcon.path': icona,
    'menu.hoverIcon.path': iconc,
    'header.display': 'none',
    //  per cambiare i colori
    // 'menu.normalIcon.color': '#8a8a8a',
    // 'menu.activeIcon.color': '#555555',
    // 'menu.disabledIcon.color': '#434343',
    // 'menu.hoverIcon.color': '#e9e9e9',
    // 'submenu.normalIcon.color': '#8a8a8a',
    // 'submenu.activeIcon.color': '#e9e9e9'
}

@Component({
    name: 'VsImageEditor',
    components: {
        ImageEditor,
        VsConfirm,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        default: false,
        required: false,
        type: Boolean,
    })
    private disableReplace?: boolean

    private showEditor = false
    private libraryItem: any = null
    private imageName = ''
    private showSaveNewImage = false
    private loadImage = true

    private useDefaultUI = true
    get options () {
        return {
            usageStatistics: false,
            cssMaxWidth: window.innerWidth,
            cssHeight: 800,
            includeUI: {
                // immagini iniziale da caricare altrimenti i comandi sono disabilitati
                loadImage: {
                    path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
                    name: 'Blank',
                },
                theme: blackTheme,
                locale: this.locale,
                // posizione del menu
                menuBarPosition: 'top',
            },
            // initMenu: 'filter',
        }
    }

    $refs: any

    get locale () {
        return this.$t('mediaLibrary.imageEditor.locale')
    }

    private cropMode () {
        const drawingMode = this.$refs.editor.invoke('getDrawingMode')
        if (drawingMode === 'CROPPER') {
            this.$refs.editor.invoke('startDrawingMode', 'FREE_DRAWING')
        } else {
            this.$refs.editor.invoke('startDrawingMode', 'CROPPER')
        }
    }

    private async saveMethodConfirm () {
        if (this.disableReplace) {
            this.openSaveNewImage('')
            return
        }
        try {
            await this.$refs.saveImageConfirm.openConfirm()
            this.openSaveNewImage(`Copia di ${this.libraryItem.name.replace(/[.][^.]+$/, '')}`)
        } catch (e) {
            const file = await this.convertImage(this.libraryItem.name.replace(/[.][^.]+$/, ''))
            if (!this.checkFilesSize([file])) {
                this.$root.$mediaLibraryToast({
                    heading: 'Errore, dimensione massima 10MB',
                    timeout: 3000,
                })
                return
            }
            this.$emit('save-replace', {
                id: this.libraryItem.id,
                file,
            })
            console.log(e)
        }
        // Ritorna il base64 del''immagine. This data can also be converted into blob, if needed.
    }

    private async convertImage (name: string) {
        if (!this.libraryItem) return
        const format = this.libraryItem.mime === 'image/png' ? 'png' : 'jpeg'
        const resp = this.$refs.editor.invoke('toDataURL', {
            format,
            quality: 0,
        })
        const file = this.dataURLtoFile(resp, name + '.' + format)
        return file
    }

    private async createNewImage () {
        const file = await this.convertImage(this.imageName)
        if (!this.checkFilesSize([file])) {
            this.$root.$mediaLibraryToast({
                heading: 'Errore, dimensione massima 10MB',
                timeout: 3000,
            })
            return
        }
        this.showSaveNewImage = false
        this.$emit('save', {
            file,
        })
    }

    private openSaveNewImage (name: string) {
        this.imageName = name
        this.showSaveNewImage = true
    }

    public async open (libraryItem: any) {
        this.showSaveNewImage = false
        this.showEditor = true
        this.libraryItem = libraryItem
        this.loadImage = true
        await this.$refs.editor.invoke('clearUndoStack')
        await this.$refs.editor.invoke('loadImageFromURL', this.publicUrlNoCache(libraryItem.public_url), 'SampleImage')
        this.loadImage = false
    }

    private publicUrlNoCache (publicUrl: string) {
        if (/\?.+=.*/.test(publicUrl)) {
            publicUrl = publicUrl + '&nocache=true'
        } else {
            publicUrl = publicUrl + '?nocache=true'
        }
        return publicUrl
    }

    public async closeEditor () {
        this.showSaveNewImage = false
        await this.$refs.editor.invoke('clearUndoStack')
        await this.$refs.editor.invoke('clearRedoStack')
        this.showEditor = false
    }

    private dataURLtoFile (dataurl: string, filename: string) {
        if (!this.libraryItem) return
        const arr = dataurl.split(',')
        if (!arr[0]) return
        const arrMatch = arr[0].match(/:(.*?);/)
        if (!arrMatch || !arrMatch[1]) return
        const mime = arrMatch[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], filename, { type: mime })
    }

    private checkFilesSize (files: any[]) {
        let filesize = 0
        for (const x in files) {
            filesize = filesize + ((files[x].size / 1024) / 1024)
        }
        if (filesize > 10) return false
        return true
    }
}
