




















































import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'DomainNameModal',
})
export default class extends Vue {
    private id = ''
    private domain = ''
    private saveButton = ''
    private open = false
    public loading = false
    resolve: any = null
    reject: any = null
    $refs: any

    public async save () {
        const valid = await this.$refs.domainForm.validate()
        if (!valid) return
        this.$emit('updated', this.domain)
        this.closeModal()
    }

    public openModal (domain: string) {
        this.loading = false
        this.domain = domain
        this.open = true
        setTimeout(() => {
            this.$refs.nameInput.$children[0].$refs.input.focus()
        }, 200)
    }

    public closeModal () {
        this.open = false
    }
}
