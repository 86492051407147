





























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsConfirm',
})
export default class extends Vue {
    @Prop({ default: 'Annulla', required: false }) closeButton?: string
    @Prop({ default: 'Conferma', required: false }) confirmButton?: string
    @Prop({ default: '', required: false }) iconName?: string
    @Prop({ default: true, required: false }) autoClose?: boolean
    @Prop({ default: false, required: false, type: Boolean }) closeOnClickOverlay?: boolean
    private showConfirm = false
    private confirmPromiseResolve: any = null
    private confirmPromiseReject: any = null

    mounted () {
        this.$emit('ready', this)
    }

    public reject () {
        this.confirmPromiseReject()
        if (this.autoClose) this.closeConfirm()
    }

    public confirm () {
        this.confirmPromiseResolve()
        if (this.autoClose) this.closeConfirm()
    }

    public closeConfirm () {
        this.showConfirm = false
    }

    public openConfirm () {
        this.showConfirm = true
        return new Promise((resolve, reject) => {
            this.confirmPromiseResolve = resolve
            this.confirmPromiseReject = reject
        })
    }
}
