/* eslint-disable max-len */
const senders = {
    title: 'Mittenti',
    tabs: {
        sendersEmail: 'Email',
        sendersSms: 'SMS',
    },
    index: {
        noResult: '0 mittenti trovati',
        status: {
            placeholder: 'Tutti gli status',
            toBeConfirmed: 'Da confermare',
            enabled: 'Approvato',
            disabled: 'Non approvato',
            approval: 'In approvazione',
        },
    },
    sendersEmail: {
    },
    sendersSms: {
        title: 'Goal tracking',
        description: 'Per attivare il Goal Tracking inserisci questo codice nell\'header del tuo sito o della landing page che vuoi monitorare.',
        copy: 'Copia codice',
    },
    senderCard: {
        status: {
            notConfirmed: 'Da confermare',
            Enabled: 'Approvato',
            Disabled: 'Non approvato',
            Approval: 'In approvazione',
        },
    },
    actions: {
        delete: 'Elimina',
        confirmCode: 'Conferma codice',
        showSender: 'Vedi mittente',

    },
}
export default {
    senders,
}
