import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getSendersSms = (params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/senders/sms`,
        {
            params,
            cancelToken,
        },
    )

export const getSenderSmsById = (senderId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/senders/sms/${senderId}`,
        {
            cancelToken,
        },
    )

export const createSenderSms = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/sms`,
        data,
        {
            cancelToken,
        },
    )

export const confirmSenderSms = (senderId: number | string, data: { auth_code: string }, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/sms/${senderId}/confirm`,
        data,
        {
            cancelToken,
        },
    )

export const sendActivationCodeSms = (senderId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/sms/${senderId}/send-activation-code`,
        {
            cancelToken,
        },
    )

export const deleteSenderSms = (senderId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/senders/sms/${senderId}`,
        {
            cancelToken,
        },
    )
