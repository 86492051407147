var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-flex vs-flex-nowrap md:vs-items-center vs-gap-x-4 vs-gap-y-3",class:{
        'vs-flex-col': !_vm.singleLine,
        'sm:vs-flex-row': !_vm.singleLine,
        'vs-flex-row': _vm.singleLine
    }},[_c('div',{staticClass:"vs-flex vs-flex-nowrap vs-items-center vs-gap-4 vs-flex-auto",class:{'vs-overflow-hidden': _vm.truncate}},[(_vm.hasPreActions)?_c('div',{staticClass:"vs-flex-none"},[_vm._t("preActions")],2):_vm._e(),_c('div',{staticClass:"vs-flex-auto",class:{'vs-overflow-hidden': _vm.truncate}},[_c('div',{staticClass:"vs-flex vs-items-center vs-gap-2",class:{'vs-h600' : _vm.size !== 'medium' && _vm.size !== 'small', 'vs-h500' : _vm.size === 'medium', 'vs-h400' : _vm.size === 'small'}},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                        boundary: 'window',
                        interactive: true,
                    }),expression:"{\n                        boundary: 'window',\n                        interactive: true,\n                    }"}],staticClass:"vs-text-grey-900",class:{'vs-truncate': _vm.truncate, 'vs-cursor-pointer': _vm.hasClickTitleListener},attrs:{"content":_vm.heading},on:{"click":function($event){return _vm.$emit('click-title')}}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.heading))]})],2),(_vm.hasHeadingActions)?_c('div',{staticClass:"vs-flex vs-items-center"},[_vm._t("headingActions")],2):_vm._e()]),_c('div',{staticClass:"vs-flex vs-items-center vs-gap-2"},[(_vm.hasMessage)?_c('div',{staticClass:"vs-body-small vs-text-grey-800",class:{'vs-truncate': _vm.truncate}},[_vm._t("messageSlot",function(){return [_vm._v(_vm._s(_vm.message))]})],2):_vm._e(),(_vm.hasMessageActions)?_c('div',{staticClass:"vs-flex vs-items-center"},[_vm._t("messageActions")],2):_vm._e()])])]),(_vm.hasActions)?_c('div',{staticClass:"vs-flex-none vs-flex vs-items-center vs-gap-4"},[_vm._t("actions")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }