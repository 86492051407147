
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsImage',
})
export default class extends Vue {
    @Prop({ default: '', required: false }) src?: string
    @Prop({ default: false, required: false }) enableHover?: boolean
    @Prop({ default: 'contain', required: false }) backgroundSize?: string
    @Prop({ default: 'center top', required: false }) backgroundPosition?: string
}
