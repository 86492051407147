























































import { Component, Vue, Watch } from 'vue-property-decorator'
import { getChilds } from '@/api/userapi/manager/users'

@Component({
    name: 'VsMainConsoleManagerSearch',
})

export default class extends Vue {
    private searchText = ''
    private searchResult: any = null
    private loading = false
    users: any[] = []
    total = 0
    pagination = {
        page: 1,
        itemsPerPage: 15,
        sortBy: null,
    }

    private filters: any = {
        status: null,
        search: null,
        sortBy: null,
    }

    $refs: any

    @Watch('filters.search', { immediate: false })
    async getUsers () {
        this.loading = true
        try {
            const resp = await getChilds(this.generateQuery())
            this.users = resp.data.docs
            this.total = resp.data.count
        } catch (e) {
            console.log(e)
            this.total = 0
            this.users = []
        }
        this.loading = false
    }

    private generateQuery () {
        const sortBy = this.pagination.sortBy || '-|createdAt'
        const sortSplit = sortBy.split('|')
        const query: any = {
            limit: this.pagination.itemsPerPage,
            skip: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            populate: 'project',
            sort: `${sortSplit[0]}${sortSplit[1]}`,
        }

        const filter: any = {
            $and: [],
        }

        if (this.filters.search) {
            const checkForHexRegExp = /^[a-f\d]{24}$/i
            const $or: any[] = [
                { email: { $regex: this.filters.search, $options: 'i' } },
                { firstname: { $regex: this.filters.search, $options: 'i' } },
                { lastname: { $regex: this.filters.search, $options: 'i' } },
                { company: { $regex: this.filters.search, $options: 'i' } },
            ]
            if (checkForHexRegExp.test(this.filters.search)) {
                $or.push({ _id: this.filters.search })
            }
            filter.$and.push({
                $or,
            })
        }

        if (this.filters.status) {
            filter.$and.push({
                status: this.filters.status,
            })
        }

        if (filter.$and.length > 0) {
            query.filter = JSON.stringify(filter)
        }
        return query
    }

    openSearch () {
        this.$refs.searchPopover.showPopover()
    }
}
