import { serviceFilemanager } from '@/utils/request'

const basePath = '/directories'

export interface GetDirectoriesQueryParams {
    mode?: string // onlyDeleted | withDeleted | notDeleted (default)
    search?: string
    searchFields?: string
    orderBy?: string
    sortedBy?: string
    searchJoin?: string
    page?: number
    limit?: number
    whereNull?: string | null
}

export const getDirectories = (params?: GetDirectoriesQueryParams) =>
    serviceFilemanager.get(basePath, {
        params,
    })

export const getTrashedDirectories = (params?: GetDirectoriesQueryParams) =>
    serviceFilemanager.get(`${basePath}/trash`, {
        params,
    })

export const getDirectoryById = (id: string) =>
    serviceFilemanager({
        url: `${basePath}/${id}`,
        method: 'get',
    })

export const getDirectoryAnchestors = (id: string) =>
    serviceFilemanager({
        url: `${basePath}/${id}/ancestors`,
        method: 'get',
    })

export interface CreateDirectoryData {
    name: string
    directory_id?: string | null
}

export const createDirectory = (data: CreateDirectoryData) =>
    serviceFilemanager.post(`${basePath}`, data)

export interface RenameDirectoryData {
    name: string
}

export const renameDirectory = (id: string, data: RenameDirectoryData) =>
    serviceFilemanager.post(`${basePath}/${id}/rename`, data)

export interface MoveDirectoryToDirectoryData {
    directory_id: string
}

export const moveDirectoryToDirectory = (id: string, data: MoveDirectoryToDirectoryData) =>
    serviceFilemanager.post(`${basePath}/${id}/move`, data)

export interface DuplicateDirectoryData {
    name?: string
}

export const duplicateDirectory = (id: string, data: DuplicateDirectoryData) =>
    serviceFilemanager.post(`${basePath}/${id}/duplicate`, data)

export interface RecoveryDirectoriesData {
    ids: string[]
}

export const recoveryDirectories = (data: RecoveryDirectoriesData) =>
    serviceFilemanager.post(`${basePath}/recovery`, data)

export interface DeleteDirectoriesData {
    ids: string[]
}

export const deleteDirectories = (data: DeleteDirectoriesData) =>
    serviceFilemanager.delete(`${basePath}`, {
        data,
    })

export const moveToTrashDirectories = (data: DeleteDirectoriesData) =>
    serviceFilemanager.post(`${basePath}/trash`, data)
