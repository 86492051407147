








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsSidenavMenuTitle',
    components: {},
})
export default class extends Vue {
    @Prop({
        default: '',
        required: false,
    }) title?: string
}
