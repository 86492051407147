





























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsFullTopBarAndSidebarLayout from '@/components/VsFullTopBarAndSidebarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import { getTemplatesCategories, getTemplates, getSystemTemplates, getLayoutTemplates, createTemplate } from '@/api/consoleApi/contents/templates'
import { getEntityLabels } from '@/api/consoleApi/entityLabels'
import { setEmailFromTemplate } from '@/api/consoleApi/contents/emails'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsDragoTemplateModal',
    components: {
        VsFullTopBarAndSidebarLayout,
        VsContainer,
        VsPreviewModal,
        VsSectionHeader,
        VsImage,
    },
})
export default class extends Vue {
    @Prop({ default: false, required: false, type: Boolean }) createTemplate!: boolean
    private loading = false
    private emailId = 0
    private open = false
    private templates: any = []
    private total = 0
    private pagination = {
        page: 1,
        itemsPerPage: 999,
    }

    templateType: 'myTemplates' | 'layout' | 'system' = 'system'
    categories: any[] = []

    private filters: {
        search: string
        entityLabels: any[]
        category: string
    } = {
        search: '',
        entityLabels: [],
        category: '',
    }

    $refs!: any

    private entityLabels = []

    get user () {
        return UserModule.user
    }

    get tagOptions () {
        return this.entityLabels.map((el: any) => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    get categoriesOptions () {
        return this.categories.map((el: any) => {
            return {
                label: el.NameIT,
                value: el.CategoryID,
            }
        })
    }

    public async openModal (emailId: number) {
        this.templates = []
        this.emailId = emailId
        this.total = 0
        this.templateType = 'system'
        this.resetFilters()
        this.open = true
        this.getTemplatesCategories()
        this.getEntityLabels()
    }

    public closeModal () {
        this.open = false
    }

    private resetFilters () {
        this.filters = {
            search: '',
            category: '',
            entityLabels: [],
        }
        this.getTemplates()
    }

    private async selectTemplate (templateId?: string | null) {
        this.loading = true
        if (this.createTemplate) {
            await this.createTemplateEmail(templateId)
        } else {
            await this.createCampaignEmail(templateId)
        }
        this.loading = false
    }

    async createTemplateEmail (templateId?: string | null) {
        try {
            const res = await createTemplate(
                {
                    title: 'Modello senza titolo',
                    description: 'Modello senza descrizione',
                    template_id: templateId,
                },
            )
            this.$emit('content-created', res.data)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Modello creato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.closeModal()
        } catch (e) {
            console.log(e)
        }
    }

    async createCampaignEmail (templateId?: string | null) {
        try {
            const resp = await setEmailFromTemplate(this.emailId, {
                template_id: templateId,
            })
            this.$emit('content-created', resp.data)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Contenuto creato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.closeModal()
        } catch (e) {
            let heading = 'Errore durante la creazione del contenuto'
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 403) {
                    heading = 'Modello non disponibile'
                }
            }
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                heading,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async getTemplates () {
        this.loading = true
        try {
            if (this.templateType === 'system') {
                const templates: any[] = []
                await this.loopCall(1, templates)
                this.templates = templates
                this.customSort(this.templates, this.user.configuration.rules.templateEmail)
                this.total = templates.length
            } else if (this.templateType === 'layout') {
                await this.getLayoutTemplates()
            } else {
                await this.getUserTemplates()
            }
        } catch (e) {
            console.log(e)
            this.total = 0
            this.templates = []
        }
        this.loading = false
    }

    async loopCall (page: number, values: any[]) {
        if (page > 10) return
        const resp = await getSystemTemplates({
            orderBy: 'id',
            sortedBy: 'desc',
            page,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        })
        values.push(...resp.data.data)
        if (resp.data.meta.pagination.total <= values.length) return
        await this.loopCall(page + 1, values)
    }

    async getUserTemplates () {
        const resp = await getTemplates(this.buildParams())
        this.templates = resp.data.data
        this.total = resp.data.meta.pagination.total
    }

    async getLayoutTemplates () {
        const resp = await getLayoutTemplates(this.buildParams())
        this.templates = resp.data.data
        this.total = resp.data.meta.pagination.total
    }

    private selectType (type: 'system' | 'layout' | 'myTemplates') {
        this.templateType = type
        this.getTemplates()
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        // if (this.pagination.orderBy) {
        //     const order = this.pagination.orderBy.split('|')
        //     params.orderBy = order[0]
        //     params.sortedBy = order[1]
        // }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.search.trim() !== '' ? `title:${this.filters.search}` : '',
            this.filters.entityLabels.length > 0 && this.templateType === 'myTemplates' ? `entityLabels.id:${this.filters.entityLabels}` : '',
            this.filters.category && this.templateType === 'system' ? `category.id:${this.filters.category}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.search.trim() !== '' ? 'title:like' : '',
            this.filters.entityLabels.length > 0 && this.templateType === 'myTemplates' ? 'entityLabels.id:in' : '',
            this.filters.category && this.templateType === 'system' ? 'category.id:=' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private async getTemplatesCategories () {
        try {
            const resp = await getTemplatesCategories()
            this.categories = resp.data.data
        } catch (e) {
        }
    }

    private async getEntityLabels () {
        try {
            const resp = await getEntityLabels()
            this.entityLabels = resp.data.data
        } catch (e) {
        }
    }

    private openPreview (template: any) {
        this.$refs.previewModal.openModalNew(template, 'emailTemplate')
    }

    isTemplateAvailable (template: any) {
        if (this.user.configuration.rules.templateEmail[0] === '*' || this.templateType === 'myTemplates') return true
        return template.user_id !== 0 || this.user.configuration.rules.templateEmail.includes(template.id + '')
    }

    customSort (array: any[], referenceArray: any[]) {
        return array.sort((a, b) => {
            const aIndex = referenceArray.indexOf(a.id + '')
            const bIndex = referenceArray.indexOf(b.id + '')

            // Se a o b non sono presenti nell'array di riferimento, li metti alla fine.
            if (aIndex === -1 && bIndex === -1) {
                return 0
            } else if (aIndex === -1) {
                return 1
            } else if (bIndex === -1) {
                return -1
            }

            // Ordina in base all'indice nell'array di riferimento.
            return aIndex - bIndex
        })
    }
}
