/* eslint-disable max-len */
const shop = {
    plans: 'Scegli Piano',
    createCartWithItems: 'Crea carrello con crediti',
    deleteUserSubscription: 'Cancella subscription stripe (di conseguenza anche utente)',
    createCartWithPlanAndAddon: 'Crea carrello con un piano e un addon',
    checkoutSession: 'Checkout',
    planShopModal: {
        back: 'Indietro',
        selectPlan: 'Scegli un piano',
        title: 'Piani in abbonamento',
        subtitle: 'Scegli un piano in abbonamento se invii email con frequenza e vuoi una soluzione{0}completa e senza pensieri, perfettamente scalabile sulle tue esigenze.',
        top: 'Il più richiesto',
        custom: 'Su misura',
        monthly: 'al mese',
        contact: 'Contattaci',
        purchase: 'Acquista',
        freeTry: 'Prova gratis 7 giorni',
        editPlan: 'Modifica piano',
        planCards: {
            small: {
                title: 'Small',
                subtitle: 'Il punto di partenza per iniziare ad inviare email intelligenti in modo professionale.',
            },
            business: {
                title: 'Business',
                subtitle: 'Per far crescere i tuoi progetti, automatizzare il lavoro e far crescere la tua azienda.',
            },
            custom: {
                title: 'Custom',
                subtitle: 'Se non trovi la tua soluzione o il piano Professional non risponde a tutte le tue esigenze costruiremo un piano apposta per te. Decidi tu quali funzionalità sono essenziali per il tuo progetto, come far evolvere le tue competenze digitali a un prezzo fatto apposta per le esigenze della tua azienda.',
            },
            communication: {
                title: 'Communication',
                subtitle: 'Il punto di partenza per iniziare ad inviare email intelligenti in modo professionale. Per te che hai necessità di comunicare in maniera continuativa con la tua base utenti.',
            },
            acquisition: {
                title: 'Acquisition',
                subtitle: 'Per far crescere i progetti digitali della tua azienda, creare strategie di lead acquisition e automatizzare il tuo business.',
            },
            professional: {
                title: 'Professional',
                subtitle: 'Per te che hai esigenze elevate, vuoi promuovere i prodotti del tuo ecommerce, integrare diversi tool e hai necessità di gestire in maniera efficace le tue strategie di marketing.',
            },
            happyhourestate2024: {
                title: 'Happy Hour',
                subtitle: '',
            },
        },
        planList: {
            small: {
                title: 'Funzionalità chiave:',
                list: [
                    {
                        label: 'Contatti illimitati',
                    },
                    {
                        label: '2 liste',
                    },
                    {
                        label: '15 campi di lista',
                    },
                    {
                        label: 'Etichette e Gruppi',
                    },
                    {
                        label: '5 modelli email',
                    },
                    {
                        label: 'Editor Drag&Drop',
                    },
                    {
                        label: 'Statistiche base',
                    },
                    {
                        label: 'Supporto via ticket',
                    },
                ],
            },
            business: {
                title: 'Funzionalità Small più:',
                list: [
                    {
                        label: '20 Liste',
                    },
                    {
                        label: '30 campi di lista',
                    },
                    {
                        label: 'Invio Multilista',
                    },
                    {
                        label: '100+ modelli email',
                    },
                    {
                        label: 'Statistiche Avanzate',
                    },
                    {
                        label: 'Marketing Automation',
                    },
                    {
                        label: 'Form e PopUp Avanzati',
                    },
                    {
                        label: 'Email Retargeting',
                    },
                    {
                        label: 'Campagne A/B test',
                    },
                    {
                        label: 'Segmentazione Avanzata',
                    },
                    {
                        label: 'Email Booster',
                    },
                    {
                        label: 'Conservazione dati iscritto 180gg',
                    },
                    {
                        label: 'Senza Logo 4Dem nelle Email',
                    },
                ],
            },
            custom: {
                title: 'In più rispetto agli altri piani:',
                list: [],
            },
            communication: {
                title: 'funzionalità chiave:',
                list: [
                    {
                        label: 'Contatti illimitati',
                    },
                    {
                        label: '2 liste',
                    },
                    {
                        label: '5 campi di lista',
                    },
                    {
                        label: 'Modelli email Premium',
                    },
                    {
                        label: '10 MB spazio Libreria Media',
                    },
                ],
            },
            acquisition: {
                title: 'funzionalità chiave:',
                list: [
                    {
                        label: 'Contatti illimitati',
                    },
                    {
                        label: '10 liste',
                    },
                    {
                        label: '10 campi di lista',
                    },
                    {
                        label: 'Modelli email Premium',
                    },
                    {
                        label: '50 MB spazio Libreria Media',
                    },
                    {
                        label: 'Invio multi lista',
                    },
                    {
                        label: '5 Form e Popup',
                    },
                    {
                        label: '5 Landing Page',
                    },
                ],
            },
            professional: {
                title: 'funzionalità chiave:',
                list: [
                    {
                        label: 'Contatti illimitati',
                    },
                    {
                        label: '20 liste',
                    },
                    {
                        label: '50 campi di lista',
                    },
                    {
                        label: 'Modelli email Premium',
                    },
                    {
                        label: '100 MB spazio Libreria Media',
                    },
                    {
                        label: 'Invio multi lista',
                    },
                    {
                        label: '10 Form e Popup',
                    },
                    {
                        label: '10 Landing Page',
                    },
                    {
                        label: 'Onboarding personalizzato',
                    },
                    {
                        label: 'Coaching personale',
                    },
                ],
            },
            happyhourestate2024: {
                title: 'funzionalità chiave:',
                list: [
                    {
                        label: 'Creazione di una lista contatti senza limiti di invio giornaliero',
                    },
                    {
                        label: 'Creazione di un mittente email',
                    },
                    {
                        label: 'Due campi di lista (es. indirizzo email, numero di telefono)',
                    },
                    {
                        label: 'Accesso all\'editor visuale drag & drop',
                    },
                    {
                        label: '5MB di spazio nella media library per caricare immagini e file',
                    },
                    {
                        label: 'Modelli base da cui partire',
                    },
                    {
                        label: 'Test di spam e invio anteprime',
                    },
                    {
                        label: 'Assistenza standard via ticket',
                    },
                ],
            },
        },
    },
    cart: {
        back: 'Indietro',
        firstStep: 'La tua scelta',
        secondStep: 'Fatturazione',
        thirdStep: 'Pagamento',
        selectPricePlan: 'Quante email invii al mese?',
        selectPriceCredits: 'Crediti',
        creditType: {
            transactional: 'Transazionali',
            email: 'Email',
            sms: 'SMS',
        },
    },
    prices: {
        addons: {
            supportChat: 'Interagisci in tempo reale con il nostro team di supporto grazie alla chat dedicata in aggiunta ai ticket di assistenza. La chat è attiva in orario lavorativo dalle 9 alle 12 e dalle 14 alle 18.',
            recipients: 'Aggiungi 5 liste per la gestione dei tuoi contatti. Le liste aggiuntive si sommano a quelle già previste dal tuo piano.',
            eplus: 'L\'integrazione E-commerce ti permette di integrare e sincronizzare i dati del tuo Shop online. Questa integrazione è compatibile con Woocommerce e Prestashop.',
            pages: 'Aggiungi 5 landing page a quelle già previste dal tuo piano per creare pagine personalizzate per farti notare',
            forms: 'Fai crescere il numero di iscritti alle tue liste. Aggiungi 5 Form e PopUp per la raccolta contatti a quelli già previsti dal tuo piano.',
            mediaLibrary: 'Aumenta lo spazio della tua Image library di 1000MB. Usa lo spazio per l\'archiviazione di immagini e file.',
            backupData: 'Aumenta di ulteriori 365 giorni la conservazione delle statistiche sul singolo contatto relativo alle statistiche di apertura e click registrati per ogni invio.',
            noLogo: 'Rimuovi il logo di qualità di 4DEM dalla parte inferiore (footer) delle email che invii',
            manager: 'L\'area manager permette l\'accesso ad un\'area della console dedicata alla gestione dei propri crediti email e all\'attribuzione ai propri sub-account. Include un pannello di controllo di operatività dei propri clienti, gestione modelli, statistiche. Non include sub-account',
            senderAuthorized: 'Aggiungi 1 mittente per l\'invio delle tue campagne. Il mittente aggiuntivo si somma a quelli già previsti dal tuo piano.',
        },
    },
}
export default {
    shop,
}
