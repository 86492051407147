








































































































































import { Component, Mixins } from 'vue-property-decorator'
import MediaLibraryActionsMixin from './../mixins/MediaLibraryActionsMixin.vue'
import VsButtonActionsContainer from '@/components/VsButtonActionsContainer/Index.vue'
import VsImageCard from '../components/VsImageCard/Index.vue'
import VsImageEditor from '../components/VsImageEditor/Index.vue'
import VsMediaLibraryDetail from '../components/VsMediaLibraryDetail/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import {
    searchImages,
} from '@/api/filemanager/services'
import { importFromUrl, uploadFile } from '@/api/filemanager/files'
import axios from 'axios'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'

@Component({
    name: 'ImportSearchOnline',
    components: {
        VsButtonActionsContainer,
        VsImageCard,
        VsImageEditor,
        VsMediaLibraryDetail,
        VsConfirm,
    },
})
export default class extends Mixins(MediaLibraryActionsMixin) {
    private search = ''
    private total = 0
    private page = 1
    private hasNextPage = true
    private loading = false
    private searchItems: any[] = []
    private loadingFiles: number[] = []

    get selectionMode () {
        return MediaLibraryModule.mode === 'selection'
    }

    get importLabel () {
        return `Importa ${this.selectionMode ? 'e seleziona' : ''}`
    }

    async searchImages (search: string) {
        if (!search) {
            this.searchItems = []
            this.total = 0
            return
        }
        try {
            this.loading = true
            const resp = await searchImages({
                page: this.page,
                search: search,
            })
            this.searchItems = this.mapImageItems(resp.data)
            this.total = resp.data.total
            this.loading = false
        } catch (e) {
            console.log(e)
            this.searchItems = []
            this.total = 0
        }
    }

    private async getNextPage () {
        this.page++
        this.loading = true
        try {
            const resp = await searchImages({
                page: this.page,
                search: this.search,
            })
            if (resp.data.length === 0) {
                this.hasNextPage = false
            }
            this.searchItems.push(
                ...this.mapImageItems(resp.data),
            )
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private mapImageItems (imageItems: any[]) {
        return imageItems.map((el: any) => {
            return {
                id: el.id,
                thumbnail: el.thumbnail,
                public_url: el.url,
                mime: 'image/',
                source: el.source,
                sourceUrl: el.service_url,
                profileUrl: el.profile_url,
                author: el.author,
            }
        })
    }

    private async openImageEditor (libraryItem: any) {
        if (libraryItem.source === 'unsplash') {
            const resp = await axios.get(libraryItem.public_url)
            libraryItem.public_url = resp.data.url
        }
        this.$refs.imageEditor.open(libraryItem)
    }

    private async saveImage (e: any) {
        try {
            const data = new FormData()
            data.append('file[]', e.file)
            const resp = await uploadFile(data)
            const errors = resp.data.filter((el: any) => !el.success)
            if (errors.length > 0) {
                let heading = this.$t('mediaLibrary.notifications.saveImage.error')
                if (errors[0].message === 'AvailableSpaceReached') heading = this.$t('mediaLibrary.notifications.noSpaceAvailable')
                this.$root.$mediaLibraryToast({
                    heading,
                    timeout: 3000,
                })
                this.openNoSpaceConfirm(e.file)
                return
            }
            this.detailItem = null
            this.$refs.imageEditor.closeEditor()
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.saveImage.success'),
                timeout: 3000,
            })
            this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.saveImage.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async importFromUrl (url: string, source: string, index: number) {
        this.loadingFiles.push(index)
        try {
            if (source === 'unsplash') {
                const resp = await axios.get(url)
                url = resp.data.url
            }
            const resp = await importFromUrl(url)

            const errors = resp.data.filter((el: any) => !el.success)

            if (errors.length > 0) {
                let heading = this.$t('mediaLibrary.notifications.importFromUrl.error')
                if (errors[0].message === 'AvailableSpaceReached') heading = this.$t('mediaLibrary.notifications.noSpaceAvailable')
                this.$root.$mediaLibraryToast({
                    heading,
                    timeout: 3000,
                })
            } else {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.importFromUrl.success'),
                    // message: resp.data[0].file.name,
                    timeout: 3000,
                })
                if (this.selectionMode) {
                    this.selectFile(resp.data[0].file)
                }
                this.getMe()
            }
        } catch (e) {
            console.log(e)
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.importFromUrl.error'),
                timeout: 3000,
            })
        }
        this.loadingFiles = this.loadingFiles.filter(el => el !== index)
    }

    private async openNoSpaceConfirm (file: any) {
        try {
            await this.$refs.noSpaceAvailableConfirm.openConfirm()
            this.directDownload(file, file.name)
        } catch (e) {
            console.log(e)
        }
    }
}
