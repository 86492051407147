import VueGtm from '@gtm-support/vue2-gtm'
import Vue from 'vue'
import router from '@/router'

export const gtm = (gtmKey: any) => {
    if (gtmKey) {
        Vue.use(VueGtm, {
            id: gtmKey,
            defer: false,
            compatibility: false,
            enabled: true,
            debug: process.env.NODE_ENV === 'development',
            loadScript: true,
            vueRouter: router,
            ignoredViews: [], // Don't trigger events for specified router names (optional)
            trackOnNextTick: false,
        })
    }
}
