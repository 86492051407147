


















import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLink from '@advision/vision/src/components/utilities/VsLink/Index.vue'

export interface VsTab {
    label: string
    iconName?: string
    iconPosition?: 'left' | 'right'
    disabled?: boolean
    to?: any
    url?: string
    id: string
    click?: (e?: any, tab?: VsTab) => {}
    clickDisabled?: (e?: any, tab?: VsTab) => {}
}

@Component({
    name: 'VsTabs',
    components: {
        VsLink,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: () => [] }) tabs!: VsTab[]
    @Prop({ required: true, default: () => [] }) activeTab!: string

    private getDynamicClasses (tab: VsTab) {
        return {
            'vs-tab': true,
            'vs-flex': true,
            'vs-items-center': true,
            'vs-whitespace-nowrap': true,
            'vs-gap-2': true,
            'vs-px-4': true,
            'vs-pb-4': true,
            'vs-border-grey-400': !this.isActiveTab(tab.id),
            'vs-border-primary-800': this.isActiveTab(tab.id) && !tab.disabled,
            'vs-border-box': true,
            'vs-border-default': true,
            'vs-block': true,
            'vs-cursor-pointer': true,
            'vs-h200': true,
            'vs-text-grey-600': tab.disabled,
            'vs-text-grey-800': !this.isActiveTab(tab.id) && !tab.disabled,
            'hover:vs-text-grey-900': !this.isActiveTab(tab.id) && !tab.disabled,
            'vs-text-primary-900': this.isActiveTab(tab.id) && !tab.disabled,
            'vs-no-underline': true,
        }
    }

    private isActiveTab (tabId: string) {
        return this.activeTab === tabId
    }

    private emitClick (e: any, tab: VsTab) {
        if (tab.disabled) {
            if (!tab.clickDisabled) return
            tab.clickDisabled(e, tab)
            return
        }

        if (tab.click) {
            tab.click(e, tab)
        } else {
            this.$emit('click', tab)
        }
    }
}
