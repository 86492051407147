import moment from 'moment'
import { CustomField, CustomFieldTypeEnum, CustomFieldValidationMethodEnum } from './customFields'

const setValidation = (cf: CustomField, leadplusCf: any) => {
    switch (cf.validation.method) {
        case CustomFieldValidationMethodEnum.numbers:
            leadplusCf.validator = 'numbers'
            leadplusCf.inputType = 'number'
            break
        case CustomFieldValidationMethodEnum.letters:
            leadplusCf.validator = 'letters'
            break
        case CustomFieldValidationMethodEnum.numbersAndLetters:
            leadplusCf.validator = 'numbers_and_letters'
            break
        case CustomFieldValidationMethodEnum.emailAddress:
            leadplusCf.validator = 'email'
            break
        case CustomFieldValidationMethodEnum.url:
            leadplusCf.validator = 'url'
            break
        case CustomFieldValidationMethodEnum.date:
            leadplusCf.validator = 'date'
            break
        case CustomFieldValidationMethodEnum.time:
            leadplusCf.validator = 'time'
            break
        case CustomFieldValidationMethodEnum.custom:
            leadplusCf.validator = 'regexp'
            leadplusCf.pattern = '/' + cf.validation.rule + '/s'
            break
        case CustomFieldValidationMethodEnum.mobile:
            leadplusCf.validator = 'mobile'
            break
        default:
            leadplusCf.validator = 'string'
            break
    }
}

const mapOptionsArray = (options: any[]) => {
    return options.map(el => {
        return {
            val: el.value,
            label: el.label,
            is_selected: el.selected,
        }
    })
}

const setSingleLine = (cf: CustomField) => {
    let id = cf.id
    const isFix = cf.validation.required
    if (cf.type === CustomFieldTypeEnum.email) {
        id = 'email'
        // isFix = cf.validation.required
    }
    if (cf.type === CustomFieldTypeEnum.mobile) {
        id = 'mobile'
        // isFix = cf.validation.required
    }
    const leadplusCf = {
        type: 'input',
        inputType: 'text',
        label: cf.name,
        visible: true,
        model: 'field_' + id,
        required: cf.validation.required,
        modelDefault: cf.default_value,
        inputName: 'cmp_field_' + id,
        fixPlaceholder: '',
        pattern: '',
        placeholder: cf.name,
        isFix: isFix,
        fieldId: cf.id,
    }

    setValidation(cf, leadplusCf)

    return leadplusCf
}

const setMultipleChoice = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.type = 'radios'
    leadplusCf.inputType = ''
    leadplusCf.values = mapOptionsArray(cf.field_options)
    leadplusCf.radiosOptions = {
        value: 'val',
        name: 'label',
    }
    return leadplusCf
}

const setHiddenField = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.visible = false
    return leadplusCf
}

const setParagraphText = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.type = 'textArea'
    leadplusCf.inputType = ''
    return leadplusCf
}

const setDateField = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.type = 'dateTimePicker'
    leadplusCf.inputType = ''
    leadplusCf.dateTimePickerOptions = {
        format: 'YYYY-MM-DD',
    }
    return leadplusCf
}

const setTimeField = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.type = 'timePicker'
    leadplusCf.inputType = ''
    // TODO: cambiare questa porcheria in console e su leadplus
    leadplusCf.format = 'HH:mm:ss'
    leadplusCf.dateTimePickerOptions = {
        format: 'HH:mm:ss',
    }
    return leadplusCf
}

const setDropDown = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.type = 'select'
    leadplusCf.inputType = ''
    leadplusCf.values = mapOptionsArray(cf.field_options)
    leadplusCf.selectOptions = {
        value: 'val',
        name: 'label',
    }
    return leadplusCf
}

const setCheckboxes = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.type = 'checklist'
    leadplusCf.inputType = ''
    leadplusCf.values = mapOptionsArray(cf.field_options)
    leadplusCf.checklistOptions = {
        value: 'val',
        name: 'label',
    }
    return leadplusCf
}

const setBooleanCheckbox = (cf: CustomField) => {
    const leadplusCf: any = setSingleLine(cf)
    leadplusCf.type = 'checkbox'
    leadplusCf.inputType = ''
    leadplusCf.listBox = false
    const option = cf.field_options[0]
    leadplusCf.text = `<p>${option && option.label ? option.label : ''}</p>`
    return leadplusCf
}

export const createLeadplusFields = (cfs: CustomField[]) => {
    return cfs.map((el: CustomField) => {
        switch (el.type) {
            case CustomFieldTypeEnum.singleLine:
            case CustomFieldTypeEnum.email:
            case CustomFieldTypeEnum.mobile:
                return setSingleLine(el)
            case CustomFieldTypeEnum.multipleChoise:
                return setMultipleChoice(el)
            case CustomFieldTypeEnum.hiddenField:
                return setHiddenField(el)
            case CustomFieldTypeEnum.paragraphText:
                return setParagraphText(el)
            case CustomFieldTypeEnum.dropdown:
                return setDropDown(el)
            case CustomFieldTypeEnum.checkboxes:
                return setCheckboxes(el)
            case CustomFieldTypeEnum.dateField:
                return setDateField(el)
            case CustomFieldTypeEnum.timeField:
                return setTimeField(el)
            case CustomFieldTypeEnum.booleanCheckbox:
                return setBooleanCheckbox(el)
            default:
                return setSingleLine(el)
        }
    })
}

const findFieldByOldModel = (customFields: CustomField[], model: string) => {
    const fieldId: string = model.replace('field_', '')

    if (!fieldId) return

    if (fieldId === 'email') {
        return customFields.find(el => el.type === CustomFieldTypeEnum.email)
    }

    if (fieldId === 'mobile') {
        return customFields.find(el => el.type === CustomFieldTypeEnum.mobile)
    }

    return customFields.find(el => el.id === parseInt(fieldId))
}

const findFieldByOldField = (fields: any[], customField: CustomField) => {
    if (!customField) return

    if (customField.type === CustomFieldTypeEnum.email) {
        return fields.find(el => el.model === 'field_email')
    }

    if (customField.type === CustomFieldTypeEnum.mobile) {
        return fields.find(el => el.model === 'field_mobile')
    }

    return fields.find(el => customField.id === parseInt(el.model.replace('field_', '')))
}

export const notExistingFields = (formFields: any[], customFields: CustomField[]) => {
    const notExistingFields: any[] = []
    for (const field of formFields) {
        if (!field.model || field.model.includes('test_acceptance_')) continue
        if (!findFieldByOldModel(customFields, field.model)) {
            notExistingFields.push({
                id: field.fieldId,
                name: field.label,
            })
        }
    }
    return notExistingFields
}

export const missingRequiredFields = (formPages: any[], customFields: CustomField[]) => {
    if (!formPages || formPages.length === 0) {
        return customFields.filter((el: CustomField) => el.validation.required)
    }

    const missingRequiredFields: any[] = []
    for (const field of customFields) {
        if (!field.validation.required) continue
        if (!findFieldByOldField(formPages[0].fields, field)) {
            missingRequiredFields.push({
                id: field.id,
                name: field.name,
            })
        }
    }
    return missingRequiredFields
}

export const fieldsWithDifferentType = (formFields: any[], customFields: CustomField[]) => {
    const fields = createLeadplusFields(customFields)
    const fieldsWithDifferentType: any[] = []
    for (const field of formFields) {
        if (!field.model || field.model.includes('test_acceptance_')) continue
        const fieldWithDifferentType = fields.find(el => el.model === field.model && field.type !== el.type)
        if (fieldWithDifferentType) {
            fieldsWithDifferentType.push({
                id: fieldWithDifferentType.fieldId,
                name: fieldWithDifferentType.label,
            })
        }
    }
    return fieldsWithDifferentType
}

export const setDefaultFields = (fields: any[], override?: any) => {
    return fields.map((field, index) => {
        if (typeof field.key === 'undefined') {
            field = Object.assign({}, field, { key: index })
        }

        if (typeof field.disabled === 'undefined') {
            field = Object.assign({}, field, {
                disabled: false,
            })
        }

        if (typeof field.columnClass === 'undefined') {
            field = Object.assign({}, field, {
                columnClass: '0',
            })
        }

        if (typeof field.labelHide === 'undefined') {
            field = Object.assign({}, field, { labelHide: true })
        }

        if (override) {
            if (typeof field.labelHide === 'undefined') {
                field = Object.assign({}, field, { labelHide: override.labelHide })
            } else {
                field.labelHide = override.labelHide
            }

            if (override.placeholderFilled) {
                field = Object.assign({}, field, { placeholder: field.label, fixPlaceholder: field.label })
            }
        }

        if (typeof field.dateTimePickerOptions !== 'undefined') {
            if (field.dateTimePickerOptions.format === 'YYYY-MM-DD') {
                field.dateTimePickerOptions.format = 'DD-MM-YYYY'
            }

            if (field.type === 'dateTimePicker' && typeof field.min !== 'undefined') {
                field.dateTimePickerOptions.min = moment(field.min, 'YYYY-MM-DD').format('YYYY') + '-01-01'
            }

            if (field.type === 'dateTimePicker' && typeof field.max !== 'undefined') {
                field.dateTimePickerOptions.max = moment(field.max, 'YYYY-MM-DD').format('YYYY') + '-12-31'
            }
        }

        if (typeof field.timePickerOptions !== 'undefined') {
            if (field.timePickerOptions.format === 'HH:mm') {
                field.timePickerOptions.format = 'HH:mm'
            }
        }

        // VALIDATORS
        if (field.type === 'checkbox') {
            field.validator = 'checkbox'
        }

        if (field.type === 'checklist') {
            field.validator = 'checklist'
        }

        return field
    })
}

export const getDefaultPages = (fields: any[]) => {
    const fixFields = setDefaultFields(fields).filter(field => field.isFix)
    return [{
        name: 'pag_' + moment().format('x'),
        pageName: 'Conversion page 1',
        title: '<h1><strong>Iscriviti alla newsletter</strong></h1>',
        description: '<h4>Iscriviti alla newsletter per ricevere le ultime novità e aggiornamenti.</h4>',
        isFix: true,
        fields: fixFields,
        button: 'submit',
    }]
}

export const getDefaultTPages = () => {
    return [{
        name: 'pag_' + moment().format('x'),
        pageName: 'Thank you 1',
        title: '<h1><strong>Congratulazioni!</strong></h1>',
        description: '<h4>Sei stato iscritto alla newsletter.</h4>',
        isFix: true,
    }]
}
