/* eslint-disable max-len */
const mediaLibrary = {
    topbar: {
        close: 'Chiudi',
        upload: 'Carica',
        clearTrash: 'Svuota il cestino',
    },
    dropZone: {
        title: 'Rilascia qui i tuoi files',
        allowedFormats: 'Formati consentiti',
        formats: 'jpeg, jpg, png, webp, pdf, zip, docx, doc, xlsx, xls, csv, zip, rar, 7z, epub, mobi, svg',
        maxSize: 'Dimensione massima 10 MB',
    },
    myFiles: {
        allFiles: 'Tutti i files',
        searchTitle: 'La tua ricerca',
        researchResult: '{count} file trovati per | {count} file trovato per | {count} file trovati per',
        deleteResearch: 'Cancella ricerca',
        filterResult: '{count} risultati | {count} risultato | {count} risultati',
    },
    emptyState: {
        title: 'Carica il tuo primo file.',
        subtitle: 'Quando caricherai un\'immagine o un file da inserire nelle tue newsletter, comparirà qui. Pronto per iniziare?',
    },
    mediaLibraryCard: {
        details: 'Dettagli',
        intoTrash: 'Nel cestino',
        insert: 'Inserisci',
        select: 'Seleziona',
        selected: 'Selezionata',
        notCompatible: 'Non compatibile',
        personalize: 'Personalizza',
        download: 'Download',
        softDelete: 'Elimina',
        recovery: 'Ripristina',
        hardDelete: 'Elimina definitivamente',
        copyUrl: 'Copia url',
    },
    fileDetail: {
        createdAt: 'Aggiunta il',
        updatedAt: 'Ultima modifica',
        deletedAt: 'Data di cancellazione',
        mime: 'Formato',
        size: 'Peso',
        imageSize: 'Dimensioni',
        path: 'Percorso',
        allFiles: '@:mediaLibrary.myFiles.allFiles',
        details: '@:mediaLibrary.mediaLibraryCard.details',
        personalize: '@:mediaLibrary.mediaLibraryCard.personalize',
        download: '@:mediaLibrary.mediaLibraryCard.download',
        softDelete: '@:mediaLibrary.mediaLibraryCard.softDelete',
        hardDelete: '@:mediaLibrary.mediaLibraryCard.hardDelete',
        recovery: '@:mediaLibrary.mediaLibraryCard.recovery',
    },
    deleteConfirm: {
        description: 'Eliminando questo file non sarai più in grado di recuperarlo | Eliminando questi file non sarai più in grado di recuperarli',
        close: 'Annulla',
        confirm: 'Elimina definitivamente',
    },
    dropFileArea: {
        title: 'Rilascia qui i tuoi files',
        subtitle: 'Formati consentiti',
        subtitleline2: 'jpeg, jpg, png, webp, pdf, zip,',
        subtitleline3: 'docx, doc, xlsx, xls, csv, zip, rar, 7z, epub, mobi, svg',
        mime: 'Formato',
        size: 'Peso',
        imageSize: 'Dimensioni',
        path: 'Percorso',
        details: 'Dettaggli',
        personalize: 'Personalizza',
        download: 'Download',
        softDelete: 'Elimina',
        hardDelete: 'Elimina definitivamente',
    },
    createDirectory: {
        name: 'Dai un titolo alla tua cartella',
        new: 'Nuova cartella',
        rename: 'Rinomina cartella',
    },
    imageEditor: {
        close: 'Chiudi',
        save: 'Salva ed esci',
        locale: {
            Apply: 'Applica',
            Arrow: 'Freccia',
            'Arrow-2': 'Freccia2',
            'Arrow-3': 'Freccia3',
            Blend: 'Miscela',
            Blur: 'Sfocatura',
            Bold: 'Grassetto',
            Brightness: 'Luminosità',
            Bubble: 'Bolla',
            Cancel: 'Cancella',
            Center: 'Centro',
            Circle: 'Cerchio',
            Color: 'Colore',
            'Color Filter': 'Filtro colorato',
            Crop: 'Ritaglia',
            // Custom
            'Custom icon': 'Icona Custom',
            Delete: 'Elimina',
            'Delete-all': 'Elimina tutto',
            Distance: 'Distanza',
            Download: 'Scarica',
            Draw: 'Disegna',
            Emboss: 'Rilievo',
            Fill: 'Riempimento',
            Filter: 'Filtro',
            Flip: 'Ribalta',
            'Flip X': 'Ribalta X',
            'Flip Y': 'Ribalta Y',
            Free: 'Libero',
            Grayscale: 'Scala di grigi',
            Heart: 'Cuore',
            Icon: 'Icona',
            Invert: 'Inverti',
            Italic: 'Corsivo',
            Left: 'Sinistra',
            Load: 'Carica',
            'Load Mask Image': 'Carica Immagine Maschera',
            Location: 'Località',
            Mask: 'Maschera',
            Multiply: 'Moltiplica',
            Noise: 'Disturbo',
            Pixelate: 'Pixelizza',
            Polygon: 'Poligono',
            Range: 'Range',
            Rectangle: 'Rettangolo',
            Redo: 'Ripeti',
            'Remove White': 'Rimuovi Bianco',
            Reset: 'Resetta',
            Right: 'Destra',
            Rotate: 'Ruota',
            Sepia: 'Seppia',
            Sepia2: 'Seppia2',
            Shape: 'Forma',
            Sharpen: 'Intensifica',
            Square: 'Quadrato',
            'Star-1': 'Stella 1',
            'Star-2': 'Stella 2',
            Straight: 'Retta',
            Stroke: 'Tratto',
            Text: 'Testo',
            'Text size': 'Dimensione del testo',
            Threshold: 'Soglia',
            Tint: 'Tinta',
            Triangle: 'Triangolo',
            Underline: 'Sottolineato',
            Undo: 'Annulla',
            Value: 'Valore',
        },
    },
    notifications: {
        saveImageReplace: {
            success: 'Immagine modificata con successo',
            error: 'Errore durante la modifica dell\'immagine',
        },
        copyFile: {
            success: 'Url copiata con successo',
            error: 'Impossibile copiare l\'url',
        },
        saveImage: {
            success: 'Immagine creata con successo',
            error: 'Errore durante la creazione dell\'immagine',
        },
        uploadFile: {
            success: 'File caricato con successo',
            error: 'Errore durante il caricamento del file',
        },
        createDirectory: {
            successCreate: 'Cartella rinominata con successo',
            successUpdate: 'Cartella creata con successo',
            error: 'Errore durante la creazione della cartella',
        },
        downloadSelectedFiles: {
            error: 'Errore durante il download del file',
        },
        moveToTrashSelectedFiles: {
            success: '{count} files spostati nel cestino con successo | {count} file spostato nel cestino con successo | {count} files spostati nel cestino con successo',
            errorSome: 'Errore durante l\'eliminazione di {count} files | Errore durante l\'eliminazione di {count} file | Errore durante l\'eliminazione di {count} files',
            error: 'Errore durante l\'eliminazione dei files',
        },
        moveAllFiles: {
            success: '{count} files spostati con successo | {count} file spostato con successo | {count} files spostati con successo',
            errorSome: 'Errore durante lo spostamento di {count} files | Errore durante lo spostamento di {count} file | Errore durante lo spostamento di {count} files',
            error: 'Errore durante lo spostamento dei files',
        },
        clearTrash: {
            success: 'Cestino svuotato con successo',
            error: 'Errore durante lo svuotamento del cestino',
        },
        recoveryFiles: {
            success: '{count} files ripristinati con successo | {count} file ripristinato con successo | {count} files ripristinati con successo',
            errorSome: 'Errore durante il ripristino di {count} files | Errore durante il ripristino di {count} file | Errore durante il ripristino di {count} files',
            error: 'Errore durante il ripristino dei files',
        },
        deleteSelectedFiles: {
            success: '{count} files eliminati con successo | {count} file eliminato con successo | {count} files eliminati con successo',
            errorSome: 'Errore durante l\'eliminazione di {count} files | Errore durante l\'eliminazione di {count} file | Errore durante l\'eliminazione di {count} files',
            error: 'Errore durante l\'eliminazione dei files',
        },
        deleteFile: {
            success: 'File eliminato con successo',
            error: 'Errore durante l\'eliminazione del file',
        },
        recoveryFile: {
            success: 'File ripristinato con successo',
            error: 'Errore durante il ripristino del file',
        },
        moveToTrashFile: {
            success: 'File spostato nel cestino con successo',
            error: 'Errore durante l\'eliminazione del file',
        },
        deleteDirectory: {
            success: 'Cartella eliminata con successo',
            error: 'Errore durante l\'eliminazione della cartella',
        },
        moveToTrashDirectory: {
            success: 'Cartella spostata nel cestino con successo',
            error: 'Errore durante l\'eliminazione della cartella',
        },
        recoveryDirectory: {
            success: 'Cartella ripristinata con successo',
            error: 'Errore durante il ripristino della cartella',
        },
        importFromUrl: {
            success: 'Importazione avvenuta con successo',
            error: 'Errore durante l\'importazione',
        },
        noSpaceAvailable: 'Errore, spazio insufficiente',
    },
    topActions: {
        selectedFiles: '{count} file selezionato | {count} file selezionati',
        moveTo: 'Sposta in',
    },
    trash: {
        title: 'Cestino',
    },
}
export default {
    mediaLibrary,
}
