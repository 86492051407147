

import { Vue, Component } from 'vue-property-decorator'
import {
    downloadFiles,
    deleteFiles,
    moveToTrashFiles,
    recoveryFiles,
} from '@/api/filemanager/files'
import { deleteDirectories, moveToTrashDirectories, recoveryDirectories } from '@/api/filemanager/directories'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'
import { getMe } from '@/api/filemanager/user'

@Component({
    name: 'MediaLibraryActionsMixin',
})
export default class extends Vue {
    public detailItem: any = null
    $refs: any
    $root: any

    get allowedTypes () {
        return MediaLibraryModule.allowedTypes
    }

    set allowedTypes (val) {
        MediaLibraryModule.SET_ALLOWED_TYPES(val)
    }

    public async getFiles () {
        throw new Error('Not implemented !')
    }

    public async getDirectories () {
        throw new Error('Not implemented !')
    }

    public showDetails (libraryItem: any) {
        this.detailItem = libraryItem
    }

    public async downloadFile (id: string) {
        try {
            await downloadFiles({
                ids: [id],
            })
        } catch (e) {
            console.log(e)
        }
    }

    public async deleteFile (id: string) {
        try {
            await this.$root.$mediaLibraryDeleteConfirm.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }

        try {
            await deleteFiles({
                ids: [id],
            })
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.deleteFile.success'),
                timeout: 3000,
            })
            this.detailItem = null
            await this.getFiles()
            await this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.deleteFile.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    public async recoveryFile (id: string) {
        try {
            await recoveryFiles({
                ids: [id],
            })
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.recoveryFile.success'),
                timeout: 3000,
            })
            this.detailItem = null
            await this.getFiles()
            await this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.recoveryFile.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    public async moveToTrashFile (id: string) {
        try {
            const resp = await moveToTrashFiles({
                ids: [id],
            })

            const errors = resp.data.filter((el: any) => !el.success)

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.moveToTrashFile.error'),
                    timeout: 3000,
                })
            } else {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.moveToTrashFile.success'),
                    timeout: 3000,
                })
            }
            this.detailItem = null
            await this.getFiles()
            await this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.moveToTrashFile.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    public async deleteDirectory (id: string) {
        try {
            await this.$root.$mediaLibraryDeleteConfirm.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }

        try {
            const resp = await deleteDirectories({
                ids: [id],
            })

            const errors = resp.data.filter((el: any) => !el.success)

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.moveToTrashFile.error'),
                    timeout: 3000,
                })
            } else {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.deleteDirectory.success'),
                    timeout: 3000,
                })
            }

            await this.getDirectories()
            await this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.deleteDirectory.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    public async moveToTrashDirectory (id: string) {
        try {
            const resp = await moveToTrashDirectories({
                ids: [id],
            })

            const errors = resp.data.filter((el: any) => !el.success)

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.moveToTrashDirectory.error'),
                    timeout: 3000,
                })
            } else {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.moveToTrashDirectory.success'),
                    timeout: 3000,
                })
            }

            await this.getDirectories()
            await this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.moveToTrashDirectory.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    public async recoveryDirectory (id: string) {
        try {
            const resp = await recoveryDirectories({
                ids: [id],
            })

            const errors = resp.data.filter((el: any) => !el.success)

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.recoveryDirectory.error'),
                    timeout: 3000,
                })
            } else {
                this.$root.$mediaLibraryToast({
                    heading: this.$t('mediaLibrary.notifications.recoveryDirectory.success'),
                    timeout: 3000,
                })
            }

            await this.getDirectories()
            await this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.recoveryDirectory.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    public async getMe () {
        try {
            const resp = await getMe()
            MediaLibraryModule.SET_USER_INFO(resp.data)
        } catch (e) {
            console.log(e)
        }
    }

    public directDownload (file: any, name: string) {
        const url = window.URL.createObjectURL(file)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
    }

    public isAllowedType (selectedFile: any) {
        if (!this.allowedTypes) return true
        if (!selectedFile.mime) return false
        return this.allowedTypes.split(',').find((el: string) => el === selectedFile.mime)
    }

    public selectFile (selectedFile: any) {
        if (!this.isAllowedType(selectedFile)) {
            this.$root.$mediaLibraryToast({
                heading: 'Non è possibile selezionare questa tipologia di file',
                timeout: 3000,
            })
            return
        }
        if (MediaLibraryModule.modalPromiseResolve) MediaLibraryModule.modalPromiseResolve(selectedFile.public_url)
        MediaLibraryModule.SET_MODAL_PROMISE_REJECT(null)
        MediaLibraryModule.SET_MODAL_PROMISE_RESOLVE(null)
        MediaLibraryModule.SET_SHOW_MEDIALIBRARY(false)
    }
}
