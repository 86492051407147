
const init = (beaconConf: any) => {
    if (!beaconConf || !window.Beacon) {
        console.log('BeaconNotFound')
        return
    }
    // window.Beacon('destroy')
    window.Beacon('init', beaconConf.apiKey)
    window.Beacon('prefill', beaconConf.prefill)
    window.Beacon('config', beaconConf.config)
    window.Beacon('identify', beaconConf.identify)
}

export default {
    init,
}
