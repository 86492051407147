var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('createSender.header'))+" ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" "+_vm._s(_vm.$t('general.close'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.createSender}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"createSmsSenderForm",attrs:{"tag":"form"}},[_c('div',{staticClass:"vs-h200 vs-mb-2"},[_vm._v(_vm._s(_vm.$t('createSender.senderData')))]),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.alias'),"rules":"required|min:2|max:14","vid":"alias","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-2",attrs:{"size":"small","name":'alias',"label":_vm.$t('campaigns.createSmsSender.data.alias'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.alias),callback:function ($$v) {_vm.$set(_vm.senderData, "alias", $$v)},expression:"senderData.alias"}})]}}])}),_c('VsCaption',{staticClass:"vs-mb-4",attrs:{"size":"small"}},[_vm._v(" NUMERICO comprensivo di prefisso internazionale (esempio: +391234567890); ALFANUMERICO fino a 11 caratteri lettere e/o numeri. I mittenti saranno registrati secondo "),_c('VsButton',{attrs:{"url":'/templates/default/REQUISITI_E_CODICE_DI_CONDOTTA.pdf',"target":"_blank","size":"small","variant":"link"}},[_vm._v("requisiti e codici di condotta")]),_vm._v(" AGCOM. ")],1),_c('VsSeparator',{staticClass:"vs-my-2"}),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.company'),"rules":"required","vid":"company","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","name":"company","label":_vm.$t('campaigns.createSmsSender.data.company'),"tooltip":_vm.$t('campaigns.createSmsSender.tips.company'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.company),callback:function ($$v) {_vm.$set(_vm.senderData, "company", $$v)},expression:"senderData.company"}})]}}])}),_c('div',{staticClass:"vs-flex vs-flex-wrap vs-gap-4"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.vat'),"rules":_vm.validationForVat,"vid":"vat","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4 vs-flex-1",attrs:{"size":"small","name":"vat","label":_vm.$t('campaigns.createSmsSender.data.vat'),"tooltip":_vm.$t('campaigns.createSmsSender.tips.vat'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.vat),callback:function ($$v) {_vm.$set(_vm.senderData, "vat", $$v)},expression:"senderData.vat"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.fiscalCode'),"rules":"required|alpha_num|min:9|max:16","vid":"fiscalCode","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4 vs-flex-1",attrs:{"size":"small","name":"fiscalCode","label":_vm.$t('campaigns.createSmsSender.data.fiscalCode'),"tooltip":_vm.$t('campaigns.createSmsSender.tips.fiscalCode'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.fiscal_code),callback:function ($$v) {_vm.$set(_vm.senderData, "fiscal_code", $$v)},expression:"senderData.fiscal_code"}})]}}])})],1),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.address'),"rules":"required","vid":"address","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","name":"address","label":_vm.$t('campaigns.createSmsSender.data.address'),"tooltip":_vm.$t('campaigns.createSmsSender.tips.address'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.address),callback:function ($$v) {_vm.$set(_vm.senderData, "address", $$v)},expression:"senderData.address"}})]}}])}),_c('div',{staticClass:"vs-flex vs-flex-wrap"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.city'),"rules":"required","vid":"city","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-w-1/2 md:vs-w-1/4 vs-pr-4 vs-mb-4",attrs:{"size":"small","name":"city","label":_vm.$t('campaigns.createSmsSender.data.city'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.city),callback:function ($$v) {_vm.$set(_vm.senderData, "city", $$v)},expression:"senderData.city"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.province'),"rules":"required|min:2|max:2|alpha","vid":"province","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-w-1/2 md:vs-w-1/4 vs-pr-4 vs-mb-4",attrs:{"size":"small","name":"province","label":_vm.$t('campaigns.createSmsSender.data.province'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.province),callback:function ($$v) {_vm.$set(_vm.senderData, "province", $$v)},expression:"senderData.province"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.country'),"rules":"required","vid":"country","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsSelect',{staticClass:"vs-w-1/2 md:vs-w-1/4 vs-pr-4 vs-mb-4",attrs:{"size":"small","name":"country","label":_vm.$t('campaigns.createSmsSender.data.country'),"filterable":"","error":errors.length > 0,"errorMessage":errors[0],"options":_vm.smsSenderCountries.map(function (el) {
                        return {
                            label: el.label,
                            value: el.code
                        }
                    })},model:{value:(_vm.senderData.country),callback:function ($$v) {_vm.$set(_vm.senderData, "country", $$v)},expression:"senderData.country"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.cap'),"rules":"required|min:5|max:5|numeric","vid":"cap","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-w-1/2 md:vs-w-1/4 vs-pr-4 vs-mb-4",attrs:{"size":"small","name":"cap","label":_vm.$t('campaigns.createSmsSender.data.cap'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.cap),callback:function ($$v) {_vm.$set(_vm.senderData, "cap", $$v)},expression:"senderData.cap"}})]}}])})],1),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.phoneNumber'),"rules":"required|libphoneNumber","vid":"phoneNumber","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsPhoneInput',{staticClass:"vs-mb-4",attrs:{"size":"small","name":"phoneNumber","label":_vm.$t('campaigns.createSmsSender.data.phoneNumber'),"tooltip":_vm.$t('campaigns.createSmsSender.tips.phoneNumber'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.phone_number),callback:function ($$v) {_vm.$set(_vm.senderData, "phone_number", $$v)},expression:"senderData.phone_number"}})]}}])}),_c('div',{staticClass:"vs-flex vs-flex-wrap vs-gap-4"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.email'),"rules":"required|email","vid":"email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4 vs-flex-1",attrs:{"size":"small","variant":"email","name":"email","label":_vm.$t('campaigns.createSmsSender.data.email'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.email),callback:function ($$v) {_vm.$set(_vm.senderData, "email", $$v)},expression:"senderData.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('campaigns.createSmsSender.data.pec'),"rules":"required|email","vid":"pec","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4 vs-flex-1",attrs:{"size":"small","variant":"email","name":'pec',"label":_vm.$t('campaigns.createSmsSender.data.pec'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.pec),callback:function ($$v) {_vm.$set(_vm.senderData, "pec", $$v)},expression:"senderData.pec"}})]}}])})],1)],1),_c('div',{staticClass:"vs-mt-4 vs-body-small"},[_vm._v(" L'approvazione avviene manualmente e può richiedere da pochi minuti fino a 24 ore di tempo. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }