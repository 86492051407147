



































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsSidenavMenuItem',
    components: {},
})
export default class extends Vue {
    @Prop({
        default: false,
        required: false,
        type: Boolean,
    }) active?: boolean

    @Prop({
        default: '',
        required: false,
    }) iconName?: string

    @Prop({
        default: '',
        required: false,
    }) title?: string

    @Prop({
        default: '',
        required: false,
    }) badge?: string
}
