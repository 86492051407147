var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"tableContainer",class:_vm.tableContainerClasses},[(_vm.loading)?_c('VsLoader',{attrs:{"overlay":""}}):_vm._e(),_c('div',{ref:"table",staticClass:"vs-table-component",class:_vm.tableClasses,attrs:{"role":"table"}},[_c('div',{staticClass:"vs-table-component-header vs-table-row vs-bg-white",attrs:{"role":"rowgroup"}},[(_vm.selectable)?_c('div',{ref:"headSelectable",staticClass:"vs-table-component-column vs-table-component-selectable-column",class:Object.assign({}, _vm.selectableColumnClasses,
                    _vm.thClasses,
                    _vm.columnClasses),attrs:{"role":"cell"}},[_vm._t("headSelectable",function(){return [_c('VsCheckbox',{attrs:{"value":_vm.allSelected || _vm.someSelected,"indeterminate":_vm.someSelected,"labelHidden":"","size":"large"},on:{"input":_vm.checkAndSelectAll}})]})],2):_vm._e(),_vm._l((_vm.headers),function(headerCol){return _c('div',{key:headerCol.id,staticClass:"vs-table-component-column vs-table-component-central-column",class:Object.assign({}, _vm.thClasses,
                    _vm.columnClasses,
                    {'vs-cursor-pointer': headerCol.sortable,
                    'vs-hidden': !headerCol.visible,
                    'vs-text-left': headerCol.textPosition !== 'center' && headerCol.textPosition !== 'right',
                    'vs-text-center': headerCol.textPosition === 'center',
                    'vs-text-right': headerCol.textPosition === 'right'}),attrs:{"role":"cell"},on:{"click":function($event){return _vm.sortByParameter(headerCol)}}},[(headerCol.visible)?_c('div',{staticClass:"vs-whitespace-nowrap vs-text-ellipsis vs-overflow-hidden"},[_vm._t(("header-" + (headerCol.key)),function(){return [_c('span',{staticClass:"vs-flex vs-items-center vs-gap-1",class:{
                                'vs-justify-items-start': headerCol.textPosition !== 'center' && headerCol.textPosition !== 'right',
                                'vs-justify-center': headerCol.textPosition === 'center',
                                'vs-justify-end': headerCol.textPosition === 'right',
                            }},[_vm._v(" "+_vm._s(headerCol.label)+" "),(headerCol.sortable)?_c('VsIcon',{staticClass:"vs-flex-none",attrs:{"name":headerCol.key === _vm.sortField && _vm.sortOrder === 'desc' ? 'chevron-up' : 'chevron-down',"size":"4","color":headerCol.key === _vm.sortField ? 'black' : 'grey-600'}}):_vm._e()],1)]},{"row":headerCol})],2):_vm._e()])}),(_vm.actionColumn)?_c('div',{ref:"headActions",staticClass:"vs-table-component-column",class:Object.assign({}, _vm.actionsColumnClasses,
                    _vm.columnClasses),attrs:{"role":"cell"}},[_vm._t("headActions")],2):_vm._e()],2),_vm._l((_vm.items),function(row,indexRow){return _c('div',{key:row.id || indexRow,staticClass:"vs-table-row",class:{
                'vs-bg-primary-200': _vm.checkedRows.includes(row[("" + _vm.selectTarget) || 'id']),
                'vs-bg-white': !_vm.checkedRows.includes(row[("" + _vm.selectTarget) || 'id']),
                'hover:vs-bg-grey-100': !_vm.checkedRows.includes(row[("" + _vm.selectTarget) || 'id'])
            },attrs:{"role":"rowgroup"}},[(_vm.selectable)?_c('div',{ref:"bodySelectable",refInFor:true,staticClass:"vs-table-component-column",class:Object.assign({}, _vm.tdClasses,
                    _vm.selectableColumnClasses,
                    _vm.columnClasses),attrs:{"role":"cell"}},[_vm._t("bodySelectable",function(){return [_c('VsCheckbox',{attrs:{"value":_vm.checkedRows,"inputValue":row[("" + _vm.selectTarget)],"size":"large","labelHidden":true},on:{"input":function($event){return _vm.$emit('select', $event)}}})]},{"row":row})],2):_vm._e(),_vm._l((_vm.headers),function(col){return _c('div',{key:col.id,staticClass:"vs-table-component-column vs-table-component-central-column",class:Object.assign({}, _vm.tdClasses,
                    _vm.columnClasses,
                    {'vs-text-left': col.textPosition !== 'center' && col.textPosition !== 'right',
                    'vs-text-center': col.textPosition === 'center',
                    'vs-text-right': col.textPosition === 'right',
                    'vs-hidden': !col.visible}),attrs:{"role":"cell"}},[(col.visible)?_c('div',{staticClass:"vs-whitespace-nowrap vs-text-ellipsis vs-overflow-hidden"},[_vm._t(("col-" + (col.key)),function(){return [_c('div',{directives:[{name:"tippy",rawName:"v-tippy"}],attrs:{"title":_vm.formatCol(row, col)}},[_vm._v(" "+_vm._s(_vm.formatCol(row, col))+" ")])]},{"row":row,"col":col})],2):_vm._e()])}),(_vm.actionColumn)?_c('div',{ref:"bodyActions",refInFor:true,staticClass:"vs-table-component-column",class:Object.assign({}, _vm.actionsColumnClasses,
                    _vm.columnClasses),attrs:{"role":"cell"}},[_vm._t("bodyActions",null,{"row":row})],2):_vm._e()],2)})],2),(_vm.items.length === 0)?_c('div',{staticClass:"vs-text-center vs-body-medium vs-p-4"},[_vm._v(" "+_vm._s(_vm.emptyText)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }