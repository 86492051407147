


































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsAddLabelModal from '@/modules/campaigns/components/VsAddLabelModal/Index.vue'
import { getTags } from '@/api/consoleApi/recipients'
import { setEmailTags } from '@/api/consoleApi/contents/emails'
import VsTagNameModal from '@/modules/lists/components/VsTagNameModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'

@Component({
    name: 'VsMapTagModal',
    components: {
        VsLoader,
        VsAddLabelModal,
        VsTagNameModal,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: () => [] }) links!: string[]
    @Prop({ required: true, default: '' }) message!: any
    @Prop({ required: true, default: () => [] }) lists!: any[]

    private id = ''
    private plainContent = ''
    private open = false
    private openTags: any = null
    private openTagsIds: any[] = []
    openTagsTemp: any[] = []
    clickTagsTemp: any[] = []
    private tagNewLinks = false
    private tags: any[] = []
    private mappedTags: any[] = []
    public loading = false

    $refs: any

    get user () {
        return UserModule.user
    }

    get clickTags () {
        return this.mappedTags.filter((tag: any) => {
            return tag.event === 'click'
        })
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get consoleBaseUrl () {
        return AppModule.consoleBaseUrl
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get tagOptions () {
        return this.tags.map((el: any) => {
            return {
                value: el.id,
                label: el.name,
            }
        })
    }

    get filteredMappedLinks () {
        return this.links.filter((link: string) => !this.mappedTags.find((mapped: any) => mapped.url === link))
    }

    get filteredMappedTempLinks () {
        return this.links.filter((link: string) => !this.clickTagsTemp.find((mapped: any) => mapped.url === link))
    }

    private getTagName (tagId: any) {
        const tag: any = this.tags.find((tag: any) => tag.id === tagId)
        if (!tag) return 'Tag non trovato'
        return `${tag.name}`
    }

    private mappedLinkNotFound (link: string) {
        return !this.links.includes(link)
    }

    public async openModal (mappedTags: any[]) {
        this.loading = true
        this.mappedTags = JSON.parse(JSON.stringify(mappedTags))
        this.openTags = mappedTags.filter(el => el.event === 'open').length > 0 ? mappedTags.filter(el => el.event === 'open') : null
        if (this.openTags) this.openTagsIds = this.openTags.map((el: any) => el.tag_id)

        this.clickTagsTemp = this.parseClickTags(mappedTags.filter(el => el.event === 'click'))
        this.open = true
        await this.getAllListsTags()
        this.loading = false
    }

    private openSelectTagModal () {
        this.$refs.vsAddLabelModal.openModal()
    }

    public closeModal () {
        this.open = false
    }

    private async getAllListsTags () {
        try {
            const tags = []
            for (const list of this.lists) {
                const listTags = await this.getTags(list.id)
                tags.push(...listTags)
            }
            this.tags = tags
        } catch (e) {
            console.log(e)
        }
    }

    private async getTags (listId: any) {
        const tags: any[] = []
        await this.loopCall(1, tags, getTags, listId)
        return tags
    }

    async loopCall (page: number, values: any[], caller: any, listId: any) {
        const resp = await caller(listId, {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller, listId)
        }
    }

    private async save () {
        const valid = await this.$refs.opentagForm.validate()
        if (!valid) return
        const emailTags: any[] = []
        for (const clickTag of this.clickTagsTemp) {
            for (const tagId of clickTag.tag_id) {
                if (!this.tags.find(tag => tag.id === tagId)) {
                    return
                }
                emailTags.push({
                    url: clickTag.url,
                    event: clickTag.event,
                    tag_id: tagId,
                })
            }
        }

        if (this.openTagsIds.length > 0) {
            for (const openId of this.openTagsIds) {
                if (!this.tags.find(tag => tag.id === openId)) {
                    return
                }
                emailTags.push({
                    event: 'open',
                    tag_id: openId,
                    url: '',
                })
            }
        }

        this.loading = true
        try {
            await setEmailTags(this.message.id, emailTags)
            this.$root.$vsToast({
                heading: 'Mappatura salvata con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('mapped-tag-saved')
            this.closeModal()
        } catch (e) {
            console.log(e)
            const toast = this.$root.$vsToast({
                heading: 'Errore durante il salvataggio della mappatura delle etichette',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    const message = get(e, 'response.data.message', {})
                    if (message[Object.keys(message)[0]][0] === 'tag_id not found') {
                        toast.message = 'Il tag selezionato non è stato trovato.'
                    }
                }
            }
        }
        this.loading = false
    }

    removeMappedTag (id: any) {
        this.mappedTags = this.mappedTags.filter(el => el.id !== id)
    }

    removeMappedTempTag (id: any) {
        this.clickTagsTemp = this.clickTagsTemp.filter(el => el.id !== id)
    }

    toggleOpenTag () {
        this.openTagsIds = []
        this.openTags = this.openTags ? null : {
            event: 'open',
            tag_id: '',
        }
    }

    addEmptyTag () {
        this.clickTagsTemp.push({
            id: Date.now(),
            tag_id: [],
            event: 'click',
            url: this.filteredMappedTempLinks[0] || '',
        })
    }

    getLinkOptions (currentUrl: string) {
        const options = this.filteredMappedTempLinks.map(el => {
            return {
                value: el,
                label: el,
            }
        })

        if (currentUrl) {
            options.push({
                label: currentUrl,
                value: currentUrl,
            })
        }
        return options
    }

    parseClickTags (clickTags: any[]) {
        const parsedClickTags: any[] = []
        clickTags.forEach(tag => {
            const index = parsedClickTags.findIndex(el => el.url === tag.url)
            if (index === -1) {
                parsedClickTags.push({
                    ...tag,
                    tag_id: [tag.tag_id],
                    id: Date.now() + `${parsedClickTags.length}`,
                })
            } else {
                parsedClickTags[index].tag_id.push(tag.tag_id)
            }
        })
        return parsedClickTags
    }

    parseOpenTags (openTags: any[]) {
        const parsedOpenTags: any[] = []
        openTags.forEach(tag => {
            parsedOpenTags.push({
                ...tag,
            })
        })
        return parsedOpenTags
    }

    notExistingTags (currentTag: any) {
        for (const tagId of currentTag.tag_id) {
            if (!this.tags.find(el => tagId === el.id)) {
                return true
            }
        }
    }

    notExistingOpenTags (tags: any) {
        for (const tagId of tags) {
            if (!this.tags.find(el => tagId === el.id)) {
                return true
            }
        }
    }
}
