var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loader-container",style:({
        background: _vm.overlay ? _vm.overlayColor : 'transparent',
        position: this.position,
    })},[_c('span',{staticClass:"vs-spinner vs-text-primary-800",style:({
            width: this.size,
            height: this.size,
            borderWidth: this.borderWidth,
        })})])}
var staticRenderFns = []

export { render, staticRenderFns }