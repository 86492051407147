








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsHelp',
})
export default class extends Vue {
    @Prop({ default: false, type: Boolean, required: false }) private active!: boolean
}
