import { serviceLeadplus } from '@/utils/request'

export const getForms = async (params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get('/forms', {
        params,
        // signal,
    })

export const getFormById = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/forms/${formId}`, {
        params,
        // signal,
    })

export const createForm = async (
    data: {
        name: string
        foreign_recipient_id: string
        domain_urls: string[]
        urlRulesOverride: string[]
        theme?: any
        pages: any[]
        thanks_pages: any[]
    },
    params?: any,
    signal?: AbortSignal,
) =>
    serviceLeadplus.post('/forms', data, {
        params,
        // signal,
    })

export const updateFormById = async (formId: string, data: any, signal?: AbortSignal) =>
    serviceLeadplus.patch(
        `/forms/${formId}`,
        data,
        {
            // signal,
        },
    )

export const updateFormTypeById = async (formId: string, data: { type: string }, signal?: AbortSignal) =>
    serviceLeadplus.patch(
        `/forms/${formId}/type`,
        data,
        {
            // signal,
        },
    )

export const updateFormStatusById = async (formId: string, data: { isActive: boolean }, signal?: AbortSignal) =>
    serviceLeadplus.patch(
        `/forms/${formId}/is-active`,
        data,
        {
            // signal,
        },
    )

export const deleteFormById = async (formId: string, signal?: AbortSignal) =>
    serviceLeadplus.delete(`/forms/${formId}`, {
        // signal,
    })

export const savePreviewImage = async (formId: string, data: { base64Image: string }, signal?: AbortSignal) =>
    serviceLeadplus.post(`/forms/${formId}/thumbnail-image`, data, {
        // signal,
    })

export const getPreviewImage = async (formId: string, params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/forms/${formId}/thumbnail-image`, {
        responseType: 'blob',
        params,
        // signal,
    })

export const getFormsTotals = async (params?: any, signal?: AbortSignal) =>
    serviceLeadplus.get(`/forms/totals`, {
        params,
        // signal,
    })
