import { RouteConfig } from 'vue-router'
import VsLayouts from '@/layouts/Index.vue'
import { UserModule } from '@/store/modules/user'
import { getShopUser } from '@/api/shop'

const dashboardRouter: RouteConfig[] = [
    {
        path: '/dashboard',
        redirect: { name: 'dashboardIndex' },
        beforeEnter: async (_to: any, _from: any, next: any) => {
            if (!UserModule.shopUser) {
                try {
                    const resp = await getShopUser()
                    UserModule.SET_SHOP_USER(resp.data)
                } catch (e) {
                    UserModule.SET_SHOP_USER(null)
                }
            }
            next()
        },
        name: 'dashboard',
        component: VsLayouts,
        meta: {
            title: 'dashboard',
        },
        children: [
            {
                path: '/',
                name: 'dashboardIndex',
                component: () => import(/* webpackChunkName: "dashboard" */ './../views/index.vue'),
                meta: {
                    hidden: true,
                    title: 'dashboard',
                },
            },
        ],
    },
]

export default dashboardRouter
