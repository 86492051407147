


















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsHintCard',
})
export default class extends Vue {
    @Prop({ required: false, default: '' }) heading!: string
    @Prop({ required: false, default: 'default' }) status!: 'default' | 'success' | 'warning' | 'alert' | 'error'

    get statusParse () {
        if (this.status === 'error') return 'alert'
        return this.status
    }
}
