import { ValidationRule } from 'vee-validate/dist/types/types'
import i18n from '@/lang'
import { parseNumber } from 'libphonenumber-js'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'
import { isValidURL } from './validate'

export const controlPIVA: ValidationRule = (value): string | boolean => {
    const stringToValidate = value.substr(2)
    if (!stringToValidate) {
        return i18n.t('customValidations.vat.generic') + ''
    }
    if (stringToValidate.length !== 11) {
        return i18n.t('customValidations.vat.wrongSize') + ''
    }
    const validi = '0123456789'
    let i = 0
    for (i = 0; i < 11; i++) {
        if (validi.indexOf(stringToValidate.charAt(i)) === -1) {
            return i18n.t('customValidations.vat.wrongCharacter') + ''
        }
    }
    let s = 0
    for (i = 0; i <= 9; i += 2) {
        s += stringToValidate.charCodeAt(i) - '0'.charCodeAt(0)
    }
    for (i = 1; i <= 9; i += 2) {
        let c = 2 * (stringToValidate.charCodeAt(i) - '0'.charCodeAt(0))
        if (c > 9) {
            c = c - 9
        }
        s += c
    }
    if ((10 - s % 10) % 10 !== stringToValidate.charCodeAt(10) - '0'.charCodeAt(0)) {
        return i18n.t('customValidations.vat.generic') + ''
    }
    return true
}

export const libphoneNumber: ValidationRule = {
    message () {
        return i18n.t('customValidations.libphoneNumber') + ''
    },
    validate (value) {
        try {
            return !(Object.keys(parseNumber(value)).length === 0 && parseNumber(value).constructor === Object)
        } catch (e) {
            return false
        }
    },
}

export const libphoneNumberMobile: ValidationRule = {
    message () {
        return i18n.t('customValidations.libphoneNumberMobile') + ''
    },
    validate (value) {
        return isValidPhoneNumber(value)
    },
}

export const alphaNumSpaces: ValidationRule = {
    message () {
        return i18n.t('customValidations.alphaNumSpaces') + ''
    },
    validate (value: any) {
        if (!value.match(/^[0-9A-Z\xC0-\xFF\s]*$/i)) {
            return false
        }
        return true
    },
}

export const alphaSpaces: ValidationRule = {
    message () {
        return i18n.t('customValidations.alphaSpaces') + ''
    },
    validate (value: any) {
        if (!value.match(/^[A-Z\xC0-\xFF\s]*$/i)) {
            return false
        }
        return true
    },
}

export const customRegex: ValidationRule = {
    message (name) {
        return i18n.t('customValidations.customRegex', { _field_: name }) + ''
    },
    validate (value, args: any) {
        const regexString: string = args[0]
        if (!regexString) return false
        return new RegExp(regexString).test(value)
    },
}

export const alphaNumSpacesAndSlash: ValidationRule = {
    message () {
        return i18n.t('customValidations.alphaNumSpacesAndSlash') + ''
    },
    validate (value: any) {
        if (!value.match(/^[0-9A-Z\xC0-\xFF\s/]*$/i)) {
            return false
        }
        return true
    },
}

export const numPositive: ValidationRule = {
    message () {
        return i18n.t('customValidations.numPositive') + ''
    },
    validate (value: any) {
        value = value + ''
        if (!value.match(/^[0-9]+[0-9]*$/i)) {
            return false
        }
        return true
    },
}

export const domain: ValidationRule = {
    message () {
        return i18n.t('customValidations.domain') + ''
    },
    validate (value: any) {
        // eslint-disable-next-line
        const regex = /^(?!(https:\/\/|http:\/\/|www\.|mailto:|smtp:|ftp:\/\/|ftps:\/\/))(((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,86}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,73}[a-zA-Z0-9]))\.(([a-zA-Z0-9\-]{2,12}\.[a-zA-Z0-9\-]{2,12})|([a-zA-Z0-9]{2,25})))|((([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9])|([a-zA-Z0-9][a-zA-Z0-9\-]{0,162}[a-zA-Z0-9]))\.(([a-zA-Z0-9]{2,12}\.[a-zA-Z0-9]{2,12})|([a-zA-Z0-9]{2,25}))))$/

        return value.match(regex)
    },
}

export const url: ValidationRule = {
    message () {
        return i18n.t('customValidations.url') + ''
    },
    validate: isValidURL,
}

export const link: ValidationRule = {
    message (label: string, args: any) {
        const hasProtocol: string = args[0]
        return hasProtocol ? i18n.t('customValidations.linkProtocol') + '' : i18n.t('customValidations.url') + ''
    },
    validate: (value, args: any) => {
        const hasProtocol: string = args[0]
        const pattern = new RegExp(`^(https?:\\/\\/)${hasProtocol ? '' : '?'}` + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
        return pattern.test(value)
    },
}

export const atLeastOneUpperletter: ValidationRule = {
    message () {
        return i18n.t('customValidations.atLeastOneUpperletter') + ''
    },
    validate (value: string) {
        let isValid = false
        for (let i = 0; i < value.length; i++) {
            if (value[i].match(/[a-z]/i)) {
                if (value[i] === value[i].toUpperCase()) {
                    isValid = true
                }
            }
        }
        return isValid
    },
}

export const atLeastOneLowerletter: ValidationRule = {
    message () {
        return i18n.t('customValidations.atLeastOneLowerletter') + ''
    },
    validate (value: string) {
        let isValid = false
        for (let i = 0; i < value.length; i++) {
            if (value[i].match(/[a-z]/i)) {
                if (value[i] === value[i].toLowerCase()) {
                    isValid = true
                }
            }
        }
        return isValid
    },
}

export const atLeastOneSpecialCharacter: ValidationRule = {
    message () {
        return i18n.t('customValidations.atLeastOneSpecialCharacter') + ''
    },
    validate (value: string) {
        let isValid = false
        for (let i = 0; i < value.length; i++) {
            if (value[i].match(/[/_~()ç°§[\]`%!@#+='$%^&*(),.?":{}|<>\\£-]/g)) {
                isValid = true
            }
        }
        return isValid
    },
}

export const onlyLettersNumbersSpacesDashUnderscore: ValidationRule = {
    message (name) {
        return i18n.t('customValidations.onlyLettersNumbersSpacesDashUnderscore', { _field_: name }) + ''
    },
    validate (value: any) {
        if (!value.match(/^[a-zA-Z0-9\s-_]+$/i)) {
            return false
        }
        return true
    },
}

export const onlyDashUnderscoreNumberAndLowercaseLettersNoSpecial: ValidationRule = {
    message (name) {
        return i18n.t('customValidations.onlyDashUnderscoreNumberAndLowercaseLettersNoSpecial', { _field_: name }) + ''
    },
    validate (value: any) {
        if (!value.match(/^[a-z0-9_]+$/)) {
            return false
        }
        return true
    },
}

export const atLeastOneNumber: ValidationRule = {
    message () {
        return i18n.t('customValidations.atLeastOneNumber') + ''
    },
    validate (value: any) {
        let isValid = false
        for (let i = 0; i < value.length; i++) {
            if (value[i].match(/[0-9]/i)) {
                if (!isNaN(value[i])) {
                    isValid = true
                }
            }
        }
        return isValid
    },
}

export const startOrEndWithSpace: ValidationRule = {
    message () {
        return i18n.t('customValidations.startOrEndWithSpace') + ''
    },
    validate (value: any) {
        let isValid = false
        if (!value.startsWith(' ') && !value.endsWith(' ')) {
            isValid = true
        }
        return isValid
    },
}

export const timeHhMmSs: ValidationRule = {
    message (name) {
        return i18n.t('customValidations.timeHhMmSs', { _field_: name }) + ''
    },
    validate (value: any) {
        return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(value)
    },
}

export const noSpaces: ValidationRule = {
    message (name) {
        return i18n.t('customValidations.noSpaces', { _field_: name }) + ''
    },
    validate (value: any) {
        const regexp = /^\S*$/
        return value.match(regexp)
    },
}

export const unique: ValidationRule = {
    message (name) {
        return i18n.t('customValidations.unique', { _field_: name }) + ''
    },
    validate (value: any, targets) {
        return !targets.includes(value)
    },
}

export const maxLength: ValidationRule = {
    message (name, args: any) {
        const max: string = args[0]
        return i18n.t('customValidations.maxLength', { _field_: name, _value_: max }) + ''
    },
    validate (value: any, args: any) {
        const max: string = args[0]
        return value.length <= max
    },
}
