














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'VsDropdown',
    components: {},
})

export default class extends Vue {
    @Prop({
        required: false,
        default: 'medium',
    }) size?: string

    @Prop({
        required: false,
        default: false,
    }) value?: boolean

    @Watch('value', { immediate: true })
    private toggleEventListener (val: boolean) {
        if (val) {
            setTimeout(() => {
                document.addEventListener('click', this.closeDropdown)
            }, 200)
        } else {
            document.removeEventListener('click', this.closeDropdown)
        }
    }

    get dropdownClasses () {
        return {
            'vs-rounded-small': true,
            'vs-shadow-light-large': true,
            'vs-py-2': true,
        }
    }

    private closeDropdown () {
        this.$emit('input', false)
        this.$emit('dropdown-close')
    }

    beforeDestroy () {
        document.removeEventListener('click', this.closeDropdown)
    }
}
