

























































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import { difference, get, union } from 'lodash'

export interface TableHeader {
    id: string | number
    key: string
    label: string
    formatter?: (value: any) => string
    sortable?: boolean
    textPosition?: 'left' | 'center' | 'right'
    width?: string
    visible?: boolean
}

@Component({
    name: 'VsTable',
    components: {
        VsLoader,
    },
})

export default class extends Vue {
    @Prop({
        required: true,
        default: () => [],
    }) items!: any[]

    @Prop({
        required: false,
        default: 'createdAt',
    }) sortField?: any

    @Prop({
        required: false,
        default: 'ASC',
    }) sortOrder?: any

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) isSortable?: boolean

    @Prop({
        required: true,
        default: () => [],
    }) headers!: TableHeader[]

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) selectable?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) actionColumn?: boolean

    @Prop({
        required: false,
        default: '',
    }) selectTarget?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) fixedSelectable?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) fixedAction?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) loading?: boolean

    @Prop({
        required: false,
        default: 'Nessun dato',
    }) emptyText?: string

    @Prop({
        required: false,
        default: () => [],
    }) checkedRows!: string[]

    private ascending = false
    private sortColumn = ''
    public selectedAll = ''
    private showShadowSelectable = false
    private showShadowActions = false

    get tableContainerClasses () {
        return {
            'vs-overflow-auto': true,
            'vs-border': true,
            'vs-rounded-small': true,
            'vs-border-solid': true,
            'vs-border-grey-400': true,
        }
    }

    get tableClasses () {
        return {
            'vs-table': true,
            'vs-w-full': true,
        }
    }

    get thClasses () {
        return {
            'vs-py-5': true,
            'vs-px-4': true,
            'vs-h300': true,
        }
    }

    get tdClasses () {
        return {
            'vs-py-4': true,
            'vs-px-4': true,
            'vs-body-medium': true,
        }
    }

    get columnClasses () {
        return {
            'vs-text-left': true,
            'vs-table-cell': true,
            'vs-align-middle': true,
            'vs-whitespace-nowrap': true,
        }
    }

    get selectableColumnClasses () {
        return {
            'vs-sticky': this.fixedSelectable,
            'vs-left-0': this.fixedSelectable,
            'vs-border-r-0': this.fixedSelectable,
            'vs-border-l-0': true,
            'vs-border-t-0': true,
            'vs-border-solid': this.fixedSelectable,
            'vs-border-grey-400': this.fixedSelectable,
            'vs-shadow-light-medium': this.showShadowSelectable && this.fixedAction,
            'vs-w-px': true,
        }
    }

    get actionsColumnClasses () {
        return {
            'vs-sticky': this.fixedAction,
            'vs-right-0': this.fixedAction,
            'vs-px-1': true,
            'vs-border-l-0': this.fixedAction,
            'vs-border-r-0': true,
            'vs-border-t-0': true,
            'vs-border-solid': this.fixedAction,
            'vs-border-grey-400': this.fixedAction,
            'vs-shadow-light-medium': this.showShadowActions && this.fixedAction,
            'vs-w-px': true,
        }
    }

    get allSelected () {
        return this.items.length > 0 && this.items.filter(el => !this.checkedRows.includes(el[`${this.selectTarget || 'id'}`])).length === 0
    }

    get someSelected () {
        return !this.allSelected && this.items.filter(el => !this.checkedRows.includes(el[`${this.selectTarget || 'id'}`])).length < this.items.length
        // return this.items.length > 0 && this.items.filter(el => !this.checkedRows.includes(el[`${this.selectTarget || 'id'}`])).length !== this.items.length
    }

    mounted () {
        if (!this.$refs.tableContainer) return
        const tableConatiner = this.$refs.tableContainer as HTMLElement
        tableConatiner.onscroll = this.calculateFixedElementsPosition
        window.addEventListener('resize', this.calculateFixedElementsPosition)
    }

    @Watch('headers', { deep: true, immediate: true })
    private calculateFixedElementsPositionAfterChangeHeaders () {
        this.calculateFixedElementsPosition()
    }

    private calculateFixedElementsPosition () {
        this.$nextTick(() => {
            if (!this.$refs.table) return
            if (!this.$refs.tableContainer) return
            const table = this.$refs.table as HTMLElement
            const tableConatiner = this.$refs.tableContainer as HTMLElement
            if (this.fixedSelectable) {
                const bodySelectable = this.$refs.bodySelectable as HTMLElement[]
                const headSelectable = this.$refs.headSelectable as HTMLElement
                if (!bodySelectable || !headSelectable) return
                this.showShadowSelectable = tableConatiner.scrollLeft > 0
            }
            if (this.fixedAction) {
                const bodyActions = this.$refs.bodyActions as HTMLElement[]
                const headActions = this.$refs.headActions as HTMLElement
                if (!bodyActions || !headActions) return
                this.showShadowActions = (tableConatiner.clientWidth + tableConatiner.scrollLeft) < table.offsetWidth
            }
        })
    }

    beforeDestroy () {
        window.removeEventListener('resize', this.calculateFixedElementsPosition)
    }

    private sortByParameter (col: any) {
        if (col.sortable) {
            this.$emit('sort-table', col.key)
        }
    }

    private formatCol (row: any[], col: TableHeader): string {
        const value = get(row, col.key, '')
        return col.formatter ? col.formatter(value) : value
    }

    private checkAndSelectAll () {
        if (this.allSelected) {
            this.deselectAll()
        } else {
            this.selectAll()
        }
    }

    private selectAll () {
        this.$emit('select', union(
            this.checkedRows,
            this.items.map(el => el[`${this.selectTarget || 'id'}`]),
        ))
    }

    private deselectAll () {
        this.$emit('select', difference(
            this.checkedRows,
            this.items.map(el => el[`${this.selectTarget || 'id'}`]),
        ))
    }
}
