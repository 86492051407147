import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/lang'
import '@/plugins/vueSnotify'
import '@/plugins/filters'
import '@/plugins/veeValidate'
// import '@/plugins/socket'
import '@/plugins/vision'
import '@/plugins/colorPicker'
import '@/styles/app.scss'
import { GtmSupport } from '@gtm-support/vue2-gtm'
import { setRouterMiddlewares } from '@/permission'
import { VsToastOptionsInterface, VsToastOptionsObjectInterface } from '@advision/vision/src/components/VsToast/types'
import moment from 'moment'
moment.locale('it')

setRouterMiddlewares(router)
Vue.config.productionTip = false

// add types to vue instance to avoid typescript errors
declare module 'vue/types/vue' {
    interface Vue {
        $snotify: any
        $mediaLibraryDeleteConfirm: any
        $mediaLibraryToast: (toastOptions: VsToastOptionsObjectInterface) => VsToastOptionsInterface
        $vsToast: (toastOptions: VsToastOptionsObjectInterface) => VsToastOptionsInterface
        $vsOpenBlockedModal: () => void
        $vsOpenUserSupport: (navigateTicket?: boolean) => void
        $vsTopnavSearc: (search: string) => void
        $gtm: GtmSupport
    }
}

new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
