

























































import { Component, Vue } from 'vue-property-decorator'
import { getEmailSpamTest } from '@/api/consoleApi/contents/emails'
import VsChart from '@/components/VsChart/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
@Component({
    name: 'VsSpamTestModal',
    components: {
        VsChart,
        VsTable,
        MyAccordion,
        VsLoader,
    },
})
export default class extends Vue {
    private controller: AbortController | null = null
    private open = false
    public loading = false
    private showDetails = false
    private errors = false
    private result: {
        hits: string
        scores: any[]
    } | null = null

    $refs: any

    public openModal (emailId: number) {
        this.errors = false
        this.loading = false
        this.result = null
        this.getEmailSpamTest(emailId)
        this.open = true
    }

    public closeModal () {
        if (this.controller) {
            this.controller.abort()
            this.controller = null
        }
        this.open = false
    }

    private async getEmailSpamTest (emailId: number) {
        this.loading = true
        try {
            this.controller = new AbortController()
            const resp = await getEmailSpamTest(emailId, this.controller.signal)
            this.controller = null
            this.result = resp.data.data
        } catch (e) {
            this.errors = true
            console.log(e)
        }
        this.loading = false
    }

    get resultHits () {
        return this.result ? Number(this.result.hits) : 0
    }

    get tableHeaders (): TableHeader[] {
        return [
            {
                id: 'point',
                key: 'point',
                label: 'Peso',
                visible: true,
            },
            {
                id: 'description',
                key: 'description',
                label: 'Descrzione',
                visible: true,
            },
        ]
    }

    get progressColor () {
        if (this.resultHits < 5) {
            return '#00BF9C'
        } else {
            return '#EC484B'
        }
    }

    get chartOptions (): any {
        return {
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 5,
                    center: ['50%', '90%'],
                    radius: '150%',
                    splitNumber: 5,
                    itemStyle: {
                        color: '#FF6E76',
                    },
                    progress: {
                        show: true,
                        roundCap: false,
                        width: 50,
                        color: '#000',
                        itemStyle: {
                            color: this.progressColor,
                        },
                    },
                    pointer: false,
                    axisLine: {
                        roundCap: false,
                        width: 200,
                        lineStyle: {
                            width: 50,
                        },
                    },
                    axisTick: false,
                    splitLine: false,
                    axisLabel: false,
                    title: {
                        show: false,
                    },
                    detail: {
                        backgroundColor: '#fff',
                        borderColor: '#999',
                        width: '60%',
                        lineHeight: 14,
                        offsetCenter: [0, -12],
                        valueAnimation: true,
                        fontSize: 14,
                        fontWeight: 700,
                        color: '#60686A',
                    },
                    data: [
                        {
                            value: this.resultHits,
                        },
                    ],
                },
            ],
        }
    }
}
