

























































































































import { Component, Vue } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'
import VsFileInput from '@/components/VsFileInput/Index.vue'
import {
    setEmailFromFile,
    setEmailFromUrl,
} from '@/api/consoleApi/contents/emails'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'

@Component({
    name: 'VsImportHtmlModal',
    components: {
        VsFullTopBarLayout,
        VsContainer,
        VsTabs,
        VsFileInput,
    },
})
export default class extends Vue {
    private open = false
    public loading = false
    private campaignId = 0
    private activeTab = 'url'
    private contentUrl = ''
    private plainContentUrl = ''
    private file: any = null
    $refs: any

    get tabs () {
        const tabs = [
            {
                label: 'Da pagina web (URL)',
                id: 'url',
                click: () => { this.activeTab = 'url' },
            },
            {
                label: 'Da file .zip',
                id: 'zip',
                click: () => { this.activeTab = 'zip' },
            },
        ]

        return tabs
    }

    get publicPath () {
        return process.env.BASE_URL
    }

    public openModal (campaignId: number, tabName: string) {
        this.loading = false
        this.campaignId = campaignId
        this.activeTab = tabName
        this.contentUrl = ''
        this.plainContentUrl = ''
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    private saveContent () {
        if (this.activeTab === 'url') this.submitFromUrlForm()
        if (this.activeTab === 'zip') this.submitFromFileForm()
    }

    private async submitFromFileForm () {
        const success = await this.$refs.fromFileForm.validate()
        if (!success) return
        this.loading = true
        const data = new FormData()
        data.append('file', this.file)
        try {
            await setEmailFromFile(
                this.campaignId,
                data,
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Contenuto importato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('save-content')
            this.closeModal()
        } catch (e) {
            console.log(e)
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 403) {
                    if (e.response.data.error === 'SpaceNotAvailableOnMediaLibrary') {
                        message = 'Non hai più spazio in media library'
                    }
                }
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'importazione del contenuto',
                message,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async submitFromUrlForm () {
        const success = await this.$refs.fromUrlForm.validate()
        if (!success) return
        this.loading = true
        try {
            await setEmailFromUrl(
                this.campaignId,
                {
                    html_url: this.contentUrl,
                    plain_url: this.plainContentUrl,
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Contenuto importato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('save-content')
            this.closeModal()
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'importazione del contenuto',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private downloadExample () {
        this.$refs.exampleLink.click()
    }
}
