import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getWorkflows = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/workflows`,
        {
            params,
            cancelToken,
        },
    )

export const getWorkflow = (workflowId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/workflows/${workflowId}`,
        {
            cancelToken,
            params,
        },
    )

export const createWorkflow = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/workflows`,
        data,
        {
            cancelToken,
        },
    )

export const updateWorkflow = (workflowId: number | string, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/workflows/${workflowId}`,
        data,
        {
            cancelToken,
        },
    )
export const deleteWorkflow = (workflowId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/workflows/${workflowId}`,
        {
            cancelToken,
        },
    )

export const pauseWorkflow = (workflowId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/workflows/${workflowId}/pause`,
        {},
        {
            cancelToken,
        },
    )

export const readyWorkflow = (workflowId: number | string, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/workflows/${workflowId}/ready`,
        {},
        {
            cancelToken,
        },
    )

export const addWorkflowMessage = (workflowId: number | string, data: {
    entity_type: 'email' | 'sms'
    name?: string
}, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/workflows/${workflowId}/messages`,
        data,
        {
            cancelToken,
        },
    )

export const getWorkflowMessages = (workflowId: number | string, params: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/workflows/${workflowId}/messages`,
        {
            cancelToken,
            params,
        },
    )

export const getWorkflowMessage = (workflowId: number | string, workflowMessageId: number, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/workflows/${workflowId}/messages/${workflowMessageId}`,
        {
            cancelToken,
        },
    )

export const getWorkflowQueue = (workflowId: number | string, params: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/workflows/${workflowId}/queue`,
        {
            cancelToken,
            params,
        },
    )

export const abortWorkflowQueue = (workflowId: number | string, workflowQueueId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/workflows/${workflowId}/queue/${workflowQueueId}/abort`,
        {},
        {
            cancelToken,
        },
    )

export const deleteWorkflowMessage = (workflowId: number | string, workflowMessageId: number, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/workflows/${workflowId}/messages/${workflowMessageId}`,
        {
            cancelToken,
        },
    )

export const duplicateWorkflowMessage = (workflowId: number | string, workflowMessageId: number, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/workflows/${workflowId}/messages/${workflowMessageId}/duplicate`,
        {},
        {
            cancelToken,
        },
    )

export const updateWorkflowMessage = (workflowId: number | string, workflowMessageId: number, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/workflows/${workflowId}/messages/${workflowMessageId}`,
        data,
        {
            cancelToken,
        },
    )

export const switchWorkflowMessage = (workflowId: number | string, workflowMessageId: number, switchWorkflowMessage: number, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/workflows/${workflowId}/messages/${workflowMessageId}/switch/${switchWorkflowMessage}`,
        {},
        {
            cancelToken,
        },
    )
