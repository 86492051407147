/* eslint-disable max-len */
const signup = {
    checkActivation: {
        linkNotWork: 'OPS! Qualcosa è andato storto, oppure il tuo link non funziona correttamente',
        noPanic: 'Niente panico.',
        loginOrContactUs: 'Effettua nuovamente il {0}{1} oppure {2}',
        login: 'login',
        contactUs: 'contattaci',
    },
}
export default {
    signup,
}
