import { formatNumber } from './formatter'
import { valueDurationFormat } from './products'
import type { IRuleV2 } from '@/store/modules/user'
import { ExpiredPolicyEnum } from '@/store/modules/user'

// questo serve ad identificare i piani ricaricabili perchè sono gli unici ad avere questa expired policy
// per definire il fatto che il credito si ricarica in maniera non ricorsiva usare il metodo isRecursive che usa la rule emailRecursivity
export const isRechargeable = (rules: IRuleV2) => {
    return rules.expiredPolicy === ExpiredPolicyEnum.emailRechargeAt
}

export const isFreemium = (rules: IRuleV2) => {
    return rules.expiredPolicy === ExpiredPolicyEnum.lastLogin
}

export const isOldSubscription = (rules: IRuleV2) => {
    return rules.expiredPolicy === ExpiredPolicyEnum.startAt
}

// da preferire a isRechargeable
export const isRecursive = (rules: IRuleV2): boolean => {
    return !!rules.emailRecursivity
}

export const canIncludesInArray = (rule: string[], containValue: string) => {
    return rule && (rule.includes('*') || rule.includes(containValue))
}

export const userPlanEmailCreditsRateString = (rules: IRuleV2) => {
    const emailRecursivity = rules.emailRecursivity
    const emailUnlimited = rules.emailUnlimited
    if (emailUnlimited) return 'Email illimitate'
    if (!emailRecursivity) return 'Email ricaricabili'
    return `${formatNumber(emailRecursivity.value)} email ${valueDurationFormat(emailRecursivity.rate)}`
}
