





















































import { Component, Vue } from 'vue-property-decorator'
import {
    createShortUrl,
} from '@/api/consoleApi/contents/sms'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsShortUrlModal',
})
export default class extends Vue {
    private show = false
    private url = ''
    private smsId: number|null = null
    private loading = false
    $refs!: any

    private async createShortUrl () {
        const valid = await this.$refs.shortUrlForm.validate()
        if (!valid || !this.smsId) return
        this.loading = true
        try {
            const resp = await createShortUrl(this.smsId, {
                url: this.url,
            })
            this.$emit('shorturl-created', resp.data.tag)
            this.$root.$vsToast({
                heading: 'Url breve creato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.show = false
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la creazione url breve',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    public openModal (smsId: number) {
        this.url = ''
        this.smsId = smsId
        this.show = true
    }
}
