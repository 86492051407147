








import { Component, Vue, Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

@Component({
    name: 'VsRouterLink',
})

export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    })
    private baseUrl?: any

    @Prop({
        required: true,
        default: '',
    })
    private path!: any

    get formatTag () {
        const route = this.getRouteIfExist(this.path)
        if (route) {
            return 'router-link'
        }
        return 'a'
    }

    get formatProps () {
        const route = this.getRouteIfExist(this.path)
        if (route) {
            return {
                to: route,
            }
        }
        return {
            href: this.baseUrl + this.path,
        }
    }

    private getRouteIfExist (path: string) {
        if (!this.$router) {
            return false
        }
        const l = this.$router.resolve({ path: path })
        if (l.resolved.matched.length > 0) {
            // the route is exists.
            return l.route as RawLocation
        }
        return false
    }
}

