










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsSidebarLayout',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: 'right',
    }) sidebarPosition?: 'left' | 'right'

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) sticky?: boolean

    @Prop({
        required: false,
        default: '',
    }) stickyDistance?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) hideSidebar?: boolean

    @Prop({
        required: false,
        default: 'medium',
    }) sidebarSize?: string

    get dynamicContainerClasses () {
        return {
            'vs-flex': !this.hideSidebar,
            'vs-gap-12': !this.hideSidebar,
            'lg:vs-gap-16': !this.sidebarSize || this.sidebarSize === 'medium',
            'lg:vs-gap-8': this.sidebarSize === 'large',
            'vs-flex-col': !this.hideSidebar && this.sidebarPosition !== 'left',
            'vs-flex-col-reverse': !this.hideSidebar && this.sidebarPosition === 'left',
            'lg:vs-flex-row': !this.hideSidebar && this.sidebarPosition !== 'left',
            'lg:vs-flex-row-reverse': !this.hideSidebar && this.sidebarPosition === 'left',
        }
    }

    get dynamicMainContentClasses () {
        return {
            'vs-flex-auto': !this.hideSidebar,
        }
    }

    get dynamicSidebarClasses () {
        return {
            'vs-overflow-hidden': true,
            'xl:vs-sticky': this.sticky,
            'xl:vs-top-16': this.sticky,
            'xl:vs-self-start': this.sticky,
        }
    }

    get cssVars () {
        return {
            '--vs-sidebarlayout-sidebar-width': this.sidebarSize === 'large' ? '394px' : '362px',
        }
    }
}
