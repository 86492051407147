
































import { Component, Mixins } from 'vue-property-decorator'
import MediaLibraryActionsMixin from '../mixins/MediaLibraryActionsMixin.vue'
import VsButtonActionsContainer from '@/components/VsButtonActionsContainer/Index.vue'
import VsMediaLibraryImage from '../components/VsMediaLibraryImage/Index.vue'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'
@Component({
    name: 'ImportFromUrl',
    components: {
        VsButtonActionsContainer,
        VsMediaLibraryImage,
    },
})
export default class extends Mixins(MediaLibraryActionsMixin) {
    get importUrl () {
        return MediaLibraryModule.importUrl
    }

    set importUrl (val) {
        MediaLibraryModule.SET_IMPORT_URL(val)
    }
}
