/* eslint-disable max-len */
const lists = {
    index: {
        title: 'Liste',
        foundLists: '{count} trovata | {count} trovate',
        orderByLabel: 'Ordina per',
        tagPlaceholder: 'Tutti i tag',
        searchPlaceholder: 'Cerca...',
        orderBy: {
            createdAtDesc: 'Più recenti',
            createdAtAsc: 'Meno recenti',
            lastCampaignSentAtDesc: 'Invio più recente',
            lastCampaignSentAtAsc: 'Invio meno recente',
        },
        emptyState: {
            title: 'Ancora nessuna lista',
            subtitle: 'Quando creerai una lista, comparirà qui. Pronto per iniziare?',
            actionText: 'Crea lista',
        },
        suppressionList: 'Lista di soppressione',
        suppressionListTip: 'I contatti inclusi in questa lista saranno esclusi da tutti gli invii',
        suppressionTotalContacts: 'Contatto | Contatti',
        type: {
            SmsOnly: 'Solo Sms',
            EmailPriority: 'Email',
            SmsPriority: 'Sms + Email',
        },
        shared: 'Condivisa',
        shareOptions: {
            yourLists: 'Le tue liste',
            sharedList: 'Condivise con te',
        },
        createTagSuccess: 'Tag creato con successo',
        noResult: '0 liste trovate',
        addTagSuccess: 'Tag assegnato con successo',
        removeTagSuccess: 'Tag rimosso con successo',
        deleteError: 'Errore durante la cancellazione della lista | Errore durante la cancellazione delle liste',
        deleteSuccess: '{count} lista eliminata con successo | {count} liste eliminate con successo',
    },
    actions: {
        newList: 'Nuova lista',
        addSubscriber: 'Aggiungi contatto',
        importSubscribers: 'Importa contatti',
        showSubscribers: 'Vedi contatti',
        shareList: 'Condividi lista',
        statistics: 'Statistiche',
        settings: 'Impostazioni',
        profiling: 'Profilazioni',
        clone: 'Duplica lista',
        delete: 'Elimina lista',
        exit: 'Esci',
    },
    topActions: {
        selectedFiles: '{count} lista selezionata | {count} liste selezionate',
        selectAll: 'Seleziona tutte',
        merge: 'Unisci',
        addTag: 'Assegna Tag',
        removeTag: 'Rimuovi Tag',
        delete: 'Elimina',
    },
    listCard: {
        open: 'Aperture:',
        click: 'Click:',
        totalContacts: 'Totale contatti:',
        totalContactsTip: 'E\' il numero totale dei contatti della lista a prescindere dal loro stato d\'iscrizione.',
        lastCampaignSent: {
            warning: {
                title: 'Lista non utilizzata da molto tempo',
                description: 'Questa è una lista che non utilizzi da più di 30 giorni. Tieni pulito il tuo account da dati che non utilizzi e cancella questa lista.',
            },
            success: {
                title: 'Lista utilizzata di frequente',
                description: 'Questa è una lista che utilizzi spesso per inviare delle campagne. Bravo continua a lavorare in questo modo con il tuo pubblico.',
            },
            info: {
                title: 'Lista utilizzata raramente',
                description: 'Questa è una lista che utilizzi raramente. Prova a interagire di più con il tuo pubblico oppure cancella la lista e fai pulizia sul tuo account.',
            },
        },
        lastEngagementRate: {
            warning: {
                title: 'Nessuna interazione recente',
            },
            success: {
                title: 'Interazioni recenti',
            },
        },
    },
    assignTagModal: {
        title: 'Assegna un tag',
        titleRemove: 'Rimuovi un tag',
        newTag: 'Crea nuovo tag',
        selectTag: 'Seleziona un tag',
        close: 'Annulla',
        save: 'Salva',
    },
    cloneListModal: {
        title: 'Duplica lista',
        description: 'Copia tutte le impostazioni della lista in una nuova (non copia gli iscritti). Se preferisci, nel campo di seguito, puoi dare un nome alla copia della lista.',
        nameLabel: 'Nome',
        namePlaceholder: 'Inserisci qui il nome della nuova lista',
        error: 'Errore durante la duplica della lista',
        success: 'Lista duplicata con successo',
        close: 'Annulla',
        save: 'Duplica',
    },
    mergeListModal: {
        title: 'Unisci due liste',
        description: 'Questa funzione effettua l\'unione degli iscritti presenti nelle liste selezionate (non copia le impostazioni ad esse legate). Se preferisci puoi dare un nome alla nuova lista.',
        nameLabel: 'Nome',
        namePlaceholder: 'Inserisci qui il nome della nuova lista',
        error: 'Errore durante l\'unione delle due liste',
        success: 'Liste unite con successo',
        close: 'Annulla',
        save: 'Unisci',
    },
    confirmDeleteList: {
        confirmButton: 'Elimina definitivamente',
        closeButton: 'Annulla',
        title: 'Eliminando questa lista non sarai più in grado di recuperarla | Eliminando queste liste non sarai più in grado di recuperarle',
        subtitle: 'Anche tutti i contatti della lista saranno eliminati definitivamente. Se hai selezionato delle liste a cui hai inviato meno di 10 giorni fa non verranno eliminate.',
    },
    createContact: {
        title: 'Lista',
        addContactToList: 'Aggiungi un contatto alla lista',
        noFields: 'Non ci sono campi sulla lista. Per favore aggiungi un campo',
        showOptional: 'Mostra tutti i campi opzionali',
        addContact: 'Aggiungi contatto',
        sidebarTitle: 'Tanti contatti?',
        sidebarButton: 'Usa l\'import multiplo',
        settings: 'Impostazioni',
        optional: 'opzionale',
        sendOptInEmail: 'Invia l\'email di conferma iscrizione',
        sendOptInEmailDesc: 'Scegli questa opzione per inviare l\'email di conferma iscrizione se il contatto non è presente in lista',
        importAs: 'Importa il contatto come:',
        subscribed: 'Iscritto',
        optIn: 'Attesa conferma iscrizione',
        others: 'Altro',
        updateIfDuplicate: 'Aggiorna il contatto se esiste in lista',
        updateIfDuplicateDesc: 'Scegli questa opzione per aggiornare il contatto esistente con le informazioni appena inserite',
        triggerFlux: 'Esegui gli automatismi collegati a questa lista',
        triggerFluxDesc: 'Scegli questa opzione per eseguire tutti i flussi di marketing automation ed i comportamenti post iscrizione collegati a questa lista.',
        optInConfirm: 'Dichiaro di rispettare le seguenti condizioni:',
        declare: 'Sto importando i dati di persone e Aziende e dei relativi dati di contatto. Dichiaro di essere consapevole che:',
        claim: `
            il mancato rispetto delle condizioni contrattuali sottoscritte potranno comportare il blocco del servizio.</br>
            Non ho recuperato da internet o da elenchi pubblici, manualmente o con software appositi, i dati che sto importando.</br>
            Se i contatti provengono da sistemi terzi, ho verificato attentamente che le informazioni reperite siano già al netto di disiscrizioni, bounce, segnalazioni (spam compliant).</br>
            In caso abbia acquistato i dati da società apposite che mi garantiscono il double opt-in e la qualità dei contatti, sono consapevole che potrebbe non essere sufficiente a esimermi da responsabilità di spam o blacklist e di privacy. Ho le prove che il consenso sia stato ottenuto dai destinatari prima dell'invio o, in alternativa, di aver individuato una specifica base giuridica per l'invio delle comunicazioni (a titolo esempificativo: legittimo interesse). Qualora decidessi di inviare mail verso indirizzi aziendali, rispetterò la normativa in materia di "comunicazioni commerciali non sollecitate"
        `,
    },
    createUpdateList: {
        createList: 'Crea Lista',
        nameList: 'Nome della lista',
        identifyAudience: 'Imposta un nome ben preciso per identificare la tua audience.',
        findOutList: 'Scopri cos\'è una lista!',
        howRecognizeSubscriber: 'Come vuoi riconoscere un nuovo contatto?',
        chooseUniqueField: 'Scegli quale campo univoco della lista deve essere utilizzato per riconoscere se un contatto è già presente oppure è nuovo.',
        findOut: 'Scopri come',
        byEmail: 'Tramite email',
        raccomanded: 'raccomandato',
        byEmailCaption: 'Con questa opzione non avrai mai due contatti con la stessa email.',
        byMobile: 'Tramite telefono',
        byMobileCaption: 'Con questa opzione non avrai mai due contatti con lo stesso numero di telefono.',
        byAlwaysNew: 'È sempre un nuovo contatto',
        byAlwaysNewCaption: 'Con questa opzione qualsiasi inserimento di contatto ne crea uno nuovo.',
        byOther: 'Altro...',
        byOtherCaption: 'Con questa opzione decidi tu con quale campo vuoi riconoscere i contatti duplicati.',
        addField: 'Aggiungi campo',
        settings: 'Opzioni',
        subscriptionMethod: 'Metodo di iscrizione',
        doubleOptIn: 'Invia email di conferma iscrizione (double opt-in)',
        doubleOptInCaption: 'Scegli questo metodo per inviare un\'email di conferma iscrizione ad ogni nuovo contatto della lista. Sarà possibile modificare la mail nelle impostazioni.',
        doubleOptInCaptionEdit: 'Scegli questo metodo per inviare un\'email di conferma iscrizione ad ogni nuovo contatto della lista',
        singleOptIn: 'Iscrivi immediatamente (single opt-in)',
        singleOptInCaption: 'Scegli questo metodo per iscrivere direttamente il contatto in lista se hai già il permesso di comunicare con questi contatti.',
        updateOptinEmail: 'Gestisci email',
        createOptinEmail: 'Crea email',
        deleteOptinEmail: 'Elimina e reimposta email di default',
        confirmDeleteOptinEmailTitle: 'Sei sicuro di voler eliminare l\'email di conferma?',
        confirmDeleteOptinEmailSubtitle: 'Eliminando l\'email di conferma verrà ripristinata quella di default.',
        preview: 'Vedi anteprima',
        suppressionListTitle: 'Scegli il comportamento alla disiscrizione',
        suppress: 'Sopprimi da tutte le liste',
        suppressCaption: 'L\'iscritto sarà inserito all\'interno della lista di soppressione. Finchè rimarrà in quella lista non gli verrà più inviata nessuna campagna.',
        dontSuppress: 'Non sopprimere',
        dontSuppressCaption: 'L\'iscritto verrà disiscritto dalla lista senza essere soppresso.',
        listCreated: 'La tua lista è stata creata',
        listCreatedDescription: 'Gli iscritti in {0} saranno {1}',
        listCreatedUniqueField: {
            email: 'riconosciuti tramite il campo email',
            mobile: 'riconosciuti tramite il campo telefono',
            none: 'sempre nuovi contatti',
            other: 'riconosciuti tramite il campo {msg}',
        },
        importContacts: 'Importa contatti',
        configureFields: 'Configura i campi',
        editConfiguration: 'Vuoi modificare la configurazione della lista appena creata?',
        goToSettings: 'Vai alle impostazioni',
        customFieldsNumber: '{count} campo | {count} campi',
        editEmail: 'Modifica email',
        subscriptionBehaviorsTitle: 'Quando un contatto si iscrive a questa lista',
        subscriptionBehaviors: 'Iscrivi in un\'altra lista',
        subscriptionBehaviorsCaption: 'Spunta questa opzione se all\'iscrizione di un nuovo contatto, vuoi iscrivere lo stesso contatto anche in un\'altra lista.',
        chooseList: 'Scegli lista...',
        unsubscribeFromList: 'Disiscrivi da una lista',
        unsubscribeFromListCaption: 'Spunta questa opzione se all\'iscrizione di un nuovo contatto, vuoi disiscrivere lo stesso contatto da un\'altra lista.',
        redirectAfterRegistration: 'Dopo l\'iscrizione, redirigi il contatto a',
        confirmationRequestPage: 'Una pagina di richiesta conferma',
        confirmationRequestPageCaption: 'Spunta questa opzione se all\'iscrizione di un nuovo contatto, vuoi mostrare al contatto una pagina personalizzata di richiesta conferma iscrizione. (Funziona solo con i form classici)',
        insertUrl: 'Inserisci un url',
        confirmationPage: 'Una pagina di conferma avvenuta',
        confirmationPageCaption: 'Spunta questa opzione se all\'iscrizione di un nuovo contatto, vuoi mostrare al contatto una pagina personalizzata di iscrizione avvenuta con successo.',
        registrationErrorPage: 'Una pagina di errore iscrizione',
        registrationErrorPageUnsubscribe: 'Una pagina di errore disiscrizione',
        registrationErrorPageCaption: 'Spunta questa opzione se all\'iscrizione di un nuovo contatto, vuoi mostrare al contatto una pagina personalizzata di errore d\'iscrizione.',
        unsubscriptionMethod: 'Metodo di disiscrizione',
        unsubscribeImmediately: 'Disiscrivi immediatamente',
        unsubscribeImmediatelyCaption: 'Nessuna conferma richiesta.',
        unsubscribeConfirmationPage: 'Con pagina di conferma (double opt-out)',
        unsubscribeConfirmationPageCaption: 'È richiesta una conferma di disiscrizione.',
        unsubscribeMotivationalChoises: 'Conferma con scelte motivazionali (double opt-out)',
        unsubscribeMotivationalChoisesCaption: 'È richiesta una conferma di disiscrizione.',
        unsubscriptionBehaviorsChoises: 'Scegli il comportamento alla disiscrizione',
        unsubscriptionBehaviorsTitle: 'Quando un contatto si disiscrive da questa lista',
        unsubscriFromAllLists: 'Disiscrivi da tutte le liste',
        unsubscriFromAllListsCaption: 'Spunta questa opzione se alla disiscrizione di un nuovo contatto, vuoi disiscrivere lo stesso contatto anche da tutte le liste presenti sul tuo account.',
        unsubscribeFromListCaption2: 'Spunta questa opzione se alla disiscrizione di un nuovo contatto, vuoi disiscrivere lo stesso contatto anche da un\'altra lista.',
        redirectAfterUnsubscription: 'Dopo la disiscrizione, redirigi il contatto a',
        confirmationPageCaption2: 'Spunta questa opzione se alla disiscrizione di un nuovo contatto, vuoi mostrare al contatto una pagina personalizzata di disiscrizione avvenuta con successo.',
        registrationErrorPageCaption2: 'Spunta questa opzione se alla disiscrizione di un nuovo contatto, vuoi mostrare al contatto una pagina personalizzata di errore di disiscrizione.',
        notificationTitle: 'Notifiche',
        notification: 'Invia una notifica in caso di iscrizione o disiscrizione',
        notificationCaption: 'Spunta questa opzione per ricevere una notifica ogni volta che un contatto si iscrive o si disiscrive da questa lista.',
        webServiceIntegrationTitle: 'Integrazione Web Services',
        webServiceId: 'ID',
        webServiceUrl: 'URL',
        webServiceTrigger: 'Si scatena',
        addWebService: 'Aggiungi Web Service',
        save: 'Salva',
    },
    customFields: {
        uniqueFieldTitle: 'Campi univoci',
        uniqueFieldSubtitle: 'Di seguito i campi utilizzati per riconoscere i nuovi iscritti',
        listFields: 'Campi della lista',
        listFieldsSubtitle: 'Di seguito i campi non univoci disponibili per questa lista.',
        addField: 'Aggiungi campo',
        foundFields: '{count} trovato | {count} trovati',
        deleteFieldConfirmButton: 'Elimina definitivamente',
        deleteFieldCloseButton: 'Annulla',
        deleteFieldText: 'Eliminando questo campo non sarai più in grado di recuperarlo',
        deleteFieldNotification: 'Attenzione, quest\'operazione è irreversibile! Procedendo verranno eliminati tutti i dati, che fanno riferimento a questo campo, dei contatti presenti in lista.',
    },
    customfieldType: {
        'Single line': 'Campo di testo (linea singola)',
        'Paragraph text': 'Campo di testo (linea multipla)',
        'Multiple choice': 'Gruppo di preferenza singola (radio button)',
        'Drop down': 'Gruppo di preferenza singola (select)',
        Checkboxes: 'Gruppo di preferenza multipla (checkbox)',
        BooleanCheckbox: 'Campo accettazione (checkbox)',
        'Date field': 'Campo data (formato YYYY-MM-DD)',
        'Time field': 'Campo ora (formato hh:mm:ss)',
        'Hidden field': 'Campo input nascosto (hidden)',
        Email: 'Campo indirizzo email',
        Mobile: 'Campo telefono cellulare',
    },
    webServiceModal: {
        addField: 'Aggiungi Web Service',
        trigger: 'Si scatena',
        atSubscription: 'All\'iscrizione',
        atUnubscription: 'Alla disiscrizione',
        url: 'Url',
        https: 'https://',
        http: 'http://',
        insertUrl: 'Inserisci una URL',
        close: 'Annulla',
        save: 'Crea',
    },
    listIdIndex: {
        contactsList: 'Contatti',
        statistics: 'Statistiche',
        profiling: 'Profilazioni',
        settings: 'Impostazioni',
        configurationFields: 'Campi e gruppi',
        importHistory: 'Importazioni',
        listSync: 'Sincronizzazione',
        tags: 'Etichette',
    },
    contactSupportModal: {
        contactSupportTitle: 'Contatta il supporto',
        editCustomField: 'Per la modifica di questo campo personalizzato',
        contactSupport: 'contatta il supporto',
        close: 'Annulla',
    },
    customFieldCard: {
        unique: 'Univoco',
        required: 'Obbligatorio',
        copyToClipboard: 'Copia negli appunti',
        edit: 'Modifica',
        delete: 'Elimina',
    },
    customFieldModal: {
        edit: 'Modifica',
        add: 'Aggiungi',
        chooseField: 'Scegli un campo',
        name: 'Nome',
        insertName: 'Inserisci un nome',
        type: 'Tipo',
        value: 'Opzione',
        tag: 'Etichetta',
        addOption: 'Aggiungi opzione',
        requiredField: 'Campo obbligatorio',
        hideAdvanced: 'Nascondi avanzate',
        advanced: 'Avanzate',
        showOnSubscribersArea: 'Rendi visibile e modificabile nell\'area iscritti',
        validation: 'Validazione',
        rule: 'Regola',
        defaultValue: 'Valore di default',
        validationNotification: 'Se modifichi il tipo o la validazione di un campo potresti perdere i dati associati a questo campo',
        close: 'Annulla',
        saveEdit: 'Modifica',
        saveAdd: 'Aggiungi',
    },
    subscriberSidebar: {
        configurationSubscriber: 'Configurazione scheda contatto',
        recognizedAs: 'Riconosciuto con:',
        alwaysNew: 'Sempre nuovo contatto',
        more: '+ altri',
        required: '*obbligatorio',
    },
    importHistory: {
        title: 'Storico importazioni',
        details: 'Vedi dettagli',
        noImports: 'Ancora nessuna importazione',
        importContacts: 'Contatti inseriti',
        updatedContacts: 'Contatti aggiornati',
        importErrors: 'Errori rilevati',
        statusName: 'Stato',
        fromSync: 'da sincronizzazione',
        methods: {
            file: 'File',
            url: 'Url',
            db: 'Database mysql',
        },
        status: {
            draft: 'Interrotta',
            ready: 'Interrotta',
            processing: 'In corso',
            failed: 'Fallita',
            done: 'Completata',
        },
        errors: {
            InternalError: 'Errore durante l\'importazione',
            FileNotReadable: 'File non leggibile',
            FileNotParsable: 'File non leggibile perché malformattato',
            DbNotReadable: 'Database non accessibile',
            DbTableNotExist: 'Tabella inesistente',
            DbQueryError: 'Errore con la query impostata',
            DbTableColumnNotExist: 'Colonne specificate inesistenti',
            DbLimitSettingsNotValid: 'Impostazione limit non valida',
            PaginatedSettingsPageKeyMissing: 'Parametro page mancante',
            ResponseCurrentPageNotFound: 'Parametro current page non trovato nella risposta API',
            ResponseTotalPageNotFound: 'Parametro total page non trovato nella risposta API',
            ApiCallFailed: 'Chiamata API fallita',
            StrategyTypeIsNotSupported: 'Tipologia di sorgente non prevista',
            MaxContactsReached: 'Numero massimo di contatti raggiunto',
            MaxExecutionTimeReached: 'Tempo massimo di importazione raggiunto',
        },
    },
    importDetailDrawer: {
        title: 'Dettaglio importazione',
        resume: 'Riepilogo',
        date: 'Data',
        method: 'Metodo',
        startAt: 'Data inizio',
        endAt: 'Data fine',
        duration: 'Durata',
        totalContacts: 'Contatti processati:',
        importContacts: 'Contatti inseriti',
        updatedContacts: 'Contatti aggiornati',
        importErrors: 'Errori rilevati',
        importNotFound: 'Importazione non trovata',
        downloadReport: 'Scarica resoconto',
    },
    importContacts: {
        exit: 'Esci',
        importContacts: 'Importa contatti in',
        firstStep: 'Caricamento',
        secondStep: 'Mappatura',
        thirdStep: 'Conferma',
        importFromFile: {
            alertMissingRequiredFields: 'Mappa il campo | Mappa i campi',
        },
        selectMethod: {
            title: 'Come vuoi importare i tuoi contatti?',
            subtitle: 'Puoi importare i tuoi contatti facilmente con CSV o copia e incolla. Cerchi un metodo per importare i contatti che non è presente?',
            contactUs: 'Contattaci!',
            chooseMethodLabel: 'Scegli il metodo di importazione',
            cannotStartMultipleImport: 'Hai già un\'importazione in corso. Attendi che finisca prima di procedere con la prossima.',
            csvText: 'Excel (CSV)',
            csvCaption: 'Puoi importare file CSV con qualsiasi numero di colonne',
            copyText: 'Copia e incolla',
            copyCaption: 'Scegli questo metodo se hai i contatti a portata di mano in un file',
            advanced: 'Metodi avanzati',
            urlText: 'Da URL',
            urlCaption: 'Scegli questo metodo per importare i contatti da una API JSON pubblica',
            dbText: 'Da database mysql',
            dbCaption: 'Scegli questo metodo per importare i contatti da un database mysql',
            next: 'Procedi al caricamento',
        },
        fromFile: {
            title: 'Seleziona un file da importare',
            subtitle: 'Se non sei sicuro di come formattare il file, ',
            downloadExample: 'scarica un esempio',
            university: 'Se vuoi approfondire, ',
            universityLink: 'leggi la guida su University',
            selectFile: '',
            filePlaceholder: 'i-miei-contatti.csv',
            fileCaption: 'Formato accettato: csv',
            fileFirstLineText: 'La prima riga contiene l\'intestazione delle colonne',
            fileFirstLineCaption: 'Lascia questa opzione spuntata se la prima riga del tuo file contiene l\'intestazione delle colonne e non deve essere importata',
            fileSeparatorLabel: 'Separatore',
            fileSeparatorComma: ', (virgola)',
            fileSeparatorSemicolon: '; (punto e virgola)',
            fileSeparatorAuto: 'automatico',
            upload: 'Carica Contatti',
            uploading: 'Caricamento in corso...',
            uploadSuccess: 'Il file è stato caricato correttamente',
            uploadError: 'Caricamento fallito',
            uploadErrorFileChanged: 'Rilevata modifica sul file',
            uploadErrorFileChangedMessage: 'Riseleziona il file per proseguire',
            uploadErrorSize: 'Il file non può essere più grande di',
            uploadErrorServerSize: 'Il file caricato è troppo grande',
            uploadErrorGeneric: 'Si è verificato un errore, riprova in un secondo momento',
            errors: {
                'The import strategy settings.file must be a file of type: csv, txt.': 'Il formato del file non è valido oppure il file potrebbe contenere dei caratteri speciali non accettati',
                'The import strategy settings.file must be a file of type: text/plain, text/csv.': 'Il formato del file non è valido oppure il file potrebbe contenere dei caratteri speciali non accettati',
                ErrorOnReadFile: 'Errore durante la lettura del file',
                ErrorOnParseCsvFile: 'Errore durante la lettura del file',
                NoResultFound: 'Non ci sono risultati da importare',
            },
            errorNotification: {
                heading: 'Il tuo file non può essere processato perché c\'è un errore sulla riga {count}.',
                subtitle: 'Per risolvere il problema puoi seguire queste accortezze:',
                hint: 'Elimina la riga con l\'errore dal tuo file',
                hint2: 'Controlla che non ci siano apostrofi (\') o doppi apici (") di troppo all\'interno della riga',
                hint3: 'Controlla che non ci siano virgole (,) o punti e virgola (; ) di troppo all\'interno della riga',
                hint4: 'Controlla di aver selezionato il separatore corretto',
            },
        },
        fromUrl: {
            title: 'Importa da URL',
            subtitle: 'In questa sezione è possibile importare gli iscritti nella tua lista a partire da una API JSON.',
            university: 'Se vuoi approfondire, ',
            universityLink: 'leggi la guida su University',
            apiUrlPlaceholder: 'https://reqres.in/api/users',
            apiUrlLabel: 'API URL',
            apiUrlCaption: 'Inserisci l\'URL di una API pubblica che risponda in JSON. La risposta deve essere un array di oggetti.',
            rootPlaceholder: 'data.users',
            rootLabel: 'Root della lista utenti (opzionale)',
            rootCaption: 'Inserisci il percorso in dot notation se la lista utenti non si trova nella root della risposta JSON',
            paginationLabel: 'Paginazione',
            paginationText: 'Abilita la paginazione',
            paginationDesc: 'Parametri per la paginazione in GET',
            pageKeyLabel: 'Chiave per il numero di pagine',
            pageKeyPlaceholder: 'Chiave',
            pageKeyCaption: 'Nome della chiave in dot notation che indica il numero di pagine',
            pageValuePlaceholder: 'Pagina',
            pageValueCaption: 'Parti da questo numero di pagina',
            limitKeyLabel: 'Limite di oggetti per pagina',
            limitKeyPlaceholder: 'Chiave',
            limitKeyCaption: 'Nome della chiave in dot notation che indica il numero massimo di elementi per pagina',
            limitValuePlaceholder: 'Limite',
            limitValueCaption: '0 per non impostare alcun limite',
            paginationResponseTitle: 'Parametri per leggere la risposta paginata',
            responseTotalPageKeyPlaceholder: 'Chiave',
            responseTotalPageKeyLabel: 'Numero totale di pagine',
            responseTotalPageKeyCaption: 'Nome della chiave in dot notation del totale delle pagine nell\'oggetto di risposta',
            responsePageKeyPlaceholder: 'Chiave',
            responsePageKeyLabel: 'Pagina corrente',
            responsePageKeyCaption: 'Nome della chiave della pagina corrente nell\'oggetto di risposta. Se vuoto è uguale a quella della chiamata',
            responseTotalPageValuePlaceholder: 'Massimo pagine',
            paginationResponseOthers: 'Altri parametri',
            responseTotalPageValueLabel: 'Massimo numero di pagine da recuperare',
            responseTotalPageValueCaption: 'Massimo delle pagine da recuperare. Se lasciato a 0 non ci saranno limiti',
            createImportError: 'Errore durante la creazione dell\'import',
            preprocessError: 'Siamo spiacenti ma non è stato possibile prelevare dei dati',
            preprocessErrorMessage: 'Verifica che l\'url o il percorso indicato nella root sia corretto',
            errors: {
                'The import_strategy_settings.url is not a valid URL.': 'API Url specificato non è valido',
                NoResultFound: 'Non ci sono risultati da importare',
                RequestPageKeyNotValid: 'Il nome della chiave della pagina corrente nell\'oggetto di risposta non è valido',
            },
        },
        fromDb: {
            title: 'Importa da Database mysql',
            subtitle: 'In questa sezione è possibile importare gli iscritti nella tua lista a da un database MySQL accessibile da remoto.',
            university: 'Se hai bisogno di più informazioni, ',
            universityLink: 'leggi la nostra guida',
            hostUrlPlaceholder: '137.15.189.128',
            hostUrlLabel: 'Indirizzo Host',
            hostUrlCaption: 'Inserisci il dominio o l\'ip del tuo database.',
            portPlaceholder: '3306',
            portLabel: 'Porta (opzionale)',
            portCaption: 'Inserisci la porta di connessione. Default 3306.',
            usernamePlaceholder: 'my_username',
            usernameLabel: 'Username',
            usernameCaption: 'Inserisci l\'username di un utente abilitato con cui accedere al database.',
            passwordPlaceholder: 'secret123',
            passwordLabel: 'Password',
            passwordCaption: 'Inserisci la password per autenticare l\'utente abilitato.',
            databaseNamePlaceholder: 'my_database',
            databaseNameLabel: 'Nome Database',
            databaseNameCaption: 'Inserisci il nome del database.',
            tableNamePlaceholder: 'my_table',
            tableNameLabel: 'Nome Tabella',
            tableNameCaption: 'Inserisci il nome della tabella da cui importare i dati.',
            advanceTitle: 'Avanzate',
            advanceLabel: 'Abilita le impostazioni avanzate',
            columnsLabel: 'Filtra le colonne della tabella',
            columnsCaption: 'Inserisci tutte le colonne che vuoi filtrare per i record estratti della tabella. Corrisponde a tutto ciò che viene dopo una SELECT. Il default corrisponde a SELECT *.',
            addColumnsButton: 'Aggiungi colonne',
            editColumnsButton: 'Modifica colonne',
            conditionPlaceholder: 'id = 1',
            conditionLabel: 'Condizione con la quale filtrare i record',
            conditionCaption: 'Inserisci la query con cui filtrare i record della tua tabella con una sintassi SQL valida corrispondente a tutto ciò che viene dopo una WHERE.',
            limitPlaceholder: 'Limite',
            limitLabel: 'Limite',
            limitCaption: '0 per non impostare alcun limite',
            upload: 'Carica Contatti',
            notificationWarning: 'Prima di effettuare un importazione da database verifica con il tuo dipartimento IT di aver messo in whitelist l\'indirizzo IP con cui effettueremo la connessione: 35.204.109.133',
            createImportError: 'Errore durante la creazione dell\'import',
            preprocessError: 'Siamo spiacenti ma non è stato possibile prelevare dei dati',
            preprocessErrorMessage: 'Verifica che l\'url o il percorso indicato nella root sia corretto',
            errors: {
                'import_strategy_settings.host must be valid host or ip': 'L\'indirizzo host specificato non è valido.',
                NoResultFound: 'Non ci sono contatti da importare',
                DbNotReadable: 'Impossibile collegarsi, verifica i parametri di connessione e riprova.',
                DbTableNotExist: 'Impossibile collegarsi, la tabella non esiste.',
                DbCountQueryError: 'Impossibile importare contatti, verifica che la condizione inserita sia una condizione SQL valida e che non inizi con la parola WHERE.',
                DbQueryError: 'Impossibile importare contatti, verifica che la condizione inserita sia una condizione SQL valida e che non inizi con la parola WHERE.',
                DbTableColumnsNotExists: 'Impossibile importare contatti, verifica che i nomi delle colonne inserite esistano sulla tabella indicata.',
            },
        },
        copyPaste: {
            title: 'Copia e incolla i tuoi contatti',
            subtitle: 'La riga di intestazione {0}.',
            subBold: 'è facoltativa',
            subtitle2: 'I campi devono essere separati da {0}.',
            subBold2: 'una tabulazione (TAB)',
            university: '@:lists.importContacts.fromFile.university',
            universityLink: '@:lists.importContacts.fromFile.universityLink',
            copyCaption: 'Il numero massimo di righe da importare è ',
            fileFirstLineText: '@:lists.importContacts.fromFile.fileFirstLineText',
            fileFirstLineCaption: '@:lists.importContacts.fromFile.fileFirstLineCaption',
            upload: '@:lists.importContacts.fromFile.upload',
            uploading: '@:lists.importContacts.fromFile.uploading',
            uploadSuccess: '@:lists.importContacts.fromFile.uploadSuccess',
            uploadError: '@:lists.importContacts.fromFile.uploadError',
            uploadErrorSize: '@:lists.importContacts.fromFile.uploadErrorSize',
            uploadErrorGeneric: '@:lists.importContacts.fromFile.uploadErrorGeneric',
            uploadErrorNotPossible: 'Non è possibile caricare i contatti',
            uploadErrorNoContent: 'Devi inserire un contenuto',
            uploadErrorLineLimit: 'Hai superato il limite di righe consentite',
            errors: {
                ErrorOnReadFile: 'Errore durante la lettura del file',
                ErrorOnParseCsvFile: 'Errore durante la lettura del file',
                NoResultFound: 'Non ci sono risultati da importare',
            },
        },
        mapFields: {
            confirm: 'Conferma mappatura',
            requiredFields: 'Campi obbligatori:',
            requiredFieldsDesc: 'Mappa tutti i campi obbligatori per poter proseguire con l\'importazione',
            title: 'Mappa i campi',
            subtitle: 'Abbiamo rilevato {0} e {1} da associare. Seleziona i dati che vuoi importare per ogni contatto. {2}',
            totalContacts: '{count} contatti|{count} contatto|{count} contatti',
            totalFields: '{count} campi|{count} campo|{count} campi',
            createFields: 'Se hai bisogno di importare più dati, {0}.',
            createFieldsButton: 'crea un campo',
            tableTitle: 'Intestazione del file',
            tableData: 'Dati',
            tableField: 'Campo da associare',
            createCustomFieldError: 'Errore durante la creazione del campo personalizzato',
        },
        review: {
            title: 'Rivedi e conferma l\'importazione',
            cardTitle: 'Riepilogo',
            list: 'Lista',
            contacts: 'Contatti rilevati',
            columns: 'Colonne',
            updateIfDuplicate: 'Aggiorna i contatti già esistenti in lista',
            claim2: 'Nel caso in cui il mio account contenesse categorie particolari di dati personali (es. lista di iscritti a un partito politico o a un sindacato, destinatari affetti da patologie, ecc.), attiverò l\'autenticazione a due fattori.',
            addContacts: 'Aggiungi contatti',
            createSync: 'Crea sincronizazione',
            addContactsBlock: 'Aggiungi e blocca contatti',
            importError: 'Errore durante l\'inserimento dei contatti',
            CannotStartMultipleImport: 'Non è possibile far partire l\'importazione perché ne hai già una in corso. Attendi che finisca e riprova.',
        },
        importSuccess: {
            title: 'Il tuo import è completato',
            subtitle: 'Sono stati aggiunti {0} {1} nella lista {2}.',
            subtitleSuppr: 'Abbiamo aggiunto {0} alla lista di soppressione.',
            subtitle2: 'e aggiornati {0}',
            newContacts: '{count} nuovi contatti | {count} nuovo contatto | {count} nuovi contatti',
            contacts: '{count} contatti | {count} contatto | {count} contatti',
            errorSentence: '{0} {1}. Controlla il riepilogo per maggiori dettagli',
            errors: 'Ci sono stati|C\'è stato|Ci sono stati',
            errors2: '{count} errori|{count} errore|{count} errori',
            createCampaign: 'Crea campagna',
            resume: 'Vedi riepilogo',
            goToDashboard: 'Vai alla dashboard',
            goToSuppressionList: 'Vai alla lista di soppressione',
        },
        importFailed: {
            title: 'Qualcosa è andato storto!',
            subtitle: 'Purtroppo il tuo import non è andato a buon fine. Rivedi i dettagli e riprova',
            newImport: 'Nuova importazione',
            resume: 'Vedi riepilogo',
        },
        importProgress: {
            title: 'Stiamo importando i contatti nella lista',
            subtitle: 'Puoi anche chiudere questa pagina, ti manderemo una email quando l\'import sarà completato.',
            newImport: 'Nuova importazione',
            resume: 'Vedi riepilogo',
        },
        addQueryColumnModal: {
            title: 'Aggiungi colonne',
            subtitle: 'Inserisci il nome delle colonne che vuoi filtrare',
            inputName: 'nome colonna',
            inputPlaceholder: 'Nome della colonna',
            addButton: 'Aggiungi colonna',
            confirm: 'Conferma',
            abort: 'Annulla',
        },
    },
    suppression: {
        title: 'Lista di soppressione',
        addSuppression: 'Aggiungi soppresso',
        tabs: {
            list: 'Elenco soppressi',
            statistics: 'Statistiche',
            importHistory: 'Storico importazioni',
        },
        importSuppressions: 'Importa soppressi',
        download: 'Scarica elenco soppressi',
        sources: {
            Administrator: 'Amministratore di sistema',
            User: 'User',
            'SPAM complaint': 'Segnalazione spam',
            'Hard Bounced': 'Hard Bounced',
        },
        list: {
            emailAddress: 'Indirizzo email',
            mobile: 'Telefono cellulare principale',
            source: 'Sorgente',
            deleteConfirm: 'Sei sicuro di voler cancellare la soppressione?',
            deleteSuccess: 'Soppresso eliminato con successo',
            deleteError: 'Errore durante l\'eliminazione del soppresso',
        },
        statistics: {
            source: {
                title: 'Soppressi per sorgente',
                download: 'Scarica report',
                empty: 'Nessun contatto soppresso',
            },
            domain: {
                title: 'Soppressi per dominio',
                download: 'Scarica report',
                empty: 'Nessun indirizzo email soppresso',
            },
        },
        addSuppressionModal: {
            title: 'Aggiungi soppresso',
            addStrategy: 'Aggiungi per',
            success: 'Soppressione aggiunta con successo',
            error: 'Errore durante l\'aggiunta della soppressione',
            errorEmail: 'Indirizzo email già soppresso',
            errorMobile: 'Telefono cellulare già soppresso',
        },
    },
    selectImportList: {
        title: 'Dove vuoi importare i contatti?',
        selectListText: 'Importa i contatti nella lista',
        name: 'Lista',
        placeholder: 'Seleziona lista',
        selectSuppressionText: 'Importa nella lista di soppressione globale',
        selectSuppressionCaption: 'Selezionando questa opzione, i contatti importati non potranno più ricevere email da te.',
        nolists: 'Non hai ancora creato una lista',
        createList: 'Crea lista',
        submit: 'Vai all\'import',
    },
    sync: {
        card: {
            actions: {
                active: 'Attiva',
                disactive: 'Disattiva',
                sourceDetails: 'Dettagli sorgente',
                mapFields: 'Mappatura campi',
                editSettings: 'Impostazioni',
                delete: 'Elimina',
            },
            activated: 'Attivata',
            disactivated: 'Disattivata',
            createdAt: 'Creata il',
            synchronization: 'SINCRONIZZAZIONE',
            target: 'Target:',
            period: 'Ricorrenza:',
            syncNumber: 'Importazioni eseguite:',
            lastSyncDate: 'Ultima importazione',
            showDetails: 'Vedi dettagli',
            subscribeMode: 'MODALITÀ ISCRIZIONE CONTATTO',
            contactStatus: 'Status contatto:',
            subscribed: 'Inserisci contatto come Iscritto',
            optin: 'Inserisci contatto in attesa conferma iscrizione',
            confirmationEmail: 'Email di conferma iscrizione:',
            send: 'Invia email di conferma',
            notsend: 'Non inviare email di conferma',
            updateIfDuplicate: 'Contatti già presenti:',
            update: 'Aggiorna contatto se già esistente',
            notupdate: 'Non aggiornare contatto se già esistente',
            triggers: 'Triggers ed eventi:',
            active: 'Esegui gli automatismi collegati',
            notactive: 'Non eseguire gli automatismi collegati',
            nosync: 'Nessuna sincronizzazione',
            createSync: 'Crea sincronizzazione',
            syncDesc: 'La sincronizzazione è un sistema automatico ricorrente che ti permette di importare contatti da una fonte esterna.',
        },
        settingsModal: {
            title: 'Impostazioni',
            period: 'Ricorrenza sincronizzazione',
        },
        periods: {
            monthly: 'Una volta al mese',
            weekly: 'Una volta a settimana',
            daily: 'Una volta al giorno',
            hourly: 'Una volta all\'ora',
        },
        syncCreated: 'Sincronizzazione creata con successo',
        syncCreatedError: 'Errore di creazione',
        syncUpdated: 'Sincronizzazione modificata con successo',
        syncUpdatedError: 'Errore durante la modifica',
        syncDeleted: 'Sincronizzazione eliminata con successo',
        syncDeletedError: 'Errore durante l\'eliminazione',
    },
    contact: {
        delete: 'Elimina contatto',
        confirmationEmail: 'Invia mail di conferma iscrizione',
        goBack: 'Torna all\'elenco contatti',
        confirmDeleteTitle: 'Eliminando questo contatto non sarai più in grado di recuperarlo',
        confirmDeleteContent: 'Se il contatto ha ricevuto comunicazioni da meno di 10 giorni sarà impossibile eliminarlo.',
        sendConfirmationEmailTitle: 'Sei sicuro di voler inviare l\'email di conferma iscrizione?',
        sendConfirmationEmailContent: 'Stai per inviare nuovamente una mail di conferma iscrizione in lista. Facendo click su invia dichiari di aver il consenso a procedere in quanto richiesto dal contatto.',
        export: 'Esporta (.csv)',
        tabs: {
            details: 'Scheda contatto',
            deliverability: 'Qualità',
            tags: 'Etichette',
            interests: 'Interessi',
            activities: 'Attività',
            eplus: 'E-commerce',
        },
        status: {
            Subscribed: 'Iscritto',
            Unsubscribed: 'Disiscritto',
            'Opt-In Pending': 'In attesa conferma iscrizione',
            'Opt-Out Pending': 'In attesa conferma disiscrizione',
        },
        suppression: {
            suppressed: 'Soppresso',
            notSuppressed: 'Non soppresso',
        },
        bounce: {
            'Not Bounced': 'Non bounced',
            Hard: 'Hard bounced',
            Soft: 'Soft bounced',
        },
        unsubscriptionMotivations: [
            'Non sono più interessato a ricevere queste comunicazioni',
            'Non reputo interessanti i contenuti che ricevo',
            'Ricevo queste email troppo frequentemente',
            'Non ricordo di essermi iscritto a questa mail list',
            'Reputo che queste email siano spam',
        ],
        activities: {
            noActivities: 'Ancora nessuna attività',
            codes: {
                subscription: 'Iscritto',
                campaignopen: 'Campagna aperta',
                autoresponderopen: 'Autoresponder aperto',
                campaignclick: 'Campagna cliccata',
                autoresponderclick: 'Autoresponder cliccato',
                campaignbounce: 'Bounce',
                autoresponderbounce: 'Bounce',
                campaignforward: 'Campagna inoltrata',
                autoresponderforward: 'Autoresponder inoltrato',
                campaignbrowserView: 'Campagna visualizzata nel browser',
                autoresponderbrowserView: 'Autoresponder visualizzato nel browser',
                campaignunsubscription: 'Disiscritto',
                autoresponderunsubscription: 'Disiscritto',
                unsubscription: 'Disiscritto',
                smsQueued: 'Sms Accodato per l\'invio',
                smsDelivered: 'Sms Consegnato',
                smsSent: 'Sms Inviato',
                smsDeliveryRejected: 'Sms - Consegna rifiutata',
                smsSentFailed: 'Sms - Invio fallito',
            },
        },
        interest: {
            tagDistribution: 'Distribuzione tag',
            forClick: 'Per click',
            forOpen: 'Per aperture',
            noStatistics: 'Ancora nessuna statistica',
        },
        eplus: {
            orders: 'Ordini',
            cartOpen: 'Carrelli aperti',
            seeDetail: 'vedi dettagli',
        },
        details: {
            canSendEmail: 'Idoneo all\'invio email',
            cannotSendEmail: 'Non idoneo all\'invio email',
            canSendSms: 'Idoneo all\'invio sms',
            cannotSendSms: 'Non idoneo all\'invio sms',
            contactChannel: 'CANALI DI CONTATTO',
            noChannel: 'Nessun canale',
            search: 'Cerca',
            searchEmail: 'Cerca Indirizzo email nelle altre liste',
            searchMobile: 'Cerca Telefono cellulare nelle altre liste',
            tags: 'ETICHETTE',
            subscriptionDate: 'DATI ISCRIZIONE',
            subscriptionStatus: 'Stato iscrizione',
            unsubscriptionTip: 'I contatti disiscritti non riceveranno le tue comunicazioni.',
            unsubscriptionStatus: 'Motivo disiscrizione',
            optinTip: 'Il contatto non risulta ancora iscritto, dovrai attendere la sua conferma.',
            optoutTip: 'I contatti in attesa di confermare la disiscrizione non riceveranno tue comunicazioni.',
            changeStatus: 'Cambia status',
            subscribe: 'Iscrivi',
            unsubscribe: 'Disiscrivi',
            optin: 'Metti in attesa conferma iscrizione',
            suppressEmail: 'Soppressione email',
            changeSuppressionStatus: 'Cambia stato di soppressione indirizzo email',
            removeEmailFromSuppressionTip: 'Non è possibile rimuovere dalla lista di soppressione un indirizzo email non inserito dall\'utente',
            removeEmailFromSuppression: 'Rimuovi l\'Indirizzo email dalla lista di soppressione',
            suppressEmailAddress: 'Sopprimi l\'indirizzo email',
            bounceStatus: 'Stato bounce email',
            emailHardBounce: 'Indirizzo email Hard bounced',
            tryRestore: 'Tenta il ripristino',
            suppressionMobile: 'Soppressione telefono',
            changeSuppressionStatusMobile: 'Cambia stato di soppressione telefono cellulare',
            removeMobileFromSuppressionTip: 'Non è possibile rimuovere dalla lista di soppressione un Telefono cellulare non inserito dall\'utente',
            removeMobileFromSuppression: 'Rimuovi il telefono cellulare dalla lista di soppressione',
            suppressMobile: 'Sopprimi il telefono cellulare',
            seeDetails: 'Vedi dettagli',
            pendingSubscription: 'In attesa conferma iscrizione il',
            sendEmailCOnfirmSubscription: 'Invia email di conferma iscrizione',
            ipPendingConfirm: 'Ip attesa conferma iscrizione',
            subscribedOn: 'Iscritto il',
            ipSubscription: 'Ip iscrizione',
            unsubscribedOn: 'Disiscritto il',
            ipUnsubscription: 'Ip disiscrizione',
            addField: 'Aggiungi campo',
            saveChanges: 'Salva modifiche',
            confirmUnsubscribeContactTitle: 'Sei sicuro di voler disiscrivere questo contatto?',
            confirmUnsubscribeContactContent: 'Il contatto non riceverà più le comunicazioni legate a questa lista.',
            unsubscribeTriggerAutomation: 'Esegui gli automatismi legati alla disiscrizione del contatto da questa lista',
            unsubscribeTriggerBehaviors: 'Esegui tutti i comportamenti legati alla disiscrizione del contatto da questa lista',
            confirmSubscribeContactTitle: 'Sei sicuro di voler iscrivere questo contatto?',
            confirmSubscribeContactContent: `
                Questa procedura metterà il contatto come iscritto in questa lista.</br>
                Utilizza questa procedura solamente se sei certo che il contatto ti abbia confermato la volontà di ricevere le tue comunicazioni.</br>
                Ti ricordiamo che è fondamentale rispettare la volontà di un contatto di non ricevere più le tue comunicazioni.
            `,
            subscribeTriggerAutomation: 'Esegui gli automatismi legati all\'iscrizione del contatto in questa lista',
            subscribeTriggerBehaviors: 'Esegui tutti i comportamenti legati all\'iscrizione del contatto in questa lista',
            triggerFlux: 'Esegui gli automatismi collegati a questa lista',
            triggerFluxSubscriptionDesc: 'Scegli questa opzione per eseguire tutti i flussi di marketing automation ed i comportamenti post iscrizione collegati a questa lista.',
            triggerFluxUnsubscriptionDesc: 'Scegli questa opzione per eseguire tutti i flussi di marketing automation ed i comportamenti post disiscrizione collegati a questa lista.',
            confirmOptinContactTitle: 'Sei sicuro di voler mettere in attesa conferma iscrizione questo contatto?',
            confirmOptinContactContent: 'Il contatto riceverà le tue comunicazioni solamente quando risulterà iscritto a questa lista.',
            optinTriggerSendConfirmationEmail: 'Invia l\'email di conferma iscrizione',
            deleteEmailSuppressionConfirmTitle: 'Sei sicuro di voler rimuovere questo indirizzo email dalla lista di soppressione?',
            deleteEmailSuppressionConfirmContent: 'Tutti i contatti iscritti alle tue liste con questo indirizzo email torneranno a ricevere le tue comunicazioni.',
            addEmailSuppressionConfirmTitle: 'Sei sicuro di voler aggiungere questo indirizzo email alla lista di soppressione?',
            addEmailSuppressionConfirmContent: 'Tutti i contatti iscritti alle tue liste con questo indirizzo email smetteranno di ricevere le tue comunicazioni.',
            deleteSmsSuppressionConfirmTitle: 'Sei sicuro di voler rimuovere questo telefono cellulare dalla lista di soppressione?',
            deleteSmsSuppressionConfirmContent: 'Tutti i contatti iscritti alle tue liste con questo telefono cellulare torneranno a ricevere le tue comunicazioni.',
            addSmsSuppressionConfirmTitle: 'Sei sicuro di voler aggiungere questo telefono cellulare alla lista di soppressione?',
            addSmsSuppressionConfirmContent: 'Tutti i contatti iscritti alle tue liste con questo telefono cellulare smetteranno di ricevere le tue comunicazioni.',
            resetConfirmTitle: 'Sei sicuro di voler procedere con il ripristino?',
            resetConfirmContent: `
                Il sistema proverà a verificare la validità dell'indirizzo email effettuando un test di connessiano alla casella postale.</br>
                La verifica non invia una vera email al contatto ma ne verifica solamente l'esistenza.</br>
                Ti ricordiamo che un indirizzo email che ha avuto problemi di recapito in passato molto probabilmente continuerà ad averne anche in futuro.
                Se possibile è consigliato chiedere un indirizzo email alternativo al diretto interessato ed aggiungerlo in lista.
            `,
        },
    },
    optinEmailModal: {
        heading: 'Email di conferma iscrizione',
        optinEmailSaved: 'Email di conferma salvata con successo',
        optinEmailSavedError: 'Errore durante il salvataggio dell\'email di conferma',
        optinEmailSavedErrorLocal: 'Non è possibile salvare il messaggio, controlla gli errori e riprova',
        confirmExitTitle: 'Sei sicuro di voler uscire?',
        confirmExitSubtitle: 'Se chiudi ora le modifiche effettuate verranno perse e verrà reimpostata l\'email di conferma iscrizione di default.',
    },
    contactList: {
        index: {
            total: '{count} contatto | {count} contatti',
        },
        topActions: {
            selectedContacts: '{count} contatto selezionato | {count} contatti selezionati',
        },
    },
    filters: {
        operators: {
            eq: 'uguale a',
            notEq: 'diverso da',
            lg: 'minore di',
            lge: 'minore uguale a',
            gt: 'maggiore di',
            gte: 'maggiore uguale a',
            like: 'contiene',
            notLike: 'non contiene',
            in: 'contiene',
            nin: 'non contiene',
            empty: 'non valorizzato',
            notEmpty: 'valorizzato',
        },
    },
    emailRecoveryModal: {
        reputationIndexTip: 'La verifica dell\'email fornisce informazioni sulla consegna e sulla qualità di un indirizzo email. Ciò significa che ti facciamo sapere se un indirizzo email è valido o meno e se può ricevere l\'email che desideri inviare.',
        reason: {
            value: {
                invalid_email: 'L\' indirizzo email non è formattato correttamente',
                invalid_domain: 'Il dominio non esiste',
                rejected_email: 'L\' indirizzo email non esiste',
                accepted_email: 'L\' indirizzo email è stato accettato dal server di posta',
                low_quality: 'L\' indirizzo email è rischioso',
                low_deliverability: 'La consegna di posta a questo indirizzo email non è garantita',
                no_connect: 'Il server di posta non è raggiungibile',
                timeout: 'Il server di posta non ha risposto nel tempo massimo consentito',
                invalid_smtp: 'Il server di posta ha restituito una risposta non valida o inaspettata',
                unavailable_smtp: 'Il server di posta non è disponibile a processare la richiesta',
                unexpected_error: 'C\'è stato un errore inaspettato',
            },
            description: {
                invalid_email: 'Indica che l\'indirizzo email non rappresenta un indirizzo sintatticamente valido.',
                invalid_domain: 'Indica che il dominio non esiste o il server di posta non è configurato per ricevere email.',
                rejected_email: 'L\'indirizzo email non esiste sul server di posta per cui non è possibile inviare.',
                accepted_email: 'Indica che l\'indirizzo email esiste. Su questo indirizzo email è sicuro inviare.',
                low_quality: 'Indica che l\'indirizzo email presenta problemi di qualità che potrebbero comportare un hard bounce o un basso coinvolgimento. Prestare sempre attenzione quando si inviano messaggi a indirizzi rischiosi.',
                low_deliverability: 'Indica che il server di posta è in grado di ricevere la posta ma la ricezione non è garantita.',
                no_connect: 'Indica se il server di posta è stato in grado di fornire la risposta alla richiesta di validità dell\'indirizzo email.',
                timeout: 'Indica se il server di posta è stato in grado di fornire la risposta alla richiesta di validità dell\'indirizzo email.',
                invalid_smtp: 'Indica se il server di posta è stato in grado di fornire la risposta alla richiesta di validità dell\'indirizzo email.',
                unavailable_smtp: 'Indica se il server di posta è stato in grado di fornire la risposta alla richiesta di validità dell\'indirizzo email.',
                unexpected_error: 'Indica se il server di posta è stato in grado di fornire la risposta alla richiesta di validità dell\'indirizzo email.',
            },
        },
        role: {
            trueValue: 'L\'indirizzo email è di ruolo o reparto',
            falseValue: 'L\'indirizzo email non è di ruolo o reparto',
            description: 'Un\'email di ruolo è un indirizzo email associato a una funzione (supporto, vendite ecc) anziché a una persona. In alcuni casi, l\'invio di email a un indirizzo di ruolo può comportare una riduzione del tasso di apertura.',
        },
        free: {
            trueValue: 'L\'indirizzo email non ha un dominio proprietario',
            falseValue: 'L\'indirizzo email ha un dominio proprietario',
            description: 'Sono indirizzi email privati (es. nome.cognome@nomeazienda.it) e si distinguono da indirizzi email che utilizzano servizi di posta gratuiti come Gmail e Yahoo! In determinati contesti, le aziende possono ricevere tassi di apertura/risposta migliori quando inviano solo a indirizzi email non gratuiti.',
        },
        disposable: {
            trueValue: 'L\'indirizzo è una email temporanea',
            falseValue: 'L\'indirizzo non è una email temporanea',
            description: 'Sono indirizzi email che puntano a domini come trashmail.com e mailinator.com. Questi servizi consentono di creare email monouso per ricevere comunicazioni iniziali da un servizio (come un\'email di attivazione) e quindi vengono eliminati. Si consiglia vivamente di non inviare posta a indirizzi email usa e getta.',
        },
    },
}
export default {
    lists,
}
