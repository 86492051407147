








































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import MainFooter from './../components/MainFooter.vue'
import LoadingScreen from './../components/LoadingScreen.vue'

@Component({
    name: 'VsAuthLayout',
    components: {
        LoginFooter: MainFooter,
        LoadingScreen,
    },
})
export default class extends Vue {
    get loadingScreen () {
        return AppModule.loadingScreen
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get user () {
        return UserModule.user
    }

    get loadingScreenText () {
        return AppModule.loadingScreenText
    }

    get beaconApiKey () {
        return AppModule.beaconApiKeyPublic
    }

    get imgUrl () {
        return AppModule.signinImg
    }

    get siteUrl () {
        return AppModule.siteUrl
    }

    get maintenanceMode () {
        return AppModule.signinMaintenanceMode
    }

    get maintenanceModeText () {
        return AppModule.signinMaintenanceModeText
    }

    get logoLogin () {
        return AppModule.consoleConf?.theme.logoLogin
    }

    beforeMount () {
        if (this.is4Dem && this.beaconApiKey && window.Beacon) {
            window.Beacon('config', {
                display: {
                    position: 'right',
                },
            })
            window.Beacon('init', this.beaconApiKey)
        }
    }

    private goToSite () {
        window.location.href = this.siteUrl
    }
}
