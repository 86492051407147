














































































































import { Component, Vue } from 'vue-property-decorator'
import VsAddOrEditAddressbookModal from '@/components/VsAddOrEditAddressbookModal/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'

import {
    getAddressbooks,
    deleteAddressbooks,
} from '@/api/consoleApi/addressbooks'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { sendEmailPreview } from '@/api/consoleApi/contents/emails'
import axios from 'axios'

export interface IPreviewContact {
    email: string
    footerid: any
}

@Component({
    name: 'VsEmailPreviewModal',
    components: {
        VsAddOrEditAddressbookModal,
        VsLoader,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private initLoading = false
    private emailId = ''
    private emailNotInFavorites = ''

    private contacts: IPreviewContact[] = []
    private selectedEmails: any[] = []
    $refs!: any

    get countEmails () {
        if (this.emailNotInFavorites) return this.selectedEmails.length + 1
        return this.selectedEmails.length
    }

    // to delete when send preview of console would be removed
    get toastFunc () {
        return this.$root.$vsToast ? this.$root.$vsToast : this.$refs?.vsToast.addToast || this.emptyFunc
    }

    // to delete when send preview of console would be removed
    emptyFunc () {
        return ''
    }

    public async openModal (emailId: string) {
        this.initLoading = true
        this.emailId = emailId
        this.selectedEmails = []
        this.emailNotInFavorites = ''
        this.show = true
        await this.getAddressbooks()
        this.initLoading = false
    }

    private async getAddressbooks () {
        try {
            const resp = await getAddressbooks()
            this.contacts = resp.data.data.map((el: any) => {
                return {
                    id: el.id,
                    email: el.email_address,
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    private async sendEmailPreview () {
        if (!this.emailId) return
        const valid = await this.$refs.formData.validate()
        if (!valid) return
        this.loading = true
        if (this.emailNotInFavorites) {
            this.selectedEmails.push(this.emailNotInFavorites)
        }
        const emails = {
            emails: this.selectedEmails,
        }
        try {
            await sendEmailPreview(this.emailId, emails)
            this.toastFunc({
                heading: this.$t('campaigns.emailPreviewModal.emailSent'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.show = false
        } catch (e) {
            this.selectedEmails = []
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 429) {
                    message = 'Puoi inviare una mail di anteprima al massimo una volta al minuto.'
                }
            }
            this.toastFunc({
                heading: this.$t('campaigns.emailPreviewModal.emailSentError'),
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.loading = false
    }

    private async deleteContact (contact: string, email: string) {
        try {
            await deleteAddressbooks(contact)
            this.toastFunc({
                heading: this.$t('campaigns.emailPreviewModal.deleteAddressSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            await this.getAddressbooks()

            if (this.selectedEmails.includes(email)) {
                const index = this.selectedEmails.indexOf(email)
                this.selectedEmails.splice(index, 1)
            }
        } catch (e) {
            this.toastFunc({
                heading: this.$t('campaigns.emailPreviewModal.deleteAddressError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    mounted () {
        // to delete when send preview of console would be removed
        this.checkSendPreviewModalEvent()
    }

    checkSendPreviewModalEvent () {
        window.openSendPreviewModal = this.openModal
    }
}
