import Cookies from 'js-cookie'
// App
const sidebarStatusKey = 'sidebar_status'
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey)
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(sidebarStatusKey, sidebarStatus)

const languageKey = 'language'
export const getLanguage = () => Cookies.get(languageKey)
export const setLanguage = (language: string) => Cookies.set(languageKey, language)

export const setCookie = (name: string, value: string, days: number) => {
    let expires = ''
    const domain = window.location.hostname
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
    }
    let cookie = name + '=' + (value || '') + expires + '; path=/;'
    if (domain) cookie += 'domain=' + domain
    document.cookie = cookie
}

export const getCookie = (name: string) => {
    const nameEQ = name + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

export const eraseCookie = (name: string) => {
    const domain = window.location.hostname
    let cookie = name + '=; Max-Age=-99999999; path=/;'
    if (domain) cookie += 'domain=' + domain
    document.cookie = cookie
}
