





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCkeditor from '@/components/VsCkeditor/Index.vue'
import {
    getEmailContentTags,
    getEmailContentButtons,
    getConfirmationEmailContentTags,
    getEmailOptinContentButtons,
} from '@/utils/personalizations'
import { CustomField } from '@/utils/customFields'
import { getFooters } from '@/api/consoleApi/contents/footers'

interface IModalData {
    content?: string
    footer?: string
}

@Component({
    name: 'VsEmailContentTextEditorModal',
    components: {
        VsFullTopBarLayout,
        VsContainer,
        VsCkeditor,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({ required: false, default: 'campaign' }) contentMode!: 'campaign' | 'optin' | 'transactional'
    private open = false
    public loading = false
    private content = ''
    private showEditor= false
    private footer: string | number = ''
    private footers: any[] = []

    private tags: any[] = []

    private buttons: any[] = []
    $refs!: any

    get consoleUrl () {
        return `${window.location.protocol}//${window.location.hostname}`
    }

    get footerOptions () {
        return this.footers.map((el) => {
            return {
                value: el.id,
                label: `${el.name}${el.default_footer ? ' (Predefinito)' : ''}`,
            }
        })
    }

    beforeMount () {
        window.addEventListener('message', this.receiveMessage)
    }

    beforeDestroy () {
        window.removeEventListener('message', this.receiveMessage)
    }

    private receiveMessage (event: any) {
        try {
            const data = JSON.parse(event.data)
            this.actionsWithObjectMessage(data, event)
        } catch (e) {
            this.actionsWithStringMessage(event)
            // data is not object
        }
    }

    private actionsWithObjectMessage (objectMessage: any, event: any) {
        if (!objectMessage.name) return
        switch (objectMessage.name) {
            case 'save':
                this.saveContent(objectMessage.value)
                break
            case 'openGallery':
                window.openVsMediaLibrary(objectMessage.mediaGalleryOptions).then((url: string) => {
                    this.$refs.ckeditorIframe.contentWindow.postMessage(
                        JSON.stringify({
                            name: objectMessage.type === 'link' ? 'linkSelected' : 'imageSelected',
                            url,
                        }),
                        '*',
                    )
                }).catch((e: any) => { console.log(e) })
                break
        }
    }

    private sendSaveMessage () {
        this.$refs.ckeditorIframe.contentWindow.postMessage('save', '*')
    }

    private actionsWithStringMessage (event: any) {
        switch (event.data) {
            case 'ready':
                this.loading = false
                event.source.postMessage(JSON.stringify({
                    name: 'init',
                    data: {
                        value: this.content,
                        tags: this.tags,
                        buttons: this.buttons,
                    },
                }), event.origin)
                break
        }
    }

    public openModal (customFields: CustomField[], data?: IModalData) {
        this.loading = true
        this.content = data?.content || ''
        this.footer = data?.footer && data?.footer !== '0' ? parseInt(data.footer) : ''
        this.tags = this.contentMode === 'optin' ? getConfirmationEmailContentTags(customFields, this.consoleUrl) : getEmailContentTags(customFields, this.consoleUrl)
        this.buttons = this.contentMode === 'optin' ? getEmailOptinContentButtons() : getEmailContentButtons()
        this.getFooters()
        this.showEditor = true
        this.open = true
    }

    private async getFooters () {
        try {
            const resp = await getFooters({ limit: 10000 })
            this.footers = resp.data.data
        } catch (e) {
            console.log(e)
            this.footers = []
        }
    }

    public closeModal () {
        this.open = false
        this.content = ''
        this.showEditor = false
    }

    private saveContent (content: any) {
        this.$emit('save-content', {
            content: content,
            footer: this.footer || 0,
        })
    }
}
