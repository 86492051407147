























































import { Component, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { authorizeSender, sendActivationCode } from '@/api/consoleApi/senders/emails'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'

@Component({
    name: 'VsConfirmSender',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private loadingSendEmail = false
    private sender: any = null
    private code = ''

    $refs: any

    public async confirmSender () {
        const validation = await this.$refs.confirmSenderForm.validate()
        if (!validation) return
        this.loading = true
        try {
            await authorizeSender(this.sender.id, this.code)
            this.$root.$vsToast({
                heading: 'Mittente abilitato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('sender-confirmed', this.sender.email)
            this.closeModal()
        } catch (e) {
            console.log(e)
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400 && e.response.data.message === 'AuthCodeIsNotValid') {
                    this.$refs.confirmSenderForm.setErrors({
                        confirmCode: ['Codice di conferma non valido'],
                    })
                }
            }
            this.$root.$vsToast({
                heading: 'Impossibile abilitare il mittente',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    public async sendVerifySenderEmail () {
        this.loadingSendEmail = true
        try {
            await sendActivationCode(this.sender.id)
            this.$root.$vsToast({
                heading: 'Email inviata con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('sender-confirmed')
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'invio dell\'email di conferma',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loadingSendEmail = false
    }

    public openModal (sender: any) {
        this.sender = sender
        this.code = ''
        this.show = true
    }

    public closeModal () {
        this.show = false
    }
}
