import { RouteConfig } from 'vue-router'
import VsLayouts from '@/layouts/Index.vue'

const leadplusRouter: RouteConfig[] = [
    {
        path: '/forms',
        name: 'leadplus',
        component: VsLayouts,
        redirect: {
            name: 'leadplusForms',
        },
        children: [
            {
                path: '',
                name: 'leadplusForms',
                component: () => import(/* webpackChunkName: "leadplus-forms" */ './../views/leadplus-forms.vue'),
            },
            {
                path: 'groups',
                name: 'leadplusGroups',
                component: () => import(/* webpackChunkName: "leadplus-groups" */ './../views/leadplus-groups.vue'),
            },
            {
                path: ':formId/edit',
                name: 'editForm',
                component: () => import(/* webpackChunkName: "leadplus-form-edit" */ './../views/id/edit.vue'),
            },
            {
                path: ':formId/statistics',
                name: 'formStatistics',
                component: () => import(/* webpackChunkName: "leadplus-form-statistics" */ './../views/id/statistics.vue'),
            },
            // {
            //     path: ':formId/test-render',
            //     name: 'testRender',
            //     component: () => import(/* webpackChunkName: "leadplus-test-render" */ './../views/id/test-render.vue'),
            // },
        ],
    },
    {
        path: '/forms/public/:formId',
        name: 'leadplusLive',
        component: () => import(/* webpackChunkName: "leadplus-live" */ './../views/leadplus-live.vue'),
        meta: {
            public: true,
        },
    },
]

export default leadplusRouter
