






















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsDrawer',
    components: {},
})

export default class extends Vue {
    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) value?: boolean

    private closeDrawer () {
        this.$emit('input', false)
        this.$emit('drawer-close')
    }
}
