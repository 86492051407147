































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsHelp from '@/components/VsHelp/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsBlockedFunctionalityModal from '@/components/VsBlockedFunctionalityModal/Index.vue'
import VsSupportEmail from '@/components/VsSupportEmail/Index.vue'
import VsMainConsoleSearch from '@/components/VsMainConsoleSearch/Index.vue'
import VsMainConsoleManagerSearch from '@/components/VsMainConsoleManagerSearch/Index.vue'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'
import VsRouterLink from '@/components/VsRouterLink/Index.vue'

@Component({
    name: 'VsTopNav',
    components: {
        VsHelp,
        VsSeparator,
        VsMainConsoleSearch,
        VsMainConsoleManagerSearch,
        VsRouterLink,
        VsBlockedFunctionalityModal,
        VsSupportEmail,
    },
})

export default class extends Vue {
    @Prop({ default: false, required: false, type: Boolean }) isManager!: boolean
    private levelList = []
    private helpActive = false
    private searchText = ''
    private searchResult: any = null
    private loading = false
    private showSearch = false
    $refs: any

    get sidebarOpen () {
        return get(AppModule, 'sidebarOpen', false)
    }

    set sidebarOpen (sidebarOpen: boolean) {
        AppModule.SET_SIDEBAR_OPEN(sidebarOpen)
    }

    get apiChannelUrl () {
        return AppModule.consoleConf?.apiChannelUrl || ''
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get user () {
        return UserModule.user
    }

    get supportRule () {
        if (!this.user) return false
        return this.user.configuration.rules.support
    }

    get logo () {
        return get(UserModule, 'settings.theme.logoConsole', '')
    }

    private setActive () {
        this.helpActive = true
    }

    private setDisactive () {
        this.helpActive = false
    }

    private formatLinkParams (path: string, isBlocked?: boolean) {
        if (isBlocked) return {}
        if (!this.$router) {
            return {
                url: '/user' + path,
            }
        }
        return {
            to: {
                path,
            },
        }
    }

    openSearch () {
        this.showSearch = true
    }
    // scommentare per breadcrumbs
    // @Watch('$route', { deep: true, immediate: true })
    // getBreadcrumb () {
    //     const matched: any = this.$route.matched.filter(item => item.meta && item.meta.showInBreadCrumbs)
    //     const first = matched[0]

    //     if (this.isDashboard(first)) {
    //         this.levelList = []
    //         return
    //     }

    //     this.levelList = matched
    //     console.log(this.levelList, 'levellist')
    // }

    // isDashboard (route: any) {
    //     const name = route && route.name
    //     if (!name) {
    //         return false
    //     }
    //     return name === 'dashboardIndex'
    // }

    // WARNING: duplicata perchè non si può usare il metodo globale fino a che viene integrata a parte su console
    private openUserSupport (navigateTicket?: boolean) {
        if (!this.supportRule) {
            this.$refs.topnavBlockedFunctionality.openModal()
        } else if (this.supportRule === 'email') {
            this.$refs.vsTopnavSupportEmail.openModal()
        } else if (this.supportRule === 'helpscout' || this.supportRule === 'helpscout_chat') {
            this.beaconClickFunc(navigateTicket)
        } else {
            this.$refs.topnavBlockedFunctionality.openModal()
        }
    }

    private beaconClickFunc (navigateTicket?: boolean) {
        if (!window.Beacon) return
        window.Beacon('open')
        if (navigateTicket) {
            window.Beacon('navigate', '/ask/')
        }
        if (!this.$gtm) {
            return
        }

        this.$gtm.trackEvent({
            event: 'event',
            Signup_Category: 'mailchef',
            Signup_Action: 'LinkClick',
            Signup_Label: 'support',
        })
    }
}
