export const copyToClipboard = (text: string) => {
    function fallbackCopyTextToClipboard (text: string) {
        const textArea = document.createElement('textarea')
        textArea.value = text
        textArea.style.top = '0'
        textArea.style.left = '0'
        textArea.style.position = 'fixed'
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()
        try {
            document.execCommand('copy')
        } catch (e) {
            console.log(e)
            throw new Error('fallbackCopyTextToClipboardExecCommandFailed')
        }

        document.body.removeChild(textArea)
    }

    function copyTextToClipboard (text: string) {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text)
        } else {
            navigator.clipboard.writeText(text).then().catch(function (e) {
                console.log(e)
                throw new Error(e)
            })
        }
    }

    copyTextToClipboard(text)
}
