import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { setLanguage } from '@/utils/cookies'
import { getLocale, loadLanguageAsync } from '@/lang'
import {
    getConsoleConfig,
} from '@/api/console'
import store from '@/store'
import { gtm } from '@/utils/gtm'
export interface IAppState {
    windowWidth: number
    language: string
    consoleConf: IConsoleConf | null
    loadingScreen: boolean
    loadingScreenText: string
    favicon: string
    footer: boolean
    universityUrl: string
    accountUrl: string
    consoleBaseUrl: string
    integrationPermissions: string[]
    siteUrl: string
    beaconApiKeyPublic: string
    signinImg: string
    signinMaintenanceMode: boolean
    signinMaintenanceModeText: string
    redirectCookie: string
    shopapiBaseUrl: string
    optinSubject: string
    sidebarOpen: boolean
    hasMocks: boolean
    pageAbortController: AbortController | null
    recaptchaSitekey: string
}

export interface IConsoleUserTheme {
    colorConsole: string
    logoConsole: string
    logoFavicon: string
    logoLogin: string
    notifySentEmail: string
}

export interface IGoogleAnalyticsSettings {
    medium: string
    source: string
}

export interface ISentThresholds {
    bounce: number
    spam: number
    unsubscription: number
}

export interface IConsoleConf {
    version: string
    theme: IConsoleUserTheme
    googleAnalyticsSettings: IGoogleAnalyticsSettings
    is4Dem: boolean
    consoleId: string
    apiChannelUrl: string
    integrationApiKey: string
    mixpanelApikey: string
    userDotComCivchatApikey: string
    userDotComApikey: string
    userDotComDomain: string
    gtmApikey: string
    userflowToken: string
    pricelistId: string
    productName: string
    leadplusHost: string
    consoleApiUrl: string
    sentThresholds: ISentThresholds
}

@Module({ dynamic: true, store, name: 'app', namespaced: true })
class App extends VuexModule implements IAppState {
    public windowWidth = window.innerWidth
    public language = getLocale()
    public consoleConf: IConsoleConf | null = null
    public loadingScreen = true
    public loadingScreenText = 'loading...'
    public favicon = ''
    public footer = true
    public universityUrl = 'https://university.4dem.it'
    public accountUrl = '' // accountUrl va utilizzata come base url solamente dove non si può usare il vue router come ad esempio in sidebar perché ustilizzato anche come libreria
    public consoleBaseUrl = process.env.VUE_APP_CONSOLE || '' // va popolato solamente in dev perché il base url dell'applicazione è diverso da quello di console, tutti i link a console devono avere questo base url
    public integrationPermissions: string[] = []
    public siteUrl = process.env.VUE_APP_SITE_URL || ''
    public beaconApiKeyPublic = process.env.VUE_APP_BEACON_APIKEY_PUBLIC || ''
    public signinImg = process.env.VUE_APP_SIGNIN_IMG || ''
    public signinMaintenanceMode = process.env.VUE_APP_SIGNIN_MAINTENANCE_MODE === 'true' || false
    public signinMaintenanceModeText = process.env.VUE_APP_SIGNIN_MAINTENANCE_MODE_TEXT || ''
    public redirectCookie = process.env.VUE_APP_REDIRECT_COOKIE || 'redirectCookie'
    public shopapiBaseUrl = process.env.VUE_APP_SHOPAPI || '' // va popolato solamente in dev perché il base url dell'applicazione è diverso da quello di console, tutti i link a console devono avere questo base url
    public optinSubject = 'Conferma la tua registrazione'
    public sidebarOpen = false
    public hasMocks = process.env.VUE_APP_LOAD_MOCK_MODULE === 'true'
    public recaptchaSitekey = process.env.VUE_APP_RECAPTCHA_SITEKEY || ''
    public pageAbortController: AbortController | null = null

    get isSmViewport () {
        return this.windowWidth < 576
    }

    get isMdViewport () {
        return this.windowWidth >= 576 && this.windowWidth < 768
    }

    get isLgViewport () {
        return this.windowWidth >= 768 && this.windowWidth < 992
    }

    get isXlViewport () {
        return this.windowWidth >= 992 // && this.windowWidth < 1200
    }

    @Mutation
    public SET_LANGUAGE (language: string) {
        this.language = language
        // set cookie
        setLanguage(this.language)
    }

    @Mutation
    public SET_SIDEBAR_OPEN (sidebarOpen: boolean) {
        this.sidebarOpen = sidebarOpen
    }

    @Mutation
    public SET_CONSOLE_CONF (consoleConf: IConsoleConf | null) {
        this.consoleConf = consoleConf
    }

    @Mutation
    public SET_ACCOUNT_URL (accountUrl: string) {
        this.accountUrl = accountUrl
    }

    @Mutation
    public SET_LOADING_SCREEN (loadingScreen: boolean) {
        this.loadingScreen = loadingScreen
    }

    @Mutation
    public SET_LOADING_SCREEN_TEXT (loadingScreenText: string) {
        this.loadingScreenText = loadingScreenText
    }

    @Mutation
    public SET_INTEGRATION_PERMISSIONS (integrationPermissions: string[]) {
        this.integrationPermissions = integrationPermissions
    }

    @Mutation
    public ADD_ROUTE (route: string) {
        if (this.integrationPermissions.indexOf(route) > -1) return
        this.integrationPermissions.push(route)
    }

    @Mutation
    public REMOVE_ROUTE (route: string) {
        const routeShopIndex = this.integrationPermissions.indexOf(route)
        if (routeShopIndex === -1) return
        this.integrationPermissions.splice(routeShopIndex, 1)
    }

    @Mutation
    public SET_OPTIN_SUBJECT (optinSubject: string) {
        this.optinSubject = optinSubject
    }

    @Mutation
    public SET_WINDOW_WIDTH (windowWidth: number) {
        this.windowWidth = windowWidth
    }

    @Mutation
    public SET_PAGE_ABORT_CONTROLLER (pageAbortController: AbortController | null) {
        this.pageAbortController = pageAbortController
    }

    @Action({ rawError: true })
    public async setLanguage (language: string) {
        await loadLanguageAsync(language)
        this.SET_LANGUAGE(language)
    }

    @Action({ rawError: true })
    public async loadAppConfig () {
        if (!this.consoleConf) {
            try {
                // get config console
                const resp = await getConsoleConfig()
                this.SET_CONSOLE_CONF({
                    version: resp.data.version,
                    theme: resp.data.theme,
                    is4Dem: resp.data.is4Dem,
                    consoleId: resp.data.consoleId,
                    apiChannelUrl: resp.data.apiChannelUrl,
                    integrationApiKey: resp.data.integrationApiKey,
                    googleAnalyticsSettings: resp.data.googleAnalyticsSettings,
                    gtmApikey: resp.data.gtmApikey,
                    mixpanelApikey: resp.data.mixpanelApikey,
                    userDotComCivchatApikey: resp.data.userDotComCivchatApikey,
                    userDotComApikey: resp.data.userDotComApikey,
                    userDotComDomain: resp.data.userDotComDomain,
                    userflowToken: resp.data.userflowToken,
                    pricelistId: resp.data.pricelistId,
                    productName: resp.data.productName,
                    leadplusHost: resp.data.leadplusHost,
                    consoleApiUrl: resp.data.consoleApiUrl,
                    sentThresholds: resp.data.sentThresholds,
                })

                if (resp.data.is4Dem) {
                    // adding integration permission based on is4Dem flag
                    this.SET_INTEGRATION_PERMISSIONS([
                        'route-login',
                        'route-signup',
                        'route-activation',
                        'route-account-communication',
                        'route-payments',
                        'route-prices',
                        'route-plan',
                        'route-integrations',
                    ])
                } else {
                    this.SET_INTEGRATION_PERMISSIONS(['route-login'])
                }
                gtm(resp.data.gtmApikey)
            } catch (e) {
                console.log(e)
            }
        }
    }
}

export const AppModule = getModule(App)
