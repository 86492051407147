/* eslint-disable max-len */
const account = {
    myAccount: {
        userData: 'Dati utente',
        title: 'Il mio account',
        errorName: {
            name: 'Nome',
            lastname: 'Cognome',
            email: 'Email',
            phone: 'Telefono',
        },
        username: 'Username',
        save: 'Salva',
        welcome: 'Benvenuto sulla dashboard del tuo account. Un unico posto con tutto sotto controllo.',
        emailUsed: 'EMAIL UTILIZZATE',
        yourPlan: 'IL TUO PIANO',
        yourAccount: 'IL TUO ACCOUNT',
        plan: 'Piano',
        contractualDates: 'Date contrattuali:',
        emailLogin: 'Email accesso:',
        willRemain: 'Ti restano {0} su',
        creditsEmail: 'Crediti Email',
        numberInfinitySendEmail: 'Numero {0} di invii email',
        infinity: 'infinito',
        tableCredit: {
            functionality: 'FUNZIONALITÀ',
            residualCredits: 'I TUOI CREDITI RESIDUI',
            deadlines: 'LE TUE SCADENZE',
            recharge: 'Ricarica',
            upgrade: 'Upgrade',
        },
        noActivePlan: 'Al momento sul tuo account non hai piani attivi.',
        successMessages: {
            dataSavedSuccessfully: 'Dati salvati con successo',
        },
        errorMessages: {
            emailNotValid: 'L\'email inserita non è valida',
            errorDuringSavingdata: 'Errore durante il salvataggio dei dati',
            somethingWentWrong: 'Qualcosa è andato storto, prova più tardi',
        },
    },
    myData: {
        yourData: 'I TUOI DATI',
        insertOrUpdate: 'Inserisci o aggiorna i tuoi dati',
    },
    myOrders: {
        yourPurchase: 'I TUOI ACQUISTI',
        purchaseMade: 'In questa sezione trovi tutti gli acquisti effettuati.',
        noOrders: 'Non hai ancora ordini',
    },
    myPlan: {
        tabs: {
            plan: 'Piano',
            invoiceData: 'Dati di fatturazione',
            history: 'Storico acquisti',
        },
        title: 'Il mio piano',
        email: 'Email',
        sms: 'Sms',
        transactional: 'Transazionali',
        readHere: 'Leggi qui',
        termsAndConditions: 'i Termini e Condizioni del tuo servizio',
        plan: 'Piano',
        free: 'Gratis',
        upgrade: 'Upgrade',
        expireOn: 'Scade il',
        requestText: 'Testo della richiesta',
        upgradePlan: 'Passa al piano',
        upgradeRequest: 'Richiesta upgrade',
        subscriptionRecap: 'Riepilogo abbonamento',
        functionalityRecap: 'Riepilogo funzionalità',
        monthlyEmail: 'email al mese',
        creditsEmail: 'crediti email',
        credits: 'Crediti',
        recharge: 'Ricarica',
        expandPlanDetails: 'Vedi dettaglio piano',
        reducePlanDetails: 'Riduci informazioni',
        of: 'di',
        analytics: 'Strumenti di analisi',
        listsAndSubscribers: 'Gestione contatti',
        emailTool: 'Strumenti di creazione email',
        sendTool: 'Strumenti di invio email',
        marketingCampaign: 'Campagne di marketing',
        support: 'Assistenza e supporto',
        subaccountAndManagement: 'Subaccount e management',
        successMessage: {
            orderCanceled: 'Ordine annullato con successo',
        },
        planDescription: {
            lists: 'Numero di liste',
            editor: 'Editor Drag&Drop',
            senders: 'Mittenti invio',
            customFields: 'Campi di lista',
            profilations: 'Profilazione contatti',
            suppression: 'Soppressione avanzata',
            crmRecipients: 'Liste CRM',
            tag: 'Etichette',
            limitSubscribers: 'Limite contatti',
            exportSubscribers: 'Esportazione contatti',
            behavioralTags: 'Etichette comportamentali',
            listsSubscription: 'Statistiche lista avanzate',
            backupData: 'Conservazione dati sui contatti',
            mediaLibrary: 'Spazio disponibile media library',
            templateEmail: 'Numero template email',
            embeddedImages: 'Immagini embedded',
            attachments: 'Allegati',
            formAndPopup: 'Form e Popup',
            pageLimits: 'Landing Page creabili',
            pagesPublic: 'Landing Page pubblicabili',
            subdomains: 'Sottodominio personalizzato',
            pageStatistics: 'Statistiche Landing Page avanzate',
            abTest: 'Campagne A/B test',
            coupon: 'Campagne Coupon',
            emailSentReport: 'Download report invio',
            booster: 'Booster',
            campaignComparison: 'Confronto campagne',
            sendListMultiple: 'Invio su liste multiple',
            noLogo: 'No logo',
            reportExport: 'Statistiche esportabili',
            automations: 'Numero marketing automation',
            automationsTriggers: 'Numero trigger automation',
            goalTracking: 'Goal Tracking',
            campaignsStatistics: 'Statistiche campagne avanzate',
            automationsStatistics: 'Statistiche automation avanzate',
            emailManagerTemplate: 'Email Manager Template',
            sharedLists: 'Condivisione liste',
            favoritesContacts: 'Contatti preferiti',
            support: 'Supporto via ticket',
            manager: 'Area manager',
            subaccount: 'Subaccount',
            ecommerce: 'Numero licenze E-Commerce Plus',
            chat: 'Chat',
            archive: 'Archivio',
        },

        invoiceData: {
            save: 'Salva',
            invoiceData: 'Dati di fatturazione',
            legend: '*Campi obbligatori/mancanti necessari per completare l\'acquisto',
            companyData: 'Dati aziendali',
            userData: 'Dati dell\'utilizzatore',
            userOwnerAccount: 'L\'utilizzatore è anche il titolare dell\'account',
            userResponsableAccount: 'L\'utilizzatore è anche il responsabile amministrativo',
            userAdminerAccount: 'Responsabile amministrativo',
            ownerAccount: 'Titolare account',
            errorName: {
                country: 'Stato',
                iva: 'Iva',
                code: 'Codice fiscale',
                sdi: 'SDI',
                pec: 'PEC',
                company: 'Nome azienda',
                sector: 'Settore',
                address: 'Indrizzo',
                civic: 'Numero civico',
                city: 'Città',
                province: 'Provincia',
                cap: 'CAP',
                name: 'Nome',
                lastname: 'Cognome',
                email: 'Email',
                phone: 'Telefono',
            },
            companyPlaceholder: 'Nome azienda*',
            sectorPlaceholder: 'Settore*',
            addressPlaceholder: 'Indirizzo*',
            sdiPlaceholder: 'Identificativo SDI',
            pecPlaceholder: 'PEC (Posta elettronica certificata)',
            civicPlaceholder: 'n°*',
            cityPlaceholder: 'Città*',
            provincePlaceholder: 'Provincia*',
            capPlaceholder: 'CAP*',
            namePlaceholder: 'Nome*',
            lastnamePlaceholder: 'Cognome*',
            emailPlaceholder: 'Email*',
            phonePlaceholder: 'Telefono*',
            imAssociate: 'Sono un\'associazione',
            associateTip: 'La mia attività rientra nei soggetti diversi dalle persone fisiche non titolari di partita iva (enti, associazioni, fondazioni, condomini, parrocchie, ecc…)',
            ivaPlaceholder: 'P.IVA',
            fiscalCode: 'Codice fiscale',
            fiscalCodeDescription: 'Codice fiscale (se diverso da partita PIVA)',
            fiscalCodeAssociation: 'Codice fiscale associazione*',
            electronicInvoice: 'Fatturazione elettronica*',
            suggestElectronicInvoice: 'Compila almeno uno dei due campi per poter ricevere la fattura elettronica',
        },
    },
    payments: {
        description: 'Qui di seguito il riepilogo di tutti gli acquisti effettuati',
        product: 'Prodotto',
        price: 'Prezzo',
        orderStatus: 'Stato dell\'ordine',
        ivaIncluded: 'IVA inclusa',
        iva: 'IVA',
        waiting: 'In attesa',
        end: 'Concludi',
        endOrder: 'Annulla ordine',
        working: 'In elaborazione',
        paid: 'Pagato',
        deleted: 'Cancellato',
        canceled: 'Annullato',
        recharge: 'Ricarica',
        plan: 'Piano',
        included: 'Incluso',
        confirmDeleteMEssage: ' Sei sicuro di voler annullare l\'ordine?',
        no: 'No',
        yes: 'Si',
    },
    settings: {
        tabs: {
            myData: 'I miei dati',
            security: 'Sicurezza',
            comunications: 'Comunicazioni',
            consents: 'Consensi',
        },
        settings: 'IMPOSTAZIONI',
        newsletter: 'Newsletter',
        alreadySubscriber: 'Sei attualmente iscritto alla newsletter di 4Dem.it',
        subscriptionStartAt: 'Data iscrizione',
        registeredEmail: 'Email registrata:',
        unsubscrube: 'Disiscrivimi',
        notYetSubscriber: 'Ma come! Davvero non sei ancora iscritto alla nostra newsletter?',
        notYetSubscriber2: 'Non perdere la possibilità di ricevere la tua formazione online gratuita insieme a promozioni dedicate e alle migliori pillole dal mondo digital!',
        notSubscriber: 'Non sei iscritto alla newsletter di 4Dem.it',
        unsubscribeAt: 'data disiscrizione:',
        mainEmail: 'Verrà registrato il tuo indirizzo email:',
        subscribe: 'Iscriviti subito',
        changePassword: 'Cambia Password',
        twoFactorAuth: 'Autenticazione a due fattori',
        abort: 'Annulla',
        confirm: 'Conferma',
        active: 'Attiva',
        copy: 'Copia',
        status: 'Stato:',
        deactive: 'Disattiva',
        regenerate: 'Rigenera',
        insertCode: 'Inserisci il codice di verifica per attivare',
        twoFaModalTitle: 'il codice per l\'autenticazione a due fattori',
        insertPassword: 'Inserisci la password del tuo account',
        password: 'Password',
        viewTwoFaTip: 'Visualizza la stringa per effettuare l\'importazione manuale',
        needHelp: 'Hai bisogno di aiuto?',
        save: 'Salva',
        oldPasswordPlaceholder: 'Password attuale',
        oldPasswordLabelWL: 'Aggiorna la password del tuo account',
        oldPasswordLabel: 'Aggiorna la password del tuo account 4Dem',
        newPasswordPlaceholder: 'Nuova password',
        accessManagerPermissionText: 'Autorizzo il mio manager ad accedere alla piattaforma',
        accessManagerPermissionLabel: 'Permessi di accesso',
        accessManagerPermissionTip: 'Ogni account creato sulla piattaforma viene associato automaticamente ad un account manager di riferimento per scopi di assistenza. Spunta questa checkox per permettere l\'accesso al tuo account in caso di necessità.',
        errorName: {
            oldPassword: 'Password attuale',
            email: 'Email',
            phone: 'Telefono',
            password: 'Password',
        },
        errorMessages: {
            emailNotValid: 'L\'email inserita non è valida',
            somethingWentWrong: 'Qualcosa è andato storto, prova più tardi',
            passwordInvalid: 'La password inserita non è corretta',
            passwordSavingError: 'Errore nel salvataggio della nuova password',
            twoFactorWrongCode: 'Il codice potrebbe essere scaduto o errato',
            authorizationUpdateAssociatedManagerError: 'Non puoi aggiornare l\'autorizzazione perché non hai un manager associato.',
        },
        successMessages: {
            copySaved: 'Copiato negli appunti',
            passwordSaved: 'Nuova password salvata',
            authorizationSaved: 'Autorizzazione salvata con successo!',
            twoFactorActivated: 'L\'autenticazione a due fattori è stata attivata con successo',
            removedFromNewsletter: 'Sei stato rimosso dalla newsletter di 4Dem',
            subscriptionNewsletter: 'Siamo felici che tu voglia ricevere la nostra newsletter!',
            subscriptionNewsletterMessage: ' Controlla subito la tua email, troverai il link per confermare la tua iscrizione!',
        },
    },
    allInOneSection: {
        footerPyram1: 'Prezzi {0} validi su tutte le {1} con contratto annuale e pagamento in un\'unica soluzione',
        footerPyram2: '* Promozioni valide per il primo anno e per le nuove attivazioni',
        vatExcluded: 'IVA esclusa',
        newActivation: 'nuove attivazioni',
        findOutFeatures: 'Scopri tutte le caratteristiche',
    },
    emailSection: {
        subscription: 'Abbonamento',
        rechargeable: 'Ricaricabile',
        allInOneSubtitle: 'Scegli un {0} se {1} e vuoi una soluzione completa e senza pensieri, perfettamente scalabile sulle tue esigenze.',
        rechargePlan: 'piano in abbonamento',
        sendEmail: 'invii email con frequenza',
    },
    prices: {
        tabs: {
            subscription: 'Abbonamento',
            rechargeablePrices: 'Ricaricabile email',
            creditsSms: 'Crediti SMS',
            creditsTransactional: 'Crediti Transazionali',
        },
        title: 'Prezzi',
        back: 'Indietro',
        subtitle: 'Scegli un piano in abbonamento se invii email con frequenza e vuoi una soluzione{0}completa e senza pensieri, perfettamente scalabile sulle tue esigenze.',
        top: 'Il più richiesto',
        custom: 'Su misura',
        monthly: 'al mese',
        contact: 'Contattaci',
        purchase: 'Acquista',
        contactUsModal: {
            heading: 'Vuoi cambiare il tuo piano?',
            textplaceholder: 'Richiedi informazioni',
            requestDefaultValue: 'Richiesta upgrade',
            description: 'Nessun problema! Contattaci telefonicamente allo {0} oppure compila il form per essere ricontattato.',
        },
        planCards: {
            small: {
                title: 'Small',
                subtitle: 'Il punto di partenza per iniziare a fare grandi cose.',
            },
            marketer: {
                title: 'Marketer',
                subtitle: 'Tutti gli strumenti dedicati ai Marketer, per le PMI che vogliono diventare grandi.',
            },
            business: {
                title: 'Business',
                subtitle: 'Per le aziende che investono in digital marketing in modo continuativo e strutturato.',
            },
            enterprise: {
                title: 'Enterprise',
                subtitle: 'Tutte le funzionalità attive e un servizio dedicato per le aziende che pensano in grande.',
            },
            enterpriseReseller: {
                title: 'Personalizzato',
                subtitle: 'Personalizza le funzionalità di 4Dem sia a livello di software che di infrastruttura di invio.',
                otherText: 'Ideale per rivenditori e esigenze superiori.',
            },
        },
        planList: {
            small: {
                title: 'Funzionalità Freemium più:',
                list: [
                    {
                        label: 'Nessun limite di invio giornaliero',
                    },
                    {
                        label: '2 liste',
                    },
                    {
                        label: '15 campi di profilazione',
                    },
                    {
                        label: 'Esportazione dati',
                    },
                    {
                        label: 'Invio SMS con statistiche',
                    },
                    {
                        label: 'Supporto via email illimitato',
                    },
                ],
            },
            marketer: {
                title: 'Funzionalità Small più:',
                list: [
                    {
                        label: '5 Liste',
                    },
                    {
                        label: '15 Campi di profilazione',
                    },
                    {
                        label: 'Segmentazione Avanzata',
                    },
                    {
                        label: 'Email Booster',
                    },
                    {
                        label: 'Tag Comportamentali',
                    },
                    {
                        label: 'Calendario Campagne',
                    },
                    {
                        label: 'Marketing Automation',
                    },
                    {
                        label: 'Email Retargeting',
                    },
                    {
                        label: 'Esportazione report in PDF',
                    },
                    {
                        label: 'Web Archivio Campagne',
                    },
                    {
                        label: 'Senza Logo 4Dem nelle Email',
                    },
                ],
            },
            business: {
                title: 'Funzionalità Marketer più:',
                list: [
                    {
                        label: '20 Liste',
                    },
                    {
                        label: '30 Campi di profilazione',
                    },
                    {
                        label: 'Invio Multilista',
                    },
                    {
                        label: 'Statistiche Avanzate',
                    },
                    {
                        label: 'Form e PopUp Avanzati',
                    },
                    {
                        label: 'Campagne A/B test',
                    },
                    {
                        label: 'Coupon Generator integrato',
                    },
                    {
                        label: 'Invio allegati da 56 KB',
                    },
                ],
            },
            enterprise: {
                title: 'Funzionalità Business più:',
                list: [
                    {
                        label: '100 Liste',
                    },
                    {
                        label: '50 Campi di profilazione',
                    },
                    {
                        label: 'Annualizzazione credito',
                    },
                    {
                        label: 'Reputation Center',
                    },
                    {
                        label: 'Chat di supporto (opzionale)',
                    },
                    {
                        label: '12 mesi di conservazione dati',
                    },
                    {
                        label: 'Opzione Whitelabel',
                        tip: 'Desideri una configurazione whitelabel per te e i tuoi clienti? IP, DOMINIO, SERVER e tanto ancora?',
                    },
                    {
                        label: 'Opzione Personalizza',
                        tip: 'Siamo in grado di configurare la migliore piattaforma personalizzata secondo le tue esigenze',
                    },
                ],
            },
        },
    },
    rechargeSection: {
        subtitle: 'Scegli una soluzione {0} per l\'{1} di {2}, senza vincoli contrattuali e con tutte le funzionalità Marketer attive.',
        rechargeable: 'Ricaricabile',
        occasionalSend: 'invio occasionale',
        email: 'email',
        subtitleLink: 'Scopri tutte le caratteristiche della Ricaricabile',
        discountReserved: 'Il listino è aggiornato con lo sconto a te riservato',
        creditsEmail: 'Crediti Email',
        price: 'Prezzo*',
        priceReserved: 'Prezzo a te riservato',
        buy: 'Acquista',
        contactUs: 'Contattaci',
        vatExcluded: '*IVA Esclusa',
        findOutFeatures: 'Scopri tutte le caratteristiche',
    },
    smsSection: {
        creditsSms: 'Crediti SMS',
        sms: 'SMS',
        subtitle1: 'Con 4Dem invii {0} o {1} in pochi click.',
        subtitle2: '{0} per la tua comunicazione',
        subtitle3: 'Una comodità unica, unita alla possibilità di inviare ad {0}.',
        campaignSms: 'Campagne SMS',
        transationalSms: 'SMS transazionali',
        statistics: 'Statistiche e funzioni avanzate',
        destinations: 'oltre 150 destinazioni in tutto il mondo',
        discountReserved: 'Il listino è aggiornato con lo sconto a te riservato',
        whereSend: 'Dove vuoi inviare?',
        whereSendTip: 'Acquista il pacchetto crediti che preferisci. Il prezzo del singolo SMS dipende dal paese di destinazione.',
        sendableSms: 'SMS inviabili',
        price: 'Prezzo*',
        priceReserved: 'Prezzo a te riservato',
        smsTip: 'Sono SMS estremamente personalizzabili. Testo del messaggio fino a 459 caratteri, personalizzazione mittente (numerico-alfanumerico), personalizzazione del contenuto del messaggio con le informazioni anagrafiche degli iscritti. L\'invio è immediato da parte dei provider, le statistiche includono la notifica di ricezione. La velocità di invio è prioritaria: entro 1 ora.',
        currency: '€ cad.',
        buy: 'Acquista',
        tableSubtitle: '*IVA esclusa. Il credito acquistato può essere utilizzato per inviare sia SMS. Ogni credito consente di inviare 1 messaggio di 160 caratteri. Oltre i 160 caratteri l\'invio sarà composto da 2 o più SMS concatenati e verrà scalato un numero di crediti superiore, proporzionale al numero di messaggi inviati. Ad esempio se il messaggio è lungo 200 caratteri, l\'invio sarà costituito da 2 SMS e quindi verranno scalati 2 crediti. La velocità di invio degli SMS è prioritaria entro 1 ora. Gli operatori telefonici in caso di traffico elevato potrebbero ritardare la spedizione senza preavviso.',
        personalizedQuote: 'Per esigenze specifiche richiedi un {0}.',
        personalizedQuoteLink: 'preventivo personalizzato',
        findOutFeatures: 'Scopri tutte le caratteristiche',
    },
    transactionalSection: {
        subtitle1: 'Acquista crediti per l\'invio di email transazionali con {0}.',
        subtitle2: 'Ogni credito equivale a un\'email transazionale.',
        senderPlus: 'SenderPlus',
        subtitleLink: 'Scopri di più sulle email transazionali',
        discountReserved: 'Il listino è aggiornato con lo sconto a te riservato',
        creditsSenderPlus: 'Crediti Email Transazionali',
        price: 'Prezzo*',
        priceReserved: 'Prezzo a te riservato',
        buy: 'Acquista',
        vatExcluded: '*IVA Esclusa',
        findOutFeatures: 'Scopri tutte le caratteristiche',
    },
}
export default {
    account,
}
