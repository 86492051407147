


















import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'

@Component({
    name: 'VsFullTopBarAndSidebarLayout',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({ default: false, type: Boolean, required: false }) overflowHidden!: boolean
    @Prop({ default: false, type: Boolean, required: false }) loading!: boolean
    @Prop({
        required: false,
        default: 'rgba(255, 255, 255, 0.8)',
    }) private overlayColor!: string

    get hasTopSlot () {
        return !!this.$slots.top
    }
}
