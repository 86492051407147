import { ECharts } from 'echarts'
import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'ResizeMixin',
})
export default class extends Vue {
    protected chart!: ECharts | null

    mounted () {
        this.initResizeEvent()
    }

    beforeDestroy () {
        this.destroyResizeEvent()
    }

    activated () {
        this.initResizeEvent()
    }

    deactivated () {
        this.destroyResizeEvent()
    }

    public chartResizeHandler () {
        if (this.chart) {
            this.chart.resize()
        }
    }

    protected initResizeEvent () {
        if (this.chartResizeHandler) {
            window.addEventListener('resize', this.chartResizeHandler)
        }
    }

    protected destroyResizeEvent () {
        if (this.chartResizeHandler) {
            window.removeEventListener('resize', this.chartResizeHandler)
        }
    }
}
