import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getEntityLabels = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/entity_labels`,
        {
            params,
            cancelToken,
        },
    )

export const getEntityLabelById = (entityLabelId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/entity_labels/${entityLabelId}`,
        {
            params,
            cancelToken,
        },
    )

export const createEntityLabel = (data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/entity_labels`,
        data,
        {
            params,
            cancelToken,
        },
    )

export const updateEntityLabelById = (entityLabelId: any, data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/entity_labels/${entityLabelId}`,
        {
            params,
            cancelToken,
        },
    )

export const deleteEntityLabelById = (entityLabelId: any, data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/entity_labels/${entityLabelId}`,
        {
            params,
            cancelToken,
        },
    )

export const assignEntityLabelToEntities = (entityLabelId: any, entityType: 'templates' | 'recipients' | 'campaigns', data: { ids: any[] }, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/entity_labels/${entityLabelId}/${entityType}`,
        data,
        {
            params,
            cancelToken,
        },
    )

export const removeEntityLabelToEntity = (entityLabelId: any, entityType: 'templates' | 'recipients' | 'campaigns', entityId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/entity_labels/${entityLabelId}/${entityType}/${entityId}`,
        {
            params,
            cancelToken,
        },
    )
