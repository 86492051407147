/* eslint-disable max-len */
const dashboard = {
    general: {
        welcome: 'Benvenuto!',
        setYoutAccount: 'Convalida il tuo account',
        createSender: 'Crea mittente',
        createCampaign: 'Crea campagna',
        importContacts: 'Importa contatti',
        contacts: 'Contatti',
        activityCampaigns: 'Attività campagne',
        automations: 'Automation',
        createAutomations: 'Crea automation',
        modulesPopUp: 'Form e Popup',
        createModule: 'Crea form',
        createPage: 'Crea landing',
        pageView: 'Visite totali',
        modules: 'Moduli',
        pages: 'Landing Page',
        news: 'News',
        lists: 'Liste:',
        sended: 'Inviate:',
        opened: 'Aperture:',
        clicked: 'Click:',
        completed: 'Completed',
        paused: 'In pausa',
        sending: 'In corso',
        ctor: 'CTOR:',
        News: 'News',
        Links: 'Link utili',
    },
    onBoard: {
        title: 'Onboarding',
        piechartInfo: '{value} su {value2}',
        step: {
            import_contacts: {
                title: 'Importa una lista di contatti verificati',
                text: 'Importa la tua prima lista di contatti all\'interno della console. Associa campi personalizzati e organizza il tuo database.',
            },
            create_sender: {
                title: 'Crea e conferma un mittente autorizzato per i tuoi invii',
                text: 'Crea un mittente e configuralo per la corretta spedizione ai tuoi destinatari.',
            },
            create_campaign: {
                title: 'Crea la tua prima Campagna Email',
                text: 'Pensa ai contenuti, alla grafica e affidati al nostro editor Drag&Drop per comporre la tua prima Campagna Email.',
            },
        },
    },
    notificationPassword: {
        cta: 'Modifica la password',
        title: 'Aggiorna ora la tua password',
        content: 'Per proteggere il tuo account ti suggeriamo di aggiornare la tua password costantemente. La tua attuale password, per i nostri sistemi, risulta essere più vecchia di 6 mesi. Vai nella tua area account per aggiornarla e aumentare la sicurezza del tuo account.',
    },
    subscription: {
        lists: 'Liste: ',
        totalSubscribers: 'Iscritti totali: ',
        on: 'su ',
        list: 'Lista | Liste',
        suppression: 'Contatti soppressi',
        openRateTotal: 'Open rate medio totale',
        clickRateTotal: 'Click rate medio totale',
        subscribersLimit: 'iscritto | iscritti',
        recipientLimit: 'lista | liste',
    },
    overviewCampaign: {
        title: 'Panoramica attività',
        latestCampaigns: 'Messaggi inviati',
        sendsOverview: 'Totale invii negli ultimi {value} mesi',
        monthlyMean: 'Media invii mensili',
    },
    campaignActivity: {
        title: 'Attività campagne',
        seeAllCampaign: 'Vedi tutte le campagne',
        goToCampaign: 'Vai alla campagna',
        latestCampaigns: 'Campagne ultimi 30 giorni: ',
        sendsOverview: 'Totale invii negli ultimi {value} mesi',
        monthlyMean: 'Media invii mensili',
        emptyState: {
            title: 'Crea la tua prima campagna email',
            subtitle: 'Crea una campagna con funzionalità standard come newsletter di aggiornamento, inviti e annunci.',
            subtitle2: 'Creala da zero o usa un modello già pronto.',
            createCampaign: 'Crea campagna',
        },
    },
    tips: {
        ort: 'Indica la percentuale di persone che hanno aperto, calcolata sul totale di messaggi inviati.',
        rmt: 'Indica la percentuale di persone che hanno cliccato, calcolata sul totale di messaggi inviati.',
    },
    automations: {
        active: 'Automation attive: ',
        totalEmalSend: 'Numero totale di email inviate',
        totalSmsSend: 'Numero totale di sms inviati',
        recovery: 'Recupera carrelli abbandonati',
        on: 'su ',
        automationLimit: 'automation | automations',
    },
    modules: {
        active: 'Form attivi: ',
        impression: 'Visualizzazioni totali',
        conversion: 'Conversioni totali',
        conversionRate: 'Tasso di conversione totale',
        on: 'su ',
        formsLimit: 'form | forms',
        tips: {
            impression: 'Indica il numero totale di volte in cui i form sono stati visualizzati',
            conversion: 'Indica il numero totale di utenti che hanno visualizzato un form e che completano l\'obiettivo desiderato',
            conversionRate: 'Indica la percentuale di utenti che hanno visualizzato un form  e che completano l\'obiettivo desiderato',
        },
    },
    pages: {
        title: 'Landing pubblicate',
        countRule: 'landing page | landing pages',
    },
    links: {
        link1: {
            icon: 'blog',
            title: 'Le novità di 4Dem e il nostro Blog',
            text: 'Tieniti aggiornato sulle ultime novità del digital direct marketing e di 4Dem.',
            cta: 'Scopri di più',
            url: 'https://www.4dem.it/blog/',
        },
        link2: {
            icon: 'training',
            title: 'I nostri Webinar Be4Dem',
            text: 'Guarda l\'archivio dei nostri webinar e partecipa a Be4Dem.',
            cta: 'Scopri di più',
            url: 'https://www.4dem.it/be4dem/',
        },
        link3: {
            icon: '4marketing',
            title: '4marketing.it',
            text: 'Abbiamo una business unit che ti aiuta a crescere digitalmente.',
            cta: 'Scopri di più',
            url: 'https://www.4marketing.it',
        },
        link4: {
            icon: 'development',
            title: '4Dem Developers',
            text: 'Sei uno sviluppatore? Scopri e testa le API di 4Dem.',
            cta: 'Scopri di più',
            url: 'https://api.4dem.it',
        },
        link5: {
            icon: 'book-opened',
            title: 'Guide e tutorials',
            text: 'Approfondimenti sulle funzionalità della tua piattaforma di email e sms marketing.',
            cta: 'Scopri di più',
            url: 'https://university.4dem.it/',
        },
        link6: {
            icon: 'integration',
            title: 'Plug-in e integrazioni',
            text: 'Wordpress, Prestashop, Magento... Scopri le nostre integrazioni.',
            cta: 'Scopri di più',
            url: 'https://developers.4dem.it/article/599-plugin-e-integrazioni',
        },
    },
    termsAndCondictions: {
        service: 'L\'uso e l\'accesso a 4Dem si intende per uso esclusivo del diretto sottoscrittore del piano ovvero è esclusivamente riservato agli utenti autorizzati. Non è possibile riprodurre in nessuna forma qualsiasi elemento di questo prodotto (schermata, testi, loghi, icone, tabelle, dati e quanto altro). Inoltre si avvisa che ogni accesso potrebbe essere monitorato dai nostri tecnici per fini di supporto tecnico e manutenzione della piattaforma. L\'uso di 4Dem è regolato da apposito contratto di servizio (anche per i piani gratuiti) a cui bisogna far riferimento e le condizioni di servizio sono riportate in ',
        thisPage: 'questa pagina',
        privacy: 'La privacy policy invece è stata inserita in ',
        thisSection: 'questa sezione',
    },
}
export default {
    dashboard,
}
