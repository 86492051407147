import { get } from 'lodash'

export enum userStatusEnum {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    PENDING_ACTIVATION = 'pending_activation',
    PENDING_ENABLED = 'pending_enabled',
}

export enum DisabledReasonEnum {
    'EXPIRED_ACCOUNT' = 'EXPIRED_ACCOUNT',
    'CUSTOMER_REQUEST' = 'CUSTOMER_REQUEST',
    'PAYMENT_ISSUES' = 'PAYMENT_ISSUES',
    'TERM_AND_CONDITION_VIOLATION' = 'TERM_AND_CONDITION_VIOLATION',
    'ADMINISTRATIVE_PROBLEMS' = 'ADMINISTRATIVE_PROBLEMS',
    'DISABLED_BY_MANAGER' = 'DISABLED_BY_MANAGER',
    'FROM_PARENT' = 'FROM_PARENT',
}

export const isPendingEnabled = (user: any) => {
    return get(user, 'status', '') === userStatusEnum.PENDING_ENABLED
}

export const isDisabled = (user: any) => {
    return get(user, 'status', '') === userStatusEnum.DISABLED
}

export const isPendingActivation = (user: any) => {
    return get(user, 'status', '') === userStatusEnum.PENDING_ACTIVATION
}

export const isEnabled = (user: any) => {
    return get(user, 'status', '') === userStatusEnum.ENABLED
}

export const isLegacyFreemium = (user: any) => {
    return get(user, 'configuration.rules.expiredPolicy', '') === 'last_login'
}
