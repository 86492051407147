import { serviceFilemanager } from '@/utils/request'

const basePath = '/services'

interface ISearchImagesParams {
    search: string
    page: number
}

export const searchImages = (params?: ISearchImagesParams) =>
    serviceFilemanager.get(`${basePath}/search/images`, {
        params,
    })
