


















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    name: 'MyAccordion',
})
export default class extends Vue {
    @Prop({ default: false, required: true, type: Boolean }) private show!: boolean

    private beforeEnter (el: any) {
        el.style.overflow = 'hidden'
        el.style.height = '0'
    }

    private enter (el: any) {
        el.style.height = el.scrollHeight + 'px'
    }

    private afterEnter (el: any) {
        el.style.height = 'auto'
        el.style.overflow = 'visible'
    }

    private beforeLeave (el: any) {
        el.style.overflow = 'hidden'
        el.style.height = el.scrollHeight + 'px'
    }

    private leave (el: any) {
        el.style.height = el.scrollHeight + 'px'
        el.style.height = '0'
    }
}
