import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'
import { IFooterData } from '../dto/footers.dto'
const basePath = '/contents/footers'

export const getFooters = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}`,
        {
            params,
            cancelToken,
        },
    )

export const getFooterById = (footerId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/${footerId}`,
        {
            cancelToken,
        },
    )

export const createFooter = (data: IFooterData, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}`,
        data,
        {
            cancelToken,
        },
    )

export const updateFooter = (footerId: number | string, data: IFooterData, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `${basePath}/${footerId}`,
        data,
        {
            cancelToken,
        },
    )

export const deleteFooterById = (footerId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `${basePath}/${footerId}`,
        {
            cancelToken,
        },
    )
