



















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsFileInput',
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) accept?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) clearable?: boolean

    @Prop({
        required: false,
        default: 'Seleziona un file',
    }) buttonText?: string

    @Prop({
        required: false,
        default: '',
    }) buttonIconName?: string

    @Prop({
        required: false,
        default: 'remove',
    }) clearIcon?: string

    @Prop({
        required: false,
        default: 'medium',
    }) size?: string

    @Prop({
        required: false,
        default: 'button',
    }) variant?: string

    @Prop({
        required: false,
        default: 'secondary',
    }) aspect?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) autoComplete?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) disabled?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) readonly?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) error?: boolean

    @Prop({
        required: false,
        default: '',
    }) caption?: string

    @Prop({
        required: false,
        default: '',
    }) iconName?: string

    @Prop({
        required: false,
        default: '',
    }) label?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) labelHidden?: boolean

    @Prop({
        required: false,
        default: '',
    }) maxLength?: string

    @Prop({
        required: false,
        default: '',
    }) minLength?: string

    @Prop({
        required: false,
        default: '',
    }) max?: string

    @Prop({
        required: false,
        default: '',
    }) min?: string

    @Prop({
        required: false,
        default: '',
    }) placeholder?: string

    @Prop({
        required: false,
        default: '',
    }) name?: string

    @Prop({
        required: false,
        default: '',
    }) identifier?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) required?: boolean

    @Prop({
        required: false,
        default: '',
    }) role?: string

    @Prop({
        required: false,
        default: '',
    }) value?: string | File

    @Prop({
        required: false,
        default: '',
    }) errorMessage?: string

    @Prop({
        required: false,
        default: 0,
    }) debounce?: number

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) clearAlwaysVisible?: boolean

    @Prop({
        required: false,
        default: '',
    }) tooltip?: string

    @Prop({
        required: false,
        default: 0,
    }) inputTabindex?: number

    $refs: any

    openSelectFile () {
        this.$emit('input', '')
        this.$refs.file.value = null
        this.$refs.file.click()
    }

    onChange () {
        const uploadedFile = this.$refs.file.files[0]
        if (!uploadedFile) {
            this.$emit('input', '')
            return
        }
        this.$emit('input', uploadedFile)
    }

    public getInputValue () {
        return this.$refs.file.files[0]
    }
}
