import VsLayouts from '@/layouts/Index.vue'

export enum settingsRoutesNames {
    settings = 'settings',
    settingsIndex = 'settingsIndex',
    generalSettings = 'generalSettings',
    notificationsSettings = 'notificationsSettings',
    notificationCampaign = 'notificationCampaign',
    developersSettings = 'developersSettings',
}

const settingsRouter = [
    {
        path: '/settings',
        name: settingsRoutesNames.settings,
        component: VsLayouts,
        redirect: {
            name: settingsRoutesNames.settingsIndex,
        },
        children: [
            {
                path: '',
                name: settingsRoutesNames.settingsIndex,
                redirect: {
                    name: settingsRoutesNames.generalSettings,
                },
                // meta: {
                //     showInBreadCrumbs: true,
                // },
                component: () => import(/* webpackChunkName: "settings-index" */ '../views/index.vue'),
                children: [
                    {
                        path: '',
                        name: settingsRoutesNames.generalSettings,
                        component: () => import(/* webpackChunkName: "general-settings" */ '../views/general-settings.vue'),
                    },
                    {
                        path: 'notifications',
                        name: settingsRoutesNames.notificationsSettings,
                        component: () => import(/* webpackChunkName: "notifications-settings" */ '../views/notifications-settings.vue'),
                    },
                    {
                        path: 'campaign',
                        name: settingsRoutesNames.notificationCampaign,
                        component: () => import(/* webpackChunkName: "notification-campaign" */ '../views/notifications-campaign.vue'),
                    },
                ],
            },
        ],
    },
]

export default settingsRouter
