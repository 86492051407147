
































































































































import { Component, Vue } from 'vue-property-decorator'
import VsDropdown from './../VsDropdown/Index.vue'
import VsButtonActionsContainer from '@/components/VsButtonActionsContainer/Index.vue'
import { createDirectory, getDirectories, getDirectoryById } from '@/api/filemanager/directories'
import { getNextPage } from '@/api/filemanager/utils'

@Component({
    name: 'VsDirectoryEditor',
    components: {
        VsDropdown,
        VsButtonActionsContainer,
    },
})

export default class extends Vue {
    private value = false
    private directories: any[] = []
    private newDirectory = false
    private newDirectoryName = ''

    private currentDirectory: any = {
        name: '',
        id: 0,
        directory_id: 0,
    }

    private checkedDirectory = null

    public async getDirectories (id: string) {
        this.directories = []
        try {
            let search = ''
            let searchFields = ''
            let whereNull = null
            if (id) {
                search += `${id}`
                searchFields += `directory_id:=`
            } else {
                whereNull = ''
                whereNull += 'directory_id'
            }

            const resp = await getDirectories({
                orderBy: 'created_at',
                sortedBy: 'desc',
                search,
                searchFields,
                whereNull,
                searchJoin: 'and',
            })
            this.directories = resp.data.data
            const nextDirectoryPageUrl = resp.data.next_page_url
            this.getNextDirectoryPageUrl(nextDirectoryPageUrl)
        } catch (e) {
            console.log(e)
            this.directories = []
        }
    }

    private async getNextDirectoryPageUrl (nextDirectoryPageUrl: string) {
        if (!nextDirectoryPageUrl) return
        try {
            const resp = await getNextPage(nextDirectoryPageUrl)
            nextDirectoryPageUrl = resp.data.next_page_url
            this.directories.push(...resp.data.data)
            this.getNextDirectoryPageUrl(nextDirectoryPageUrl)
        } catch (e) {
            console.log(e)
        }
    }

    public async getDirectoryById (id: string) {
        try {
            const resp = await getDirectoryById(id)
            this.currentDirectory = resp.data
        } catch (e) {
            console.log(e)
            this.currentDirectory = {
                name: '',
                id: 0,
                directory_id: 0,
            }
        }
    }

    private async changeDirectory (id: string) {
        if (id) {
            await this.getDirectoryById(id)
        } else {
            this.currentDirectory = {
                name: '',
                id: 0,
                directory_id: 0,
            }
        }
        await this.getDirectories(id)
    }

    private openNewDirectory () {
        this.newDirectoryName = ''
        this.newDirectory = true
    }

    private async createDirectory () {
        // const resp = await this.$refs.createDirectoryForm.validate()
        // if (!resp) return
        try {
            await createDirectory({
                name: this.newDirectoryName,
                directory_id: this.currentDirectory && this.currentDirectory.id ? this.currentDirectory.id : null,
            })
            this.$emit('directory-created', {
                name: this.newDirectoryName,
                directoryId: this.currentDirectory.id,
            })
            this.newDirectory = false
            this.getDirectories(this.currentDirectory.id)
        } catch (e) {

        }
    }

    public async show (directoryId: string) {
        this.checkedDirectory = null
        await this.getDirectoryById(directoryId)
        await this.getDirectories(directoryId)
        this.value = true
    }

    public close () {
        this.value = false
    }
}
