



















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsMediaLibraryImage from './../VsMediaLibraryImage/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'

export interface ILibraryItem {
    id: string
    user_id: string
    directory_id: string
    uuid: string
    name: string
    size: number
    mime: string
    public_url: string
    deleted_at: string | null
}

export enum libraryItemType {
    default = 'default',
    trash = 'trash',
    insert = 'insert',
}

@Component({
    name: 'VsImageCard',
    components: {
        VsDropdownButton,
        VsMediaLibraryImage,
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: false,
        default: () => [],
    }) private value!: any[]

    @Prop({
        required: false,
        default: '',
    }) private identifier?: string

    @Prop({
        required: false,
        default: '',
    }) private title?: string

    @Prop({
        required: false,
        default: '',
    }) private titleLink?: string

    @Prop({
        required: false,
        default: '',
    }) private subtitle?: string

    @Prop({
        required: false,
        default: '',
    }) private subtitleLink?: string

    @Prop({
        required: false,
        default: '',
    }) private imageUrl?: string

    @Prop({
        required: false,
        default: '',
    }) private imageMime?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) private loading?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) private disabled?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) private showActions?: boolean

    @Prop({
        required: false,
        default: true,
        type: Boolean,
    }) private showOverlay?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) private checkable?: boolean

    @Prop({
        required: false,
        default: '',
    }) private allowedTypes?: string

    @Prop({
        required: false,
        default: '',
    }) private currentSelectedFile?: string

    private hover = false
    private menuIsOpen = false
    private focused = false
    private imageSize = ''

    get selected () {
        return this.value.indexOf(this.identifier) > -1
    }

    get cardTitleClasses () {
        return {
            'vs-h300': true,
            'vs-text-grey-900': true,
            'vs-text-primary-800': this.selected,
            'vs-text-grey-600': this.disabled,
            'vs-truncate': true,
        }
    }

    private onMouseOver () {
        this.hover = true
    }

    private onMouseLeave () {
        if (this.menuIsOpen) return
        this.hover = false
    }

    private openMenu () {
        this.focused = true
        this.menuIsOpen = true
        this.$emit('menu-open')
    }

    private closeMenu () {
        this.menuIsOpen = false
        this.$emit('menu-close')
    }

    private openDetails (itemId: string) {
        this.$emit('show-details', itemId)
    }

    private selectFile (libraryItem: any) {
        this.$emit('select-file', libraryItem)
    }

    private setImageSize (imageSize: string) {
        this.imageSize = imageSize
    }

    private checkCard (e: any) {
        // private toggleCheckFile (e: boolean, libraryItemId: string) {
        // if (!this.libraryItem.id) return
        // const index = this.value.indexOf(this.libraryItem.id)
        // const data = [...this.value]
        // if (e) {
        //     if (index === -1) data.push(this.libraryItem.id)
        // } else {
        //     if (index !== -1) data.splice(index, 1)
        // }
        this.$emit('input', e)
    }
}
