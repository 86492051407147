import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { getLanguage } from '@/utils/cookies'
import { merge } from 'lodash'

import veevalidateItLocale from '@/lang/validations/it'
import customValidationsLangIt from './customValidations/it'
import accountModuleLangIt from '@/modules/account/lang/it'
import activationModuleLangIt from '@/modules/activation/lang/it'
import loginModuleLangIt from '@/modules/login/lang/it'
import shopModuleLangIt from '@/modules/shop/lang/it'
import signupModuleLangIt from '@/modules/signup/lang/it'
import mediaLibraryModuleLangIt from '@/modules/mediaLibrary/lang/it'
import dashboardModuleLangIt from '@/modules/dashboard/lang/it'
import campaignsModuleLangIt from '@/modules/campaigns/lang/it'
import listsModuleLangIt from '@/modules/lists/lang/it'
import landingpagesModuleLangIt from '@/modules/landingpages/lang/it'
import leadplusModuleLangIt from '@/modules/leadplus/lang/it'
import settingsModuleLangIt from '@/modules/settings/lang/it'
import developersModuleLangIt from '@/modules/developers/lang/it'
import sendersModuleLangIt from '@/modules/senders/lang/it'
import mockModuleLangIt from '@/modules/mock/lang/it'
import emailEditorModuleLangIt from '@/modules/emailEditor/lang/it'
import managerModuleLangIt from '@/modules/manager/lang/it'
import whatsappModuleLangIt from '@/modules/whatsapp/lang/it'
import automationsModuleLangIt from '@/modules/automations/lang/it'
import workflowsModuleLangIt from '@/modules/workflows/lang/it'
import smsModuleLangIt from '@/modules/sms/lang/it'

// User defined lang
import itLocale from './it'

Vue.use(VueI18n)

const messages: any = {
    it: {
        validations: veevalidateItLocale,
        ...itLocale,
        ...customValidationsLangIt,
        ...loginModuleLangIt,
        ...accountModuleLangIt,
        ...activationModuleLangIt,
        ...shopModuleLangIt,
        ...signupModuleLangIt,
        ...mediaLibraryModuleLangIt,
        ...dashboardModuleLangIt,
        ...campaignsModuleLangIt,
        ...listsModuleLangIt,
        ...landingpagesModuleLangIt,
        ...leadplusModuleLangIt,
        ...settingsModuleLangIt,
        ...developersModuleLangIt,
        ...sendersModuleLangIt,
        ...mockModuleLangIt,
        ...managerModuleLangIt,
        ...emailEditorModuleLangIt,
        ...whatsappModuleLangIt,
        ...automationsModuleLangIt,
        ...workflowsModuleLangIt,
        ...smsModuleLangIt,
    },
}

export const getLocale = () => {
    const cookieLanguage = getLanguage()
    if (cookieLanguage) {
        return cookieLanguage
    }

    const language = navigator.language.toLowerCase()
    const locales = Object.keys(messages)
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale
        }
    }

    // Default language is italian
    return 'it'
}

const i18n = new VueI18n({
    locale: 'it',
    messages,
})

export const setI18nLanguage = (lang: string) => {
    i18n.locale = lang
    return lang
}

// TODO array che verifica se l'import è già stato eseguito per la lingua selezionata
const loadedLanguages: string[] = ['it'] // our default language that is preloaded

// TODO metodo asincrono per caricare i faile di lingua
// (permette di caricare solamente la lingua corrente e in caso di switch a quel punto caricare l'altra)
export const loadLanguageAsync = async (lang: string) => {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    const languageMessages = {}

    const appLocale = await import(`@/lang/${lang}.ts`)
    merge(languageMessages, appLocale.default)
    // esempio lingua libreria element ui
    // const elementUiLocale = await import(`element-ui/lib/locale/lang/${lang}.js`)
    // merge(languageMessages, elementUiLocale.default)
    const veevalidateLocale = await import(`@/lang/validations/${lang}.ts`)
    merge(languageMessages, { validations: veevalidateLocale.default })
    const moduleLoginLocale = await import(`@/modules/login/lang/${lang}.ts`)
    merge(languageMessages, moduleLoginLocale.default)
    const moduleAccountLocale = await import(`@/modules/account/lang/${lang}.ts`)
    merge(languageMessages, moduleAccountLocale.default)
    const moduleMediaLibraryLocale = await import(`@/modules/mediaLibrary/lang/${lang}.ts`)
    merge(languageMessages, moduleMediaLibraryLocale.default)
    const moduleDashboardLocale = await import(`@/modules/dashboard/lang/${lang}.ts`)
    merge(languageMessages, moduleDashboardLocale.default)
    const campaignsModuleLocale = await import(`@/modules/campaigns/lang/${lang}.ts`)
    merge(languageMessages, campaignsModuleLocale.default)
    const listsModuleLocale = await import(`@/modules/lists/lang/${lang}.ts`)
    merge(languageMessages, listsModuleLocale.default)
    const landingpagesModuleLocale = await import(`@/modules/landingpages/lang/${lang}.ts`)
    merge(languageMessages, landingpagesModuleLocale.default)
    const settingsModuleLocale = await import(`@/modules/settings/lang/${lang}.ts`)
    merge(languageMessages, settingsModuleLocale.default)
    const developersModuleLocale = await import(`@/modules/developers/lang/${lang}.ts`)
    merge(languageMessages, developersModuleLocale.default)
    const mockModuleLocale = await import(`@/modules/mock/lang/${lang}.ts`)
    merge(languageMessages, mockModuleLocale.default)
    const managerModuleLocale = await import(`@/modules/manager/lang/${lang}.ts`)
    merge(languageMessages, managerModuleLocale.default)
    const emailEditorModuleLocale = await import(`@/modules/emailEditor/lang/${lang}.ts`)
    merge(languageMessages, emailEditorModuleLocale.default)
    const whatsappModuleLocale = await import(`@/modules/whatsapp/lang/${lang}.ts`)
    merge(languageMessages, whatsappModuleLocale.default)
    const automationsModuleLocale = await import(`@/modules/automations/lang/${lang}.ts`)
    merge(languageMessages, automationsModuleLocale.default)
    const workflowsModuleLocale = await import(`@/modules/workflows/lang/${lang}.ts`)
    merge(languageMessages, workflowsModuleLocale.default)
    const smsModuleLocale = await import(`@/modules/sms/lang/${lang}.ts`)
    merge(languageMessages, smsModuleLocale.default)

    messages[lang] = languageMessages

    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
}

// const importAndMergeModuleLanguage = async (path: string, languageMessages: object) => {
//     try {
//         const moduleLanguage = await import(path)
//         merge(languageMessages, moduleLanguage.default)
//     } catch (e) {
//         console.log(e)
//     }
//     return languageMessages
// }

// loadLanguageAsync(getLocale())

export default i18n
