












import { Component, Vue, Prop } from 'vue-property-decorator'
@Component({
    name: 'LoadingScreen',
})
export default class extends Vue {
    @Prop({ required: false, default: '' }) loadingScreenText?: string
}
