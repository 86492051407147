import { serviceUserApi } from '@/utils/request'

export const getChilds = (params?: any, cancelToken?: any) =>
    serviceUserApi.get(`/manager/users`, { cancelToken, params })

export const impersonateChild = (childId: any, cancelToken?: any) =>
    serviceUserApi.get(`/manager/users/${childId}/impersonate`, { cancelToken })

export const managerOverview = (cancelToken?: any) =>
    serviceUserApi.get(`/manager/users/overview`, { cancelToken })

export const getChildById = (childId: string, cancelToken?: any) =>
    serviceUserApi.get(`/manager/users/${childId}`, { cancelToken })

export const getOverviewChilds = (cancelToken?: any) =>
    serviceUserApi.get('/manager/users/overview', { cancelToken })

export const getAssignedEplusLicenses = (cancelToken?: any) =>
    serviceUserApi.get('/manager/users/assigned-eplus-licenses', { cancelToken })

export const createChild = (data: {
    firstname: string
    lastname: string
    email: string
    phone?: string | null
    company?: string
    password: string
    configurationSlug: 'expert' | 'starter' | string
    supportRuleActive?: boolean
    templateEmailRule?: any
}, cancelToken?: any) =>
    serviceUserApi.post(`/manager/users`, data, { cancelToken })

export const updateChild = (childId: string, data: {
    firstname: string
    lastname: string
    email: string
    phone?: string | null
    company?: string
}, cancelToken?: any) =>
    serviceUserApi.patch(`/manager/users/${childId}`, data, { cancelToken })

export const changeChildStatus = (childId: string, data: {
    status: string
    disabledReason: string
    sendNotification: boolean
}, cancelToken?: any) =>
    serviceUserApi.patch(`/manager/users/${childId}/change-status`, data, { cancelToken })

export const deleteChild = (childId: string, cancelToken?: any) =>
    serviceUserApi.delete(`/manager/users/${childId}`, { cancelToken })

export const switchChildToExpert = (childId: string, cancelToken?: any) =>
    serviceUserApi.patch(`/manager/users/${childId}/expert-switch`, {}, { cancelToken })

export const changeChildSupportRule = (childId: string, data: { enable: boolean }, cancelToken?: any) =>
    serviceUserApi.patch(`/manager/users/${childId}/change-support`, data, { cancelToken })

export const getChangePasswordLink = (childId: string, cancelToken?: any) =>
    serviceUserApi.get(
        `/manager/users/${childId}/change-password-link `,
        {
            cancelToken,
        },
    )

export const changeChildEplusLicense = (
    childId: string,
    data: {
        value: any
    },
    cancelToken?: any,
) =>
    serviceUserApi.patch(`/manager/users/${childId}/change-eplus-license`, data, { cancelToken })
