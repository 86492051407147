import { serviceUserApi } from '@/utils/request'

export enum ServiceAuthEnum {
    filemanager = 'filemanager',
    shop = 'shop',
    landingpage = 'landingpage',
    gamification = 'gamification',
    userapi = 'userapi',
}

export const getToken = (service: 'shop' | 'landingpage' | 'filemanager' | 'leadplus', cancelToken?: any) =>
    serviceUserApi.get(`/token/${service}`, { cancelToken })
