/* eslint-disable max-len */
const activation = {
    registration: {
        welcome: 'Benvenuto su 4Dem',
        subtitle: 'Ci piacerebbe poterti chiamare per nome.',
        next: 'Avanti',
        requiredFields: '*Campi obbligatori',
        readyToStart: 'Sei pronto a iniziare?',
        letsKnow: 'Conosciamoci meglio!',
        compileData: 'Compila i tuoi dati per attivare l\'account e iniziare la tua esperienza in piattaforma gratuitamente!',
        compileDataAndActivate: 'Compila i dati, attiva il tuo account e procedi all\'acquisto.',
        nEmailSendMonthly: 'Quante email invii mensilmente?*',
        firstStep: '0 - 1.000',
        secondStep: '1.001 - 2.500',
        thirdStep: '2.501 - 5.000',
        fourthStep: '5.001 - 25.000',
        fifthStep: '25.001 - 50.000',
        sixthStep: '50.001 - 100.000',
        seventhStep: 'oltre 100.000',
        useDigitalMarketingPlatform: 'Utilizzi già una piattaforma di Digital Marketing?*',
        noOne: 'No nessuna',
        mailChimp: 'MailChimp',
        sendinBlue: 'SendinBlue',
        mailUp: 'MailUp',
        other: 'Altro',
        hereWeAre: 'Ci siamo!',
        termsAndConditionsWarning: 'L\'email marketing è una cosa seria, per questo per utilizzare l\'account ti chiediamo di leggere, comprendere e sottoscrivere i {0} della piattaforma 4Dem. Ti chiediamo inoltre di prendere visione e accettare la nostra {1}.',
        contractLink: 'termini contrattuali di utilizzo',
        privacyLink: 'informativa privacy',
        clauses: 'Per una maggiore tutela e trasparenza nei tuoi confronti ti chiediamo inoltre di prendere visione e accettare le {0} del nostro contratto.',
        clausesLink: 'clausole vessatorie',
        subscribeMe: 'Iscrivimi alla newsletter di 4Dem.',
        receiveContent: 'Selezionando "Iscrivimi alla newsletter di 4Dem" dichiari di aver letto la nostra informativa privacy e accetti di ricevere i nostri contenuti formativi, informativi e promozionali.',
        okActive: 'Ok, attiva!',
        continueShop: 'Prosegui con il tuo acquisto',
        loginOtherAccount: 'Accedi con un altro account',
        signupReasonTitle: 'Penso di usare 4Dem principalmente per: *',
        signupReasonCommunicate: 'Comunicare con i miei contatti',
        signupReasonLeadGeneration: 'Raccogliere nuovi contatti',
        signupReasonBoth: 'Entrambe le scelte precedenti',
    },
}
export default {
    activation,
}
