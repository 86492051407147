import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'
import { UserModule } from '@/store/modules/user'
import { getShopUser } from '@/api/shop'
import { userStatusEnum } from '@/utils/users'

const loginRouter: RouteConfig[] = [
    {
        path: '/signin',
        name: 'login',
        redirect: { name: 'loginIndex' },
        component: VsLayouts,
        meta: {
            hidden: true,
            public: true,
            notLoggedinRoute: true,
            userPermissions: [],
            integrationPermissions: ['route-login'],
        },
        children: [
            {
                path: '',
                name: 'loginIndex',
                component: () => import(/* webpackChunkName: "login" */ '@/modules/login/views/login.vue'),
                meta: {
                    hidden: true,
                    public: true,
                    notLoggedinRoute: true,
                    integrationPermissions: ['route-login'],
                    layout: LayoutsEnum.vsAuth,
                },
            },
            {
                path: 'reset/:resetId',
                name: 'reset',
                component: () => import(/* webpackChunkName: "reset" */ '@/modules/login/views/check-reset-page.vue'),
                meta: {
                    hidden: true,
                    public: true,
                    integrationPermissions: ['route-login'],
                    layout: LayoutsEnum.vsAuth,
                },
            },
            {
                path: 'disabled',
                name: 'disabledUser',
                component: () => import(/* webpackChunkName: "disabled-user" */ '@/modules/login/views/disabled-user.vue'),
                beforeEnter: async (_to: any, _from: any, next: any) => {
                    if (!UserModule.shopUser) {
                        try {
                            const resp = await getShopUser()
                            UserModule.SET_SHOP_USER(resp.data)
                        } catch (e) {
                            UserModule.SET_SHOP_USER(null)
                        }
                    }
                    next()
                },
                meta: {
                    hidden: true,
                    public: false,
                    userStatusPermissions: [userStatusEnum.DISABLED],
                    integrationPermissions: ['route-login'],
                    layout: LayoutsEnum.vsAuth,
                },
            },
        ],
    },
    {
        path: '/auth-redirect',
        component: () => import(/* webpackChunkName: "auth-redirect" */ '@/modules/login/views/auth-redirect.vue'),
        meta: {
            hidden: true,
            public: true,
            integrationPermissions: ['route-login'],
        },
    },
]

export default loginRouter
