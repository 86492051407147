var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-sidebar-manager-container vs-scrollbar",class:{
        'open': _vm.sidebarOpen
    }},[_c('style',{tag:"component"}),_c('transition',{attrs:{"name":"fade"}},[(_vm.sidebarOpen)?_c('div',{staticClass:"overlay xl:vs-hidden",on:{"click":function($event){_vm.sidebarOpen = false}}}):_vm._e()]),_c('div',{staticClass:"vs-sidebar-manager vs-bg-grey-900 vs-flex vs-flex-col"},[_c('div',{staticClass:"vs-pt-8 vs-px-6 vs-flex-none vs-relative"},[_c('VsButton',{staticClass:"vs-absolute vs-top-0 vs-right-0 vs-text-white xl:vs-hidden",attrs:{"variant":"icon","aspect":"ghost","size":"large","iconName":"close"},on:{"click":function($event){_vm.sidebarOpen = false}}}),_c('div',{staticClass:"vs-logo-container vs-h-8"},[(_vm.logo || !_vm.is4Dem)?_c('router-link',{attrs:{"to":{
                        name: 'dashboardManager'
                    }}},[_c('div',{staticClass:"vs-w-full vs-h-full",style:(("background: url(" + _vm.logo + ") left center / contain no-repeat;"))})]):_c('VsBrand',{attrs:{"aspect":"inverse","to":{
                        name: 'dashboardManager'
                    }}})],1),_c('div',{staticClass:"vs-h200 vs-text-white vs-uppercase vs-italic vs-mb-12 vs-my-2"},[_vm._v(" manager ")])],1),_c('div',{staticClass:"vs-sidebar-manager-content vs-flex-auto vs-pl-6 vs-pr-2"},[_c('VsNavigationGroup',{staticClass:"vs-mb-2",attrs:{"prependIconName":"users","aspect":"inverse","label":"Account","active":_vm.audience,"appendIconName":"arrow-down"},on:{"click":function($event){_vm.audience = !_vm.audience}}},[_c('VsActionItem',{attrs:{"label":"Elenco account","aspect":"inverse","showTick":"","to":{
                        name: 'managerIndex'
                    },"active":_vm.$route.name === 'managerIndex'},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1),(_vm.hasManagerTemplateEditor)?_c('VsNavigationGroup',{staticClass:"vs-mb-2",attrs:{"prependIconName":"paint","aspect":"inverse","label":_vm.$t('sidebar.sections.creativity'),"active":_vm.creativity,"appendIconName":"arrow-down"},on:{"click":function($event){_vm.creativity = !_vm.creativity}}},[_c('VsActionItem',{attrs:{"label":_vm.$t('sidebar.subsections.models'),"aspect":"inverse","to":{
                        name: 'managerEmailEditorIndex'
                    },"showTick":"","active":_vm.currentPage === 'managerEmailEditorIndex'},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1):_vm._e(),_c('VsNavigationGroup',{staticClass:"vs-mb-2",attrs:{"prependIconName":"gear","aspect":"inverse","label":"Impostazioni","active":_vm.options,"appendIconName":"arrow-down"},on:{"click":function($event){_vm.options = !_vm.options}}},[_c('VsActionItem',{attrs:{"label":"Notifiche","aspect":"inverse","showTick":"","to":{
                        name: 'managerNotifications'
                    },"active":_vm.currentPage === 'managerNotifications'},nativeOn:{"click":function($event){$event.stopPropagation();}}})],1),(_vm.hasManagerDocumentations)?_c('VsNavigationGroup',{staticClass:"vs-mb-2",attrs:{"prependIconName":"book-closed","aspect":"inverse","label":"Documentazione","url":_vm.universityUrl + '/article/783-guide-whitelabel',"target":"_blank"}}):_vm._e()],1),_c('div',{staticClass:"vs-pb-8 vs-pt-4 vs-px-6 vs-flex-none vs-overflow-hidden"},[_c('VsPopover',{staticStyle:{"display":"block!important"},attrs:{"placement":"top"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('VsNavigationGroup',{attrs:{"prependIconName":"user","aspect":"inverse","truncate":"","label":_vm.username,"appendIconName":"arrow-down"}})]},proxy:true},{key:"default",fn:function(ref){
                    var closePopover = ref.closePopover;
return [_c('div',{staticStyle:{"min-width":"196px"},on:{"click":closePopover}},[_c('VsActionItem',{attrs:{"label":"Dashboard","to":{
                                name: 'dashboardManager',
                            },"showTick":"","active":_vm.currentPage === 'dashboardManager'}}),_c('VsActionItem',{attrs:{"label":"Area utente","prependIconName":"4marketing","to":{
                                name: 'dashboard',
                            },"showTick":"","active":_vm.currentPage === 'dashboard'}}),_c('VsSeparator',{staticClass:"vs-my-2"}),_c('VsActionItem',{attrs:{"label":_vm.$t('sidebar.avatarLinks.logout'),"aspect":"alert","showTick":"","url":'/app/user/logout'}})],1)]}}])}),_c('div',{staticClass:"vs-text-center"},[(_vm.configurationName)?_c('div',{staticClass:"vs-body-small vs-mt-2 vs-text-white"},[_vm._v(" "+_vm._s(_vm.configurationName)+" ")]):_vm._e(),(_vm.hasManagerShop)?_c('VsButton',_vm._b({staticClass:"vs-mt-2",attrs:{"iconName":"shopping-cart","size":"small","aspect":"inverse"}},'VsButton',_vm.formatLinkParams('/shop/prices'),false),[_vm._v(" Shop ")]):_vm._e()],1)],1)]),(!_vm.externalLibrary)?_c('VsMediaLibrary',{ref:"mediaLibrary"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }