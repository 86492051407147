var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.senderData.id ? 'Modifica mittente autorizzato' : _vm.$t('createSender.header'))+" ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":function($event){_vm.show = false}}},[_vm._v(" "+_vm._s(_vm.$t('general.close'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.createSender}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"createSenderForm",attrs:{"tag":"form"}},[_c('div',{staticClass:"vs-mb-6"},[_c('div',{staticClass:"vs-h200 vs-mb-2"},[_vm._v(_vm._s(_vm.$t('createSender.senderData')))]),_c('div',{staticClass:"vs-body-small vs-mb-4"},[_vm._v(_vm._s(_vm.$t('createSender.senderDataSubtitle')))]),_c('div',{staticClass:"vs-gap-4 vs-grid vs-grid-cols-1 md:vs-grid-cols-2"},[_c('ValidationProvider',{attrs:{"vid":"email","name":_vm.$t('createSender.emailAddress'),"rules":"required|email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","name":_vm.$t('createSender.emailAddress'),"label":_vm.$t('createSender.emailAddress'),"error":errors.length > 0,"errorMessage":errors[0],"variant":"email"},model:{value:(_vm.senderData.email),callback:function ($$v) {_vm.$set(_vm.senderData, "email", $$v)},expression:"senderData.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.nominative'),"rules":"required","slim":"","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","name":_vm.$t('createSender.nominative'),"label":_vm.$t('createSender.nominative'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.name),callback:function ($$v) {_vm.$set(_vm.senderData, "name", $$v)},expression:"senderData.name"}})]}}])})],1)]),_c('div',[_c('div',{staticClass:"vs-h200 vs-mb-2"},[_vm._v(_vm._s(_vm.$t('createSender.csaData')))]),_c('div',{staticClass:"vs-body-small vs-mb-4"},[_vm._v(_vm._s(_vm.$t('createSender.csaDataSubtitle')))]),_c('div',[_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.businessName'),"rules":"required","slim":"","vid":"csa_data.nominative"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","name":_vm.$t('createSender.businessName'),"label":_vm.$t('createSender.businessName'),"tooltip":_vm.$t('createSender.tips.businessName'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.nominative),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "nominative", $$v)},expression:"senderData.csa_data.nominative"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.fiscalCode'),"rules":"required|max:20","slim":"","vid":"csa_data.ivaFCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","name":_vm.$t('createSender.fiscalCode'),"label":_vm.$t('createSender.fiscalCode'),"tooltip":_vm.$t('createSender.tips.fiscalCode'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.ivaFCode),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "ivaFCode", $$v)},expression:"senderData.csa_data.ivaFCode"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.address'),"rules":"required","slim":"","vid":"csa_data.address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","name":_vm.$t('createSender.address'),"label":_vm.$t('createSender.address'),"tooltip":_vm.$t('createSender.tips.address'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.address),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "address", $$v)},expression:"senderData.csa_data.address"}})]}}])}),_c('div',{staticClass:"vs-gap-4 vs-flex-wrap vs-mb-4 vs-grid md:vs-grid-cols-4 vs-grid-cols-1"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.city'),"rules":"required","slim":"","vid":"csa_data.city"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","name":_vm.$t('createSender.city'),"label":_vm.$t('createSender.city'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.city),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "city", $$v)},expression:"senderData.csa_data.city"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.province'),"rules":"required|min:2","slim":"","vid":"csa_data.province"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","name":_vm.$t('createSender.province'),"label":_vm.$t('createSender.province'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.province),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "province", $$v)},expression:"senderData.csa_data.province"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.country'),"rules":"required","slim":"","vid":"csa_data.country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","name":_vm.$t('createSender.country'),"label":_vm.$t('createSender.country'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.country),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "country", $$v)},expression:"senderData.csa_data.country"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.cap'),"rules":"required|max:10","slim":"","vid":"csa_data.cap"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{attrs:{"size":"small","name":_vm.$t('createSender.cap'),"label":_vm.$t('createSender.cap'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.cap),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "cap", $$v)},expression:"senderData.csa_data.cap"}})]}}])})],1),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.mobile'),"rules":"required|libphoneNumber","slim":"","vid":"csa_data.telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsPhoneInput',{staticClass:"vs-mb-4",attrs:{"size":"small","name":_vm.$t('createSender.mobile'),"label":_vm.$t('createSender.mobile'),"tooltip":_vm.$t('createSender.tips.mobile'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.telephone),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "telephone", $$v)},expression:"senderData.csa_data.telephone"}})]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('createSender.contactAddress'),"rules":"required|email","slim":"","vid":"csa_data.email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"size":"small","variant":"email","name":_vm.$t('createSender.contactAddress'),"label":_vm.$t('createSender.contactAddress'),"tooltip":_vm.$t('createSender.tips.email'),"error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.senderData.csa_data.email),callback:function ($$v) {_vm.$set(_vm.senderData.csa_data, "email", $$v)},expression:"senderData.csa_data.email"}})]}}])}),_c('VsCheckbox',{staticClass:"vs-mb-4 vs-mt-8",attrs:{"labelHidden":"","text":"imposta il mittente come predefinito"},model:{value:(_vm.senderData.default),callback:function ($$v) {_vm.$set(_vm.senderData, "default", $$v)},expression:"senderData.default"}})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }