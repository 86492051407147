import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'
import {
    ContactStatusEnum,
} from '@/api/consoleApi/types'

export interface DeleteContactsBulkDto {
    subscribers_id: string
}
export interface SubscribeContactDto {
    email_address?: string
    mobile?: string
    subscription?: {
      ip: string
      status?: ContactStatusEnum
      date?: string
    }
    triggers?: {
      automation?: boolean
      behaviors?: boolean
      send_confirmation_email?: boolean
    }
    update_if_duplicate: boolean
    custom_fields?: {
      id: number | string
      value: any
    }[]
    tags?: number[]
}

export const getList = (listId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}`,
        {
            cancelToken,
        },
    )

export const getLists = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients`,
        {
            params,
            cancelToken,
        },
    )

export const createList = (data: {
    name: string
    opt_in: {
        mode: 'single' | 'double'
    }
    opt_out: {
        add_to_global_suppression: boolean
        mode: string
    }
    custom_fields: any[]
}, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/`,
        data,
        {
            cancelToken,
        },
    )

export const updateList = (listId: string | number, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/recipients/${listId}`,
        data,
        {
            cancelToken,
        },
    )

export const deleteList = (listId: string | number, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/recipients/${listId}`,
        {
            cancelToken,
        },
    )

export const deleteListBulk = (listIds: any[], cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/recipients/bulk`,
        {
            data: {
                ids: listIds,
            },
            cancelToken,
        },
    )

export const getListSegments = (listId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/segments`,
        {
            params,
            cancelToken,
        },
    )

export const getListSegmentById = (listId: number | string, segmentId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/segments/${segmentId}`,
        {
            params,
            cancelToken,
        },
    )

export const getListCustomFields = (listId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/custom_fields`,
        {
            cancelToken,
            params,
        },
    )

export const getListWebServices = (listId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/web_services`,
        {
            cancelToken,
            params,
        },
    )

export const duplicateList = (listId: number | string, data?: {name: string}, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/duplicate`,
        data,
        {
            cancelToken,
        },
    )

export const subscribeContact = (listId: number | string, data: SubscribeContactDto, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/subscribe`,
        data,
        {
            cancelToken,
        },
    )

export const getListContacts = (listId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/contacts`,
        {
            cancelToken,
            params,
        },
    )

export const getListContactById = (listId: number | string, contactId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/contacts/${contactId}`,
        {
            cancelToken,
            params,
        },
    )

export const sendOptin = (listId: number | string, contactId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/contacts/${contactId}/send-optin`,
        {},
        {
            cancelToken,
            params,
        },
    )

export const updateListContactById = (listId: number | string, contactId: number | string, data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/recipients/${listId}/contacts/${contactId}/update`,
        data,
        {
            cancelToken,
            params,
        },
    )

// non esiste ancora per questo scopo
export const unsubscribeListContactById = (listId: number | string, contactId: number | string, motivation?: string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/unsubscribe`,
        {
            subscriber_id: contactId,
            ip: '0.0.0.0',
            motivation: motivation || '0',
        },
        {
            cancelToken,
            params,
        },
    )

export const deleteListContactById = (listId: number | string, contactId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/recipients/${listId}/contacts/${contactId}/delete`,
        {
            cancelToken,
            params,
        },
    )

export const recoveryHardBounce = (listId: number | string, contactId: number | string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/contacts/${contactId}/recovery-hard-bounce`,
        {},
        {
            cancelToken,
            params,
        },
    )

export const deleteContactBulk = (listId: number | string, data: DeleteContactsBulkDto, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `recipients/${listId}/contacts/bulk/delete`,
        data,
        {
            cancelToken,
        },
    )

export const getSynchronizations = (listId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/synchronizations`,
        {
            cancelToken,
        },
    )

export const createSynchronization = (listId: any, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/synchronizations`,
        data,
        {
            cancelToken,
        },
    )

export const getSynchronization = (listId: any, id: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/synchronizations/${id}`,
        {
            cancelToken,
        },
    )

export const updateSynchronization = (listId: any, id: any, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/recipients/${listId}/synchronizations/${id}`,
        data,
        {
            cancelToken,
        },
    )

export const updateSynchronizationActive = (listId: any, id: any, data: { active: boolean }, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/recipients/${listId}/synchronizations/${id}/active`,
        data,
        {
            cancelToken,
        },
    )

export const deleteSynchronization = (listId: any, id: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/recipients/${listId}/synchronizations/${id}`,
        {
            cancelToken,
        },
    )

export const getSharedLists = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/shared`,
        {
            params,
            cancelToken,
        },
    )

export const getTags = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/tags`,
        {
            params,
            cancelToken,
        },
    )

export const createTag = (listId: any, data: { name: string }, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/tags`,
        data,
        {
            cancelToken,
        },
    )

export const updateTag = (listId: any, tagId: any, data: { name: string }, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/recipients/${listId}/tags/${tagId}`,
        data,
        {
            cancelToken,
        },
    )

export const getTagById = (listId: any, tagId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/tags/${tagId}`,
        {
            cancelToken,
        },
    )

export const deleteTagById = (listId: any, tagId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/recipients/${listId}/tags/${tagId}`,
        {
            cancelToken,
        },
    )

export const addTagToContact = (listId: any, tagId: any, contactId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/tags/${tagId}/contacts/${contactId}`,
        {},
        {
            cancelToken,
        },
    )

export const removeTagToContact = (listId: any, tagId: any, contactId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/recipients/${listId}/tags/${tagId}/contacts/${contactId}`,
        {
            cancelToken,
        },
    )

export const getContactInterests = (listId: any, contactId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/recipients/${listId}/contacts/${contactId}/statistics/interest`,
        {
            cancelToken,
        },
    )

export const sendCampaignToContact = (listId: any, contactId: any, campaignId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/contacts/${contactId}/send-campaign/${campaignId}`,
        {},
        {
            cancelToken,
        },
    )

export interface ChangeStatusDto {
    status: ContactStatusEnum.subscribed | ContactStatusEnum.optIn | ContactStatusEnum.unsubscribed
    triggers?: {
      automation?: boolean
      behaviors?: boolean
      send_confirmation_email?: boolean
    }
    motivation?: 1 | 2 | 3 | 4 | 5
    ip: string
    timestamp?: number
}

export const changeContactStatus = (listId: any, contactId: any, data: ChangeStatusDto, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/recipients/${listId}/contacts/${contactId}/change-status`,
        data,
        {
            cancelToken,
        },
    )

export const sendWhastappMessage = (listId: any, contactId: any, data: {
    template_id: any
}, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `recipients/${listId}/contacts/${contactId}/send-whatsapp-message`,
        data,
        {
            cancelToken,
        },
    )

export const getContactActivities = (listId: any, contactId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `recipients/${listId}/contacts/${contactId}/timeline`,
        { cancelToken },
    )
