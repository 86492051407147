

































































































import { getListCustomFields, getLists } from '@/api/consoleApi/recipients'
import { createForm } from '@/api/leadplus/forms'
import { Component, Vue } from 'vue-property-decorator'
import {
    createLeadplusFields,
    getDefaultPages,
    getDefaultTPages,
} from '@/utils/leadplus'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'
import { LandingpageOptionsModule } from '@/store/modules/landingpageoptions'

@Component({
    name: 'CreateFormModal',
})
export default class extends Vue {
    private name = ''
    private url = ''
    private listId = ''
    private list: any = null
    private customFields: any[] = []
    private lists = []
    private open = false
    public loading = false
    $refs!: any

    async save () {
        const valid = await this.$refs.formForm.validate()
        if (!valid) return
        this.createForm()
    }

    parseHostName (url: string) {
        try {
            const newUrl = new URL(url)
            this.url = newUrl?.hostname || url
        } catch (e) {
            this.url = url
        }
    }

    async createForm () {
        this.loading = true

        try {
            const resp = await createForm({
                name: this.name,
                foreign_recipient_id: this.listId + '',
                domain_urls: [window.location.origin, LandingpageOptionsModule.renderUrl],
                urlRulesOverride: this.url ? [this.url] : [],
                pages: getDefaultPages(createLeadplusFields(this.customFields)),
                thanks_pages: getDefaultTPages(),
            })

            this.$emit('form-created', resp.data)
            this.closeModal()
        } catch (e) {
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 403 && e.response.data.message === 'FormLimitReached') {
                    message = 'Hai raggiunto il limite di form creabili'
                }
            }
            this.$root.$vsToast({
                heading: 'Errore durante la creazione del form',
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }

    async getLists (search: string) {
        try {
            const response = await getLists(this.buildParams(search))
            this.lists = response.data.data
        } catch (e) {

        }
    }

    private buildParams (search: string) {
        const params: any = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            page: 1,
            limit: 100,
            searchJoin: 'and',
            search: this.buildSearch(search),
            searchFields: this.buildSearchFields(search),
        }

        return params
    }

    private buildSearch (search: string) {
        const arr = [
            search.trim() !== '' ? `name:${search}` : '',
        ].filter((el) => el !== '')

        return arr.join(';')
    }

    private buildSearchFields (search: string) {
        const searchFields = [
            search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    public openModal () {
        this.loading = false
        this.name = ''
        this.url = ''
        this.listId = ''
        this.list = null
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    async selectList (item: string) {
        const list: any = this.lists.find((el: any) => el.id === item)
        if (!list) {
            this.listId = ''
            this.list = null
            this.customFields = []
        } else {
            this.listId = list.id
            this.list = list
            await this.getCustomFields()
        }
    }

    formatBadgeLabel (item: any) {
        return this.list.name || item
    }

    private async getCustomFields () {
        this.loading = true
        const cf: any[] = []
        await this.loopCall(1, cf, getListCustomFields)
        this.customFields = cf
        this.loading = false
        // this.loadForm()
    }

    async loopCall (page: number, values: any[], caller: any) {
        const resp = await caller(this.list.id, {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }
}
