




import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
@Component({
    name: 'App',
})
export default class extends Vue {
    mounted () {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize)
        })
    }

    beforeDestroy () {
        window.removeEventListener('resize', this.onResize)
    }

    onResize () {
        AppModule.SET_WINDOW_WIDTH(window.innerWidth)
    }
}
