






















































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getSendersEmail } from '@/api/consoleApi/senders/emails'
import { updateEmail } from '@/api/consoleApi/contents/emails'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import VsCreateSender from '@/components/VsCreateSender/index.vue'
import VsConfirmSender from '@/components/VsConfirmSender/index.vue'
import { textContainEmoji } from '@/utils/validate'
import { IMessageEmail } from '@/api/consoleApi/dto/campaigns.dto'
import { AppModule } from '@/store/modules/app'
import VsDkimModal from '@/modules/senders/components/VsDkimModal/Index.vue'

@Component({
    name: 'VsEmailSenderCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsInlineErrorsCheck,
        VsCreateSender,
        VsHintCard,
        VsDkimModal,
        VsConfirmSender,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) message!: IMessageEmail
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean

    private senders: any[] = []
    private sendersLength = 0
    private selectedSender: any = null

    private tempSelectedSender: any = null
    private tempSenderEmail = ''
    private tempSenderName = ''
    private tempReplyToName = ''
    private tempReplyToEmail = ''
    private showReplyTo = false

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get senderEnabledStatus () {
        if (!this.tempSelectedSender) return 'default'
        return this.tempSelectedSender.status === 'Enabled' ? 'success' : 'error'
    }

    get senderDKIMStatus () {
        if (!this.tempSelectedSender) return 'default'
        return this.tempSelectedSender.dkim?.data.enabled ? 'success' : 'default'
    }

    get emojiStatus () {
        if (!this.tempSenderName && !this.tempReplyToName && !this.tempSelectedSender) return 'default'
        return textContainEmoji(!this.tempSenderName && this.tempSelectedSender ? this.tempSelectedSender.name : this.tempSenderName) || textContainEmoji(this.tempReplyToName) ? 'warning' : 'success'
    }

    get totalSenderStatus () {
        if (
            this.senderEnabledStatus === 'error'
        ) return 'alert'
        if (this.emojiStatus === 'warning') return 'warning'
        return 'success'
    }

    $refs: any

    openTab () {
        this.$emit('open-tab', 'sender')
    }

    async beforeMount () {
        await this.getSenders('')
        this.sendersLength = this.senders.length
        await this.getCurrentSender()
    }

    get sender () {
        return this.message.from_email
    }

    get senderCardStatus () {
        if (!this.message.from_email) return 'default'
        return this.selectedSender && this.selectedSender.status === 'Enabled' ? 'success' : 'error'
    }

    private async getCurrentSender (senderEmail?: string) {
        const sender = senderEmail || this.message.from_email
        if (!sender) return
        try {
            const resp = await getSendersEmail({
                limit: 1,
                search: `email:${sender}`,
                searchFields: 'email:like',
            })
            if (sender) this.tempSelectedSender = resp.data.data[0]
            this.selectedSender = resp.data.data[0]
        } catch (e) {
            console.log(e)
        }
    }

    private async getSenders (search: string) {
        try {
            const resp = await getSendersEmail({
                limit: 20,
                search: `email:${search}`,
                searchFields: 'email:like',
            })
            this.senders = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    private setSender (senderEmail: string) {
        this.tempSelectedSender = this.senders.find((el: any) => el.email === senderEmail)
    }

    private async senderCreated (sender: any) {
        await this.getSenders('')
        this.tempSenderEmail = sender.email
        this.tempSelectedSender = sender
        this.setSender(sender.email)
        this.sendersLength = this.senders.length
    }

    private openCreateSenderModal (sender?: any) {
        this.$refs.createSender.openModal(sender)
    }

    private openTabSender () {
        this.tempSelectedSender = this.selectedSender ? JSON.parse(JSON.stringify(this.selectedSender)) : null
        this.tempSenderEmail = JSON.parse(JSON.stringify(this.message.from_email))
        this.tempSenderName = this.tempSelectedSender && this.tempSelectedSender.name !== this.message.from_name ? JSON.parse(JSON.stringify(this.message.from_name)) : ''
        this.tempReplyToName = JSON.parse(JSON.stringify(this.message.reply_to_name))
        this.tempReplyToEmail = JSON.parse(JSON.stringify(this.message.reply_to_email))
        this.showReplyTo = !!(this.tempReplyToName || this.tempReplyToEmail)
        this.openTab()
    }

    private async saveSender () {
        try {
            if (!this.tempSelectedSender) throw new Error('SenderNotFound')
            const isValid = await this.$refs.senderForm.validate()
            if (!isValid) {
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: this.$t('campaigns.editCampaign.sections.sender.errors.saveError'),
                    aspect: VsToastAspectEnum.alert,
                })
                return
            }
            await updateEmail(
                this.message.id,
                {
                    sender: {
                        id: this.tempSelectedSender.id,
                        override_from_name: this.tempSenderName || this.tempSelectedSender.name,
                        override_reply_to_email: this.showReplyTo ? this.tempReplyToEmail : '',
                        override_reply_to_name: this.showReplyTo ? this.tempReplyToName : '',
                    },
                },
            )
            this.selectedSender = this.tempSelectedSender
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.sender.saveSuccess'),
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('sender-saved')
            this.$emit('open-tab', '')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.sender.errors.saveError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async getSendersAndVerifyActive () {
        await this.getSenders('')
        if (this.tempSelectedSender.status !== 'Enabled') {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.sender.errors.notVerify'),
                message: this.$t('campaigns.editCampaign.sections.sender.errors.controllInbox'),
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }
}
