export enum SenderSmsStatusEnum {
    Enabled = 'Enabled',
    Disabled = 'Disabled',
    Approval = 'Approval',
}

export interface ISenderData {
    Address: string[]
    Cap: string[]
    Cf: string[]
    City: string[]
    Company: string[]
    Country: string[]
    Email: string[]
    EmailPec: string[]
    Fax: string
    Phone: string[]
    Province: string[]
    Vat: string[]
}

export interface ISenderSmsGetAll {
    id: number
    alias: string
    confirmed: boolean
    status: string
    status_description: string
    user_id: number
    data: ISenderData
}
