






import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'

export enum VsContainerVariantEnum {
    'default' = 'default',
    'full-width' = 'full-width',
    'narrow' = 'narrow',
}

export enum VsContainerMarginEnum {
    'auto' = 'auto',
    'none' = 'none',
}

@Component({
    name: 'VsContainer',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: false,
        default: 'rgba(255, 255, 255, 0.8)',
    }) private overlayColor!: string

    @Prop({
        required: false,
        default: VsContainerMarginEnum.auto,
    }) margin?: VsContainerMarginEnum

    @Prop({
        required: false,
        default: VsContainerVariantEnum['default'],
    }) variant?: VsContainerVariantEnum

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) noPadding?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) noYPadding?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) noXPadding?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) fullHeight?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) loading?: boolean

    get dynamicContainerClasses () {
        return {
            'vs-mx-auto': VsContainerMarginEnum.auto,
            // decomment box-content and comment box-border if layout should calculate padding for width
            // 'vs-box-content': true,
            'vs-box-border': true,
            'vs-pl-4': !this.noPadding && !this.noXPadding,
            'md:vs-pl-12': !this.noPadding && !this.noXPadding,
            'xl:vs-pl-16': !this.noPadding && !this.noXPadding,
            'vs-pr-0': !this.noPadding && !this.noXPadding,
            'md:vs-pr-8': !this.noPadding && !this.noXPadding,
            'xl:vs-pr-12': !this.noPadding && !this.noXPadding,
            'vs-h-full': this.fullHeight,
            'vs-py-4': !this.noPadding && !this.noYPadding,
            'md:vs-py-8': !this.noPadding && !this.noYPadding,
            'vs-container-default-max-width': !this.variant || this.variant === VsContainerVariantEnum.default,
            'vs-container-narrow-max-width': this.variant === VsContainerVariantEnum.narrow,
        }
    }
}
