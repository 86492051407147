














































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { createNewSenderAuthorized, editSenderAuthorized } from '@/api/consoleApi/senders/emails'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'

@Component({
    name: 'VsCreateSender',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private senderData: any = {
        name: '',
        email: '',
        old_email: '',
        csa_data: {
            nominative: '',
            ivaFCode: '',
            address: '',
            city: '',
            province: '',
            country: '',
            cap: '',
            telephone: '',
            email: '',
        },
    }

    $refs: any

    mounted () {
        this.$emit('ready', this)
    }

    public async createSender () {
        const validation = await this.$refs.createSenderForm.validate()
        if (!validation) return
        this.loading = true
        if (this.senderData.id) {
            try {
                await editSenderAuthorized(this.senderData.id, this.senderData)

                this.$emit('sender-created', this.senderData)
                this.$root.$vsToast({
                    heading: 'Mittente modificato con successo',
                    message: this.senderData.email !== this.senderData.old_email ? 'Ti abbiamo inviato un\'email di verifica, accedi alla casella di posta del mittente inserito per verificarlo' : '',
                    timeout: this.senderData.email !== this.senderData.old_email ? 0 : 3000,
                    closable: this.senderData.email !== this.senderData.old_email,
                    aspect: VsToastAspectEnum.success,
                })
                this.show = false
            } catch (e) {
                if (axios.isAxiosError(e)) {
                    if (e.response?.status === 400) {
                        for (const field in e.response.data.message) {
                            if (!e.response.data.message[field]) continue
                            if (field === 'email') {
                                e.response.data.message[field] =
                                    e.response.data.message[field][0] === 'The email has a invalid sender domain.'
                                        ? ['il dominio utilizzato è un dominio non consentito per la creazione del mittente']
                                        : ['Indirizzo email già utilizzato']
                            } else {
                                e.response.data.message[field] = ['']
                            }
                        }

                        this.$refs.createSenderForm.setErrors(e.response.data.message)
                    }
                }
                console.log(e)
                this.$root.$vsToast({
                    heading: 'Errore durante la modifica del mittente',
                    timeout: 3000,
                    aspect: VsToastAspectEnum.alert,
                })
            }
        } else {
            try {
                await createNewSenderAuthorized(this.senderData)
                this.$emit('sender-created', this.senderData)
                this.$root.$vsToast({
                    heading: this.$t('createSender.success'),
                    message: 'Ti abbiamo inviato un\'email di verifica, accedi alla casella di posta del mittente inserito per verificarlo',
                    timeout: 0,
                    closable: true,
                    aspect: VsToastAspectEnum.success,
                })
                this.show = false
            } catch (e) {
                let message = ''
                if (axios.isAxiosError(e)) {
                    if (e.response?.status === 403) {
                        message = 'Hai superato il limite di mittenti creabili'
                    }
                    if (e.response?.status === 400) {
                        for (const field in e.response.data.message) {
                            if (!e.response.data.message[field]) continue
                            if (field === 'email') {
                                e.response.data.message[field] =
                                    e.response.data.message[field][0] === 'The email has a invalid sender domain.'
                                        ? ['il dominio utilizzato è un dominio non consentito per la creazione del mittente']
                                        : ['Indirizzo email già utilizzato']
                            } else {
                                e.response.data.message[field] = ['']
                            }
                        }

                        this.$refs.createSenderForm.setErrors(e.response.data.message)
                    }
                }
                this.$root.$vsToast({
                    heading: 'Errore durante la creazione del mittente',
                    message,
                    timeout: 3000,
                    aspect: VsToastAspectEnum.alert,
                })
            }
        }
        this.loading = false
    }

    public openModal (sender?: any) {
        this.loading = false
        this.show = true
        this.senderData = {
            name: '',
            email: '',
            old_email: '',
            csa_data: {
                nominative: '',
                ivaFCode: '',
                address: '',
                city: '',
                province: '',
                country: '',
                cap: '',
                telephone: '',
                email: '',
            },
        }

        if (sender) {
            const newSender = JSON.parse(JSON.stringify(sender))
            if (!newSender.csa_data) {
                newSender.csa_data = {
                    nominative: '',
                    ivaFCode: '',
                    address: '',
                    city: '',
                    province: '',
                    country: '',
                    cap: '',
                    telephone: '',
                    email: '',
                }
            }
            newSender.old_email = sender.email
            this.senderData = newSender
        }
    }
}
