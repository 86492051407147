import {
    formatDate,
} from '@/utils/formatter'

export enum CustomFieldTypeEnum {
    singleLine = 'Single line',
    paragraphText = 'Paragraph text',
    multipleChoise = 'Multiple choice',
    dropdown = 'Drop down',
    checkboxes = 'Checkboxes',
    email = 'Email',
    mobile = 'Mobile',
    hiddenField = 'Hidden field',
    dateField = 'Date field',
    timeField = 'Time field',
    booleanCheckbox = 'BooleanCheckbox',
}

export enum CustomFieldValidationMethodEnum {
    disabled = 'Disabled',
    numbers = 'Numbers',
    letters = 'Letters',
    numbersAndLetters = 'Numbers and letters',
    emailAddress = 'Email address',
    mobile = 'Mobile',
    url = 'URL',
    date = 'Date',
    time = 'Time',
    enum = 'Enum',
    custom = 'Custom',
    onlyLettersNumbersSpacesDashUnderscore = 'Letters, numbers, spaces, dash and underscore',
}

export enum CustomFieldVisibilityEnum {
    userOnly = 'User Only',
    public = 'public',
}

export interface FieldOption {
    label: string
    value: string
    selected: boolean
}
export interface CustomField {
    default_value: any
    field_options: FieldOption[]
    global: boolean
    id: number | string
    name: string
    other_options: [string, string, string, string, string]
    recipient_id: number
    type: CustomFieldTypeEnum
    placeholder: string
    validation: {
        required: boolean
        unique: boolean
        method: CustomFieldValidationMethodEnum
        rule: string
    }
    visibility: CustomFieldVisibilityEnum
}

export const customFieldsValuesFormatter = (value: any, customFieldType: CustomFieldTypeEnum) => {
    switch (customFieldType) {
        case CustomFieldTypeEnum.dateField: return formatDate(value)
        case CustomFieldTypeEnum.booleanCheckbox: return value ? 'vero' : 'falso'
        case CustomFieldTypeEnum.checkboxes:
            if (!value) return ''
            return Array.isArray(value) ? value.join(',') : value
        default: return value
    }
}
