export const sectorType: any[] = [
    {
        value: '1',
        label: 'Agricoltura e allevamento',
    },
    {
        value: '2',
        label: 'Animali',
    },
    {
        value: '3',
        label: 'Arredamento e antiquariato',
    },
    {
        value: '4',
        label: 'Artigianato (falegname, fabbro, elettricista ecc)',
    },
    {
        value: '5',
        label: 'Associazioni No Profit',
    },
    {
        value: '6',
        label: 'Auto e Moto',
    },
    {
        value: '7',
        label: 'Banche e Assicurazioni',
    },
    {
        value: '8',
        label: 'Bar e ristorazione',
    },
    {
        value: '9',
        label: 'Consulenza e agenzie di consulenza',
    },
    {
        value: '10',
        label: 'E-commerce (attività principale)',
    },
    {
        value: '11',
        label: 'Edilizia - materiali e manutenzione',
    },
    {
        value: '12',
        label: 'Editoria e Quotidiani',
    },
    {
        value: '13',
        label: 'Fiere congressi e conferenze',
    },
    {
        value: '14',
        label: 'Formazione, Corsi e istruzione',
    },
    {
        value: '15',
        label: 'Grafica, stampa e design',
    },
    {
        value: '16',
        label: 'Hobby e tempo libero',
    },
    {
        value: '17',
        label: 'ICT, web e pubblicità',
    },
    {
        value: '18',
        label: 'Immobiliare e agenzie immobiliari',
    },
    {
        value: '19',
        label: 'Industria',
    },
    {
        value: '20',
        label: 'Istituzionale e amministrazioni pubbliche',
    },
    {
        value: '21',
        label: 'Logistica e Trasporti',
    },
    {
        value: '22',
        label: 'Medical - Healthcare e Wellness',
    },
    {
        value: '23',
        label: 'Settori produttivi',
    },
    {
        value: '24',
        label: 'Sport e associazioni sportive',
    },
    {
        value: '25',
        label: 'Studi professionali e liberi professionisti',
    },
    {
        value: '26',
        label: 'Telefonia e telecomunicazioni',
    },
    {
        value: '27',
        label: 'Turismo e hotellerie',
    },
    {
        value: '28',
        label: 'Vendita al dettaglio (negozi, alimentari)',
    },
    {
        value: '29',
        label: 'Altro',
    },
]

export const zonesOptions: any[] = [
    {
        value: 'AG',
        label: 'Agrigento',
    },
    {
        value: 'AL',
        label: 'Alessandria',
    },
    {
        value: 'AN',
        label: 'Ancona',
    },
    {
        value: 'AO',
        label: 'Aosta',
    },
    {
        value: 'AR',
        label: 'Arezzo',
    },
    {
        value: 'AP',
        label: 'Ascoli Piceno',
    },
    {
        value: 'AT',
        label: 'Asti',
    },
    {
        value: 'AV',
        label: 'Avellino',
    },
    {
        value: 'BA',
        label: 'Bari',
    },
    {
        value: 'BT',
        label: 'Barletta-Andria-Trani',
    },
    {
        value: 'BL',
        label: 'Belluno',
    },
    {
        value: 'BN',
        label: 'Benevento',
    },
    {
        value: 'BG',
        label: 'Bergamo',
    },
    {
        value: 'BI',
        label: 'Biella',
    },
    {
        value: 'BO',
        label: 'Bologna',
    },
    {
        value: 'BZ',
        label: 'Bolzano',
    },
    {
        value: 'BS',
        label: 'Brescia',
    },
    {
        value: 'BR',
        label: 'Brindisi',
    },
    {
        value: 'CA',
        label: 'Cagliari',
    },
    {
        value: 'CL',
        label: 'Caltanissetta',
    },
    {
        value: 'CB',
        label: 'Campobasso',
    },
    {
        value: 'CI',
        label: 'Carbonia-Iglesias',
    },
    {
        value: 'CE',
        label: 'Caserta',
    },
    {
        value: 'CT',
        label: 'Catania',
    },
    {
        value: 'CZ',
        label: 'Catanzaro',
    },
    {
        value: 'CH',
        label: 'Chieti',
    },
    {
        value: 'CO',
        label: 'Como',
    },
    {
        value: 'CS',
        label: 'Cosenza',
    },
    {
        value: 'CR',
        label: 'Cremona',
    },
    {
        value: 'KR',
        label: 'Crotone',
    },
    {
        value: 'CN',
        label: 'Cuneo',
    },
    {
        value: 'EN',
        label: 'Enna',
    },
    {
        value: 'FM',
        label: 'Fermo',
    },
    {
        value: 'FE',
        label: 'Ferrara',
    },
    {
        value: 'FI',
        label: 'Firenze',
    },
    {
        value: 'FG',
        label: 'Foggia',
    },
    {
        value: 'FC',
        label: 'Forlì-Cesena',
    },
    {
        value: 'FR',
        label: 'Frosinone',
    },
    {
        value: 'GE',
        label: 'Genova',
    },
    {
        value: 'GO',
        label: 'Gorizia',
    },
    {
        value: 'GR',
        label: 'Grosseto',
    },
    {
        value: 'IM',
        label: 'Imperia',
    },
    {
        value: 'IS',
        label: 'Isernia',
    },
    {
        value: 'AQ',
        label: 'L\'Aquila',
    },
    {
        value: 'SP',
        label: 'La Spezia',
    },
    {
        value: 'LT',
        label: 'Latina',
    },
    {
        value: 'LE',
        label: 'Lecce',
    },
    {
        value: 'LC',
        label: 'Lecco',
    },
    {
        value: 'LI',
        label: 'Livorno',
    },
    {
        value: 'LO',
        label: 'Lodi',
    },
    {
        value: 'LU',
        label: 'Lucca',
    },
    {
        value: 'MC',
        label: 'Macerata',
    },
    {
        value: 'MN',
        label: 'Mantova',
    },
    {
        value: 'MS',
        label: 'Massa-Carrara',
    },
    {
        value: 'MT',
        label: 'Matera',
    },
    {
        value: 'VS',
        label: 'Medio Campidano',
    },
    {
        value: 'ME',
        label: 'Messina',
    },
    {
        value: 'MI',
        label: 'Milano',
    },
    {
        value: 'MO',
        label: 'Modena',
    },
    {
        value: 'MB',
        label: 'Monza e della Brianza',
    },
    {
        value: 'NA',
        label: 'Napoli',
    },
    {
        value: 'NO',
        label: 'Novara',
    },
    {
        value: 'NU',
        label: 'Nuoro',
    },
    {
        value: 'OG',
        label: 'Ogliastra',
    },
    {
        value: 'OT',
        label: 'Olbia-Tempio',
    },
    {
        value: 'OR',
        label: 'Oristano',
    },
    {
        value: 'PD',
        label: 'Padova',
    },
    {
        value: 'PA',
        label: 'Palermo',
    },
    {
        value: 'PR',
        label: 'Parma',
    },
    {
        value: 'PV',
        label: 'Pavia',
    },
    {
        value: 'PG',
        label: 'Perugia',
    },
    {
        value: 'PU',
        label: 'Pesaro e Urbino',
    },
    {
        value: 'PE',
        label: 'Pescara',
    },
    {
        value: 'PC',
        label: 'Piacenza',
    },
    {
        value: 'PI',
        label: 'Pisa',
    },
    {
        value: 'PT',
        label: 'Pistoia',
    },
    {
        value: 'PN',
        label: 'Pordenone',
    },
    {
        value: 'PZ',
        label: 'Potenza',
    },
    {
        value: 'PO',
        label: 'Prato',
    },
    {
        value: 'RG',
        label: 'Ragusa',
    },
    {
        value: 'RA',
        label: 'Ravenna',
    },
    {
        value: 'RC',
        label: 'Reggio Calabria',
    },
    {
        value: 'RE',
        label: 'Reggio Emilia',
    },
    {
        value: 'RI',
        label: 'Rieti',
    },
    {
        value: 'RN',
        label: 'Rimini',
    },
    {
        value: 'RM',
        label: 'Roma',
    },
    {
        value: 'RO',
        label: 'Rovigo',
    },
    {
        value: 'SA',
        label: 'Salerno',
    },
    {
        value: 'SS',
        label: 'Sassari',
    },
    {
        value: 'SV',
        label: 'Savona',
    },
    {
        value: 'SI',
        label: 'Siena',
    },
    {
        value: 'SR',
        label: 'Siracusa',
    },
    {
        value: 'SO',
        label: 'Sondrio',
    },
    {
        value: 'TA',
        label: 'Taranto',
    },
    {
        value: 'TE',
        label: 'Teramo',
    },
    {
        value: 'TR',
        label: 'Terni',
    },
    {
        value: 'TO',
        label: 'Torino',
    },
    {
        value: 'TP',
        label: 'Trapani',
    },
    {
        value: 'TN',
        label: 'Trento',
    },
    {
        value: 'TV',
        label: 'Treviso',
    },
    {
        value: 'TS',
        label: 'Trieste',
    },
    {
        value: 'UD',
        label: 'Udine',
    },
    {
        value: 'VA',
        label: 'Varese',
    },
    {
        value: 'VE',
        label: 'Venezia',
    },
    {
        value: 'VB',
        label: 'Verbano-Cusio-Ossola',
    },
    {
        value: 'VC',
        label: 'Vercelli',
    },
    {
        value: 'VR',
        label: 'Verona',
    },
    {
        value: 'VV',
        label: 'Vibo Valentia',
    },
    {
        value: 'VI',
        label: 'Vicenza',
    },
    {
        value: 'VT',
        label: 'Viterbo',
    },
]

export const countriesOptions: any[] = [
    {
        value: 'AE',
        label: 'United Arab Emirates',
    },
    {
        value: 'AU',
        label: 'Australia',
    },
    {
        value: 'BR',
        label: 'Brazil',
    },
    {
        value: 'CA',
        label: 'Canada',
    },
    {
        value: 'CH',
        label: 'Switzerland',
    },
    {
        value: 'CL',
        label: 'Chile',
    },
    {
        value: 'ES',
        label: 'Spain',
    },
    {
        value: 'AT',
        label: 'Austria',
    },
    {
        value: 'BE',
        label: 'Belgium',
    },
    {
        value: 'BG',
        label: 'Bulgaria',
    },
    {
        value: 'CY',
        label: 'Cyprus',
    },
    {
        value: 'CZ',
        label: 'Czech Republic',
    },
    {
        value: 'DE',
        label: 'Germany',
    },
    {
        value: 'DK',
        label: 'Denmark',
    },
    {
        value: 'FI',
        label: 'Finland',
    },
    {
        value: 'FR',
        label: 'France',
    },
    {
        value: 'GR',
        label: 'Greece',
    },
    {
        value: 'HR',
        label: 'Croatia',
    },
    {
        value: 'HU',
        label: 'Hungary',
    },
    {
        value: 'IE',
        label: 'Ireland',
    },
    {
        value: 'IT',
        label: 'Italia',
    },
    {
        value: 'LT',
        label: 'Lithuania',
    },
    {
        value: 'LU',
        label: 'Luxembourg',
    },
    {
        value: 'LV',
        label: 'Latvia',
    },
    {
        value: 'MT',
        label: 'Malta',
    },
    {
        value: 'NL',
        label: 'Netherlands',
    },
    {
        value: 'PL',
        label: 'Poland',
    },
    {
        value: 'PT',
        label: 'Portugal',
    },
    {
        value: 'RO',
        label: 'Romania',
    },
    {
        value: 'SE',
        label: 'Sweden',
    },
    {
        value: 'SI',
        label: 'Slovenia',
    },
    {
        value: 'SK',
        label: 'Slovakia',
    },
    {
        value: 'GB',
        label: 'United Kingdom',
    },
    {
        value: 'HK',
        label: 'Hong Kong',
    },
    {
        value: 'ID',
        label: 'Indonesia',
    },
    {
        value: 'IN',
        label: 'India',
    },
    {
        value: 'JP',
        label: 'Japan',
    },
    {
        value: 'KR',
        label: 'Republic of Korea',
    },
    {
        value: 'LI',
        label: 'Liechtenstein',
    },
    {
        value: 'MX',
        label: 'Mexico',
    },
    {
        value: 'MY',
        label: 'Malaysia',
    },
    {
        value: 'NO',
        label: 'Norway',
    },
    {
        value: 'NZ',
        label: 'New Zealand',
    },
    {
        value: 'RU',
        label: 'Russian Federation',
    },
    {
        value: 'SA',
        label: 'Saudi Arabia',
    },
    {
        value: 'SG',
        label: 'Singapore',
    },
    {
        value: 'TH',
        label: 'Thailand',
    },
    {
        value: 'TW',
        label: 'Taiwan',
    },
    {
        value: 'US',
        label: 'United States',
    },
    {
        value: 'ZA',
        label: 'South Africa',
    },
]

export const fiscalCodes = [
    { iso: 'AE', country: 'United Arab Emirates', value: 'ae_trn', description: 'United Arab Emirates TRN', example: '123456789012345' },
    { iso: 'AU', country: 'Australia', value: 'au_abn', description: 'Australian Business Number (AU ABN)', example: '12345678912' },
    { iso: 'BR', country: 'Brazil', value: 'br_cnpj', description: 'Brazilian CNPJ number', example: '01.234.456/5432-10' },
    { iso: 'BR', country: 'Brazil', value: 'br_cpf', description: 'Brazilian CPF number', example: '123.456.789-87' },
    { iso: 'CA', country: 'Canada', value: 'ca_bn', description: 'Canadian BN', example: '123456789' },
    { iso: 'CA', country: 'Canada', value: 'ca_qst', description: 'Canadian QST number', example: '1234567890TQ1234' },
    { iso: 'CH', country: 'Switzerland', value: 'ch_vat', description: 'Switzerland VAT number', example: 'CHE-123.456.789 MWST' },
    { iso: 'CL', country: 'Chile', value: 'cl_tin', description: 'Chilean TIN', example: '12.345.678-K' },
    { iso: 'ES', country: 'Spain', value: 'es_cif', description: 'Spanish CIF number', example: 'A12345678' },
    { iso: 'AT', country: 'Austria', value: 'eu_vat', description: 'European VAT number', example: 'ATU12345678' },
    { iso: 'BE', country: 'Belgium', value: 'eu_vat', description: 'European VAT number', example: 'BE0123456789' },
    { iso: 'BG', country: 'Bulgaria', value: 'eu_vat', description: 'European VAT number', example: 'BG0123456789' },
    { iso: 'CY', country: 'Cyprus', value: 'eu_vat', description: 'European VAT number', example: 'CY12345678Z' },
    { iso: 'CZ', country: 'Czech Republic', value: 'eu_vat', description: 'European VAT number', example: 'CZ1234567890' },
    { iso: 'DE', country: 'Germany', value: 'eu_vat', description: 'European VAT number', example: 'DE123456789' },
    { iso: 'DK', country: 'Denmark', value: 'eu_vat', description: 'European VAT number', example: 'DK12345678' },
    { iso: 'ES', country: 'Spain', value: 'eu_vat', description: 'European VAT number', example: 'ESA1234567Z' },
    { iso: 'FI', country: 'Finland', value: 'eu_vat', description: 'European VAT number', example: 'FI12345678' },
    { iso: 'FR', country: 'France', value: 'eu_vat', description: 'European VAT number', example: 'FRAB123456789' },
    { iso: 'GR', country: 'Greece', value: 'eu_vat', description: 'European VAT number', example: 'EL123456789' },
    { iso: 'HR', country: 'Croatia', value: 'eu_vat', description: 'European VAT number', example: 'HR12345678912' },
    { iso: 'HU', country: 'Hungary', value: 'eu_vat', description: 'European VAT number', example: 'HU12345678912' },
    { iso: 'IE', country: 'Ireland', value: 'eu_vat', description: 'European VAT number', example: 'IE1234567AB' },
    { iso: 'IT', country: 'Italia', value: 'eu_vat', description: 'European VAT number', example: 'IT12345678912' },
    { iso: 'LT', country: 'Lithuania', value: 'eu_vat', description: 'European VAT number', example: 'LT123456789123' },
    { iso: 'LU', country: 'Luxembourg', value: 'eu_vat', description: 'European VAT number', example: 'LU12345678' },
    { iso: 'LV', country: 'Latvia', value: 'eu_vat', description: 'European VAT number', example: 'LV12345678912' },
    { iso: 'MT', country: 'Malta', value: 'eu_vat', description: 'European VAT number', example: 'MT12345678' },
    { iso: 'NL', country: 'Netherlands', value: 'eu_vat', description: 'European VAT number', example: 'NL123456789B12' },
    { iso: 'PL', country: 'Poland', value: 'eu_vat', description: 'European VAT number', example: 'PL1234567890' },
    { iso: 'PT', country: 'Portugal', value: 'eu_vat', description: 'European VAT number', example: 'PT123456789' },
    { iso: 'RO', country: 'Romania', value: 'eu_vat', description: 'European VAT number', example: 'RO1234567891' },
    { iso: 'SE', country: 'Sweden', value: 'eu_vat', description: 'European VAT number', example: 'SE123456789123' },
    { iso: 'SI', country: 'Slovenia', value: 'eu_vat', description: 'European VAT number', example: 'SI12345678' },
    { iso: 'SK', country: 'Slovakia', value: 'eu_vat', description: 'European VAT number', example: 'SK1234567891' },
    { iso: 'GB', country: 'United Kingdom', value: 'eu_vat', description: 'Northern Ireland VAT number', example: 'XI123456789' },
    { iso: 'GB', country: 'United Kingdom', value: 'gb_vat', description: 'United Kingdom VAT number', example: 'GB123456789' },
    { iso: 'HK', country: 'Hong Kong', value: 'hk_br', description: 'Hong Kong BR number', example: '12345678' },
    { iso: 'ID', country: 'Indonesia', value: 'id_npwp', description: 'Indonesian NPWP number', example: '12.345.678.9-012.345' },
    { iso: 'IN', country: 'India', value: 'in_gst', description: 'Indian GST number', example: '12ABCDE3456FGZH' },
    { iso: 'JP', country: 'Japan', value: 'jp_cn', description: 'Japanese Corporate Number (Hōjin Bangō)', example: '1234567891234' },
    { iso: 'JP', country: 'Japan', value: 'jp_rn', description: 'Japanese Registered Foreign Businesses\' Registration Number (Tōroku Kokugai Jigyōsha no Tōroku Bangō)', example: '12345' },
    { iso: 'KR', country: 'Republic of Korea', value: 'kr_brn', description: 'Korean BRN', example: '123-45-67890' },
    { iso: 'LI', country: 'Liechtenstein', value: 'li_uid', description: 'Liechtensteinian UID number', example: 'CHE123456789' },
    { iso: 'MX', country: 'Mexico', value: 'mx_rfc', description: 'Mexican RFC number', example: 'ABC010203AB9' },
    { iso: 'MY', country: 'Malaysia', value: 'my_frp', description: 'Malaysian FRP number', example: '12345678' },
    { iso: 'MY', country: 'Malaysia', value: 'my_itn', description: 'Malaysian ITN', example: 'C 1234567890' },
    { iso: 'NO', country: 'Norway', value: 'no_vat', description: 'Norwegian VAT number', example: '123456789MVA' },
    { iso: 'NZ', country: 'New Zealand', value: 'nz_gst', description: 'New Zealand GST number', example: '123456789' },
    { iso: 'RU', country: 'Russian Federation', value: 'ru_inn', description: 'Russian INN', example: '1234567891' },
    { iso: 'RU', country: 'Russian Federation', value: 'ru_kpp', description: 'Russian KPP', example: '123456789' },
    { iso: 'SA', country: 'Saudi Arabia', value: 'sa_vat', description: 'Saudi Arabia VAT', example: '123456789012345' },
    { iso: 'SG', country: 'Singapore', value: 'sg_gst', description: 'Singaporean GST', example: 'M12345678X' },
    { iso: 'SG', country: 'Singapore', value: 'sg_uen', description: 'Singaporean UEN', example: '123456789F' },
    { iso: 'TH', country: 'Thailand', value: 'th_vat', description: 'Thai VAT', example: '1234567891234' },
    { iso: 'TW', country: 'Taiwan', value: 'tw_vat', description: 'Taiwanese VAT', example: '12345678' },
    { iso: 'US', country: 'United States', value: 'us_ein', description: 'United States EIN', example: '12-3456789' },
    { iso: 'ZA', country: 'South Africa', value: 'za_vat', description: 'South African VAT number', example: '4123456789' },
]

export const smsSenderCountries = [
    { code: 'AD', label: 'Andorra' },
    { code: 'AE', label: 'United Arab Emirates' },
    { code: 'AF', label: 'Afghanistan' },
    { code: 'AG', label: 'Antigua & Barbuda' },
    { code: 'AI', label: 'Anguilla' },
    { code: 'AL', label: 'Albania' },
    { code: 'AM', label: 'Armenia' },
    { code: 'AN', label: 'Netherlands Antilles' },
    { code: 'AO', label: 'Angola' },
    { code: 'AQ', label: 'Antarctica' },
    { code: 'AR', label: 'Argentina' },
    { code: 'AS', label: 'American Samoa' },
    { code: 'AT', label: 'Austria' },
    { code: 'AU', label: 'Australia' },
    { code: 'AW', label: 'Aruba' },
    { code: 'AZ', label: 'Azerbaijan' },
    { code: 'BA', label: 'Bosnia and Herzegovina' },
    { code: 'BB', label: 'Barbados' },
    { code: 'BD', label: 'Bangladesh' },
    { code: 'BE', label: 'Belgium' },
    { code: 'BF', label: 'Burkina Faso' },
    { code: 'BG', label: 'Bulgaria' },
    { code: 'BH', label: 'Bahrain' },
    { code: 'BI', label: 'Burundi' },
    { code: 'BJ', label: 'Benin' },
    { code: 'BM', label: 'Bermuda' },
    { code: 'BN', label: 'Brunei Darussalam' },
    { code: 'BO', label: 'Bolivia' },
    { code: 'BR', label: 'Brazil' },
    { code: 'BS', label: 'Bahama' },
    { code: 'BT', label: 'Bhutan' },
    { code: 'BV', label: 'Bouvet Island' },
    { code: 'BW', label: 'Botswana' },
    { code: 'BY', label: 'Belarus' },
    { code: 'BZ', label: 'Belize' },
    { code: 'CA', label: 'Canada' },
    { code: 'CC', label: 'Cocos (Keeling) Islands' },
    { code: 'CF', label: 'Central African Republic' },
    { code: 'CG', label: 'Congo' },
    { code: 'CH', label: 'Switzerland' },
    { code: 'CI', label: 'Côte D\'ivoire (Ivory Coast)' },
    { code: 'CK', label: 'Cook Iislands' },
    { code: 'CL', label: 'Chile' },
    { code: 'CM', label: 'Cameroon' },
    { code: 'CN', label: 'China' },
    { code: 'CO', label: 'Colombia' },
    { code: 'CR', label: 'Costa Rica' },
    { code: 'CU', label: 'Cuba' },
    { code: 'CV', label: 'Cape Verde' },
    { code: 'CX', label: 'Christmas Island' },
    { code: 'CY', label: 'Cyprus' },
    { code: 'CZ', label: 'Czech Republic' },
    { code: 'DE', label: 'Germany' },
    { code: 'DJ', label: 'Djibouti' },
    { code: 'DK', label: 'Denmark' },
    { code: 'DM', label: 'Dominica' },
    { code: 'DO', label: 'Dominican Republic' },
    { code: 'DZ', label: 'Algeria' },
    { code: 'EC', label: 'Ecuador' },
    { code: 'EE', label: 'Estonia' },
    { code: 'EG', label: 'Egypt' },
    { code: 'EH', label: 'Western Sahara' },
    { code: 'ER', label: 'Eritrea' },
    { code: 'ES', label: 'Spain' },
    { code: 'ET', label: 'Ethiopia' },
    { code: 'FI', label: 'Finland' },
    { code: 'FJ', label: 'Fiji' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)' },
    { code: 'FM', label: 'Micronesia' },
    { code: 'FO', label: 'Faroe Islands' },
    { code: 'FR', label: 'France' },
    { code: 'FX', label: 'France, Metropolitan' },
    { code: 'GA', label: 'Gabon' },
    { code: 'GB', label: 'United Kingdom (Great Britain)' },
    { code: 'GD', label: 'Grenada' },
    { code: 'GE', label: 'Georgia' },
    { code: 'GF', label: 'French Guiana' },
    { code: 'GH', label: 'Ghana' },
    { code: 'GI', label: 'Gibraltar' },
    { code: 'GL', label: 'Greenland' },
    { code: 'GM', label: 'Gambia' },
    { code: 'GN', label: 'Guinea' },
    { code: 'GP', label: 'Guadeloupe' },
    { code: 'GQ', label: 'Equatorial Guinea' },
    { code: 'GR', label: 'Greece' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { code: 'GT', label: 'Guatemala' },
    { code: 'GU', label: 'Guam' },
    { code: 'GW', label: 'Guinea-Bissau' },
    { code: 'GY', label: 'Guyana' },
    { code: 'HK', label: 'Hong Kong' },
    { code: 'HM', label: 'Heard & McDonald Islands' },
    { code: 'HN', label: 'Honduras' },
    { code: 'HR', label: 'Croatia' },
    { code: 'HT', label: 'Haiti' },
    { code: 'HU', label: 'Hungary' },
    { code: 'ID', label: 'Indonesia' },
    { code: 'IE', label: 'Ireland' },
    { code: 'IL', label: 'Israel' },
    { code: 'IN', label: 'India' },
    { code: 'IO', label: 'British Indian Ocean Territory' },
    { code: 'IQ', label: 'Iraq' },
    { code: 'IR', label: 'Islamic Republic of Iran' },
    { code: 'IS', label: 'Iceland' },
    { code: 'IT', label: 'Italy' },
    { code: 'JM', label: 'Jamaica' },
    { code: 'JO', label: 'Jordan' },
    { code: 'JP', label: 'Japan' },
    { code: 'KE', label: 'Kenya' },
    { code: 'KG', label: 'Kyrgyzstan' },
    { code: 'KH', label: 'Cambodia' },
    { code: 'KI', label: 'Kiribati' },
    { code: 'KM', label: 'Comoros' },
    { code: 'KN', label: 'St. Kitts and Nevis' },
    { code: 'KP', label: 'Korea, Democratic People\'s Republic of' },
    { code: 'KR', label: 'Korea, Republic of' },
    { code: 'KW', label: 'Kuwait' },
    { code: 'KY', label: 'Cayman Islands' },
    { code: 'KZ', label: 'Kazakhstan' },
    { code: 'LA', label: 'Lao People\'s Democratic Republic' },
    { code: 'LB', label: 'Lebanon' },
    { code: 'LC', label: 'Saint Lucia' },
    { code: 'LI', label: 'Liechtenstein' },
    { code: 'LK', label: 'Sri Lanka' },
    { code: 'LR', label: 'Liberia' },
    { code: 'LS', label: 'Lesotho' },
    { code: 'LT', label: 'Lithuania' },
    { code: 'LU', label: 'Luxembourg' },
    { code: 'LV', label: 'Latvia' },
    { code: 'LY', label: 'Libyan Arab Jamahiriya' },
    { code: 'MA', label: 'Morocco' },
    { code: 'MC', label: 'Monaco' },
    { code: 'MD', label: 'Moldova, Republic of' },
    { code: 'MG', label: 'Madagascar' },
    { code: 'MH', label: 'Marshall Islands' },
    { code: 'ML', label: 'Mali' },
    { code: 'MN', label: 'Mongolia' },
    { code: 'MM', label: 'Myanmar' },
    { code: 'MO', label: 'Macau' },
    { code: 'MP', label: 'Northern Mariana Islands' },
    { code: 'MQ', label: 'Martinique' },
    { code: 'MR', label: 'Mauritania' },
    { code: 'MS', label: 'Monserrat' },
    { code: 'MT', label: 'Malta' },
    { code: 'MU', label: 'Mauritius' },
    { code: 'MV', label: 'Maldives' },
    { code: 'MW', label: 'Malawi' },
    { code: 'MX', label: 'Mexico' },
    { code: 'MY', label: 'Malaysia' },
    { code: 'MZ', label: 'Mozambique' },
    { code: 'NA', label: 'Namibia' },
    { code: 'NC', label: 'New Caledonia' },
    { code: 'NE', label: 'Niger' },
    { code: 'NF', label: 'Norfolk Island' },
    { code: 'NG', label: 'Nigeria' },
    { code: 'NI', label: 'Nicaragua' },
    { code: 'NL', label: 'Netherlands' },
    { code: 'NO', label: 'Norway' },
    { code: 'NP', label: 'Nepal' },
    { code: 'NR', label: 'Nauru' },
    { code: 'NU', label: 'Niue' },
    { code: 'NZ', label: 'New Zealand' },
    { code: 'OM', label: 'Oman' },
    { code: 'PA', label: 'Panama' },
    { code: 'PE', label: 'Peru' },
    { code: 'PF', label: 'French Polynesia' },
    { code: 'PG', label: 'Papua New Guinea' },
    { code: 'PH', label: 'Philippines' },
    { code: 'PK', label: 'Pakistan' },
    { code: 'PL', label: 'Poland' },
    { code: 'PM', label: 'St. Pierre & Miquelon' },
    { code: 'PN', label: 'Pitcairn' },
    { code: 'PR', label: 'Puerto Rico' },
    { code: 'PT', label: 'Portugal' },
    { code: 'PW', label: 'Palau' },
    { code: 'PY', label: 'Paraguay' },
    { code: 'QA', label: 'Qatar' },
    { code: 'RE', label: 'Réunion' },
    { code: 'RO', label: 'Romania' },
    { code: 'RU', label: 'Russian Federation' },
    { code: 'RW', label: 'Rwanda' },
    { code: 'SA', label: 'Saudi Arabia' },
    { code: 'SB', label: 'Solomon Islands' },
    { code: 'SC', label: 'Seychelles' },
    { code: 'SD', label: 'Sudan' },
    { code: 'SE', label: 'Sweden' },
    { code: 'SG', label: 'Singapore' },
    { code: 'SH', label: 'St. Helena' },
    { code: 'SI', label: 'Slovenia' },
    { code: 'SJ', label: 'Svalbard & Jan Mayen Islands' },
    { code: 'SK', label: 'Slovakia' },
    { code: 'SL', label: 'Sierra Leone' },
    { code: 'SM', label: 'San Marino' },
    { code: 'SN', label: 'Senegal' },
    { code: 'SO', label: 'Somalia' },
    { code: 'SR', label: 'Suriname' },
    { code: 'ST', label: 'Sao Tome & Principe' },
    { code: 'SV', label: 'El Salvador' },
    { code: 'SY', label: 'Syrian Arab Republic' },
    { code: 'SZ', label: 'Swaziland' },
    { code: 'TC', label: 'Turks & Caicos Islands' },
    { code: 'TD', label: 'Chad' },
    { code: 'TF', label: 'French Southern Territories' },
    { code: 'TG', label: 'Togo' },
    { code: 'TH', label: 'Thailand' },
    { code: 'TJ', label: 'Tajikistan' },
    { code: 'TK', label: 'Tokelau' },
    { code: 'TM', label: 'Turkmenistan' },
    { code: 'TN', label: 'Tunisia' },
    { code: 'TO', label: 'Tonga' },
    { code: 'TP', label: 'East Timor' },
    { code: 'TR', label: 'Turkey' },
    { code: 'TT', label: 'Trinidad & Tobago' },
    { code: 'TV', label: 'Tuvalu' },
    { code: 'TW', label: 'Taiwan, Province of China' },
    { code: 'TZ', label: 'Tanzania, United Republic of' },
    { code: 'UA', label: 'Ukraine' },
    { code: 'UG', label: 'Uganda' },
    { code: 'UM', label: 'United States Minor Outlying Islands' },
    { code: 'US', label: 'United States of America' },
    { code: 'UY', label: 'Uruguay' },
    { code: 'UZ', label: 'Uzbekistan' },
    { code: 'VA', label: 'Vatican City State (Holy See)' },
    { code: 'VC', label: 'St. Vincent & the Grenadines' },
    { code: 'VE', label: 'Venezuela' },
    { code: 'VG', label: 'British Virgin Islands' },
    { code: 'VI', label: 'United States Virgin Islands' },
    { code: 'VN', label: 'Viet Nam' },
    { code: 'VU', label: 'Vanuatu' },
    { code: 'WF', label: 'Wallis & Futuna Islands' },
    { code: 'WS', label: 'Samoa' },
    { code: 'YE', label: 'Yemen' },
    { code: 'YT', label: 'Mayotte' },
    { code: 'YU', label: 'Yugoslavia' },
    { code: 'ZA', label: 'South Africa' },
    { code: 'ZM', label: 'Zambia' },
    { code: 'ZR', label: 'Zaire' },
    { code: 'ZW', label: 'Zimbabw' },
]

export const countries = [
    {
        cee: false,
        vat_regex: '',
        code: 'AF',
        name: 'Afghanistan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AL',
        name: 'Albania',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'DZ',
        name: 'Algeria',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'DS',
        name: 'American Samoa',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AD',
        name: 'Andorra',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AO',
        name: 'Angola',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AI',
        name: 'Anguilla',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AQ',
        name: 'Antarctica',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AG',
        name: 'Antigua and Barbuda',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AR',
        name: 'Argentina',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AM',
        name: 'Armenia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AW',
        name: 'Aruba',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AU',
        name: 'Australia',
    },
    {
        cee: true,
        vat_regex: 'ATU[0-9]{8}',
        code: 'AT',
        name: 'Austria',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AZ',
        name: 'Azerbaijan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BS',
        name: 'Bahamas',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BH',
        name: 'Bahrain',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BD',
        name: 'Bangladesh',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BB',
        name: 'Barbados',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BY',
        name: 'Belarus',
    },
    {
        cee: true,
        vat_regex: 'BE[0-9]{9,10}',
        code: 'BE',
        name: 'Belgium',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BZ',
        name: 'Belize',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BJ',
        name: 'Benin',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BM',
        name: 'Bermuda',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BT',
        name: 'Bhutan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BO',
        name: 'Bolivia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BA',
        name: 'Bosnia and Herzegovina',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BW',
        name: 'Botswana',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BV',
        name: 'Bouvet Island',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BR',
        name: 'Brazil',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'IO',
        name: 'British Indian Ocean Territory',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BN',
        name: 'Brunei Darussalam',
    },
    {
        cee: true,
        vat_regex: 'BG[0-9]{9,10}',
        code: 'BG',
        name: 'Bulgaria',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BF',
        name: 'Burkina Faso',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'BI',
        name: 'Burundi',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KH',
        name: 'Cambodia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CM',
        name: 'Cameroon',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CA',
        name: 'Canada',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CV',
        name: 'Cape Verde',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KY',
        name: 'Cayman Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CF',
        name: 'Central African Republic',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TD',
        name: 'Chad',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CL',
        name: 'Chile',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CN',
        name: 'China',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CX',
        name: 'Christmas Island',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CC',
        name: 'Cocos (Keeling) Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CO',
        name: 'Colombia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KM',
        name: 'Comoros',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CG',
        name: 'Congo',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CK',
        name: 'Cook Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CR',
        name: 'Costa Rica',
    },
    {
        cee: true,
        vat_regex: 'HR[0-9]{11}',
        code: 'HR',
        name: 'Croatia (Hrvatska)',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CU',
        name: 'Cuba',
    },
    {
        cee: true,
        vat_regex: 'CY[0-9]{8}[A-Z]',
        code: 'CY',
        name: 'Cyprus',
    },
    {
        cee: true,
        vat_regex: 'CZ[0-9]{8,10}',
        code: 'CZ',
        name: 'Czech Republic',
    },
    {
        cee: true,
        vat_regex: 'DK[0-9]{8}',
        code: 'DK',
        name: 'Denmark',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'DJ',
        name: 'Djibouti',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'DM',
        name: 'Dominica',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'DO',
        name: 'Dominican Republic',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TP',
        name: 'East Timor',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'EC',
        name: 'Ecuador',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'EG',
        name: 'Egypt',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SV',
        name: 'El Salvador',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GQ',
        name: 'Equatorial Guinea',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ER',
        name: 'Eritrea',
    },
    {
        cee: true,
        vat_regex: 'EE[0-9]{9}',
        code: 'EE',
        name: 'Estonia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ET',
        name: 'Ethiopia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'FK',
        name: 'Falkland Islands (Malvinas)',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'FO',
        name: 'Faroe Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'FJ',
        name: 'Fiji',
    },
    {
        cee: true,
        vat_regex: 'FI[0-9]{8}',
        code: 'FI',
        name: 'Finland',
    },
    {
        cee: true,
        vat_regex: 'FR[A-Z0-9]{2}[0-9]{8}[A-Z0-9]',
        code: 'FR',
        name: 'France',
    },
    // {
    //     cee: false,
    //     vat_regex: 'FR[A-Z0-9]{2}[0-9]{8}[A-Z0-9]',
    //     code: 'FX',
    //     name: 'France, Metropolitan',
    // },
    {
        cee: false,
        vat_regex: '',
        code: 'GF',
        name: 'French Guiana',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PF',
        name: 'French Polynesia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TF',
        name: 'French Southern Territories',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GA',
        name: 'Gabon',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GM',
        name: 'Gambia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GE',
        name: 'Georgia',
    },
    {
        cee: true,
        vat_regex: 'DE[0-9]{9}',
        code: 'DE',
        name: 'Germany',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GH',
        name: 'Ghana',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GI',
        name: 'Gibraltar',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GK',
        name: 'Guernsey',
    },
    {
        cee: true,
        vat_regex: 'EL[0-9]{9}',
        code: 'GR',
        name: 'Greece',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GL',
        name: 'Greenland',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GD',
        name: 'Grenada',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GP',
        name: 'Guadeloupe',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GU',
        name: 'Guam',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GT',
        name: 'Guatemala',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GN',
        name: 'Guinea',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GW',
        name: 'Guinea-Bissau',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GY',
        name: 'Guyana',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'HT',
        name: 'Haiti',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'HM',
        name: 'Heard and Mc Donald Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'HN',
        name: 'Honduras',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'HK',
        name: 'Hong Kong',
    },
    {
        cee: true,
        vat_regex: 'HU[0-9]{8}',
        code: 'HU',
        name: 'Hungary',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'IS',
        name: 'Iceland',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'IN',
        name: 'India',
    },
    {
        cee: true,
        vat_regex: 'GB[0-9]{9}',
        code: 'IM',
        name: 'Isle of Man',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ID',
        name: 'Indonesia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'IR',
        name: 'Iran (Islamic Republic of)',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'IQ',
        name: 'Iraq',
    },
    {
        cee: true,
        vat_regex: 'IE[0-9]{7}[A-Z0-9]{2}',
        code: 'IE',
        name: 'Ireland',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'IL',
        name: 'Israel',
    },
    {
        cee: true,
        vat_regex: 'IT[0-9]{11}',
        code: 'IT',
        name: 'Italy',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'CI',
        name: 'Ivory Coast',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'JE',
        name: 'Jersey',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'JM',
        name: 'Jamaica',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'JP',
        name: 'Japan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'JO',
        name: 'Jordan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KZ',
        name: 'Kazakhstan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KE',
        name: 'Kenya',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KI',
        name: 'Kiribati',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KP',
        name: 'Korea, Democratic People\'s Republic of',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KR',
        name: 'Korea, Republic of',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'XK',
        name: 'Kosovo',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KW',
        name: 'Kuwait',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KG',
        name: 'Kyrgyzstan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LA',
        name: 'Lao People\'s Democratic Republic',
    },
    {
        cee: true,
        vat_regex: 'LV[0-9]{11}',
        code: 'LV',
        name: 'Latvia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LB',
        name: 'Lebanon',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LS',
        name: 'Lesotho',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LR',
        name: 'Liberia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LY',
        name: 'Libyan Arab Jamahiriya',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LI',
        name: 'Liechtenstein',
    },
    {
        cee: true,
        vat_regex: 'LT[0-9]{9}([0-9]{3})?',
        code: 'LT',
        name: 'Lithuania',
    },
    {
        cee: true,
        vat_regex: 'LU[0-9]{8}',
        code: 'LU',
        name: 'Luxembourg',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MO',
        name: 'Macau',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MK',
        name: 'Macedonia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MG',
        name: 'Madagascar',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MW',
        name: 'Malawi',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MY',
        name: 'Malaysia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MV',
        name: 'Maldives',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ML',
        name: 'Mali',
    },
    {
        cee: true,
        vat_regex: 'MT[0-9]{8}',
        code: 'MT',
        name: 'Malta',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MH',
        name: 'Marshall Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MQ',
        name: 'Martinique',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MR',
        name: 'Mauritania',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MU',
        name: 'Mauritius',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TY',
        name: 'Mayotte',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MX',
        name: 'Mexico',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'FM',
        name: 'Micronesia, Federated States of',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MD',
        name: 'Moldova, Republic of',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MC',
        name: 'Monaco',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MN',
        name: 'Mongolia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ME',
        name: 'Montenegro',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MS',
        name: 'Montserrat',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MA',
        name: 'Morocco',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MZ',
        name: 'Mozambique',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MM',
        name: 'Myanmar',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NA',
        name: 'Namibia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NR',
        name: 'Nauru',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NP',
        name: 'Nepal',
    },
    {
        cee: true,
        vat_regex: 'NL[0-9]{9}B([0-9]{2})',
        code: 'NL',
        name: 'Netherlands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AN',
        name: 'Netherlands Antilles',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NC',
        name: 'New Caledonia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NZ',
        name: 'New Zealand',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NI',
        name: 'Nicaragua',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NE',
        name: 'Niger',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NG',
        name: 'Nigeria',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NU',
        name: 'Niue',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NF',
        name: 'Norfolk Island',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'MP',
        name: 'Northern Mariana Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'NO',
        name: 'Norway',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'OM',
        name: 'Oman',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PK',
        name: 'Pakistan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PW',
        name: 'Palau',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PS',
        name: 'Palestine',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PA',
        name: 'Panama',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PG',
        name: 'Papua New Guinea',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PY',
        name: 'Paraguay',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PE',
        name: 'Peru',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PH',
        name: 'Philippines',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PN',
        name: 'Pitcairn',
    },
    {
        cee: true,
        vat_regex: 'PL[0-9]{10}',
        code: 'PL',
        name: 'Poland',
    },
    {
        cee: true,
        vat_regex: 'PT[0-9]{9}',
        code: 'PT',
        name: 'Portugal',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PR',
        name: 'Puerto Rico',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'QA',
        name: 'Qatar',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'RE',
        name: 'Reunion',
    },
    {
        cee: true,
        vat_regex: 'RO[0-9]{2,10}',
        code: 'RO',
        name: 'Romania',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'RU',
        name: 'Russian Federation',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'RW',
        name: 'Rwanda',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'KN',
        name: 'Saint Kitts and Nevis',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LC',
        name: 'Saint Lucia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'VC',
        name: 'Saint Vincent and the Grenadines',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'WS',
        name: 'Samoa',
    },
    {
        cee: false,
        vat_regex: 'SM[0-9]{5}',
        code: 'SM',
        name: 'San Marino',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ST',
        name: 'Sao Tome and Principe',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SA',
        name: 'Saudi Arabia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SN',
        name: 'Senegal',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'RS',
        name: 'Serbia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SC',
        name: 'Seychelles',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SL',
        name: 'Sierra Leone',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SG',
        name: 'Singapore',
    },
    {
        cee: true,
        vat_regex: 'SK[0-9]{10}',
        code: 'SK',
        name: 'Slovakia',
    },
    {
        cee: true,
        vat_regex: 'SI[0-9]{8}',
        code: 'SI',
        name: 'Slovenia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SB',
        name: 'Solomon Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SO',
        name: 'Somalia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ZA',
        name: 'South Africa',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'GS',
        name: 'South Georgia South Sandwich Islands',
    },
    {
        cee: true,
        vat_regex: 'ES([0-9]|[A-Z])[0-9]{7}([A-Z]|[0-9])',
        code: 'ES',
        name: 'Spain',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'LK',
        name: 'Sri Lanka',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SH',
        name: 'St. Helena',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'PM',
        name: 'St. Pierre and Miquelon',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SD',
        name: 'Sudan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SR',
        name: 'Suriname',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SJ',
        name: 'Svalbard and Jan Mayen Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SZ',
        name: 'Swaziland',
    },
    {
        cee: true,
        vat_regex: 'SE[0-9]{12}',
        code: 'SE',
        name: 'Sweden',
    },
    {
        cee: false,
        vat_regex: '(CH[0-9]{6}|CHE-[0-9]{3}.[0-9]{3}.[0-9]{3} (MWST|TVA|IVA))',
        code: 'CH',
        name: 'Switzerland',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'SY',
        name: 'Syrian Arab Republic',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TW',
        name: 'Taiwan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TJ',
        name: 'Tajikistan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TZ',
        name: 'Tanzania, United Republic of',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TH',
        name: 'Thailand',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TG',
        name: 'Togo',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TK',
        name: 'Tokelau',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TO',
        name: 'Tonga',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TT',
        name: 'Trinidad and Tobago',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TN',
        name: 'Tunisia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TR',
        name: 'Turkey',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TM',
        name: 'Turkmenistan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TC',
        name: 'Turks and Caicos Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'TV',
        name: 'Tuvalu',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'UG',
        name: 'Uganda',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'UA',
        name: 'Ukraine',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'AE',
        name: 'United Arab Emirates',
    },
    {
        cee: true,
        vat_regex: 'GB[0-9]{9}',
        code: 'GB',
        name: 'United Kingdom',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'US',
        name: 'United States',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'UM',
        name: 'United States minor outlying islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'UY',
        name: 'Uruguay',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'UZ',
        name: 'Uzbekistan',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'VU',
        name: 'Vanuatu',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'VA',
        name: 'Vatican City State',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'VE',
        name: 'Venezuela',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'VN',
        name: 'Vietnam',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'VG',
        name: 'Virgin Islands (British)',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'VI',
        name: 'Virgin Islands (U.S.)',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'WF',
        name: 'Wallis and Futuna Islands',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'EH',
        name: 'Western Sahara',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'YE',
        name: 'Yemen',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'YU',
        name: 'Yugoslavia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ZR',
        name: 'Zaire',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ZM',
        name: 'Zambia',
    },
    {
        cee: false,
        vat_regex: '',
        code: 'ZW',
        name: 'Zimbabwe',
    },
]
