import { serviceConsoleApiSubscriberArea } from '@/utils/request'
import { CancelToken } from 'axios'

export const getMe = (cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.get(
        `/subscriber-area/me`,
        {
            cancelToken,
        },
    )

export const authenticate = (data: {
    userMongoId: string
    email: string
    recipientId?: any
}, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.post(
        `/subscriber-area/authenticate`,
        data,
        {
            cancelToken,
        },
    )

export const getSubscriberRecipients = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.get(
        `/subscriber-area/recipients`,
        {
            params,
            cancelToken,
        },
    )

export const getSubscriberRecipient = (listId: string | number, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.get(
        `/subscriber-area/recipients/${listId}`,
        {
            params,
            cancelToken,
        },
    )

export const getSubscriber = (listId: string | number, contactId: string | number, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.get(
        `/subscriber-area/recipients/${listId}/contacts/${contactId}`,
        {
            params,
            cancelToken,
        },
    )

export const getListCustomFields = (listId: string | number, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.get(
        `/subscriber-area/recipients/${listId}/custom-fields`,
        {
            params,
            cancelToken,
        },
    )

export const getSubscriberSuppression = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.get(
        `/subscriber-area/suppression`,
        {
            params,
            cancelToken,
        },
    )

export const updateContact = (listId: string | number, contactId: string | number, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.put(
        `/subscriber-area/recipients/${listId}/contacts/${contactId}`,
        data,
        {
            cancelToken,
        },
    )

export const changeContactStatus = (listId: string | number, contactId: string | number, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.patch(
        `/subscriber-area/recipients/${listId}/contacts/${contactId}/change-status`,
        data,
        {
            cancelToken,
        },
    )

export const forgetContact = (cancelToken?: CancelToken) =>
    serviceConsoleApiSubscriberArea.post(
        `/subscriber-area/forget`,
        {},
        {
            cancelToken,
        },
    )
