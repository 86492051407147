import Vue from 'vue'
import {
    formatDate,
    formatUnixDate,
    formatFromNow,
    formatFromNowUnix,
    formatPercentage,
    formatNumberInfinite,
    formatNumber,
    formatCurrency,
    formatBytes,
    formatKbToMb,
    calculateBytes,
    zeroToDashValue,
    formatCurrencyNoZero,
    convertMsToTime,
    formatNumberWithThousandsSeparator,
} from '@/utils/formatter'

Vue.filter('formatDate', formatDate)
Vue.filter('formatUnixDate', formatUnixDate)
Vue.filter('formatFromNow', formatFromNow)
Vue.filter('formatFromNowUnix', formatFromNowUnix)
Vue.filter('formatPercentage', formatPercentage)
Vue.filter('formatNumberInfinite', formatNumberInfinite)
Vue.filter('formatNumber', formatNumber)
Vue.filter('formatNumberWithThousandsSeparator', formatNumberWithThousandsSeparator)
Vue.filter('formatCurrency', formatCurrency)
Vue.filter('formatCurrencyNoZero', formatCurrencyNoZero)
Vue.filter('formatBytes', formatBytes)
Vue.filter('formatKbToMb', formatKbToMb)
Vue.filter('calculateBytes', calculateBytes)
Vue.filter('zeroToDashValue', zeroToDashValue)
Vue.filter('convertMsToTime', convertMsToTime)
