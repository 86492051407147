







































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'

@Component({
    name: 'VsMediaLibraryImage',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) url?: string

    @Prop({
        required: false,
        default: '',
    }) mime!: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) rounded!: boolean

    private loading = false

    $refs: any
    $options: any

    get isImage () {
        return this.mime.includes('image/')
    }

    get isVideo () {
        return this.mime.includes('video/')
    }

    private publicUrlNoCache (publicUrl: string) {
        if (/\?.+=.*/.test(publicUrl)) {
            publicUrl = publicUrl + '&nocache=true'
        } else {
            publicUrl = publicUrl + '?nocache=true'
        }
        return publicUrl
    }

    private getImageSize () {
        if (!this.$refs.image) return
        const img = this.$refs.image
        return `${this.$options.filters.formatNumber(img.naturalWidth)} x ${this.$options.filters.formatNumber(img.naturalHeight)}`
    }

    // @Watch('url', { immediate: false, deep: true })
    private imageOnload () {
        if (this.$refs.image) {
            this.loading = true
            this.$refs.image.onload = () => {
                this.loading = false
                this.$emit('image-size', this.getImageSize())
            }

            this.$refs.image.onerror = () => {
                this.$emit('image-size', '0 x 0')
                this.loading = false
            }
        }
    }

    mounted () {
        this.imageOnload()
    }
}
