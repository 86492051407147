





















































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsActionCard from '@/components/VsActionCard/Index.vue'

export interface ILibraryItem {
    id: string
    user_id: string
    directory_id: string
    name: string
    deleted_at: string | null
}

export enum libraryItemType {
    default = 'default',
    trash = 'trash',
}

@Component({
    name: 'VsMediaLibraryDirectoryCard',
    components: {
        VsDropdownButton,
        VsActionCard,
    },
})
export default class extends Vue {
    @Prop({
        required: true,
        default: () => {
            return {
                id: '',
                user_id: '',
                directory_id: '',
                name: '',
                deleted_at: '',
            }
        },
    }) private libraryItem!: ILibraryItem

    private menuIsOpen = false
    private focused = false

    get cardTitleClasses () {
        return {
            'vs-media-library-card-text': true,
            'vs-body-large': true,
            'vs-text-grey-800': true,
            'group-hover/item:vs-text-grey-900': true,
            'vs-truncate': true,
        }
    }

    private closeMenu () {
        this.$emit('menu-close')
    }

    private deleteDirectory (id: string) {
        this.$emit('delete-directory', id)
    }

    private moveToTrashDirectory (id: string) {
        this.$emit('move-to-trash-directory', id)
    }

    private renameDirectory (id: string) {
        this.$emit('rename-directory', id)
    }

    private recoveryDirectory (id: string) {
        this.$emit('recovery-directory', id)
    }
}
