













































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsDrawer from './../VsDrawer/Index.vue'
import VsMediaLibraryImage from './../VsMediaLibraryImage/Index.vue'
import {
    getFileAnchestors,
    getFileById,
    renameFile,
} from '@/api/filemanager/files'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'

@Component({
    name: 'VsMediaLibraryDetail',
    components: {
        VsDrawer,
        VsMediaLibraryImage,
    },
})

export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) value?: string

    @Prop({
        required: false,
        default: null,
    }) detailItem?: any

    private libraryItem: any = null

    private filePaths: any[] = []

    $refs: any
    private title = ''
    private editTitle = false
    private imageSize = ''

    get isImage () {
        return this.libraryItem.mime.includes('image/')
    }

    get rootFolder () {
        return MediaLibraryModule.rootFolder
    }

    private goTopath (path: any) {
        this.$emit('path-selected', path)
        this.closeDrawer()
    }

    private async renameFile () {
        if (!this.libraryItem) return
        const re = /(?:\.([^.]+))?$/
        const ext = re.exec(this.libraryItem.name)
        let name = this.title
        if (ext && ext[1]) {
            name = name + '.' + ext[1]
        }

        try {
            await renameFile(this.libraryItem.id, {
                name,
            })
            await this.getFileById(this.libraryItem.id)
            this.$emit('rename-file', this.libraryItem)
        } catch (e) {
            console.log(e)
        }
        this.editTitle = false
    }

    private openEditTitle (name: string) {
        this.title = name
        this.editTitle = true
    }

    private closeDrawer () {
        this.editTitle = false
        this.$emit('input', null)
    }

    private setImageSize (imageSize: string) {
        this.imageSize = imageSize
    }

    @Watch('detailItem', { immediate: true, deep: true })
    private async getData (detailItem: any) {
        if (!detailItem || !detailItem.id) return
        this.libraryItem = null
        this.filePaths = []
        this.libraryItem = {
            ...detailItem,
        }
        // this.getFileById(detailItem.id)
        this.getFileAnchestors(detailItem.id)
    }

    private async getFileAnchestors (id: any) {
        try {
            const resp = await getFileAnchestors(id)
            this.filePaths = []
            this.filePaths.push(this.rootFolder, ...resp.data.map((el: any) => {
                return {
                    name: el.name,
                    id: el.directory_id,
                }
            }))
        } catch (e) {
            console.log(e)
            this.filePaths = []
        }
    }

    private async getFileById (id: any) {
        try {
            const resp = await getFileById(id)
            this.libraryItem = resp.data
        } catch (e) {
            console.log(e)
            this.filePaths = []
        }
    }
}
