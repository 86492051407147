










import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import VsSidebar from './vs-sidebar/Index.vue'
import VsSidebarManager from './vs-sidebar-manager/Index.vue'
import VsFull from './vs-full/Index.vue'
import VsAuth from './vs-auth/Index.vue'
import VsBlockedFunctionalityModal from '@/components/VsBlockedFunctionalityModal/Index.vue'
import VsSupportEmail from '@/components/VsSupportEmail/Index.vue'
import beacon from '@/utils/beacon'
import { LayoutsEnum } from './types'
import { get } from 'lodash'
import { pSBC } from '@/utils/colors'

@Component({
    name: 'VsLayouts',
    components: {
        VsSidebar,
        VsSidebarManager,
        VsFull,
        VsAuth,
        VsBlockedFunctionalityModal,
        VsSupportEmail,
    },
})
export default class extends Vue {
    $refs: any
    get layoutName () {
        return this.$route?.meta?.layout || LayoutsEnum.vsSidebar
    }

    get user () {
        return UserModule.user
    }

    get supportRule () {
        if (!this.user) return false
        return this.user.configuration.rules.support
    }

    get beaconConf () {
        return UserModule.settings?.beacon
    }

    get primaryColor () {
        const appColor = get(AppModule, 'consoleConf.theme.colorConsole', null)
        return get(UserModule, 'settings.theme.colorConsole', appColor)
    }

    get darkerPrimaryColor () {
        if (!this.primaryColor) return ''
        return pSBC(-0.2, this.primaryColor)
    }

    get layoutCssVars () {
        if (this.primaryColor) {
            return `
                :root {
                    --vs-color-primary-800: ${this.primaryColor};
                    --vs-color-primary-900: ${this.darkerPrimaryColor};
                }
            `
        }
        return ``
    }

    async mounted () {
        const loader = document.getElementById('first-loader')
        if (loader) loader.style.display = 'none'
        this.$root.$vsOpenUserSupport = this.openUserSupport
    }

    destroy () {
        const selector = document.querySelector('.openSupport')
        if (selector) {
            selector.removeEventListener('click', this.beaconClickFuncEvent)
        }
    }

    @Watch('beaconConf', { immediate: true, deep: true })
    private loadBeacon (val: any) {
        if (!val) return
        beacon.init(val)
        this.$nextTick(() => {
            const selector = document.querySelector('.openSupport')
            if (selector) {
                selector.addEventListener('click', this.beaconClickFuncEvent)
            }
        })
    }

    private beaconClickFuncEvent () {
        this.beaconClickFunc()
    }

    private beaconClickFunc (navigateTicket?: boolean) {
        if (!window.Beacon) return
        window.Beacon('open')
        if (navigateTicket) {
            window.Beacon('navigate', '/ask/')
        }
        if (!this.$gtm) {
            return
        }

        this.$gtm.trackEvent({
            event: 'event',
            Signup_Category: 'mailchef',
            Signup_Action: 'LinkClick',
            Signup_Label: 'support',
        })
    }

    private toastReady (el: any) {
        this.$root.$vsToast = el.addToast
    }

    private blockedFunctionalityModalReady (openModal: any) {
        this.$root.$vsOpenBlockedModal = openModal
    }

    private openUserSupport (navigateTicket?: boolean) {
        if (!this.supportRule) {
            this.$refs.rootBlockedFunctionality.openModal()
        } else if (this.supportRule === 'email') {
            this.$refs.vsSupportEmail.openModal()
        } else if (this.supportRule === 'helpscout' || this.supportRule === 'helpscout_chat') {
            this.beaconClickFunc(navigateTicket)
        } else {
            this.$refs.rootBlockedFunctionality.openModal()
        }
    }

    private openSupportModal () {
        this.$refs.vsSupportEmail.openModal()
    }
}
