

































































import { Component, Vue } from 'vue-property-decorator'
import { agencyOpenTicket } from '@/api/console'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsSupportEmail',
})
export default class extends Vue {
    private show = false
    private subject = ''
    private message = ''
    private loading = false
    $refs: any

    public openModal () {
        this.subject = ''
        this.message = ''
        this.show = true
    }

    private async createTag () {
        const valid = await this.$refs.formData.validate()
        if (!valid) return
        this.loading = true
        try {
            const resp = await agencyOpenTicket({
                subject: this.subject,
                message: this.message,
            })
            if (resp.data.type === 'success') {
                this.$root.$vsToast({
                    heading: resp.data.message,
                    aspect: VsToastAspectEnum.success,
                    timeout: 3000,
                })
            } else {
                this.$root.$vsToast({
                    heading: resp.data.message,
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
            }
            this.show = false
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'invio del messaggio',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }
}
