



















































import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'EditNameModal',
})
export default class extends Vue {
    private id = ''
    private name = ''
    private saveButton = ''
    private open = false
    public loading = false
    resolve: any = null
    reject: any = null
    $refs: any

    public async save () {
        const valid = await this.$refs.nameForm.validate()
        if (!valid) return
        this.$emit('updated', {
            id: this.id,
            name: this.name,
        })
        this.closeModal()
    }

    public openModal (name: string, id?: any) {
        this.loading = false
        this.name = name
        this.id = id || ''
        this.open = true
        setTimeout(() => {
            this.$refs.nameInput.$children[0].$refs.input.focus()
        }, 200)
    }

    public closeModal () {
        this.open = false
    }
}
