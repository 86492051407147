














































































































































































































































import { UserModule } from '@/store/modules/user'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import { AppModule } from '@/store/modules/app'
import { get } from 'lodash'
import VsRouterLink from '@/components/VsRouterLink/Index.vue'
import { pSBC } from '@/utils/colors'
import { getChilds } from '@/api/userapi/manager/users'
import { canIncludesInArray } from '@/utils/rules'
import VsMediaLibrary from '@/modules/mediaLibrary/views/Index.vue'

@Component({
    name: 'VsSideBar',
    components: {
        VsSeparator,
        VsRouterLink,
        VsMediaLibrary,
    },
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    })
    private currentPage?: string

    @Prop({
        required: false,
        default: false,
    })
    private externalLibrary?: boolean

    private currentNav = ''

    private humanReputation = ''

    private audience = false
    private creativity = false
    private options = false
    private loading = false

    users: any[] = []
    total = 0
    pagination = {
        page: 1,
        itemsPerPage: 15,
        sortBy: null,
    }

    private filters: any = {
        status: null,
        search: null,
        sortBy: null,
    }

    $refs: any

    get managerRules () {
        return this.user.configuration.rules.managerRules
    }

    get hasManagerShop () {
        return canIncludesInArray(this.managerRules.webAppPermissions, 'shop')
    }

    get hasManagerTemplateEditor () {
        return this.managerRules.templatesEditor
    }

    get hasManagerDocumentations () {
        return canIncludesInArray(this.managerRules.webAppPermissions, 'documentations')
    }

    get sidebarOpen () {
        return get(AppModule, 'sidebarOpen', false)
    }

    set sidebarOpen (sidebarOpen: boolean) {
        AppModule.SET_SIDEBAR_OPEN(sidebarOpen)
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get logo () {
        return get(UserModule, 'settings.theme.logoConsole', '')
    }

    get user () {
        return UserModule.user
    }

    get username () {
        return UserModule.userCompleteName(true)
    }

    get primaryColor () {
        return get(UserModule, 'settings.theme.colorConsole', null)
    }

    get darkerPrimaryColor () {
        if (!this.primaryColor) return ''
        return pSBC(-0.2, this.primaryColor)
    }

    get configurationName () {
        return get(this.user, 'configuration.name', '')
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get layoutCssVars () {
        // da cancellare quando non ci sarà più bisogno di buildare la sidebar
        if (this.primaryColor) {
            return `
                :root {
                    --vs-color-primary-800: ${this.primaryColor};
                    --vs-color-primary-900: ${this.darkerPrimaryColor};
                }
            `
        }
        return ``
    }

    @Watch('audience', { immediate: false })
    @Watch('creativity', { immediate: false })
    @Watch('options', { immediate: false })
    saveSessionStorage () {
        window.sessionStorage.setItem('admin-sidebar-navigation', JSON.stringify({
            audience: this.audience,
            creativity: this.creativity,
            options: this.options,
        }))
    }

    beforeMount () {
        this.getOpenNavigationFromSessionStorage()
    }

    private getOpenNavigationFromSessionStorage () {
        let navigations: any = window.sessionStorage.getItem('admin-sidebar-navigation')
        if (!navigations) return
        navigations = JSON.parse(navigations)
        this.audience = navigations.audience
        this.creativity = navigations.creativity
        this.options = navigations.options
    }

    private formatLinkParams (path: string, isBlocked?: boolean) {
        if (isBlocked) return {}
        if (!this.$router) {
            return {
                url: '/user' + path,
            }
        }
        return {
            to: {
                path,
            },
        }
    }

    private openNav (navName: string) {
        if (this.currentNav === navName) {
            this.currentNav = ''
            return
        }
        this.currentNav = navName
    }

    @Watch('filters.search', { immediate: false })
    async getUsers () {
        this.loading = true
        try {
            const resp = await getChilds(this.generateQuery())
            this.users = resp.data.docs
            this.total = resp.data.count
        } catch (e) {
            console.log(e)
            this.total = 0
            this.users = []
        }
        this.loading = false
    }

    private generateQuery () {
        const sortBy = this.pagination.sortBy || '-|createdAt'
        const sortSplit = sortBy.split('|')
        const query: any = {
            limit: this.pagination.itemsPerPage,
            skip: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            populate: 'project',
            sort: `${sortSplit[0]}${sortSplit[1]}`,
        }

        const filter: any = {
            $and: [],
        }

        if (this.filters.search) {
            const checkForHexRegExp = /^[a-f\d]{24}$/i
            const $or: any[] = [
                { email: { $regex: this.filters.search, $options: 'i' } },
                { firstname: { $regex: this.filters.search, $options: 'i' } },
                { lastname: { $regex: this.filters.search, $options: 'i' } },
                { company: { $regex: this.filters.search, $options: 'i' } },
            ]
            if (checkForHexRegExp.test(this.filters.search)) {
                $or.push({ _id: this.filters.search })
            }
            filter.$and.push({
                $or,
            })
        }

        if (this.filters.status) {
            filter.$and.push({
                status: this.filters.status,
            })
        }

        if (filter.$and.length > 0) {
            query.filter = JSON.stringify(filter)
        }
        return query
    }
}
