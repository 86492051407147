
















































import { Component, Vue } from 'vue-property-decorator'
import { get } from 'lodash'
import {
    createAddressbooks,
} from '@/api/consoleApi/addressbooks'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

export interface IPreviewContact {
    email_address: string
}

@Component({
    name: 'VsAddOrEditAddressbookModal',
})
export default class extends Vue {
    private show = false
    private loading = false
    private contactId = ''
    $refs!: any

    // to delete when send preview of console would be removed
    get toastFunc () {
        return this.$root.$vsToast ? this.$root.$vsToast : this.$refs?.vsToast.addToast || this.emptyFunc
    }

    // to delete when send preview of console would be removed
    emptyFunc () {
        return ''
    }

    private contact: IPreviewContact = {
        email_address: '',
    }

    public async openModal (data: any) {
        this.show = true
        if (data?.contactId) {
            // await this.getAddressbooks()
        } else {
            this.contact.email_address = ''
        }
    }

    private async createAddressbooks () {
        const valid = await this.$refs.formData.validate()
        if (!valid) return
        this.loading = true
        try {
            await createAddressbooks({
                email_address: this.contact.email_address,
            })

            this.toastFunc({
                heading: 'Contatto salvato fra i preriti',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('addressbook-created')
            this.show = false
        } catch (e) {
            let message = ''
            const serverMsg = get(e, 'response.data.message.email_address', '')
            const errorCode = get(e, 'response.status', '')
            console.log(errorCode)
            if (serverMsg) message = serverMsg[0]
            if (message === 'The email address has already been taken.') {
                message = 'Esiste già questo indirizzo email fra i contatti preferiti salvati'
                this.$refs.formData.setErrors({
                    email: ['Esiste già questo indirizzo email fra i contatti preferiti salvati'],
                })
            }

            if (errorCode === 403) {
                message = 'Hai raggiunto il massimo di contatti salvabili'
            }

            this.toastFunc({
                heading: 'Errore durante il salvataggio del contatto preferito',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }
}
