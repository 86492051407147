import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'

export interface IUserInfo {
    files: {
        notTrashedCount: number
        notTrashedSize: string
        trashedCount: number
        trashedSize: string
    }
    user: {
        id: string
        rules: {
            limit: {
                unit: string
                value: number
            }
        }
    }
}
export interface IMediaLibraryState {
    showMediaLibrary: boolean
    url: string
    currentPage: string
    mode: string
    allowedTypes: string
    libraryItems: any[]
    checkedFileIds: string[]
    searchInput: string
    currentDirectory: any
    uploadAcceptedFiles: string[]
    modalPromiseResolve: any
    modalPromiseReject: any
    rootFolder: any
    userInfo: IUserInfo
    importUrl: string
}

@Module({ dynamic: true, store, name: 'mediaLibrary', namespaced: true })
class MediaLibrary extends VuexModule implements IMediaLibraryState {
    public modalPromiseResolve: any = null
    public modalPromiseReject: any = null
    public showMediaLibrary = false
    public url = ''
    public currentPage = 'home' // home | trash
    public mode = 'standard'
    public allowedTypes = ''
    public libraryItems: any[] = []
    public checkedFileIds: string[] = []
    public searchInput = ''
    public importUrl = ''
    public userInfo = {
        files: {
            notTrashedCount: 0,
            notTrashedSize: '0',
            trashedCount: 0,
            trashedSize: '0',
        },
        user: {
            id: '',
            rules: {
                limit: {
                    unit: 'MB',
                    value: 0,
                },
            },
        },
    }

    public rootFolder: any = {
        id: 0,
        name: 'Tutti i files',
    }

    public currentDirectory: any = {
        id: 0,
        name: 'Tutti i files',
    }

    public uploadAcceptedFiles = [
        'image/jpeg',
        'text/plain',
        'image/gif',
        'image/png',
        'application/pdf',
        'application/zip',
        'image/x-icon',
        'application/msword',
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        'application/x-rar-compressed',
        'application/x-rar',
        'application/octet-stream',
        'application/x-7z-compressed',
        'image/webp',
        'text/ico',
        'image/ico',
        'image/icon',
        'application/ico',
        'image/vnd.microsoft.icon',
        'application/epub+zip',
        'application/x-mobipocket-ebook',
        'application/octet-stream',
        'image/svg+xml',
    ]

    @Mutation
    public SET_SHOW_MEDIALIBRARY (showMediaLibrary: boolean) {
        this.showMediaLibrary = showMediaLibrary
    }

    @Mutation
    public SET_CURRENT_PAGE (currentPage: string) {
        this.currentPage = currentPage
    }

    @Mutation
    public SET_LIBRARY_ITEMS (libraryItems: any[]) {
        this.libraryItems = libraryItems
    }

    @Mutation
    public SET_CHECKED_FILE_IDS (checkedFileIds: string[]) {
        this.checkedFileIds = checkedFileIds
    }

    @Mutation
    public SET_URL (url: string) {
        this.url = url
    }

    @Mutation
    public SET_MODE (mode: string) {
        this.mode = mode
    }

    @Mutation
    public SET_ALLOWED_TYPES (allowedTypes: string) {
        this.allowedTypes = allowedTypes
    }

    @Mutation
    public SET_MODAL_PROMISE_RESOLVE (modalPromiseResolve: any) {
        this.modalPromiseResolve = modalPromiseResolve
    }

    @Mutation
    public SET_MODAL_PROMISE_REJECT (modalPromiseReject: any) {
        this.modalPromiseReject = modalPromiseReject
    }

    @Mutation
    public SET_CURRENT_DIRECTORY (currentDirectory: any) {
        this.currentDirectory = currentDirectory
    }

    @Mutation
    public SET_SEARCH_INPUT (searchInput: any) {
        this.searchInput = searchInput
    }

    @Mutation
    public SET_USER_INFO (userInfo: IUserInfo) {
        this.userInfo = userInfo
    }

    @Mutation
    public SET_IMPORT_URL (importUrl: string) {
        this.importUrl = importUrl
    }
}

export const MediaLibraryModule = getModule(MediaLibrary)
