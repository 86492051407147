var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{attrs:{"closable":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Crea il tuo form ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"size":"large","aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"size":"large","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"formForm"},[_c('ValidationProvider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsInput',{ref:"nameInput",staticClass:"vs-mb-6",attrs:{"label":'Dai un nome al tuo form',"size":"large","error":errors.length > 0,"errorMessage":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Lista","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsInputSearch',{ref:"nameInput",staticClass:"vs-mb-6",attrs:{"label":'Seleziona una lista a cui associarlo',"size":"large","caption":"Scegli con attenzione la lista in cui saranno raccolti e salvati i contatti di questo form perché in futuro non potrà essere modificata","error":errors.length > 0,"errorMessage":errors[0],"placeholder":"Cerca...","boundary":"viewport","options":_vm.lists.map(function (el) {
                    return {
                        value: el.id,
                        label: el.name,
                    }
                }),"formatBadgeLabel":_vm.formatBadgeLabel,"debounce":500,"value":_vm.listId},on:{"search":_vm.getLists,"input":_vm.selectList}})]}}])}),_c('ValidationProvider',{attrs:{"name":"URL","rules":{ regex: /^(?!:\/\/)(?:[a-zA-Z0-9-]+\.){1,}(?:[a-zA-Z]{2,})$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-6",attrs:{"value":_vm.url,"label":"Aggiungi il dominio del tuo sito web (opzionale)","size":"large","caption":"Copia il link della pagina in cui dovrà apparire il form, il sistema preleva in automatico il dominio.","error":errors.length > 0,"errorMessage":errors[0]},on:{"input":_vm.parseHostName}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }