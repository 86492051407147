












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { search } from '@/api/console'

@Component({
    name: 'VsMainConsoleSearch',
})

export default class extends Vue {
    @Prop({ default: false, required: false, type: Boolean }) openAtMount!: boolean
    private searchText = ''
    private searchResult: any = null
    private loading = false
    $refs: any

    mounted () {
        this.$root.$vsTopnavSearc = this.topnavSearc
    }

    private async search () {
        this.openSearch()
        if (this.searchText.length < 3) {
            this.searchResult = null
            return
        }
        try {
            this.loading = true
            const response = await search({
                keyword: this.searchText,
            })
            this.searchResult = response.data
        } catch (e) {
            console.log(e)
            this.searchResult = null
        }
        this.loading = false
    }

    openSearch () {
        this.$refs.searchPopover.showPopover()
    }

    public topnavSearc (text: string) {
        this.searchText = text
        this.search()
    }
}
