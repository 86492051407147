import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'

const emailEditorRouter: RouteConfig[] = [
    {
        path: '/emailtemplates',
        redirect: { name: 'emailEditorIndex' },
        name: 'emailEditor',
        component: VsLayouts,
        children: [
            {
                path: '/',
                name: 'emailEditorIndex',
                component: () => import(/* webpackChunkName: "emailEditor" */ './../views/index.vue'),
                meta: {
                    hidden: true,
                },
            },
            {
                path: ':templateId/edit',
                name: 'templateEdit',
                component: () => import(/* webpackChunkName: "template-edit" */ '@/modules/emailEditor/views/id/edit.vue'),
            },
        ],
    },
    {
        path: '/footers',
        redirect: { name: 'footersIndex' },
        name: 'footers',
        component: VsLayouts,
        children: [
            {
                path: '/',
                name: 'footersIndex',
                component: () => import(/* webpackChunkName: "footers" */ './../views/footers.vue'),
                meta: {
                    hidden: true,
                },
            },
        ],
    },
]

export default emailEditorRouter
