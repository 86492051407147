import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'

enum listsRouteNames {
    lists = 'lists',
    listsIndex = 'listsIndex',
    listCreate = 'listCreate',
    createContact = 'createContact',
    listIdIndex = 'listIdIndex',
    listEdit = 'listEdit',
    listCustomFields = 'listCustomFields',
    listImportIndex = 'listImportIndex',
    listImportSelectMethod = 'listImportSelectMethod',
    listImportFromFile = 'listImportFromFile',
    listImportFromUrl = 'listImportFromUrl',
    listImportFromDb = 'listImportFromDb',
    listImportCopyPaste = 'listImportCopyPaste',
    listStatistics = 'listStatistics',
    listOblivion = 'listOblivion',
    createSyncIndex = 'createSyncIndex',
    createSyncSelectMethod = 'createSyncSelectMethod',
    createSyncFromUrl = 'createSyncFromUrl',
    createSyncFromDb = 'createSyncFromDb',
    importHistory = 'importHistory',
    importDetails = 'importDetails',
    listSync = 'listSync',
    tags = 'tags',
    contacts = 'contacts',
    suppressionListIndex = 'suppressionListIndex',
    suppressionList = 'suppressionList',
    suppressionStatistics = 'suppressionStatistics',
    suppressionImportHistory = 'suppressionImportHistory',
    suppressionImportDetails = 'suppressionImportDetails',
    listImportIndexSuppression = 'listImportIndexSuppression',
    listImportSelectMethodSuppression = 'listImportSelectMethodSuppression',
    listImportFromFileSuppression = 'listImportFromFileSuppression',
    listImportFromUrlSuppression = 'listImportFromUrlSuppression',
    listImportCopyPasteSuppression = 'listImportCopyPasteSuppression',
    listImportFromDbSuppression = 'listImportFromDbSuppression',
    contactIndex = 'contactIndex',
    contactDetails = 'contactDetails',
    contactDeliverability = 'contactDeliverability',
    contactInterests = 'contactInterests',
    contactActivities = 'contactActivities',
    contactTags = 'contactTags',
    contactEplus = 'contactEplus',
}

const listsRouter: RouteConfig[] = [
    {
        path: '/lists',
        redirect: { name: listsRouteNames.listsIndex },
        name: listsRouteNames.lists,
        component: VsLayouts,
        children: [
            {
                path: '/',
                name: listsRouteNames.listsIndex,
                component: () => import(/* webpackChunkName: "lists" */ '../views/index.vue'),
                meta: {
                    hidden: true,
                },
            },
            {
                path: 'create',
                name: listsRouteNames.listCreate,
                component: () => import(/* webpackChunkName: "list-create" */ '../views/create.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                    hidden: true,
                },
            },
            {
                path: 'oblivion',
                name: listsRouteNames.listOblivion,
                component: () => import(/* webpackChunkName: "list-oblivion" */ '../views/oblivion/index.vue'),
            },
            {
                path: 'suppression',
                name: listsRouteNames.suppressionListIndex,
                redirect: {
                    name: listsRouteNames.suppressionList,
                },
                component: () => import(/* webpackChunkName: "suppression-list-index" */ '@/modules/lists/views/suppression/index.vue'),
                meta: {
                    hidden: true,
                },
                children: [
                    {
                        path: '',
                        name: listsRouteNames.suppressionList,
                        component: () => import(/* webpackChunkName: "suppression-list" */ '@/modules/lists/views/suppression/suppression-list.vue'),
                    },
                    {
                        path: 'statistics',
                        name: listsRouteNames.suppressionStatistics,
                        component: () => import(/* webpackChunkName: "suppression-statistics" */ '@/modules/lists/views/suppression/statistics.vue'),
                    },
                    {
                        path: 'import-history',
                        name: listsRouteNames.suppressionImportHistory,
                        component: () => import(/* webpackChunkName: "suppression-import-history" */ '@/modules/lists/views/suppression/import-history.vue'),
                    },
                    {
                        path: 'import-history/:importId/details',
                        name: listsRouteNames.suppressionImportDetails,
                        component: () => import(/* webpackChunkName: "suppression-import-history" */ '@/modules/lists/views/suppression/import-history.vue'),
                    },
                ],
            },
            {
                path: 'contacts/import-suppression',
                name: listsRouteNames.listImportIndexSuppression,
                component: () => import(/* webpackChunkName: "contacts-import-index" */ '@/modules/lists/views/id/contacts/import/index.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
                redirect: {
                    name: listsRouteNames.listImportSelectMethodSuppression,
                },
                children: [
                    {
                        path: '',
                        name: listsRouteNames.listImportSelectMethodSuppression,
                        component: () => import(/* webpackChunkName: "contacts-import-suppression-select-method" */ '@/modules/lists/views/id/contacts/import/select-method.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromfile',
                        name: listsRouteNames.listImportFromFileSuppression,
                        component: () => import(/* webpackChunkName: "contacts-import-suppression-from-file" */ '@/modules/lists/views/id/contacts/import/import-from-file.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromurl',
                        name: listsRouteNames.listImportFromUrlSuppression,
                        component: () => import(/* webpackChunkName: "contacts-import-suppression-from-url" */ '@/modules/lists/views/id/contacts/import/import-from-url.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromdb',
                        name: listsRouteNames.listImportFromDbSuppression,
                        component: () => import(/* webpackChunkName: "contacts-import-suppression-from-db" */ '@/modules/lists/views/id/contacts/import/import-from-db.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'copy-paste',
                        name: listsRouteNames.listImportCopyPasteSuppression,
                        component: () => import(/* webpackChunkName: "contacts-import-suppression-copy-paste" */ '@/modules/lists/views/id/contacts/import/import-copy-paste.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                ],
            },
            {
                path: ':listId/contacts/add',
                name: listsRouteNames.createContact,
                component: () => import(/* webpackChunkName: "create-contact" */ '../views/id/contacts/CreateContact.vue'),
                meta: {
                    hidden: true,
                    title: 'Aggiungi contatto',
                },
            },
            {
                path: ':listId',
                name: listsRouteNames.listIdIndex,
                redirect: {
                    name: listsRouteNames.listEdit,
                },
                component: () => import(/* webpackChunkName: "lists-id-index" */ '@/modules/lists/views/id/index.vue'),
                meta: {
                    hidden: true,
                },
                children: [
                    {
                        path: 'edit',
                        name: listsRouteNames.listEdit,
                        component: () => import(/* webpackChunkName: "lists-edit" */ '@/modules/lists/views/id/edit.vue'),
                    },
                    {
                        path: 'custom-fields',
                        name: listsRouteNames.listCustomFields,
                        component: () => import(/* webpackChunkName: "lists-custom-fields" */ '@/modules/lists/views/id/custom-fields.vue'),
                    },
                    {
                        path: 'import-history',
                        name: listsRouteNames.importHistory,
                        component: () => import(/* webpackChunkName: "lists-import-history" */ '@/modules/lists/views/id/import-history.vue'),
                    },
                    {
                        path: 'import-history/:importId/details',
                        name: listsRouteNames.importDetails,
                        component: () => import(/* webpackChunkName: "lists-import-history" */ '@/modules/lists/views/id/import-history.vue'),
                    },
                    {
                        path: 'sync',
                        name: listsRouteNames.listSync,
                        component: () => import(/* webpackChunkName: "lists-sync" */ '@/modules/lists/views/id/sync.vue'),
                    },
                    {
                        path: 'contacts',
                        name: listsRouteNames.contacts,
                        component: () => import(/* webpackChunkName: "contacts-list" */ '@/modules/lists/views/id/contactsList.vue'),
                    },
                    {
                        path: 'tags',
                        name: listsRouteNames.tags,
                        component: () => import(/* webpackChunkName: "lists-tags" */ '@/modules/lists/views/id/tags.vue'),
                    },
                    {
                        path: 'statistics',
                        name: listsRouteNames.listStatistics,
                        component: () => import(/* webpackChunkName: "list-statistics" */ '@/modules/lists/views/id/statistics.vue'),
                        meta: {
                            title: 'Statistiche lista',
                        },
                    },
                ],
            },
            {
                path: ':listId/contacts/import',
                name: listsRouteNames.listImportIndex,
                component: () => import(/* webpackChunkName: "contacts-import-index" */ '../../lists/views/id/contacts/import/index.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
                redirect: {
                    name: listsRouteNames.listImportSelectMethod,
                },
                children: [
                    {
                        path: '',
                        name: listsRouteNames.listImportSelectMethod,
                        component: () => import(/* webpackChunkName: "contacts-import-select-method" */ '../../lists/views/id/contacts/import/select-method.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromfile',
                        name: listsRouteNames.listImportFromFile,
                        component: () => import(/* webpackChunkName: "contacts-import-from-file" */ '../../lists/views/id/contacts/import/import-from-file.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromurl',
                        name: listsRouteNames.listImportFromUrl,
                        component: () => import(/* webpackChunkName: "contacts-import-from-url" */ '../../lists/views/id/contacts/import/import-from-url.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromdb',
                        name: listsRouteNames.listImportFromDb,
                        component: () => import(/* webpackChunkName: "contacts-import-from-db" */ '../../lists/views/id/contacts/import/import-from-db.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'copy-paste',
                        name: listsRouteNames.listImportCopyPaste,
                        component: () => import(/* webpackChunkName: "contacts-import-copy-paste" */ '../../lists/views/id/contacts/import/import-copy-paste.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                ],
            },
            {
                path: ':listId/contacts/:contactId',
                name: listsRouteNames.contactIndex,
                component: () => import(/* webpackChunkName: "contact-index" */ '../../lists/views/id/contacts/id/index.vue'),
                redirect: {
                    name: listsRouteNames.contactDetails,
                },
                children: [
                    {
                        path: '',
                        name: listsRouteNames.contactDetails,
                        component: () => import(/* webpackChunkName: "contact-details" */ '../../lists/views/id/contacts/id/details.vue'),
                    },
                    {
                        path: 'deliverability',
                        name: listsRouteNames.contactDeliverability,
                        component: () => import(/* webpackChunkName: "contact-deliverability" */ '../../lists/views/id/contacts/id/deliverability.vue'),
                    },
                    {
                        path: 'interests',
                        name: listsRouteNames.contactInterests,
                        component: () => import(/* webpackChunkName: "contact-interests" */ '../../lists/views/id/contacts/id/interests.vue'),
                    },
                    {
                        path: 'activities',
                        name: listsRouteNames.contactActivities,
                        component: () => import(/* webpackChunkName: "contact-activities" */ '../../lists/views/id/contacts/id/activities.vue'),
                    },
                    {
                        path: 'eplus',
                        name: listsRouteNames.contactEplus,
                        component: () => import(/* webpackChunkName: "contact-eplus" */ '../../lists/views/id/contacts/id/eplus.vue'),
                    },
                    {
                        path: 'tags',
                        name: listsRouteNames.contactTags,
                        component: () => import(/* webpackChunkName: "contact-tags" */ '@/modules/lists/views/id/contacts/id/tags.vue'),
                    },
                ],
            },
            {
                path: ':listId/sync/create',
                name: listsRouteNames.createSyncIndex,
                component: () => import(/* webpackChunkName: "create-sync-index" */ '../../lists/views/id/contacts/import/index.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                },
                redirect: {
                    name: listsRouteNames.createSyncSelectMethod,
                },
                children: [
                    {
                        path: '',
                        name: listsRouteNames.createSyncSelectMethod,
                        component: () => import(/* webpackChunkName: "create-sync-select-method" */ '../../lists/views/id/contacts/import/select-method.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromurl',
                        name: listsRouteNames.createSyncFromUrl,
                        component: () => import(/* webpackChunkName: "create-sync-from-url" */ '../../lists/views/id/contacts/import/import-from-url.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                    {
                        path: 'fromdb',
                        name: listsRouteNames.createSyncFromDb,
                        component: () => import(/* webpackChunkName: "create-sync-from-db" */ '../../lists/views/id/contacts/import/import-from-db.vue'),
                        meta: {
                            layout: LayoutsEnum.vsFull,
                        },
                    },
                ],
            },

        ],
    },
]

export default listsRouter
