import VsLayouts from '@/layouts/Index.vue'

export enum sendersRoutesNames {
    senders = 'senders',
    sendersIndex = 'sendersIndex',
    sendersSms = 'sendersSms',
    sendersEmail = 'sendersEmail',
    goalTrackingSenders = 'goalTrackingSenders',
}

const sendersRouter = [
    {
        path: '/senders',
        name: sendersRoutesNames.senders,
        component: VsLayouts,
        redirect: {
            name: sendersRoutesNames.sendersIndex,
        },
        children: [
            {
                path: '',
                name: sendersRoutesNames.sendersIndex,
                redirect: {
                    name: sendersRoutesNames.sendersEmail,
                },
                component: () => import(/* webpackChunkName: "senders-index" */ '../views/index.vue'),
                children: [
                    {
                        path: 'sms',
                        name: sendersRoutesNames.sendersSms,
                        component: () => import(/* webpackChunkName: "senders-sms" */ '../views/senders-sms.vue'),
                    },
                    {
                        path: 'email',
                        name: sendersRoutesNames.sendersEmail,
                        component: () => import(/* webpackChunkName: "senders-email" */ '../views/senders-email.vue'),
                    },
                ],
            },
        ],
    },
]

export default sendersRouter
