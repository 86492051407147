import { serviceConsoleApi } from '@/utils/request'
import { CreateEmailMappedLinkTagsDto } from '../dto/behavioralTags.dto'
import { AxiosRequestConfig } from 'axios'
const basePath = '/contents/emails'

export const getEmails = (signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}`,
        {
            // signal,
        },
    )

export const getEmail = (emailId: number | string, params?: any, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}/${emailId}`,
        {
            params,
            // signal,
        },
    )

export const createEmail = (data?: any, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}`,
        data,
        {
            // signal,
        },
    )

export const updateEmail = (emailId: number, data?: any, signal?: AbortSignal) =>
    serviceConsoleApi.put(
        `${basePath}/${emailId}`,
        data,
        {
            // signal,
        },
    )

export const deleteEmail = (emailId: number | string, data?: any, signal?: AbortSignal) =>
    serviceConsoleApi.delete(
        `${basePath}/${emailId}`,
        {
            // signal,
        },
    )

export const setEmailFromFile = (emailId: number, data: any, options?: AxiosRequestConfig, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/from-file`,
        data,
        {
            ...options,
            // signal,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    )

export const setEmailFromUrl = (emailId: number, data: { html_url: string, plain_url?: string}, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/from-url`,
        data,
        {
            // signal,
        },
    )

export const setEmailFromTemplate = (emailId: number, data: { template_id?: string | null }, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/from-template`,
        data,
        {
            // signal,
        },
    )

export const getEmailPreview = (emailId: number, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}/${emailId}/preview`,
        {
            // signal,
        },
    )

export const sendEmailPreview = (emailId: string | number, data: { emails: string[] }, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/send-preview`,
        data,
        {
            // signal,
        },
    )

export const getEmailSpamTest = (emailId: number, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}/${emailId}/spam-test`,
        {
            // signal,
        },
    )

export const getEmailLinkPreview = (emailId: number, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/generate-secure-preview-url`,
        {
            // signal,
        },
    )

export const getEmailOptinDefault = (signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}/opt-in/preview`,
        {
            // signal,
        },
    )

export const getEmailMappedLinkTags = (emailId: number | string, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}/${emailId}/tags/link`,
        {
            // signal,
        },
    )

export const setTagOnLink = (emailId: number | string, data: CreateEmailMappedLinkTagsDto, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/tags/link`,
        data,
        {
            // signal,
        },
    )

export const setTagOnOpen = (emailId: number | string, data: any, signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/tags/open`,
        data,
        {
            // signal,
        },
    )

export const setEmailTags = (emailId: number | string, data: {
    tag_id: number
    event: 'open' | 'click'
    url: string
}[], signal?: AbortSignal) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/tags`,
        {
            tags: data,
        },
        {
            // signal,
        },
    )

export const getEmailTags = (emailId: number | string, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}/${emailId}/tags`,
        {
            // signal,
        },
    )

export const uploadThumbnailImage = (emailId: number | string, data: { base64_image: string }) =>
    serviceConsoleApi.post(
        `${basePath}/${emailId}/thumbnail-image`,
        data,
    )

export const getUploadedTemplateImage = (emailId: number | string) =>
    serviceConsoleApi.get(`${basePath}/${emailId}/thumbnail-image`)
