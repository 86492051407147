




















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
    name: 'VsEmptyState',
})
export default class extends Vue {
    @Prop({
        default: '',
        required: false,
    }) title!: string

    @Prop({
        default: '',
        required: false,
    }) subtitle!: string

    @Prop({
        default: true,
        required: false,
    }) action!: boolean

    @Prop({
        default: '',
        required: false,
    }) actionText!: string

    @Prop({
        default: '',
        required: false,
    }) image!: string
}
