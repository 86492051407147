import { get } from 'lodash'
import { Route } from 'vue-router'

export const routeHasPermission = (permissions: string[], metaPermissionName: string, route: Route) => {
    if (route && route.meta && route.meta[metaPermissionName]) {
        return permissions.some(permission => get(route, `meta.${metaPermissionName}`, []).includes(permission))
    } else {
        return true
    }
}

export const hasPermission = (permissions: string[], permission: string) => {
    return permissions.includes(permission)
}
