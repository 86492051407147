import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'
const basePath = '/contents/templates'

export const uploadTemplateImage = (templateId: string, data: { base64_image: string }, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}/${templateId}/preview-image`,
        data,
        {
            cancelToken,
        },
    )

export const getUploadedTemplateImage = (templateId: string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/${templateId}/preview-image`,
        {
            cancelToken,
        },
    )

export const deleteThemeById = (id: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `${basePath}/${id}`,
        {
            cancelToken,
        },
    )

export const deleteThemes = (data: { ids: number[] }, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}/bulk/delete`,
        data,
        {
            cancelToken,
        },
    )

export const getTemplatesCategories = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/categories`,
        {
            cancelToken,
        },
    )

export const getTemplates = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}`,
        {
            cancelToken,
            params,
        },
    )

export const getTemplateById = (templateId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/${templateId}`,
        {
            cancelToken,
            params,
        },
    )

export const getSystemTemplates = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/system`,
        {
            cancelToken,
            params,
        },
    )

export const getLayoutTemplates = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/layout`,
        {
            cancelToken,
            params,
        },
    )

export const exportAsHtml = (templateId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/${templateId}/export`,
        {
            cancelToken,
        },
    )

export const createModelFromEmail = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}/save-from-email`,
        data,
        {
            cancelToken,
        },
    )

export const templatePreview = (templateId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `${basePath}/${templateId}/preview`,
        {
            cancelToken,
        },
    )

export const duplicateTemplate = (templateId: any, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}/${templateId}/duplicate`,
        data,
        {
            cancelToken,
        },
    )

export const createTemplate = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `${basePath}`,
        data,
        {
            cancelToken,
        },
    )

export const editTemplate = (templateId: any, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `${basePath}/${templateId}`,
        data,
        {
            cancelToken,
        },
    )
