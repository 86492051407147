import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getSendersEmail = (params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/senders/email?include=dkim`,
        {
            params,
            cancelToken,
        },
    )

export const createNewSenderAuthorized = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/email`,
        data,
        {
            cancelToken,
        },
    )

export const editSenderAuthorized = (senderId: any, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/senders/email/${senderId}`,
        data,
        {
            cancelToken,
        },
    )

export const deleteSenderAuthorized = (senderId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/senders/email/${senderId}`,
        {
            cancelToken,
        },
    )

export const sendActivationCode = (senderId: any, params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/email/${senderId}/send-activation-code`,
        {
            params,
            cancelToken,
        },
    )

export const authorizeSender = (senderId: any, code: string, params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/email/${senderId}/confirm`,
        {
            auth_code: code,
        },
        {
            params,
            cancelToken,
        },
    )

export const verifySenderSpf = (senderId: any, params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/email/${senderId}/verify-spf`,
        {
            params,
            cancelToken,
        },
    )

export const verifySenderDkim = (senderId: any, params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/email/${senderId}/verify-dkim`,
        {
            params,
            cancelToken,
        },
    )

export const getSelectorDkim = (params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/senders/email/dkim-selector`,
        {
            params,
            cancelToken,
        },
    )

export const activateSenderDkim = (senderId: any, params?: any | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/senders/email/${senderId}/activate-dkim`,
        {
            params,
            cancelToken,
        },
    )
