

















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { uniqBy } from 'lodash'

@Component({
    name: 'VsAddLabelModal',
})
export default class extends Vue {
    @Prop({ required: true, default: () => [] }) links!: string[]
    @Prop({ required: true, default: () => [] }) mappedTags!: any[]
    @Prop({ required: true, default: () => [] }) lists!: any[]
    @Prop({ required: true, default: () => [] }) tags!: any[]

    private open = false
    private loading = false
    private selectedLink = ''
    private event = 'open'
    private selectedTag = ''
    $refs: any

    // get filteredLinks () {
    //     return this.links.filter((link: any) => {
    //         return this.mappedLinks.find((mapped: any) => mapped.url === link)
    //     })
    // }

    get filteredLinks () {
        return uniqBy(this.links, (link) => link)
    }

    get tagOptions () {
        const tags: any[] = []
        this.tags.map((el: any) => {
            tags.push({
                value: el.id,
                label: el.name,
            })
        })
        return tags
    }

    public async openModal () {
        this.selectedLink = ''
        this.selectedTag = ''
        this.loading = false
        this.open = true
    }

    public closeModal () {
        this.open = false
    }

    private async save () {
        const valid = await this.$refs.mappedTagForm.validate()
        if (!valid) return
        this.$emit('add-mapped-tag', {
            url: this.selectedLink,
            tag_id: this.selectedTag,
            event: 'click',
            id: Date.now(),
        })
        this.closeModal()
    }
}
