import Vue from 'vue'
import i18n from '@/lang'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import * as veevalidaterules from 'vee-validate/dist/rules'
import {
    libphoneNumber,
    controlPIVA,
    alphaNumSpaces,
    alphaNumSpacesAndSlash,
    alphaSpaces,
    numPositive,
    domain,
    url,
    atLeastOneUpperletter,
    atLeastOneLowerletter,
    atLeastOneSpecialCharacter,
    onlyLettersNumbersSpacesDashUnderscore,
    onlyDashUnderscoreNumberAndLowercaseLettersNoSpecial,
    atLeastOneNumber,
    startOrEndWithSpace,
    timeHhMmSs,
    libphoneNumberMobile,
    customRegex,
    noSpaces,
    link,
    maxLength,
    unique,
} from '@/utils/customValidations'
extend('libphoneNumber', libphoneNumber)
extend('controlPIVA', controlPIVA)
extend('alphaNumSpaces', alphaNumSpaces)
extend('alphaNumSpacesAndSlash', alphaNumSpacesAndSlash)
extend('alphaSpaces', alphaSpaces)
extend('numPositive', numPositive)
extend('domain', domain)
extend('url', url)
extend('link', link)
extend('atLeastOneUpperletter', atLeastOneUpperletter)
extend('atLeastOneLowerletter', atLeastOneLowerletter)
extend('atLeastOneSpecialCharacter', atLeastOneSpecialCharacter)
extend('onlyLettersNumbersSpacesDashUnderscore', onlyLettersNumbersSpacesDashUnderscore)
extend('onlyDashUnderscoreNumberAndLowercaseLettersNoSpecial', onlyDashUnderscoreNumberAndLowercaseLettersNoSpecial)
extend('atLeastOneNumber', atLeastOneNumber)
extend('startOrEndWithSpace', startOrEndWithSpace)
extend('timeHhMmSs', timeHhMmSs)
extend('libphoneNumberMobile', libphoneNumberMobile)
extend('customRegex', customRegex)
extend('noSpaces', noSpaces)
extend('unique', unique)
extend('maxLength', maxLength)

const rules: any = veevalidaterules
// loop over all rules
for (const rule in rules) {
    // add the rule
    extend(rule, {
        ...rules[rule],
        message: (_, values) => i18n.t('validations.' + rule, values),
    })
}

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
