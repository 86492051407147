/* eslint-disable max-len */
const leadplus = {
    general: {
        plan: 'Piano',
    },
    index: {
        emptyState: {
            title: 'Crea il tuo primo form.',
            subtitle: 'Raccogli nuovi indirizzi email con un form di iscrizione alla tua newsletter, potrai coltivare i lead e migliorare la tua strategia di email marketing!',
            actionText: 'Crea form',
        },
    },
}
export default {
    leadplus,
}
