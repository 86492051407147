import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getSms = (smsId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/contents/sms/${smsId}`,
        {
            cancelToken,
        },
    )

export const getAllSms = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/contents/sms`,
        {
            cancelToken,
        },
    )

export const getSmsPreview = (smsId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/contents/sms/${smsId}/preview`,
        {
            cancelToken,
        },
    )

export const createSms = (data?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/contents/sms`,
        data,
        {
            cancelToken,
        },
    )

export const createShortUrl = (smsId: number, data: { url: string }, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/contents/sms/${smsId}/short-url`,
        data,
        {
            cancelToken,
        },
    )

export const updateSms = (smsId: number, data?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/contents/sms/${smsId}`,
        data,
        {
            cancelToken,
        },
    )
