/* eslint-disable max-len */
const emailEditor = {
    index: {
        foundTemplates: '{count} modello trovato | {count} modelli trovati',
    },
    topActions: {
        selectedFiles: '{count} modello selezionato | {count} modelli selezionati',
        selectAll: 'Seleziona tutti',
        addTag: 'Assegna Tag',
        removeTag: 'Rimuovi Tag',
        delete: 'Elimina',
    },
    confirmDeleteTemplate: {
        confirmButton: 'Elimina definitivamente',
        title: 'Sicuro di voler rimuovere il Modello? | Sicuro di voler rimuovere i modelli?',
        subtitle: 'Il modello verrà eliminato e non sarà più possibile recuperarlo. | I modelli verranno eliminati e non sarà più possibile recuperarli.',
    },
}

const footer = {
    index: {
        foundFooters: '{count} footer trovati',
        create: 'Crea',
    },
    emptyState: {
        title: 'Crea il tuo primo footer personalizzato',
        subtitle: 'Potrai selezionarlo in fase di creazione campagna',
        createFooter: 'Crea footer',
    },
    confirmDeleteFooter: {
        confirmButton: 'Elimina definitivamente',
        title: 'Sicuro di voler rimuovere questo footer?',
        subtitle: 'Il footer verrà eliminato e non sarà più possibile recuperarlo.',
    },
    confirmDefaultFooter: {
        confirmButton: 'Imposta come predefinito',
        removeButton: 'Rimuovi come predefinito',
        titleConfirm: 'Sicuro di voler impostare lo stato di predefinito a questo footer?',
        titleRemove: 'Sicuro di voler rimuovere lo stato di predefinito da questo footer?',
    },
}
export default {
    emailEditor,
    footer,
}
