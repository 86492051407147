







import * as echarts from 'echarts'
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import ResizeMixin from '@/mixins/charts/resize'

@Component({
    name: 'VsChart',
})
export default class extends Mixins(ResizeMixin) {
    @Prop({ default: 'vs-chart' }) private className!: string
    @Prop({ default: '100%' }) private width!: string
    @Prop({ default: '350px' }) private height!: string

    @Prop({
        default: () => {
            return {}
        },
    })
    private options!: any

    declare chart: echarts.ECharts | null

    mounted () {
        this.initChart()
    }

    beforeDestroy () {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    }

    @Watch('options', { deep: true })
    private initChart () {
        if (
            this.chart !== null &&
            this.chart !== undefined
        ) {
            this.chart.dispose()
        }

        this.chart = echarts.init(this.$el as HTMLDivElement)
        this.chart.setOption(this.options)
    }
}
