









































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsFullTopBarLayout from '@/components/VsFullTopBarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsEmailPreviewModal from '@/modules/campaigns/components/VsEmailPreviewModal/Index.vue'
import VsPhonePreview from '@/modules/campaigns/components/VsPhonePreview/Index.vue'
import VsWhatsappMobile from '@/components/VsWhatsappMobile/Index.vue'
import { getEmailLinkPreview, getEmailPreview } from '@/api/consoleApi/contents/emails'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getSmsPreview } from '@/api/consoleApi/contents/sms'
import { SmsCounter } from '@/utils/smsCounter'
import { getWaTemplatePreview } from '@/api/consoleApi/contents/whatsapp'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { templatePreview } from '@/api/consoleApi/contents/templates'

export interface ICampaignMessage {
    type: string
    subject: string
    sender: string
    id: any
}
@Component({
    name: 'VsPreviewModal',
    components: {
        VsLoader,
        VsFullTopBarLayout,
        VsContainer,
        VsSeparator,
        VsEmailPreviewModal,
        VsPhonePreview,
        VsWhatsappMobile,
    },
})
export default class extends Vue {
    private open = false
    private type: any = 'email'
    private subject = ''
    private sender: any = false
    private messageId: any = null
    private device = 'desktop'
    private htmlPreview = ''
    private title = ''
    private description = ''
    private loading = false
    private smsCounter = new SmsCounter()

    $refs: any

    get combinedSender () {
        if (!this.sender) return 'nd'
        return this.sender
    }

    get previewCounter () {
        if (!this.htmlPreview) return false
        return this.smsCounter.count(this.htmlPreview)
    }

    get deviceOptions () {
        const devices = [
            {
                label: 'Desktop',
                value: 'desktop',
            },
            {
                label: 'Mobile portrait',
                value: 'portrait',
            },
            {
                label: 'Mobile landscape',
                value: 'landscape',
            },
        ]

        if (this.type === 'sms') {
            devices.shift()
        }
        return devices
    }

    public openModalNew (message: any, messageType: string) {
        this.htmlPreview = ''
        this.type = messageType
        this.subject = message.subject || 'nd'
        if (!message.from_name && message.from_email) {
            this.sender = message.from_email
        } else if (message.from_name && message.from_email) {
            this.sender = `${message.from_name} (${message.from_email})`
        } else if (message.whatsapp_sender) {
            this.sender = message.whatsapp_sender
        } else {
            this.sender = false
        }

        if (messageType === 'emailTemplate') {
            this.title = message.Title || message.title
            this.description = message.Description || message.description
        }

        // if (messageType === 'whatsapp') {
        //     this.htmlPreview = message.body
        // }

        this.device = this.type === 'sms' ? 'portrait' : 'desktop'
        this.open = true
        this.messageId = message.id || ''
        this.getPreview()
    }

    private async getPreview () {
        this.loading = true
        try {
            if (this.type === 'email') {
                const resp = await getEmailPreview(this.messageId)
                // eslint-disable-next-line no-useless-escape
                this.htmlPreview = resp.data.replace('</body>', '<script>document.addEventListener("click", function(evt) {evt.preventDefault()}, false)<\/script></body>')
            } else if (this.type === 'emailTemplate') {
                const resp = await templatePreview(this.messageId)
                this.htmlPreview = resp.data
            } else if (this.type === 'whatsapp') {
                const resp = await getWaTemplatePreview(this.messageId)
                this.htmlPreview = resp.data.data.body
            } else {
                const resp = await getSmsPreview(this.messageId)
                this.htmlPreview = resp.data.body
                this.sender = resp.data.from
            }
        } catch (e) {
            let messageType = 'la mail'
            if (this.type === 'whatsapp') {
                messageType = 'il template WhatsApp'
            }
            if (this.type === 'sms') {
                messageType = 'l\'sms'
            }
            this.$root.$vsToast({
                heading: `Non è possibile visualizzare l'anteprima perché ${messageType} non esiste.`,
                aspect: VsToastAspectEnum.alert,
                timeout: 5000,
            })
            console.log(e)
        }
        this.loading = false
    }

    private closeModal () {
        this.open = false
    }

    private async copyLinkPreview () {
        try {
            const link = await getEmailLinkPreview(this.messageId)
            copyToClipboard(link.data.data.url)
            this.$root.$vsToast({
                heading: 'Link di anteprima copiato negli appunti',
                aspect: VsToastAspectEnum.success,
                timeout: 5000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la copia del link di anteprima',
                aspect: VsToastAspectEnum.alert,
                timeout: 5000,
            })
        }
    }
}
