

























































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import MediaLibraryActionsMixin from '../mixins/MediaLibraryActionsMixin.vue'
import VsMediaLibraryCard from '../components/VsMediaLibraryCard/Index.vue'
import VsMediaLibraryDetail from '../components/VsMediaLibraryDetail/Index.vue'
import VsMediaLibraryDirectoryCard from '../components/VsMediaLibraryDirectoryCard/Index.vue'
import VsButtonActionsContainer from '@/components/VsButtonActionsContainer/Index.vue'
import VsTopMultiActions from './../components/VsTopMultiActions/Index.vue'

import {
    deleteFiles,
    getTrashedFiles,
    recoveryFiles,
} from '@/api/filemanager/files'

import {
    getNextPage,
} from '@/api/filemanager/utils'

import {
    deleteAllTrashFiles,
} from '@/api/filemanager/trash'

import {
    getTrashedDirectories,
} from '@/api/filemanager/directories'
import { MediaLibraryModule } from '@/store/modules/mediaLibrary'

@Component({
    name: 'Trash',
    components: {
        VsMediaLibraryCard,
        VsMediaLibraryDetail,
        VsMediaLibraryDirectoryCard,
        VsButtonActionsContainer,
        VsTopMultiActions,
    },
})
export default class extends Mixins(MediaLibraryActionsMixin) {
    private mediaLibraryType = 'trash'
    private libraryDirectories: any[] = []
    private nextPageUrl = ''
    private nextDirectoryPageUrl = ''
    private mimeFilter = ''
    private orderBy = null
    private total = 0

    get filterOptions () {
        return [
            {
                value: 'image/jpeg,image/gif,image/png,image/x-icon,image/webp,image/ico,image/vnd.microsoft.icon,image/icon,image/svg+xml',
                label: `Immagini`,
            },
            {
                value: 'text/plain,application/pdf,application/msword,application/excel,application/vnd.ms-excel,application/x-excel,application/x-msexcel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/ico,application/epub+zip,application/x-mobipocket-ebook,application/octet-stream',
                label: `Documenti`,
            },
            {
                value: 'application/zip,application/x-rar-compressed,application/x-rar,application/x-7z-compressed',
                label: `Archivi`,
            },
        ]
    }

    get orderOptions () {
        return [
            {
                value: 'created_at-desc',
                label: 'Più recenti',
            },
            {
                value: 'created_at-asc',
                label: 'Meno recenti',
            },
        ]
    }

    get currentSelectedFile () {
        return MediaLibraryModule.url
    }

    set currentSelectedFile (val) {
        MediaLibraryModule.SET_URL(val)
    }

    get libraryItems () {
        return MediaLibraryModule.libraryItems
    }

    set libraryItems (val) {
        MediaLibraryModule.SET_LIBRARY_ITEMS(val)
    }

    get checkedFileIds () {
        return MediaLibraryModule.checkedFileIds
    }

    set checkedFileIds (val) {
        MediaLibraryModule.SET_CHECKED_FILE_IDS(val)
    }

    get searchInput () {
        return MediaLibraryModule.searchInput
    }

    set searchInput (val) {
        MediaLibraryModule.SET_SEARCH_INPUT(val)
    }

    async beforeMount () {
        this.getTrashItems()
    }

    @Watch('orderBy', { immediate: false, deep: true })
    @Watch('mimeFilter', { immediate: false, deep: true })
    public async getFiles () {
        try {
            let search = ''
            let searchFields = ''

            if (this.mimeFilter) {
                search += `;mime:${this.mimeFilter}`
                searchFields += ';mime:in'
            }

            const orderByToSplit = this.orderBy || 'created_at-desc'

            const [orderBy, sortedBy] = orderByToSplit.split('-')

            const resp = await getTrashedFiles({
                orderBy,
                sortedBy,
                search,
                searchFields,
                searchJoin: 'and',
            })
            this.nextPageUrl = resp.data.next_page_url
            this.libraryItems = resp.data.data
            this.total = resp.data.total
        } catch (e) {
            console.log(e)
            this.nextPageUrl = ''
            this.libraryItems = []
            this.total = 0
        }
    }

    private async getNextPage () {
        if (!this.nextPageUrl) return
        try {
            const resp = await getNextPage(this.nextPageUrl)
            this.nextPageUrl = resp.data.next_page_url
            this.libraryItems.push(...resp.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    private async getNextDirectoryPageUrl () {
        if (!this.nextDirectoryPageUrl) return
        try {
            const resp = await getNextPage(this.nextDirectoryPageUrl)
            this.nextDirectoryPageUrl = resp.data.next_page_url
            this.libraryDirectories.push(...resp.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    public async getDirectories () {
        try {
            this.checkedFileIds = []
            const search = ''
            const searchFields = ''

            const resp = await getTrashedDirectories({
                orderBy: 'deleted_at',
                sortedBy: 'desc',
                search,
                searchFields,
                limit: 40,
                page: 1,
                searchJoin: 'and',
            })
            this.nextDirectoryPageUrl = resp.data.next_page_url
            this.libraryDirectories = resp.data.data
        } catch (e) {
            console.log(e)
            this.nextDirectoryPageUrl = ''
            this.libraryDirectories = []
        }
    }

    public async clearTrash () {
        try {
            await this.$root.$mediaLibraryDeleteConfirm.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }
        try {
            await deleteAllTrashFiles()
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.clearTrash.success'),
                timeout: 3000,
            })
            this.getFiles()
            this.getDirectories()
            this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.clearTrash.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async recoveryFiles () {
        if (this.checkedFileIds.length === 0) return
        try {
            const resp = await recoveryFiles({
                ids: this.checkedFileIds,
            })

            const succes = resp.data.filter((el: any) => el.success)
            const errors = resp.data.filter((el: any) => !el.success)

            this.$root.$mediaLibraryToast({
                heading: this.$tc('mediaLibrary.notifications.recoveryFiles.success', succes.length),
                timeout: 3000,
            })

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$tc('mediaLibrary.notifications.recoveryFiles.errorSome', errors.length),
                    timeout: 3000,
                })
            }

            this.checkedFileIds = []
            this.getFiles()
            this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.recoveryFiles.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private async deleteSelectedFiles () {
        if (this.checkedFileIds.length === 0) return
        try {
            await this.$root.$mediaLibraryDeleteConfirm.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }

        try {
            const resp = await deleteFiles({
                ids: this.checkedFileIds,
            })

            const succes = resp.data.filter((el: any) => el.success)
            const errors = resp.data.filter((el: any) => !el.success)

            this.$root.$mediaLibraryToast({
                heading: this.$tc('mediaLibrary.notifications.deleteSelectedFiles.success', succes.length),
                timeout: 3000,
            })

            if (errors.length > 0) {
                this.$root.$mediaLibraryToast({
                    heading: this.$tc('mediaLibrary.notifications.deleteSelectedFiles.errorSome', errors.length),
                    timeout: 3000,
                })
            }

            this.checkedFileIds = []
            this.getFiles()
            this.getMe()
        } catch (e) {
            this.$root.$mediaLibraryToast({
                heading: this.$t('mediaLibrary.notifications.deleteSelectedFiles.error'),
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private selectAllFiles () {
        this.checkedFileIds = [...this.libraryItems.map((el: any) => el.id)]
    }

    private async getTrashItems () {
        this.searchInput = ''
        this.checkedFileIds = []
        this.libraryItems = []
        this.libraryDirectories = []
        this.getFiles()
        this.getDirectories()
    }

    private searchItemAndReplace (libraryItem: any) {
        this.libraryItems.map((el: any) => {
            if (el.id === libraryItem.id) el = libraryItem
            return el
        })
    }
}
