const init = (user: any, config: any) => {
    const apiKey = config.userDotComCivchatApikey
    const origin = window.location.origin
    const platform = origin.endsWith('/') ? origin : `${origin}/`
    if (apiKey) {
        window.civchat = {
            apiKey: apiKey,
            user_id: user._id,
            first_name: user.firstname,
            last_name: user.lastname,
            email: user.email,
            platform,
        }
        const script = document.createElement('script')
        script.src = `https://${config.userDotComDomain}.user.com/widget.js`
        script.async = true
        script.onload = () => {
            console.log('usercom-loaded')
        }
        document.body.appendChild(script)
        return window.civchat
    }
}

const trackPageView = () => {
    if (window.UE) {
        const origin = window.location.origin
        const platform = origin.endsWith('/') ? origin : `${origin}/`

        window.UE.pageHit({
            apiKey: window.civchat.apiKey,
            user_id: window.civchat.user_id,
            first_name: window.civchat.first_name,
            last_name: window.civchat.last_name,
            email: window.civchat.email,
            platform,
        })
    } else {
        console.error('UE.pageHit is not available')
    }
}

export default {
    init,
    trackPageView,
}
