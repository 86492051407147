/* eslint-disable max-len */
const workflows = {
    index: {
        foundWorkflows: '{count} marketing automation trovato | {count} marketing automation trovati',
        emptyState: {
            title: 'Crea il tuo primo marketing automation',
            subtitle: 'Quando creerai un marketing automation, comparirà qui. Pronto per iniziare?',
            actionText: 'Crea automation',
        },
    },
    workflowCard: {
        status: {
            draft: 'In bozza',
            pause: 'In pausa',
            ready: 'Attivo',
        },
        triggerName: {
            campaignActivity: {
                title: 'Attività campagna',
                description: '',
            },
            updateSubscriber: {
                title: 'Quando un contatto della lista viene modificato',
                description: '',
            },
            welcomeMessage: {
                title: 'Quando un contatto si iscrive in lista',
                description: '',
            },
            flowEnd: {
                title: 'Al termine di un\'altra Marketing Automation',
                description: '',
            },
            onDate: {
                title: 'Al decorrere di una data di calendario',
                description: '',
            },
            webSite: {
                title: 'Attività sul sito',
                description: '',
            },
            ecommerceAbbandonedCart: {
                title: 'Carrello abbandonato',
                description: '',
                settings: {
                    value: '{count} ora | {count} ore',
                },
            },
            ecommerceProductPurchased: {
                title: 'Specifico prodotto acquistato',
                description: '',
            },
            ecommerceCategoryPurchased: {
                title: 'Specifica categoria acquistata',
                description: '',
            },
            ecommerceBrandPurchased: {
                title: 'Specifico brand acquistato',
                description: '',
            },
            ecommerceOrderCompleted: {
                title: 'Ordine concluso',
                description: '',
            },
            ecommerceActivityHistory: {
                title: 'Attività contatto sull\' ecommerce',
                description: '',
                settings: {
                    operator: {
                        greater: 'maggiore di',
                        smaller: 'minore di',
                    },
                    type: {
                        amountSpentOnPeriod: 'totale speso',
                        numberOfProductPurchasedOnPeriod: 'numero di prodotti acquistati',
                        numberOfOrderCompletedOnPeriod: 'numero di ordini completati',
                    },
                    period: {
                        last_week: 'nell\' ultima settimana',
                        last_month: 'nell\' ultimo mese',
                        last_three_month: 'negli ultimi 3 mesi',
                        last_six_month: 'negli ultimi 6 mesi',
                        last_year: 'nell\' ultimo anno',
                        ever: 'da sempre',
                    },
                },
            },
            form: {
                title: 'Quando un contatto si iscrive a un form (Deprecato)',
                description: '',
            },
        },
    },
    queueStatus: {
        ready: 'Pianificato',
        sent: 'Inviato',
        failed: 'Fallito',
        cancel: 'Cancellato',
        process: 'Processato',
    },
    workflowErrors: {
        notification: {
            heading: 'Informazioni mancanti',
            message: 'Compila tutte le sezioni mancanti:',
        },
        sections: {
            settings: 'Punto di partenza',
            timeline: 'Timeline',
        },
    },
    messageTrigger: {
        as: {
            immediatily: 'immediatamente',
            days: '{count} giorno | {count} giorni',
            months: '{count} mese | {count} mesi',
            hours: '{count} ora | {count} ore',
        },
        type: {
            before: 'prima',
            after: 'dopo',
        },
        from: {
            sent: 'è stato inviato',
            delivered: 'è stato consegnato',
            failed: 'è fallito',
            clicked: 'è stato cliccato',
            notclicked: 'non è stato cliccato',
            opened: 'è stato aperto',
            notopened: 'non è stato aperto',
        },
        days: {
            0: 'lunedì',
            1: 'martedì',
            2: 'mercoledì',
            3: 'giovedì',
            4: 'venerdì',
            5: 'sabato',
            6: 'domenica',
        },
    },
}
export default {
    workflows,
}
