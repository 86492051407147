/* eslint-disable max-len */
const manager = {
    userStatus: {
        enabled: 'Abilitato',
        disabled: 'Disabilitato',
        pending_activation: 'Attesa conferma email',
        pending_enabled: 'Attesa abilitazione',
    },
    dashboard: {
        assignedCredits: 'più {credits} assegnato ai subaccount | più {credits} assegnati ai subaccount',
        assignedLicense: 'più {license} assegnata ai subaccount | più {license} assegnate ai subaccount',
    },
    child: {
        creditHistory: {
            foundOperations: '{count} trovata | {count} trovate',
            searchPlaceholder: 'Cerca...',
            typePlaceholder: 'Tutte le tipologie',
            clearFilters: 'Svuota filtri',
            orderByPlaceholder: 'Più recenti',
            type: {
                email: 'Email',
                sms: 'SMS',
                transactional: 'Transazionali',
            },
            operation: {
                add: 'Aggiunti',
                sub: 'Rimossi',
            },
        },
    },
}
export default {
    manager,
}
