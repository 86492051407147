
















import { Component, Vue } from 'vue-property-decorator'
import VsTopNav from '@/components/VsTopNav/Index.vue'
import VsSideBar from '@/components/VsSideBar/Index.vue'

interface ILoginRespone {
    user: any
    token: string
}

@Component({
    name: 'VsSidebarLayout',
    components: {
        VsTopNav,
        VsSideBar,
    },
})
export default class extends Vue {
}
