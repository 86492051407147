import { serviceUserApi } from '@/utils/request'

export const login = (data: {email: string, password: string}, cancelToken?: any) =>
    serviceUserApi.post(`/users/login`, data, { cancelToken })

export const me = (cancelToken?: any) =>
    serviceUserApi.get(`/users/me`, { cancelToken })

export const updateUser = (data: any, cancelToken?: any) =>
    serviceUserApi.patch(`/users`, data, { cancelToken })

export const activateUser = (data: {otpSignup: string}, cancelToken?: any) =>
    serviceUserApi.post(`/users/activate`, data, { cancelToken })

export const enableUser = (data: any, cancelToken?: any) =>
    serviceUserApi.post(`/users/enabled`, data, { cancelToken })

export const sendActivationEmail = (cancelToken?: any) =>
    serviceUserApi.post(`/users/send-activation-email`, { cancelToken })

export const recoverPassword = (email: string, cancelToken?: any) =>
    serviceUserApi.post(`/users/recover-password`, { email }, { cancelToken })

export const resetPassword = (password: string, token: string, cancelToken?: any) =>
    serviceUserApi.post(
        `/users/reset-password`,
        {
            password,
        },
        {
            cancelToken,
            headers: {
                Authorization: 'Bearer ' + token,
            },
        },
    )

export const send2FaCode = (data: {code: string}, token: string, cancelToken?: any) =>
    serviceUserApi.post(
        `/users/login/2fa`,
        data,
        {
            headers: {
                Authorization: 'Bearer ' + token,
            },
            cancelToken,
        },
    )

export const retrieve2FaCode = (data: {password: string}, cancelToken?: any) =>
    serviceUserApi.post(
        `/users/2fa/retrieve-secret`,
        data,
        {
            cancelToken,
        },
    )

export const turnOn2FaCode = (data: {code: string}, cancelToken?: any) =>
    serviceUserApi.post(
        `/users/2fa/turn-on`,
        data,
        {
            cancelToken,
        },
    )

export const turnOff2FaCode = (data: {password: string}, cancelToken?: any) =>
    serviceUserApi.post(
        `/users/2fa/turn-off`,
        data,
        {
            cancelToken,
        },
    )

export const generate2FaCode = (data: {password: string}, cancelToken?: any) =>
    serviceUserApi.post(
        `/users/2fa/generate`,
        data,
        {
            cancelToken,
        },
    )

export const subscribe = (data: {email: string, password: string}, cancelToken?: any) =>
    serviceUserApi.post(
        `/users/signup`,
        data,
        {
            cancelToken,
        },
    )

export const proxyImages = (url: string, cancelToken?: any) =>
    serviceUserApi.get(`/users/screenshot-images-proxy?url=${encodeURI(url)}`, { cancelToken })

export const exitImpersonate = (cancelToken?: any) =>
    serviceUserApi.get(`/users/exit-impersonate`, { cancelToken })

export const updateAuthorization = (authorized: boolean, cancelToken?: any) =>
    serviceUserApi.patch(`/users/parent-authorization`, { authorized }, { cancelToken })
