













































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { IMessageSms } from '@/api/consoleApi/dto/campaigns.dto'
import { getSmsPreview, updateSms } from '@/api/consoleApi/contents/sms'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import VsPhonePreview from '@/modules/campaigns/components/VsPhonePreview/Index.vue'
import VsShortUrlModal from '@/modules/campaigns/components/VsShortUrlModal/Index.vue'
import { CustomField } from '@/utils/customFields'
import { getSmsContentTags } from '@/utils/personalizations'
import { Picker } from 'emoji-mart-vue-2'
import { SmsCounter } from '@/utils/smsCounter'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'VsSmsContentCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsDropdownButton,
        VsInlineErrorsCheck,
        VsPhonePreview,
        VsShortUrlModal,
        VsHintCard,
        VsSeparator,
        Picker,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) message!: IMessageSms
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: true, default: () => [] }) customFields!: CustomField[]
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) emitWithoutSave!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    @Prop({ required: false, default: 'campaign' }) variant!: 'campaign' | 'optin' | 'transactional'

    private loading = false
    private messageLength = 160
    private maxCharacter = 640
    private tempBody = ''
    private cursorPosition: undefined | number = undefined
    private smsPreview = ''
    private smsCounter = new SmsCounter()
    $refs: any

    openTab () {
        this.$emit('open-tab', 'content')
    }

    get counter () {
        return this.smsCounter.count(this.message.body)
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get tempCounter () {
        return this.smsCounter.count(this.tempBody)
    }

    get previewCounter () {
        if (!this.smsPreview) return false
        return this.smsCounter.count(this.smsPreview)
    }

    get splittedMessages () {
        const reg = new RegExp(`.{1,${this.messageLength}}`, 'gs')
        return this.tempBody.match(reg)
    }

    get campaignContentStatus () {
        if (!this.message.body) return 'default'
        if (this.message.body.length > this.maxCharacter) return 'error'
        return 'success'
    }

    get campaignTempContentStatus () {
        if (!this.tempBody.trim()) return 'default'
        if (this.smsLengthStatus === 'error') return 'error'
        return 'success'
    }

    get hasBody () {
        if (!this.tempBody.trim()) return 'default'
        return 'success'
    }

    get smsLengthStatus () {
        if (!this.tempBody.trim()) return 'default'
        if (this.tempCounter.length > this.maxCharacter) return 'error'
        return 'success'
    }

    get smsId () {
        return this.message.id
    }

    get contentTags () {
        return getSmsContentTags(this.customFields)
    }

    get messages () {
        if (!this.tempBody) return 0
        return Math.ceil(this.tempBody.length / this.messageLength)
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    private openTabContent () {
        this.tempBody = this.message.body
        this.cursorPosition = undefined
        this.openTab()
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    private async saveContent () {
        if (this.emitWithoutSave) {
            this.$emit('content-saved', this.tempBody.trim())
            this.$emit('open-tab', '')
            return
        }
        try {
            await updateSms(
                this.message.id,
                {
                    body: this.tempBody.trim(),
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.contentSms.contentSaved'),
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('open-tab', '')
            this.$emit('content-saved')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.contentSms.contentSavedError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private detectBodyInputCursorPosition () {
        this.cursorPosition = this.$refs?.bodyInput?.$el.childNodes[1].selectionStart || this.tempBody.length
    }

    private appendText (text: string) {
        this.tempBody = this.insertString(this.tempBody, text, this.cursorPosition)
    }

    private addEmoji (emoji: {
        id: string
        name: string
        colons: string
        text: string
        emoticons: string[]
        skin: number | null
        native: string
    }) {
        this.tempBody = this.insertString(this.tempBody, emoji.native, this.cursorPosition)
    }

    private insertString (mainString: string, insString: string, pos?: undefined | number) {
        if (typeof pos === 'undefined') {
            pos = this.tempBody.length
        }
        if (typeof insString === 'undefined') {
            insString = ''
        }
        this.cursorPosition = pos + insString.length
        return mainString.slice(0, pos) + insString + mainString.slice(pos)
    }

    @Watch('message.body', { immediate: true, deep: true })
    private async getPreview () {
        if (!this.message.body) return
        try {
            this.smsPreview = this.message.body
            this.$emit('sms-preview', this.message.body)
        } catch (e) {
            console.log(e)
        }
    }
}
