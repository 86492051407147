






































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsSectionHeader',
})
export default class extends Vue {
    @Prop({ default: false, required: false, type: Boolean }) truncate!: boolean
    // singleLine determine if actions go multiple line on mobile or not
    @Prop({ default: false, required: false, type: Boolean }) singleLine!: boolean
    @Prop({ default: '', required: false }) heading!: string
    @Prop({ default: '', required: false }) message!: string
    @Prop({ default: 'large', required: false }) size!: string

    get hasPreActions () {
        return !!this.$slots.preActions
    }

    get hasActions () {
        return !!this.$slots.actions
    }

    get hasMessage () {
        return !!this.$slots.messageSlot || this.message
    }

    get hasBadgeSlot () {
        return !!this.$slots.badgeSlot
    }

    get hasHeadingActions () {
        return !!this.$slots.headingActions
    }

    get hasMessageActions () {
        return !!this.$slots.messageActions
    }

    get hasClickTitleListener () {
        return this.$listeners && this.$listeners['click-title']
    }
}
