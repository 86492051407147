import { CustomField, CustomFieldTypeEnum } from './customFields'

export const staticsTags = {
    // subscriber
    subscriberCampaignId: '%Subscriber:CampaignID%',
    subscriberEmailAddress: '%Subscriber:EmailAddress%',
    subscriberMobile: '%Subscriber:Mobile%',
    subscriberUnsubscriptionDate: '%Subscriber:UnsubscriptionDate%',
    subscriberSubscriptionDate: '%Subscriber:SubscriptionDate%',
    subscriberOptInDate: '%Subscriber:OptInDate%',
    subscriberOptInIp: '%Subscriber:OptInIP%',
    subscriberId: '%Subscriber:SubscriberID%',
    subscriberUnsubscriptionIp: '%Subscriber:UnsubscriptionIP%',
    subscriberSubscriptionIp: '%Subscriber:SubscriptionIP%',
    subscriberLeadplusId: '%Subscriber:LeadPlusID%',
    subscriberUnsubscriptionMotivation: '%Subscriber:UnsubscriptionMotivation%',
    subscriberOptInTime: '%Subscriber:OptInTime%',
    subscriberReferral: '%Subscriber:Referral%',
    subscriberSource: '%Subscriber:Source%',
    subscriberSubscriptionStatus: '%Subscriber:SubscriptionStatus%',
    subscriberSubscriptionTime: '%Subscriber:SubscriptionTime%',
    subscriberBounceType: '%Subscriber:BounceType%',
    subscriberUnsubscriptionTime: '%Subscriber:UnsubscriptionTime%',
    conditionalIf: '[IF:condition1<>condition2]',
    conditionalElse: '[ELSE]',
    conditionalEndIf: '[ENDIF]',
    // campaigns
    linkForward: '%Link:Forward%',
    linkBrowser: '%Link:WebBrowser%',
    linkReportAbuse: '%Link:ReportAbuse%',
    linkShareTwitter: '%Link:SocialShare:Twitter%',
    linkShareFacebook: '%Link:SocialShare:Facebook%',
    linkRemoteContent: '%RemoteContent=http://%',
    // link lista
    linkUnsubscribe: '%Link:Unsubscribe%',
    linkSubscriberArea: '%Link:SubscriberArea%',
    // link conferma
    linkConfirm: '%Link:Confirm%',
    linkReject: '%Link:Reject%',
    // Lista
    listId: '%List:ID%',
    listName: '%List:Name%',
    // dati csa
    csaNominative: '%Csa:nominative%',
    csaIvaFCode: '%Csa:ivaFCode%',
    csaAddress: '%Csa:address%',
    csaCity: '%Csa:city%',
    csaCap: '%Csa:cap%',
    csaProvince: '%Csa:province%',
    csaCountry: '%Csa:country%',
    csaEmail: '%Csa:email%',
    csaTelephone: '%Csa:telephone%',
    // altro
    date: '%Date=d/m/Y%',
    qrCode: '%QRthis[string][3]%',
    barCode: '%BarCode39[string][1]%',
    youtubeVideo: '%YouTube[VideoID]%',
    // RSS
    rssUrl: '%RSS:[url]:[numero di articoli da visualizzare]%',
    rssTitle: '%RSS:TITLE:[lunghezza]%',
    rssContent: '%RSS:CONTENT:[lunghezza]%',
    rssImage: '%RSS:IMG%',
    rssPostLink: '%RSS:POST-LINK:[ Leggi di più]%',
    rssCategories: '%RSS:CATEGORIES%',
    rssCommentsLink: '%RSS:COMMENTS-LINK%',
    rssPublishDate: '%RSS:PUBLISH-DATE%',
    rssAuthor: '%RSS:AUTHOR%',
    rssEnd: '%RSS:END%',
}

export const getCustomFieldTag = (customField: CustomField): string => {
    let fieldKey = customField.placeholder
    if (!fieldKey) {
        switch (customField.type) {
            case CustomFieldTypeEnum.email:
                fieldKey = 'EmailAddress'
                break
            case CustomFieldTypeEnum.mobile:
                fieldKey = 'Mobile'
                break
            default:
                fieldKey = `CustomField${customField.id}`
                break
        }
    }
    return `%Subscriber:${fieldKey}%`
}

export const getSubscribersTags = (customFields: CustomField[]) => {
    return {
        label: true,
        title: 'Contatto',
        tags: [
            ...customFields.map(cf => {
                return {
                    title: `${cf.name}`,
                    tag: getCustomFieldTag(cf),
                }
            }),
            {
                title: 'Data iscrizione',
                tag: staticsTags.subscriberSubscriptionDate,
            },
            {
                title: 'Ip iscrizione',
                tag: staticsTags.subscriberSubscriptionIp,
            },
        ],
    }
}

export const getCampaignLinksTags = (consoleUrl: string) => {
    return {
        label: true,
        title: 'Link Campagna',
        tags: [
            {
                title: 'Inoltra ad un amico',
                tag: `<a href="${staticsTags.linkForward}">Clicca qui per inoltrare questa email ad un amico</a>`,
            },
            {
                title: 'Visualizza nel Browser',
                tag: `<span>Cliccare <a href="${staticsTags.linkBrowser}" target="_blank">qui</a> per visualizzare la versione web</span>`,
            },
            {
                title: 'Segnala Abuso',
                tag: `<a href="${staticsTags.linkReportAbuse}">Segnala Abuso</a>`,
            },
            {
                title: 'Condividi su Twitter',
                tag: `<a href="${staticsTags.linkShareTwitter}"><img alt="Twitter Share" style="display:block;margin:0;border:0;padding:0;" src="${consoleUrl}/templates/default/img/share-tweet.png"></a>`,
            },
            {
                title: 'Condividi su Facebook',
                tag: `<a href="${staticsTags.linkShareFacebook}"><img alt="Facebook Share" style="display:block;margin:0;border:0;padding:0;" src="${consoleUrl}/templates/default/img/share-fb.jpg"></a>`,
            },
            {
                title: 'Contenuto Remoto',
                tag: staticsTags.linkRemoteContent,
            },
        ],
    }
}

export const getListLinksTags = () => {
    return {
        label: true,
        title: 'Link Lista',
        tags: [
            {
                title: 'Link di disiscrizione',
                tag: `<span>Cliccare <a href="${staticsTags.linkUnsubscribe}" target="_blank">qui</a> per annullare la sottoscrizione</span>`,
            },
            {
                title: 'Area iscritti',
                tag: `<a href="${staticsTags.linkSubscriberArea}">Clicca qui per visualizzare i tuoi dati</a>`,
            },
        ],
    }
}

export const getConfirmationLinksTags = () => {
    return {
        label: true,
        title: 'Link di Conferma',
        tags: [
            {
                title: 'Link conferma opt-in',
                tag: `<a href="${staticsTags.linkConfirm}">Clicca qui per confermare la tua iscrizione</a>`,
            },
            {
                title: 'Link rifiuto opt-in',
                tag: `<a href="${staticsTags.linkReject}">Clicca qui per annullare la tua iscrizione</a>`,
            },
        ],
    }
}

export const getCsaTags = () => {
    return {
        label: true,
        title: 'Dati del mittente',
        tags: [
            {
                title: 'Tutti i dati (mittente)',
                tag: `${staticsTags.csaNominative} - ${staticsTags.csaIvaFCode} - ${staticsTags.csaAddress} - ${staticsTags.csaCity} - ${staticsTags.csaCap} - ${staticsTags.csaProvince} - ${staticsTags.csaCountry} - ${staticsTags.csaEmail} - ${staticsTags.csaTelephone}`,
            },
            {
                title: 'Nominativo (mittente)',
                tag: staticsTags.csaNominative,
            },
            {
                title: 'Email resp. (mittente)',
                tag: staticsTags.csaEmail,
            },
            {
                title: 'Tel resp. (mittente)',
                tag: staticsTags.csaTelephone,
            },
            {
                title: 'P.Iva/C.F. (mittente)',
                tag: staticsTags.csaIvaFCode,
            },
            {
                title: 'Nazione (mittente)',
                tag: staticsTags.csaCountry,
            },
            {
                title: 'Città (mittente)',
                tag: staticsTags.csaCity,
            },
            {
                title: 'Provincia (mittente)',
                tag: staticsTags.csaProvince,
            },
            {
                title: 'Indirizzo (mittente)',
                tag: staticsTags.csaAddress,
            },
            {
                title: 'Cap (mittente)',
                tag: staticsTags.csaCap,
            },
        ],
    }
}

export const getOthersTags = () => {
    return {
        label: true,
        title: 'Altro',
        tags: [
            {
                title: 'Data invio email',
                tag: staticsTags.date,
            },
            {
                title: 'QR code generator',
                tag: staticsTags.qrCode,
            },
            {
                title: 'Bar code39 generator',
                tag: staticsTags.barCode,
            },
            {
                title: 'YouTube frame link',
                tag: staticsTags.youtubeVideo,
            },
            {
                title: 'Personalizzazione condizionale',
                tag: `${staticsTags.conditionalIf} true content block ${staticsTags.conditionalElse} false content block ${staticsTags.conditionalEndIf}`,
            },
        ],
    }
}

export const getRssTags = () => {
    return {
        label: true,
        title: 'RSS',
        tags: [
            {
                title: 'Inizio cattura articoli RSS',
                tag: staticsTags.rssUrl,
            },
            {
                title: 'Titolo articolo',
                tag: staticsTags.rssTitle,
            },
            {
                title: 'Contenuto articolo',
                tag: staticsTags.rssContent,
            },
            {
                title: 'Immagine articolo',
                tag: staticsTags.rssImage,
            },
            {
                title: 'Collegamento all\'articolo',
                tag: staticsTags.rssPostLink,
            },
            {
                title: 'Categorie',
                tag: staticsTags.rssCategories,
            },
            {
                title: 'Collegamento ai commenti dell\'articolo',
                tag: staticsTags.rssCommentsLink,
            },
            {
                title: 'Data di pubblicazione dell\'articolo',
                tag: staticsTags.rssPublishDate,
            },
            {
                title: 'Autore dell\'articolo',
                tag: staticsTags.rssAuthor,
            },
            {
                title: 'Fine cattura articoli RSS',
                tag: staticsTags.rssEnd,
            },
        ],
    }
}

export const getConfirmationEmailContentTags = (customFields: CustomField[], consoleUrl: string) => [
    getSubscribersTags(customFields),
    getConfirmationLinksTags(),
    getCampaignLinksTags(consoleUrl),
    getCsaTags(),
    getOthersTags(),
    getRssTags(),
]

export const getEmailContentTags = (customFields: CustomField[], consoleUrl: string) => [
    getSubscribersTags(customFields),
    getCampaignLinksTags(consoleUrl),
    getListLinksTags(),
    getCsaTags(),
    getOthersTags(),
    getRssTags(),
]

export const getEmailSubjectTags = (customFields: CustomField[]) => [
    getSubscribersTags(customFields),
]

export const getSmsContentTags = (customFields: CustomField[]) => [
    getSubscribersTags(customFields),
]

export const buttons = {
    unsubscriptionLink: {
        name: 'unsubscriptionLink',
        label: 'Inserisci Link di Cancellazione',
        command: 'insertUnsubscriptionLink',
        html: `Cliccare <a href="${staticsTags.linkUnsubscribe}" target="_blank">qui</a> per annullare la sottoscrizione`,
    },
    browserViewLink: {
        name: 'browserViewLink',
        label: 'Inserisci Link Visualizza nel browser',
        command: 'insertBrowserViewLink',
        html: `Cliccare <a href="${staticsTags.linkBrowser}" target="_blank">qui</a> per visualizzare la versione web`,
    },
    confirmSubscriptionLink: {
        name: 'confirmSubscriptionLink',
        label: 'Inserisci Link conferma opt-in',
        command: 'insertConfirmSubscriptionLink',
        html: `<a href="${staticsTags.linkConfirm}">Clicca qui per confermare la tua iscrizione</a>`,
    },
}

export const getEmailContentButtons = () => [
    buttons.unsubscriptionLink,
    buttons.browserViewLink,
]

export const getEmailOptinContentButtons = () => [
    buttons.confirmSubscriptionLink,
    buttons.browserViewLink,
]

export const getDragoContentTags = (customFields: CustomField[]) => {
    return {
        id: 'contatto',
        title: {
            it: 'Contatto',
            en: 'Contatto',
        },
        items: [
            ...customFields.map(cf => {
                return {
                    label: {
                        it: `${cf.name}`,
                        en: `${cf.name}`,
                    },
                    replacer: getCustomFieldTag(cf),
                }
            }),
            {
                label: {
                    it: 'Data iscrizione',
                    en: 'Data iscrizione',
                },
                replacer: staticsTags.subscriberSubscriptionDate,
            },
            {
                label: {
                    it: 'Ip iscrizione',
                    en: 'Ip iscrizione',
                },
                replacer: staticsTags.subscriberSubscriptionIp,
            },
        ],
    }
}

export const getConfirmationEmailContentButtons = () => [
    buttons.browserViewLink,
]

export const contentContainsUnsubscriptionLink = (content: string) => {
    return content.includes(staticsTags.linkUnsubscribe)
}

export const contentContainsConfirmationLink = (content: string) => {
    return content.includes(staticsTags.linkConfirm)
}

export const contentHasPlaceHolderImages = (content: string) => {
    return content.includes('src=""')
}

export const contentHasCsaTags = (content: string) => {
    return [
        staticsTags.csaNominative,
        staticsTags.csaIvaFCode,
        staticsTags.csaAddress,
        staticsTags.csaCity,
        staticsTags.csaCap,
        staticsTags.csaProvince,
        staticsTags.csaCountry,
        staticsTags.csaEmail,
        staticsTags.csaTelephone,
    ].every(tag => content.toUpperCase().includes(tag.toUpperCase()))
}

export const getCampaignLinks = (message: any, footerHtml: string) => {
    // eslint-disable-next-line no-useless-escape
    const reg = /href=(["'])http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=;,]*)/gi
    const matches = message.html_content.match(reg)
    const footerMatches = footerHtml.match(reg)
    const allMatches = []
    if (matches) {
        allMatches.push(...matches)
    }
    if (footerMatches) {
        allMatches.push(...footerMatches)
    }
    return allMatches.map(el => el.replace('href="', '').replace('href=\'', ''))
}
