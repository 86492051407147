var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vs-percentage-bar vs-w-full vs-rounded-full vs-relative vs-overflow-hidden",class:{
        'vs-bg-grey-800': _vm.dark,
        'vs-bg-grey-200': !_vm.dark,
    },style:({
        height: _vm.height,
    })},_vm._l((_vm.percentagesBars),function(percentage,index){return _c('div',{key:index,staticClass:"vs-percentage-inner vs-absolute vs-rounded-full",style:({
            height: _vm.height,
            width: percentage.width,
            background: percentage.color,
        })})}),0)}
var staticRenderFns = []

export { render, staticRenderFns }