
























import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsOverlayCard',
})
export default class extends Vue {
    private hover = false

    private onMouseOver () {
        this.hover = true
    }

    private onMouseLeave () {
        this.hover = false
    }
}
