











































































import { Component, Prop, Vue } from 'vue-property-decorator'

export interface IPath {
    name: string
    id: string
}

@Component({
    name: 'VsBreadCrumbs',
})
export default class extends Vue {
    @Prop({
        default: () => [],
        required: false,
    }) paths!: IPath[]

    @Prop({
        default: 2,
        required: false,
    }) visiblePaths!: number

    private breadcrumbDropdownIsOpen = false

    private isVisiblePath (index: number) {
        const pathPosition = index + 1
        if (this.paths.length <= this.visiblePaths) return true
        if (pathPosition === 1) return true
        const visibleAfterCut = this.visiblePaths - 1
        return pathPosition > (this.paths.length - visibleAfterCut)
    }

    private pathSelected (path: IPath) {
        this.$emit('path-selected', path)
    }

    private openMenu () {
        this.breadcrumbDropdownIsOpen = true
    }
}
