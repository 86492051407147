import VsLayouts from '@/layouts/Index.vue'
// import { LayoutsEnum } from '@/layouts/types'
// import { UserModule } from '@/store/modules/user'
// import { userStatusEnum } from '@/utils/users'

export enum mockRoutesNames {
    mock = 'mock',
    mockIndex = 'mockIndex',
    mockShop = 'mockShop',
    mockCartGenerator = 'mockCartGenerator',
    mockSuccess = 'mockSuccess',
    mockError = 'mockError',
    mockCart = 'mockCart',
    mockCheckout = 'mockCheckout',
    mockPlans = 'mockPlans',
    contactIndexMock = 'contactIndexMock',
    contactTags = 'contactTags',
}

const mockRouter = [
    {
        path: '/mock',
        name: mockRoutesNames.mock,
        component: VsLayouts,
        redirect: {
            name: mockRoutesNames.mockIndex,
        },
        meta: {
            title: 'mock',
        },
        children: [
            {
                path: '',
                name: mockRoutesNames.mockIndex,
                component: () => import(/* webpackChunkName: "mock-index" */ '../views/index.vue'),
            },
        ],
    },
]

export default mockRouter
