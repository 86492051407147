/* eslint-disable max-len */
const mock = {
    shop: {
        plans: 'Scegli Piano',
        createCartWithItems: 'Crea carrello con crediti',
        deleteUserSubscription: 'Cancella subscription stripe (di conseguenza anche utente)',
        createCartWithPlanAndAddon: 'Crea carrello con un piano e un addon',
        checkoutSession: 'Checkout',
        planShopModal: {
            back: 'Indietro',
            selectPlan: 'Scegli un piano',
            title: 'Piani in abbonamento',
            subtitle: 'Scegli un piano in abbonamento se invii email con frequenza e vuoi una soluzione{0}completa e senza pensieri, perfettamente scalabile sulle tue esigenze.',
            top: 'Il più richiesto',
            custom: 'Su misura',
            monthly: 'al mese',
            contact: 'Contattaci',
            purchase: 'Acquista',
            planCards: {
                small: {
                    title: 'Small',
                    subtitle: 'Il punto di partenza per iniziare ad inviare email intelligenti in modo professionale.',
                },
                business: {
                    title: 'Business',
                    subtitle: 'Per far crescere i tuoi progetti, automatizzare il lavoro e far crescere la tua azienda.',
                },
                custom: {
                    title: 'Enterprise',
                    subtitle: 'Il piano enterprise è studiato per le aziende con esigenze superiori ed è espandibile su misura grazie all’infrastruttura flessibile 4Dem.',
                },
            },
            planList: {
                small: {
                    title: 'Funzionalità chiave:',
                    list: [
                        {
                            label: 'Contatti illimitati',
                        },
                        {
                            label: '2 liste',
                        },
                        {
                            label: '15 campi di lista',
                        },
                        {
                            label: 'Etichette e Gruppi',
                        },
                        {
                            label: '5 modelli email',
                        },
                        {
                            label: 'Editor Drag&Drop',
                        },
                        {
                            label: 'Statistiche base',
                        },
                        {
                            label: 'Supporto via ticket',
                        },
                    ],
                },
                business: {
                    title: 'Funzionalità Small più:',
                    list: [
                        {
                            label: '20 Liste',
                        },
                        {
                            label: '30 campi di lista',
                        },
                        {
                            label: 'Invio Multilista',
                        },
                        {
                            label: '100+ modelli email',
                        },
                        {
                            label: 'Tag Comportamentali',
                        },
                        {
                            label: 'Statistiche Avanzate',
                        },
                        {
                            label: 'Marketing Automation',
                        },
                        {
                            label: 'Form e PopUp Avanzati',
                        },
                        {
                            label: 'Email Retargeting',
                        },
                        {
                            label: 'Campagne A/B test',
                        },
                        {
                            label: 'Segmentazione Avanzata',
                        },
                        {
                            label: 'Email Booster',
                        },
                        {
                            label: 'Conservazione dati iscritto 180gg',
                        },
                        {
                            label: 'Senza Logo 4Dem nelle Email',
                        },
                    ],
                },
                custom: {
                    title: 'Funzionalità Business più:',
                    list: [
                        {
                            label: '100 liste',
                        },
                        {
                            label: '50 campi di lista',
                        },
                        {
                            label: 'IP dedicato',
                        },
                        {
                            label: 'Whitelabel',
                        },
                        {
                            label: 'Success Manager dedicato',
                        },
                        {
                            label: 'Api dedicate',
                        },
                        {
                            label: 'Area Manager',
                        },
                        {
                            label: 'Gestione di sub-account',
                        },
                        {
                            label: 'Integrazioni ad hoc',
                        },
                        {
                            label: 'Supporto dedicato',
                        },
                        {
                            label: 'Conservazione dati espandibile',
                        },
                        {
                            label: 'Incluso nel programma 4Dem Beta',
                        },
                    ],
                },
            },
        },
        cart: {
            back: 'Indietro',
            firstStep: 'La tua scelta',
            secondStep: 'Fatturazione',
            thirdStep: 'Pagamento',
            selectPricePlan: 'Quante email invii al mese?',
            selectPriceCredits: 'Crediti',
            creditType: {
                transactional: 'Transazionali',
                email: 'Email',
                sms: 'SMS',
            },
        },
    },
    account: {
        myPlan: {
            title: 'Il mio piano',
        },
        myAccount: {
            title: 'Il mio account',
        },
    },
}
export default {
    mock,
}
