import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'

const campaignsRouter: RouteConfig[] = [
    {
        path: '/campaigns',
        redirect: { name: 'campaignsIndex' },
        name: 'campaigns',
        component: VsLayouts,
        children: [
            {
                path: '/',
                name: 'campaignsIndex',
                component: () => import(/* webpackChunkName: "campaigns" */ './../views/index.vue'),
                meta: {
                    hidden: true,
                },
            },
            {
                path: ':campaignId/heatmap',
                name: 'heatmap',
                component: () => import(/* webpackChunkName: "heatmap" */ '../views/id/HeatmapPage.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                    hidden: true,
                },
            },
            {
                path: ':campaignId/edit',
                name: 'campaignEdit',
                component: () => import(/* webpackChunkName: "campaigns-edit" */ '@/modules/campaigns/views/id/edit.vue'),
            },
            {
                path: ':campaignId/booster',
                name: 'campaignBooster',
                component: () => import(/* webpackChunkName: "campaigns-booster" */ '@/modules/campaigns/views/id/booster.vue'),
                meta: {
                    layout: LayoutsEnum.vsFull,
                    hidden: true,
                },
            },
            {
                path: ':campaignId/statistics',
                name: 'campaignStatistics',
                component: () => import(/* webpackChunkName: "campaigns-statistics" */ '@/modules/campaigns/views/id/statistics.vue'),
            },
        ],
    },
    {
        path: '/ck',
        name: 'ckeditor',
        component: () => import(/* webpackChunkName: "ckeditor" */ '@/modules/campaigns/components/VsCkeditorCampaign/Index.vue'),
        meta: {
            hidden: true,
            public: true,
        },
    },
]

export default campaignsRouter
