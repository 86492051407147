
































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsDropdownButton',
    components: {
    },
})
export default class extends Vue {
    @Prop({
        required: false,
        default: '',
    }) label?: string

    @Prop({
        required: false,
        default: 'secondary',
    }) aspect?: string

    @Prop({
        required: false,
        default: '',
    }) appendTo?: string

    @Prop({
        required: false,
        default: 'icon',
    }) variant?: string

    @Prop({
        required: false,
        default: 'large',
    }) size?: string

    @Prop({
        required: false,
        default: 'more-vertical',
    }) iconName?: string

    @Prop({
        required: false,
        default: 'bottom-end',
    }) placement?: string

    @Prop({
        required: false,
        default: 'scrollParent',
    }) boundary?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) disabled?: boolean

    @Prop({
        required: false,
        default: 'left',
    }) iconPosition?: string

    @Prop({
        required: false,
        default: '160px',
    }) minWidth?: string

    @Prop({
        required: false,
        default: 'none',
    }) maxHeight?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) expand?: boolean

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) wrapping?: boolean
}
