/* eslint-disable max-len */
const login = {
    loginToYourAccount: 'Accedi al tuo account',
    emailPlaceholder: 'Inserisci il tuo indirizzo email',
    continue: 'Continua',
    reset: 'Resetta',
    needHelp: 'Hai bisogno di aiuto?',
    contactUs: 'Contattaci',
    noAccount: 'Non hai un account?',
    registerNow: 'Registrati',
    activation: 'Attivazione',
    activeAccount: 'Devi ancora attivare il tuo account tramite il link di attivazione ricevuto tramite email.',
    resendActivationLink: 'Reinviami il link di attivazione',
    loginWithOtherAccount: 'Accedi con un altro indirizzo email',
    login: 'Accedi',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'Ho dimenticato la password',
    resetPassword: 'Reimposta la tua password',
    checkInbox: 'Controlla la casella di posta!',
    passwordResetted: 'La tua password è stata modificata con successo!',
    sendEmail: 'Ti abbiamo inviato una email.',
    clickActivationLink: 'Dovrai cliccare sul link di attivazione per confermare il tuo indirizzo e {0}.',
    activateAccount: 'attivare l\'account',
    somethingWrong: 'OPS! Qualcosa è andato storto',
    noPanic: 'Niente panico.',
    warning: 'Attenzione',
    accountDisabled: 'Il tuo account è stato disabilitato per i seguenti motivi:',
    contactCommercial: 'Ti invitiamo a contattare il tuo commerciale di riferimento oppure di contattarci a questi recapiti:',
    back: 'Indietro',
    passwordForgot: 'Password dimenticata?',
    backToLogin: 'Torna al login',
    emailResetPassword: 'Ti abbiamo inviato una email per reimpostare la password del tuo account.',
    somethingWrongEditPassword: 'Qualcosa è andato storto nella modifica della password, riprova più tardi.',
    checkResetPage: {
        checkEmail: 'Ti abbiamo inviato una email di conferma per il cambio password del tuo account.',
    },
    disabledReasons: {
        PAYMENT_ISSUES: 'Motivi di pagamento',
        EXPIRED_ACCOUNT: 'Scadenza account',
        CUSTOMER_REQUEST: 'Richiesta del cliente',
        TERM_AND_CONDITION_VIOLATION: 'Violazione dei termini e condizioni di utilizzo',
        ADMINISTRATIVE_PROBLEMS: 'Disabilitato per problemi amministrativi',
        DISABLED_BY_MANAGER: 'Disabilitato dal Manager',
        FROM_PARENT: 'Manager disabilitato',
    },
}
export default {
    login,
}
