








































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsPhonePreview',
})
export default class extends Vue {
    @Prop({ required: true, default: '' }) content!: string
    @Prop({ required: true, default: 0 }) messageLength?: number
    @Prop({ required: false, default: false, type: Boolean }) full?: boolean

    get splittedMessages () {
        const reg = new RegExp(`.{1,${this.messageLength}}`, 'gs')
        return this.content.match(reg)
    }
}
