import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'
import { ScheduleCampaignDto } from './dto/campaigns.dto'

export const getCampaigns = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/campaigns`,
        {
            params,
            cancelToken,
        },
    )

export const getCampaign = (campaignId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/campaigns/${campaignId}?include=message,split_test`,
        {
            cancelToken,
        },
    )

export const createCampaign = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns`,
        data,
        {
            cancelToken,
        },
    )

export const createCampaignSplitTest = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/split-test`,
        data,
        {
            cancelToken,
        },
    )

export const getSplitTestCampaignEmails = (campaignId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/campaigns/${campaignId}/split-test-emails?include=email.footer`,
        {
            cancelToken,
        },
    )

export const addSplitTestCampaignEmail = (campaignId: any, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/split-test-emails`,
        data,
        {
            cancelToken,
        },
    )

export const deleteSplitTestCampaignEmail = (campaignId: any, emailId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/campaigns/${campaignId}/split-test-emails/${emailId}`,
        {
            cancelToken,
        },
    )

export const duplicateSplitTestCampaignEmail = (campaignId: any, emailId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/split-test-emails/${emailId}/duplicate`,
        {},
        {
            cancelToken,
        },
    )

export const updateCampaign = (campaignId: number | string, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/campaigns/${campaignId}`,
        data,
        {
            cancelToken,
        },
    )

export const getCampaignLists = (campaignId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/campaigns/${campaignId}/recipients`,
        {
            cancelToken,
        },
    )

export const deleteCampaignsBulk = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/campaigns/bulk`,
        {
            data,
            cancelToken,
        },
    )

export const deleteCampaign = (campaignId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/campaigns/${campaignId}`,
        {
            cancelToken,
        },
    )

export const setCampaignLists = (campaignId: number | string, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/recipients`,
        data,
        {
            cancelToken,
        },
    )

export const scheduleCampaign = (campaignId: number | string, data: ScheduleCampaignDto, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/schedule`,
        data,
        {
            cancelToken,
        },
    )

export const pauseCampaign = (campaignId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/pause`,
        {
            cancelToken,
        },
    )

export const abortCampaign = (campaignId: number | string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/abort`,
        {
            cancelToken,
        },
    )

export const duplicateCampaign = (campaignId: number | string, name?: string, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/duplicate`,
        {
            name,
        },
        {
            cancelToken,
        },
    )

export const createBoosterCampaign = (campaignId: number | string, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/campaigns/${campaignId}/booster`,
        data,
        {
            cancelToken,
        },
    )
