






import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsButtonActionsContainer',
})
export default class extends Vue {

}
