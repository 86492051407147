import type { IRateInterval } from '@/store/modules/user'

export const valueDurationFormat = (rate: IRateInterval) => {
    switch (rate.interval) {
        case 'days': return 'al giorno'
        case 'years': return 'all\'anno'
        case 'months': return 'al mese'
        default: return ''
    }
}
