




























import { Component, Vue, Prop } from 'vue-property-decorator'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import { AppModule } from '@/store/modules/app'

@Component({
    name: 'VsCollapseCard',
    components: {
        MyAccordion,
    },
})
export default class extends Vue {
    @Prop({ default: 'default', required: false }) cardStatus!: string
    @Prop({ default: false, required: false, type: Boolean }) show!: boolean
    @Prop({ default: false, required: false, type: Boolean }) disabled!: boolean
    @Prop({ default: false, required: false, type: Boolean }) showIcon!: boolean

    get iconColor () {
        if (this.cardStatus === 'default') return 'grey-600'
        if (this.cardStatus === 'error') return 'alert-500'
        return 'success-500'
    }

    get iconName () {
        return this.cardStatus === 'error' ? 'error' : 'success'
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }
}
