
































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import VsRouterLink from '@/components/VsRouterLink/Index.vue'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsBlockedFunctionalityModal',
    components: {
        VsRouterLink,
    },
})
export default class extends Vue {
    get accountUrl () {
        return AppModule.accountUrl
    }

    get hasShop () {
        return UserModule.user.configuration.rules.shop
    }

    mounted () {
        this.$emit('ready', this.openModal)
    }

    private show = false

    public openModal () {
        this.show = true
    }

    private goToPrices (path: string) {
        if (!this.$router) {
            window.location.href = '/user' + path
            return
        }
        this.$router.push({
            path,
        })
        this.show = false
    }
}
