import { serviceConsoleApi } from '@/utils/request'
const basePath = '/contents/emails'

export const getAttachmentsByEmailId = (emailId: number | string, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${basePath}/${emailId}/attachments`,
        {
            // signal,
        },
    )

export const uploadAttachment = (emailId: any, file: any, signal?: AbortSignal) => {
    const data = new FormData()
    data.append('attachment_file', file)
    return serviceConsoleApi.post(
        `${basePath}/${emailId}/attachments`,
        data,
        {
            // signal,
        },
    )
}

export const deleteAttachmentById = (emailId: number | string, attachmentId: number, signal?: AbortSignal) =>
    serviceConsoleApi.delete(
        `${basePath}/${emailId}/attachments/${attachmentId}`,
        {
            // signal,
        },
    )
