import VsLayouts from '@/layouts/Index.vue'

export enum developersRoutesNames {
    developers = 'developers',
    developersIndex = 'developersIndex',
    apikeyDevelopers = 'apikeyDevelopers',
    integrationsDevelopers = 'integrationsDevelopers',
    goalTrackingDevelopers = 'goalTrackingDevelopers',
}

const developersRouter = [
    {
        path: '/developers',
        name: developersRoutesNames.developers,
        component: VsLayouts,
        redirect: {
            name: developersRoutesNames.developersIndex,
        },
        children: [
            {
                path: '',
                name: developersRoutesNames.developersIndex,
                redirect: {
                    name: developersRoutesNames.apikeyDevelopers,
                },
                // meta: {
                //     showInBreadCrumbs: true,
                // },
                component: () => import(/* webpackChunkName: "developers-index" */ '../views/index.vue'),
                children: [
                    {
                        path: '',
                        name: developersRoutesNames.apikeyDevelopers,
                        component: () => import(/* webpackChunkName: "apikey-developers" */ '../views/apikey-developers.vue'),
                    },
                    {
                        path: 'integrations',
                        name: developersRoutesNames.integrationsDevelopers,
                        component: () => import(/* webpackChunkName: "integrations-developers" */ '../views/integrations-developers.vue'),
                    },
                    {
                        path: 'goal-tracking',
                        name: developersRoutesNames.goalTrackingDevelopers,
                        component: () => import(/* webpackChunkName: "goal-tracking-developers" */ '../views/goal-tracking-developers.vue'),
                    },
                ],
            },
        ],
    },
]

export default developersRouter
