





















































import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsEmailPlainContentModal',
})
export default class extends Vue {
    private id = ''
    private plainContent = ''
    private open = false
    public loading = false
    $refs: any

    public async save () {
        const valid = await this.$refs.plainContentForm.validate()
        if (!valid) return
        this.$emit('updated', this.plainContent)
    }

    public openModal (plainContent: string) {
        this.loading = false
        this.plainContent = plainContent
        this.open = true
        setTimeout(() => {
            this.$refs.nameInput.$children[0].$refs.input.focus()
        }, 200)
    }

    public closeModal () {
        this.open = false
    }
}
