
















import { Component, Vue } from 'vue-property-decorator'
import VsTopNav from '@/components/VsTopNav/Index.vue'
import VsSideBarManager from '@/components/VsSideBarManager/Index.vue'

@Component({
    name: 'VsSidebarManagerLayout',
    components: {
        VsTopNav,
        VsSideBarManager,
    },
})
export default class extends Vue {
}
