import { RouteConfig } from 'vue-router'

import VsLayouts from '@/layouts/Index.vue'
import { LayoutsEnum } from '@/layouts/types'
import { UserModule } from '@/store/modules/user'

const managerRouter: RouteConfig[] = [
    {
        path: '/manager',
        redirect: { name: 'dashboardManager' },
        name: 'manager',
        component: VsLayouts,
        beforeEnter: async (to, from, next) => {
            if (!UserModule?.user?.configuration?.rules?.manager) {
                next({ name: 'dashboard' })
                return
            }
            next()
        },
        children: [
            {
                path: 'dashboard',
                name: 'dashboardManager',
                component: () => import(/* webpackChunkName: "dashboard-manager" */ '../views/dashboard.vue'),
                meta: {
                    layout: LayoutsEnum.vsSidebarManager,
                },
            },
            {
                path: 'childs',
                name: 'managerIndex',
                component: () => import(/* webpackChunkName: "manager-index" */ '../views/index.vue'),
                meta: {
                    layout: LayoutsEnum.vsSidebarManager,
                },
            },
            {
                path: 'childs/:childId',
                name: 'managerChild',
                component: () => import(/* webpackChunkName: "manager-child" */ '../views/childId/index.vue'),
                meta: {
                    layout: LayoutsEnum.vsSidebarManager,
                },
            },
            {
                path: 'options',
                redirect: { name: 'managerNotifications' },
                name: 'managerOptions',
            },
            {
                path: 'options/notifications',
                name: 'managerNotifications',
                component: () => import(/* webpackChunkName: "manager-notifications" */ '../views/options/managerNotifications.vue'),
                meta: {
                    layout: LayoutsEnum.vsSidebarManager,
                },
            },
            {
                path: 'emailtemplates',
                name: 'managerEmailEditorIndex',
                component: () => import(/* webpackChunkName: "manager-email-editor" */ '@/modules/emailEditor/views/index.vue'),
                meta: {
                    layout: LayoutsEnum.vsSidebarManager,
                },
            },
            {
                path: 'emailtemplates/:templateId/edit',
                name: 'managerTemplateEdit',
                component: () => import(/* webpackChunkName: "manager-template-edit" */ '@/modules/emailEditor/views/id/edit.vue'),
                meta: {
                    layout: LayoutsEnum.vsSidebarManager,
                },
            },
        ],
    },
]

export default managerRouter
