








































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { createSenderSms } from '@/api/consoleApi/senders/sms'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import { smsSenderCountries } from '@/utils/constants'
import { get } from 'lodash'

@Component({
    name: 'VsCreateSmsSender',
    components: {
        ValidationProvider,
        VsSeparator,
        ValidationObserver,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private senderData: any = {
        alias: '',
        company: '',
        vat: '',
        fiscal_code: '',
        address: '',
        city: '',
        province: '',
        country: 'IT',
        phone_number: '',
        email: '',
        pec: '',
        cap: '',
    }

    $refs: any

    get smsSenderCountries () {
        return smsSenderCountries
    }

    get validationForVat () {
        if (this.senderData.country === 'IT') {
            return {
                required: true,
                numeric: true,
                min: 9,
                max: 11,
            }
        }
        return { required: true }
    }

    mounted () {
        this.$emit('ready', this)
    }

    public async createSender () {
        const validation = await this.$refs.createSmsSenderForm.validate()
        if (!validation) return
        this.loading = true
        try {
            await createSenderSms(this.senderData)

            this.$root.$vsToast({
                heading: this.$t(`campaigns.createSmsSender.senderCreated`),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })

            this.$emit('sender-created', this.senderData)
            this.show = false
        } catch (e) {
            const message = this.evalutateErrorsAndGetResponse(e)
            this.$root.$vsToast({
                heading: 'Errore durante la creazione del mittente',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private evalutateErrorsAndGetResponse (e: any) {
        const status = get(e, 'response.status', 0)

        if (status === 403) {
            return 'Hai superato il limite di mittenti creabili'
        }

        if (status === 400) {
            if (e.response?.data?.message?.alias) {
                if (e.response.data.message.alias[0].includes('The alias has already been taken.')) {
                    this.$refs.createSmsSenderForm.setErrors({
                        alias: ['Questo alias è già stato utilizzato'],
                    })
                    return
                }
                this.$refs.createSmsSenderForm.setErrors({
                    alias: [this.$t('campaigns.createSmsSender.errors.invalidAlias')],
                })
            }
        }
        if (status === 406) {
            if (e.response.data.error && e.response.data.error === 'AliasMustBeSameOfPhoneNumber') {
                this.$refs.createSmsSenderForm.setErrors({
                    alias: ['L\'alias deve essere uguale al telefono cellulare'],
                })
            }
        }
    }

    private getMappedFieldByError (error: any) {
        const maps = {
            invalidAlias: 'alias',
            invalidBusinessName: 'company',
            invalidVatNumber: 'vat',
            invalidAddress: 'address',
            invalidCity: 'city',
            invalidPostCode: 'cap',
            invalidProvince: 'province',
            invalidCountry: 'country',
            invalidEmail: 'email',
            invalidPhone: 'phoneNumber',
            invalidTaxCode: 'fiscalCode',
            invalidPec: 'pec',
        }

        return get(maps, error)
    }

    public openModal () {
        this.loading = false
        this.show = true
        this.senderData = {
            alias: '',
            company: '',
            vat: '',
            fiscal_code: '',
            address: '',
            city: '',
            province: '',
            country: 'IT',
            phone_number: '',
            email: '',
            pec: '',
            cap: '',
        }
    }
}
