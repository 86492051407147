import { RouteConfig } from 'vue-router'
import VsLayouts from '@/layouts/Index.vue'
import { UserModule } from '@/store/modules/user'
import { getShopUser } from '@/api/shop'

const accountRouter: RouteConfig[] = [
    {
        path: '/account',
        name: 'account',
        component: VsLayouts,
        redirect: {
            name: 'accountIndex',
        },
        children: [
            {
                path: '',
                name: 'accountIndex',
                redirect: {
                    name: 'myAccount',
                },
                component: () => import(/* webpackChunkName: "account-index" */ '@/modules/account/views/account/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'myAccount',
                        component: () => import(/* webpackChunkName: "my-account" */ '@/modules/account/views/account/my-account.vue'),
                    },
                    {
                        path: 'security',
                        name: 'security',
                        component: () => import(/* webpackChunkName: "account-security" */ '@/modules/account/views/account/security.vue'),
                    },
                    {
                        path: 'consents',
                        name: 'consents',
                        component: () => import(/* webpackChunkName: "account-consents" */ '@/modules/account/views/account/consents.vue'),
                    },
                    // {
                    //     path: 'communication',
                    //     name: 'communication',
                    //     component: () => import(/* webpackChunkName: "account-communication" */ '@/modules/account/views/account/communication.vue'),
                    //     meta: {
                    //         integrationPermissions: ['route-account-communication'],
                    //     },
                    // },
                ],
            },
        ],
    },
    {
        path: '/account/prezzi',
        redirect: {
            name: 'prices',
        },
    },
    {
        path: '/plan',
        name: 'plan',
        component: VsLayouts,
        redirect: {
            name: 'planIndex',
        },
        beforeEnter: async (_to: any, _from: any, next: any) => {
            if (!UserModule.shopUser) {
                try {
                    const resp = await getShopUser()
                    UserModule.SET_SHOP_USER(resp.data)
                } catch (e) {
                    console.log(e)
                    UserModule.SET_SHOP_USER(null)
                }
            }
            next()
        },
        children: [
            {
                path: '',
                name: 'planIndex',
                redirect: {
                    name: 'myPlan',
                },
                component: () => import(/* webpackChunkName: "plan-index" */ '@/modules/account/views/plan/index.vue'),
                children: [
                    {
                        path: '',
                        name: 'myPlan',
                        component: () => import(/* webpackChunkName: "my-plan" */ '@/modules/account/views/plan/my-plan.vue'),
                    },
                    {
                        path: 'invoice-data',
                        name: 'invoiceData',
                        component: () => import(/* webpackChunkName: "invoice-data" */ '@/modules/account/views/plan/invoice-data.vue'),
                        meta: {
                            integrationPermissions: ['invoice-data'],
                        },
                    },
                ],
            },
        ],
    },
]

export default accountRouter
