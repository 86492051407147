
















































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getSendersSms } from '@/api/consoleApi/senders/sms'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import VsCreateSmsSender from '@/modules/campaigns/components/VsCreateSmsSender/Index.vue'
import VsConfirmSmsSender from '@/modules/campaigns/components/VsConfirmSmsSender/Index.vue'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import { IMessageSms } from '@/api/consoleApi/dto/campaigns.dto'
import { updateSms } from '@/api/consoleApi/contents/sms'
import { AppModule } from '@/store/modules/app'
import { SenderSmsStatusEnum } from '@/api/consoleApi/dto/senders.dto'

@Component({
    name: 'VsSmsSenderCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsInlineErrorsCheck,
        VsCreateSmsSender,
        VsConfirmSmsSender,
        VsHintCard,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) message!: IMessageSms
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    @Prop({ required: false, default: false, type: Boolean }) emitWithoutSave!: boolean
    private loading = false
    private senders: any[] = []

    private tempSenderSms = ''

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get tempSelectedSender () {
        if (!this.senders || this.senders.length === 0) return null
        return this.senders.find(el => el.alias === this.tempSenderSms)
    }

    get selectedSender () {
        if (!this.senders || this.senders.length === 0) return null
        return this.senders.find(el => el.alias === this.message.from)
    }

    get senderEnabledStatus () {
        if (!this.tempSelectedSender) return 'default'
        return this.tempSelectedSender.status === 'Enabled' ? 'success' : 'error'
    }

    get senderConfirmed () {
        if (!this.tempSelectedSender) return 'default'
        return this.tempSelectedSender.confirmed ? 'success' : 'error'
    }

    get totalSenderStatus () {
        if (
            this.senderEnabledStatus === 'error' || this.senderConfirmed === 'error'
        ) return 'alert'
        return 'success'
    }

    $refs: any

    openTab () {
        this.$emit('open-tab', 'sender')
    }

    beforeMount () {
        this.getSenders()
    }

    get sender () {
        return this.message.from
    }

    get senderCardStatus () {
        if (!this.message.from) return 'default'
        return this.selectedSender && this.selectedSender.status === 'Enabled' && this.selectedSender.confirmed ? 'success' : 'error'
    }

    private async getSenders () {
        this.loading = true
        try {
            const resp = await getSendersSms({ limit: 100 })
            this.senders = resp.data.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    private async senderCreated (sender: any) {
        await this.getSenders()
        this.tempSenderSms = sender.alias
    }

    private openCreateSenderModal (sender?: any) {
        this.$refs.createSender.openModal(sender)
    }

    private openTabSender () {
        this.tempSenderSms = this.message.from
        this.openTab()
    }

    private async saveSender () {
        if (this.emitWithoutSave) {
            this.$emit('sender-saved', this.tempSenderSms)
            this.$emit('open-tab', '')
            return
        }
        try {
            if (!this.tempSelectedSender) throw new Error('SenderNotFound')
            const isValid = await this.$refs.senderForm.validate()
            if (!isValid) {
                this.$root.$vsToast({
                    timeout: 3000,
                    heading: this.$t('campaigns.editCampaign.sections.senderSms.errors.saveError'),
                    aspect: VsToastAspectEnum.alert,
                })
                return
            }
            await updateSms(
                this.message.id,
                {
                    sender_id: this.tempSelectedSender.id,
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.senderSms.saveSuccess'),
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('sender-saved')
            this.$emit('open-tab', '')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.senderSms.errors.saveError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async getSendersAndVerifyActive () {
        await this.getSenders()
        if (this.tempSelectedSender.status !== 'Enabled') {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.senderSms.errors.notVerify'),
                message: this.$t('campaigns.editCampaign.sections.senderSms.errors.controllInbox'),
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    get badgeStatusAspect () {
        if (!this.selectedSender || !this.selectedSender?.status) return ''
        switch (this.selectedSender?.status || null) {
            case SenderSmsStatusEnum.Approval:
                return 'info'
            case SenderSmsStatusEnum.Disabled:
                return 'alert'
            case SenderSmsStatusEnum.Enabled:
                return 'success'
            default:
                return 'grey'
        }
    }

    get badgeStatusIcon () {
        if (!this.selectedSender || !this.selectedSender?.status) return ''
        switch (this.selectedSender?.status || null) {
            case SenderSmsStatusEnum.Approval:
                return 'clock'
            case SenderSmsStatusEnum.Disabled:
                return 'error'
            case SenderSmsStatusEnum.Enabled:
                return 'double-check'
        }
    }
}
