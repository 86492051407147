


















































import { createTag, updateTag } from '@/api/consoleApi/recipients'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsTagNameModal',
})
export default class extends Vue {
    @Prop({ required: true, default: '' }) listId!: string
    private id = ''
    private name = ''
    private open = false
    public loading = false
    $refs: any

    public async save () {
        const valid = await this.$refs.nameForm.validate()
        if (!valid) return
        if (this.id) {
            this.updateTag({
                id: this.id,
                name: this.name,
            })
        } else {
            this.createTag({
                name: this.name,
            })
        }
    }

    async updateTag (e: any) {
        this.loading = true
        try {
            const data = {
                name: e.name,
            }
            await updateTag(this.listId, e.id, data)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Etichetta modificata con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('updated')
            this.closeModal()
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Qualcosa è andato storto',
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async createTag (e: any) {
        this.loading = true
        try {
            const data = {
                name: e.name,
            }
            await createTag(this.listId, data)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Etichetta creato con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('created')
            this.closeModal()
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Qualcosa è andato storto',
                aspect: VsToastAspectEnum.alert,
            })
            this.$refs.tagNameModal.loading = false
            console.log(e)
        }
        this.loading = false
    }

    public openModal (name?: string, id?: any) {
        this.loading = false
        this.name = name || ''
        this.id = id || ''
        this.open = true
        setTimeout(() => {
            this.$refs.nameInput.$children[1].$refs.input.focus()
        }, 200)
    }

    public closeModal () {
        this.open = false
    }
}
