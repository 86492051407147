








































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsSidenavMenuItem from './VsSidenavMenuItem.vue'
import VsSidenavMenuTitle from './VsSidenavMenuTitle.vue'
import VsPercentageBar from './../VsPercentageBar/Index.vue'
import { MediaLibraryModule, IUserInfo } from '@/store/modules/mediaLibrary'

@Component({
    name: 'VsSidenav',
    components: {
        VsSidenavMenuItem,
        VsSidenavMenuTitle,
        VsPercentageBar,
    },
})
export default class extends Vue {
    $options: any
    @Prop({ default: false }) open!: boolean

    get vsSidenavClasses () {
        return {
            'vs-bg-grey-900': true,
            open: this.open,
        }
    }

    get userInfo (): IUserInfo {
        return MediaLibraryModule.userInfo
    }

    get totalSpaceAvailable (): number {
        return this.userInfo.user.rules.limit.value
    }

    get totalSpaceAvailableUnit (): string {
        return this.userInfo.user.rules.limit.unit
    }

    get totalSpaceUsed (): number {
        return (this.notTrashedSize + this.trashedSize)
    }

    get notTrashedSize (): number {
        return parseInt(this.userInfo.files.notTrashedSize)
    }

    get trashedSize (): number {
        return parseInt(this.userInfo.files.trashedSize)
    }

    get spacePercentages (): any[] {
        return [
            {
                partialValue: this.notTrashedSize ? this.$options.filters.calculateBytes(this.notTrashedSize, 3, this.totalSpaceAvailableUnit) : 0,
            },
            {
                partialValue: this.trashedSize ? this.$options.filters.calculateBytes(this.trashedSize, 3, this.totalSpaceAvailableUnit) : 0,
            },
        ]
    }

    get currentPage () {
        return MediaLibraryModule.currentPage
    }

    set currentPage (val: string) {
        MediaLibraryModule.SET_CURRENT_PAGE(val)
    }

    get textTip () {
        return `${this.$options.filters.formatBytes(this.totalSpaceUsed, 2, 'MB')} occupati di cui ${this.$options.filters.formatBytes(this.trashedSize, 2, 'MB')} nel cestino`
    }

    private changePage (pageName: string) {
        this.currentPage = pageName
    }
}
