




















import { Component, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsFullTopBarLayout',
})
export default class extends Vue {
}
