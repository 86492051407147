import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const createAddressbooks = (data: { email_address: string }, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/addressbooks`,
        data,
        {
            cancelToken,
        },
    )

export const getAddressbooks = (cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/addressbooks`,
        {
            cancelToken,
        },
    )

export const deleteAddressbooks = (addressbookId: string, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/addressbooks/${addressbookId}`,
        {
            cancelToken,
        },
    )
