

































































import { Component, Vue } from 'vue-property-decorator'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { activateSenderDkim, getSelectorDkim, verifySenderDkim } from '@/api/consoleApi/senders/emails'

@Component({
    name: 'VsDkimModal',
})
export default class extends Vue {
    private open = false
    private loading = false
    private verifyLoading = false
    private sender: any = null
    selector = ''
    step = 1
    $refs: any

    get code () {
        if (!this.selector) return ''
        return `
${this.selector}._domainkey.${this.domainFromSender}. CNAME ${this.selector}._domainkey.${window.location.host}.`
    }

    get domainFromSender () {
        if (!this.sender) return ''
        return this.sender.email.replace(/.*@/, '')
    }

    public async openModal (sender: any) {
        this.sender = sender
        this.step = 1
        this.open = true
        this.getSelector()
    }

    public closeModal () {
        this.open = false
    }

    async getSelector () {
        try {
            const resp = await getSelectorDkim()
            this.selector = resp.data.data.selector
        } catch (e) {
            console.log(e)
        }
    }

    async verifySenderDkim () {
        this.verifyLoading = true
        try {
            await verifySenderDkim(this.sender.id)
            this.step = 2
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la verifica.',
                timeout: 5000,
                message: 'Il dominio del mittente non risulta ancora registrato nei dns',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.verifyLoading = false
    }

    async activateSenderDkim () {
        this.verifyLoading = true
        try {
            await activateSenderDkim(this.sender.id)
            this.$root.$vsToast({
                heading: 'Congratulazioni!',
                message: 'Hai attivato con successo la DKIM sul mittente.',
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('dkim-activated')
            this.closeModal()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'attivazione.',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.verifyLoading = false
    }
}
