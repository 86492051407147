

















import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
@Component({
    name: 'MainFooter',
})
export default class extends Vue {
    get privacyUrl () {
        const siteUrl = process.env.VUE_APP_SITE_URL ? process.env.VUE_APP_SITE_URL : 'https://www.4dem.it'
        return siteUrl + '/informativa-privacy'
    }

    get showFooter () {
        return AppModule.footer
    }
}
