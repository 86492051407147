import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/store'
import { CustomerDto } from '@/api/types/customer'
import mixpanel from '@/utils/mixpanel'
import usercom from '@/utils/usercom'
import {
    getLayoutSettings,
} from '@/api/consoleApi/user'
import { AppModule } from './app'
import { OverridedMixpanel } from 'mixpanel-browser'
import { get } from 'lodash'
import { formatPriceName, getUserPlan } from '@/utils/shop'
import { valueDurationFormat } from '@/utils/products'
import { ShopUser } from '@/api/shop/types/shop'
import { userStatusEnum } from '@/utils/users'

export enum ExpiredPolicyEnum {
    startAt = 'start_at',
    emailRechargeAt = 'email_recharge_at',
    lastLogin = 'last_login',
    fromParent = 'from_parent',
    never = 'never',
}

export enum SupportEnum {
    none = 'none',
    helpscout = 'helpscout',
    email = 'email',
    helpscoutChat = 'helpscout_chat',
}

export enum RateIntervalEnum {
    days = 'days',
    months = 'months',
    years = 'years',
}

export interface IRateInterval {
    interval: RateIntervalEnum
    value: number
}

export interface IRule {
    default: any
    active: boolean
    value_type: string // enum
    value: any
    _id: string
    name: string
}

export interface IRuleV2 {
    backupData: IRateInterval
    expiredCredits: IRateInterval
    expiredPlan: IRateInterval
    landingpage: {
        export: boolean
        pagesLimit: number
        pagesPublic: number
        statisticsFull: boolean
        subdomain: boolean
        templatesFull: boolean
    }
    managerRules: {
        accountStarter: number
        accountExpert: number
        eplusLicense: number
        eplusProducts: number
        ruleEditor: string[]
        templatesEditor: boolean
        webAppPermissions: string[]
    }
    abTest: boolean
    archive: boolean
    attachmentsBytes: number
    automation: number
    automationStatisticFull: boolean
    automationTriggerAvailable: string[]
    behavioralTag: boolean
    booster: boolean
    campaignComparison: boolean
    campaignStatisticFull: boolean
    crmRecipientEnabled: boolean
    customFieldsLocal: number
    embeddedImage: boolean
    emailRecursivity: {
        rate: IRateInterval
        value: number
    } | null
    emailSentReport: boolean
    emailUnlimited: boolean
    eplusLicense: number
    eplusProductsLimit: number
    expiredPolicy: ExpiredPolicyEnum
    exportHtml: boolean
    goalTracking: boolean
    leadplusForms: number
    listStatisticFull: boolean
    manager: boolean
    maxFavoriteContacts: number
    mediaLibraryMegaBytes: number
    noLogo: boolean
    recipientLimit: number
    reportExport: boolean
    reputationFull: boolean
    segments: boolean
    sendListMultiple: boolean
    shareRecipients: boolean
    shop: boolean
    subscribersExport: boolean
    subscribersLimit: number
    support: SupportEnum
    tag: boolean
    templateEmail: string[]
    templatesEditor: boolean
    suppressionFull: boolean
}

export interface IConfiguration {
    _id: string
    userId: string
    rules: IRuleV2
    rulesV2: IRuleV2
    name: string
    createdAt: string
    updatedAt: string
}
export interface IUser {
    id: string
    recharges: {
        email: {
            rechargeAt: string | null
            expiredAt: string | null
        }
        sms: {
            rechargeAt: string | null
            expiredAt: string | null
        }
        transactional: {
            rechargeAt: string | null
            expiredAt: string | null
        }
    }
    automaticFlows: {
        reminder: {
            active: boolean
            notification: boolean
        }
        reminderPendingEnabled: {
            active: boolean
            notification: boolean
        }
        delete: {
            active: boolean
            notification: boolean
        }
        disabled: {
            active: boolean
            notification: boolean
        }
        deleteCredits: {
            active: boolean
            notification: boolean
        }
        legacyRenew: {
            active: boolean
            notification: boolean
        }
        active: boolean
    }
    parent: {
        id: string
        relationType: string
        authorized: boolean
    }
    passwordModifiedAt: string
    firstname: string
    lastname: string
    email: string
    phone: string
    website: string
    company: string
    city: string
    disabledReason: string | null // enum o null
    trusted: boolean
    status: userStatusEnum // enum
    impersonate?: string
    changeStatusAt: string
    configurationId: string
    configuration: IConfiguration
    integrationId: string
    lastLogin: string
    activationStepCompleted: number
    twoFactorAuthEnabled: boolean
    privacyAcceptedAt: string| null
    startAtLegacy: string | null
    endAt: string | null
    _id: string
    createdAt: string
    updatedAt: string
}

export interface IUserState {
    user: IUser
    shopUser: ShopUser | null
    isLegacyUser: boolean | null
    shopToken: string
    gamificationToken: string
    filemanagerToken: string
    leadplusToken: string
    landingpageToken: string
    isLoggedIn: boolean
    userCredits: any[]
    settings: any
    Mixpanel?: OverridedMixpanel
}
export interface IConsoleTheme {
    colorConsole: string
    logoConsole: string
    logoFavicon: string
    logoLogin: string
}
export interface IUserSettings {
    theme: IConsoleTheme
    beacon: any
}

@Module({ dynamic: true, store, name: 'user', namespaced: true })
class User extends VuexModule implements IUserState {
    public user: IUser = null as any
    public shopUser: ShopUser | null = null
    public isLegacyUser: boolean | null = null
    public customer: CustomerDto | null = null
    public shopToken = ''
    public filemanagerToken = ''
    public gamificationToken = ''
    public landingpageToken = ''
    public leadplusToken = ''
    public isLoggedIn = false
    public settings: IUserSettings | null = null
    public userCredits: any[] = []
    public Mixpanel?: OverridedMixpanel
    public UserCom?: any;

    get userCompleteName () {
        return (truncate?: boolean) => {
            let first = get(this.user, 'profile.accounts_data.main.firstname', '')
            if (!first) first = get(this.user, 'firstname', '')
            let last = get(this.user, 'profile.accounts_data.main.lastname', '')
            if (!last) last = get(this.user, 'lastname', '')
            if (!last && !first) return get(this.user, 'email', '')
            return `${first} ${last && truncate ? last[0] + '.' : last || ''}`
        }
    }

    // PLAN NAME FROM SHOP WITHOUT CREDITS
    get shopUserPlanName () {
        if (!this.shopUser) return ''
        const plan = getUserPlan(this.shopUser)
        return formatPriceName(plan.price)
    }

    get userPlanEmailCredits () {
        return this.user?.configuration.rules.emailRecursivity
    }

    get userPlanEmailCreditsRate () {
        if (!this.user?.configuration.rules.emailRecursivity) return ''
        return valueDurationFormat(this.user?.configuration.rules.emailRecursivity.rate)
    }

    get consoleUserId () {
        return get(this.settings, 'consoleUserId', '')
    }

    @Mutation
    public SET_USER (user: IUser) {
        // TODO: eliminare questa riga quando le rules avranno l'interfaccia delle rulesV2 anche a backend
        user.configuration.rules = user.configuration.rulesV2 || user.configuration.rules
        this.user = user
    }

    @Mutation
    public SET_SHOP_USER (shopUser: any) {
        this.shopUser = shopUser
    }

    @Mutation
    public SET_IS_LEGACY_USER (isLegacyUser: boolean) {
        this.isLegacyUser = isLegacyUser
    }

    @Mutation
    public SET_CUSTOMER (customer: CustomerDto) {
        this.customer = customer
    }

    @Mutation
    public SET_SHOP_TOKEN (token: string) {
        this.shopToken = token
    }

    @Mutation
    public SET_GAMIFICATION_TOKEN (token: string) {
        this.gamificationToken = token
    }

    @Mutation
    public SET_FILEMANAGER_TOKEN (token: string) {
        this.filemanagerToken = token
    }

    @Mutation
    public SET_LANDINGPAGE_TOKEN (token: string) {
        this.landingpageToken = token
    }

    @Mutation
    public SET_LEADPLUS_TOKEN (token: string) {
        this.leadplusToken = token
    }

    @Mutation
    private SET_IS_LOGGED_IN (isLoggedIn: boolean) {
        this.isLoggedIn = isLoggedIn
    }

    @Mutation
    public SET_USER_CREDITS (userCredits: any) {
        this.userCredits = userCredits
    }

    @Mutation
    public SET_SETTINGS (settings: IUserSettings | null) {
        this.settings = settings
    }

    @Action({ rawError: true })
    public loginUser (data: {user: IUser, shopUser: any, isLegacyUser: boolean}) {
        this.SET_SHOP_USER(data.shopUser)
        this.SET_IS_LEGACY_USER(data.isLegacyUser)
        this.SET_USER(data.user)
        this.SET_IS_LOGGED_IN(true)
        if (data.user.configuration.rules.shop) {
            AppModule.ADD_ROUTE('route-shop')
            AppModule.ADD_ROUTE('route-prices')
            AppModule.ADD_ROUTE('invoice-data')
            AppModule.ADD_ROUTE('route-payments')
        } else {
            AppModule.REMOVE_ROUTE('route-shop')
            AppModule.REMOVE_ROUTE('route-prices')
            AppModule.REMOVE_ROUTE('invoice-data')
            AppModule.REMOVE_ROUTE('route-payments')
        }
    }

    @Action({ rawError: true })
    public logoutUser () {
        if (window.Beacon) {
            window.Beacon('logout', { endActiveChat: true })
        }
        this.SET_IS_LOGGED_IN(false)
        this.SET_USER(null as any)
        this.SET_SHOP_USER(null as any)
        this.SET_IS_LEGACY_USER(null as any)
        this.SET_SETTINGS(null)
    }

    @Action({ rawError: true })
    public async loadTrackingTools (appConfig: any) {
        console.log('...loading tracking tools')
        try {
            this.Mixpanel = mixpanel.init(this.user, appConfig)
        } catch (e) {
            console.log(e, 'error init mixpanel')
        }
        this.UserCom = usercom.init(this.user, appConfig)
    }

    @Action({ rawError: true })
    public async setUserSettings (appConfig: any) {
        if (!this.settings) {
            await this.loadTrackingTools(appConfig)
            try {
                const res = await getLayoutSettings()
                this.SET_SETTINGS(res.data.data)
            } catch (e) {
                console.log(e)
            }
        }
    }
}

export const UserModule = getModule(User)
